// import { makeAutoObservable } from "mobx";
import { makeAutoObservable, makeObservable } from "mobx";
import DisposerManager from "../utils/DisposerManager";
import { makeSimpleAutoObservable } from "../utils/makeSimpleAutoObservable";
import EventDispatcher from "./EventDispatcher"
import { LooseObject } from "./Types";


/**
 * @class Store
 * @description
 * The Store class is the base class for all stores in the application. 
 * Once `init()` is called a Store then keeps track of its own state using MobX. 
 * Properties can then be observed for changes.
 */
export default class Store extends EventDispatcher {
    disposer: DisposerManager;
    constructor(){
        super();
        this.disposer = new DisposerManager();
    }

    /**
     * The init method is used to initialize the state management for a store.
     * 
     * If you wish a property to not be tracked by state management, you can provide an object that contains the properties to exclude.
     * 
     * @example
     * ```
     * this.init({
     *   myExcludedProperty: false,
     *   myOtherExcludedProperty: false
     * });
     * ```
     * 
     * @param {LooseObject} overrides - An object containing properties to override the default state management behavior.
     * */
    init = (overrides:LooseObject = {}) => {
        overrides.childClass = false;
        makeSimpleAutoObservable(this, overrides);
    }

    initStateManagement = (exclude: string[] = []) => {
        makeSimpleAutoObservable(this, exclude);
    }
}