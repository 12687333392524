import { Button, message as AlertTriggerer } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import DocumentStore from 'stores/DocumentStore';
import React from 'react';

export const REMOVE_MEMBER_BUTTON_LABEL = 'Remove';

export interface RemoveMemberButtonProps {
  memberId: string;
  parentDocument: DocumentStore;
}

class RemoveMemberButton extends React.Component<RemoveMemberButtonProps>{
  isLoading: boolean = false;

  constructor(props: RemoveMemberButtonProps) {
    super(props);

    makeObservable(this, {
      isLoading: observable,
    });
  }

  turnOnLoader = () => {
    this.isLoading = true;
  }

  turnOffLoader = () => {
    this.isLoading = false;
  }

  handleOnRemove = async () => {
    const { parentDocument } = this.props;
    const { turnOffLoader, turnOnLoader } = this;

    try {
      turnOnLoader();
      const { memberId } = this.props;
      await parentDocument.members.removeMember(memberId);
    }
    catch (error) {
      AlertTriggerer.error((<span>Something went wrong <b>removing the user</b>. Please try again...</span>));
    }
    finally {
      turnOffLoader();
    }
  }

  render() {
    const { parentDocument } = this.props;
    const { canModify: editionEnabled } = parentDocument;
    const { handleOnRemove } = this;
    const { isLoading } = this;

    return (
      <Button disabled={!editionEnabled} loading={isLoading} onClick={handleOnRemove}>
        {REMOVE_MEMBER_BUTTON_LABEL}
      </Button>
    );
  }
}

export default observer(RemoveMemberButton);
