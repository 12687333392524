import { Breadcrumb, BreadcrumbContainer, Container, } from './NavBreadcrumb.styles';
import { documentPathToParams } from 'utils';
import { EditDocumentButton, Link } from 'components/UI';
import { observer } from 'mobx-react';
// import { Breadcrumb as BreadcrumbType, DocumentType } from 'stores/Types';
import React from 'react';
import store from 'stores';
import { reaction } from 'mobx';
import type BreadcrumbManager from 'stores/BreadcrumbManager';
import type BreadcrumbType from 'stores/Breadcrumb';

export interface NavBreadcrumbProps {
  breadcrumbs: BreadcrumbManager;
  className?: string;
}

function renderBreadcrumb(breadcrumb: BreadcrumbType) {

  if (breadcrumb.url === '') {
    return <span>{breadcrumb.name}</span>;
  } else if(store.user.authenticated === false){
    return <span>{breadcrumb.name}</span>;
  } else {
    return (
      <Link
        document={breadcrumb}
        url={breadcrumb.url}
      >
        {breadcrumb.name}
      </Link>
    );
  }
}

class NavBreadcrumb extends React.Component<NavBreadcrumbProps> {
  constructor(props: NavBreadcrumbProps) {
    super(props);
  }

  render() {
    const { isMobile } = store.ui;
    const { breadcrumbs } = this.props;

    if(!breadcrumbs) {
      return null;
    }

    return (
      <Container {... this.props}>
        <BreadcrumbContainer>
          <Breadcrumb>
            {breadcrumbs.all.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index}>
                {renderBreadcrumb(breadcrumb)}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </BreadcrumbContainer>
      </Container>
    )
  }
}

export default observer(NavBreadcrumb);
