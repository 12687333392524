import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  font-size: 18px;
  gap: 16px;
  height: 150px;
  justify-content: center;
  padding-bottom: 10px;
`;

export const AddButton = styled(Button)`
  width: fit-content;
`;
