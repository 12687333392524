import MainContent from 'components/MainContent';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  
  p {
    margin-top: 10px;
  }
`;

class MobileLayout extends React.Component {
  constructor(props: {}) {
    super(props);
  }

  render() {
    return (
      <Container>
        <MainContent />
      </Container>
    );
  }
};

export default MobileLayout;
