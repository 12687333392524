import {
  buildColumn,
  TableColumnSortType,
} from 'components/UI/Table/Table/ColumnBuilder';
import { basicTimestampDateRenderer } from 'components/UI/Table/Table/ColumnBuilder/ColumnBuilder.service';
import { StarSwitch } from 'components/UI';
import { Table } from 'components/UI/Table';
import { Tag } from 'antd';
import DocumentFolder from 'stores/DocumentFolder';
import React from 'react';
import { subscribe } from 'stores/SubscribeUtil';

export interface StarredTableProps {
  items: DocumentFolder<any, any, any>[];
  onItemClick?: (item: DocumentFolder) => void;
  title: string;
}

class StarredTable<T> extends React.Component<StarredTableProps>{
  constructor(props: StarredTableProps) {
    super(props);
  }

  getTableColumns() {
    return [
      buildColumn<DocumentFolder>({
        sourceData: (rowData) => rowData.isStarred,
        render: (rowData) => <StarSwitch store={rowData} />,
        sortType: TableColumnSortType.Alphabetical,
        title: 'Starred',
        width: 32,
      }),
      buildColumn<DocumentFolder>({
        sourceData: (rowData) => rowData.data.name,
        sortType: TableColumnSortType.Alphabetical,
        title: 'Name',
        width: '15%',
      }),
      buildColumn<DocumentFolder>({
        render: (rowData) => (rowData.data.tags || []).map((tag: string) => <Tag key={`tag.${tag}`}>{tag}</Tag>),
        sortType: TableColumnSortType.NoSort,
        title: 'Tags',
      }),
      buildColumn<DocumentFolder>({
        defaultSortOrder: 'descend',
        sourceData: (rowData) => rowData.data.dateModified,
        render: basicTimestampDateRenderer,
        sortType: TableColumnSortType.FirestampDate,
        title: 'Modified',
        width: '25%',
      }),
    ];
  };

  render() {
    const { items, onItemClick, title } = this.props;
    const columns = this.getTableColumns();

    return (
      <Table
        columnsDefinition={columns}
        data={items}
        headerButtons={[]}
        onRowClick={(record) => onItemClick?.(record)}
        onRowMiddleClick={(record) => window.open(record.url, '_blank')}
        title={title}
      />
    );
  }
}

export default StarredTable;
