import { BasicVariantContainer, FlatVariantContainer } from "./TableWrapper.styles";
import { ConfigProvider } from "antd";
import { EmptyContentBoxProps } from "components/UI/EmptyContentBox/EmptyContentBox";
import { WithClassName } from "utils/Types/common";
import EmptyContentBox from "components/UI/EmptyContentBox";
import React, { PropsWithChildren } from "react";


export enum TableVariants {
  BASIC = 'BASIC',
  FLAT = 'FLAT',
}

export type TableWrapperProps = EmptyContentBoxProps & WithClassName & {
  variant?: TableVariants;
};

class TableWrapper extends React.Component<PropsWithChildren<TableWrapperProps>>{
  constructor(props: TableWrapperProps) {
    super(props);
  }

  render() {
    const { children, className, variant, ...emptyBoxProps } = this.props;

    const Container = (() => {
      if (variant === TableVariants.BASIC) {
        return BasicVariantContainer;
      }

      if (variant == TableVariants.FLAT) {
        return FlatVariantContainer;
      }

      return BasicVariantContainer;
    })();

    return (
      <ConfigProvider
        renderEmpty={() => (
          <EmptyContentBox
            {...emptyBoxProps}
          />
        )}>
        <Container className={className}>
          {children}
        </Container>
      </ConfigProvider>
    );
  }
}

export default TableWrapper;
