import { Dropdown, Icon, Menu } from 'antd';
import Bundle from 'stores/Bundle';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import { observer } from 'mobx-react';
import store from 'stores';

export interface BundleOptionsMenuProps {
  bundle: Bundle;
}

class BundleOptionsMenu extends React.Component<BundleOptionsMenuProps>{
  constructor(props: BundleOptionsMenuProps) {
    super(props);
  }

  downloadSourceFilesViaWindow = () => {
    const { bundle } = this.props;
    const { sourceURL } = bundle;

    window.open(sourceURL, '_blank');
  }

  archiveBundle = () => {
    const { bundle } = this.props;
    const { id } = bundle;

    store.creative.bundles.archive(id);
  }

  render() {
    const { downloadSourceFilesViaWindow, archiveBundle } = this;
    const { bundle } = this.props;
    const { compositeArchivePath } = bundle.data;

    if (compositeArchivePath) {
      return null;
    }

    return (
      <Dropdown
        disabled={bundle.loading}
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item onClick={downloadSourceFilesViaWindow}>
              <Icon type="link" />
              Source Files
            </Menu.Item>
            <Menu.Item onClick={archiveBundle}>
              <Icon type="delete" />
              Archive
            </Menu.Item>
          </Menu>
        )}
      >
        <DrawerButton>
          <Icon type="ellipsis" />
        </DrawerButton>
      </Dropdown>
    );
  }
};

export default observer(BundleOptionsMenu);
