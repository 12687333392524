import React from 'react';
import styled from 'styled-components';
import CloseIcon from './closeIcon.svg';
import store from 'stores';
import { AnimationPanelViews } from 'stores/Animation';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  height: 56px;
  justify-content: space-between;
  padding-right: 12px;
  background: #FFFFFF;
  border-bottom: 1px solid #D9D9D9;
  border-top: 1px solid #D9D9D9;
`;

const Icon = styled.img`
  height: 24px;
  margin: 9px;
  width: 24px;
  z-index: 0;
`;

const LeftContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
  justify-content: flex-start;
`;

const Label = styled.span`
  font-size: 16px; 
`;

export interface DrawerHeaderProps {
  handleOnClose?: () => void;
  label?: string;
  content?: React.ReactNode;
}

class DrawerHeader extends React.Component<DrawerHeaderProps>{
  constructor(props: DrawerHeaderProps) {
    super(props);
  }

  render() {

    return (
      <Container>
        <LeftContainer>
          <Icon src={CloseIcon} onClick={() => {
            store.animation.setPanel(AnimationPanelViews.NONE);
            this.props.handleOnClose ? this.props.handleOnClose() : null;
          }}/>
          <Label>{this.props.label}</Label>
        </LeftContainer>
        {this.props.content ? this.props.content : <></>}
      </Container>
    );
  }
};

export default DrawerHeader;
