import Asset from './Asset';
import Template from './Template';
import Color from './Color';
import Select from './Select';
import Label from './Label';
import DynamicGroup from './Group';
import Dimensions from './Dimensions';
import Boolean from './Boolean';
import None from './None';
import Number from './Number';
import Percentage from './Percentage';
import Pixels from './Pixels';
import Points from './Points';
import Position from './Position';
import SVGText from './SVGText';
import SVGData from './SVGData';
import Text from './Text';
import { DynamicPropertyType } from './DynamicPropertyType';
import { DynamicProperty, DynamicPropertyStatus } from './Property';
import Store from '../../Store';
import { CSVAdRowData } from 'stores/AdsManager/AdsManager';

// let DynamicPropertyType = {
//   ASSET : 'Asset',
//   SVGText : 'SVGText',
//   SVGData : 'SVGData',
//   GROUP : 'Group',
//   TEXT : 'Text',
//   TEMPLATE : 'Template',
//   COLOR : 'Color',
//   SELECT : 'Select',
//   NUMBER : 'Number',
//   BOOLEAN : 'Boolean',
//   DIMENSIONS : 'Dimensions',
//   LABEL : 'Label',
//   PERCENTAGE : 'Percentage',
//   POSITION : 'Position',
//   PIXELS : 'Pixels',
//   POINTS : 'Points',
//   NONE : 'None',
//   DEFAULT : '',
// }
// let creative = store.creative;
// creative.dynamicProperties.add('template', DynamicPropertyType.TEMPLATE, creative.templates.all[0].id);
// creative.dynamicProperties.add('dimensions', DynamicPropertyType.DIMENSIONS, undefined);
// creative.dynamicProperties.get('dimensions')?.set('width', 300);
// creative.dynamicProperties.get('dimensions')?.set('height', 250);
// creative.dynamicProperties.add('label', DynamicPropertyType.LABEL, 'Untitled');
// creative.dynamicProperties.add('backupImageFilesize', DynamicPropertyType.NUMBER, 40000);
// creative.dynamicProperties.add('backupImageScale', DynamicPropertyType.NUMBER, 1);
// creative.dynamicProperties.add('bundleFilesize', DynamicPropertyType.NUMBER, 150000);

//creative.dynamicProperties.merge(creative.templates.all[0].properties.properties)

export type TypesObject = {
  [typeName: string]: typeof DynamicProperty
}

export type DynamicPropertyData = {
  name: string,
  reserved: boolean,
  type: string,
  value: any,
}

export interface IDynamicProperty<T> extends Store {
  name: string,
  reserved: boolean,
  type: DynamicPropertyType,
  unit?: string,
  parent: IDynamicProperty<any> | null | undefined,
  label: string;
  value: T | typeof undefined,
  initialValue: T | T[] | typeof undefined,
  changed: boolean,
  status: DynamicPropertyStatus,
  fullpath?: string,
  // default(): any | undefined;
  getChanged: () => boolean,
  setDefault?: () => void,
  subscribe(component:any): void,
  get(...args:any): T | typeof undefined | IDynamicProperty<any>,
  transform: (value: any) => any,
  set(...args:any): void,
  getCSVData(): string | number,
  setInitialValue(...args:any): void,
  reset(): void,
  revert(): void,
  render?(): void,
  resetChanged(): void,
  onUpdate(...args:any): void,
  onInit?(...args:any): void,
  rename(name: string): void,
  clear(): void,
  setReserved(isReserved: boolean): void,
  setUsingCSV?(value: any): void,
  setUsingCSVRowObject?(value: CSVAdRowData): void,
  setUsingObject?(value: any): void,
  getString(): string,
  getObject(): any,
  getPreviewData(): any,
  getRoot(): IDynamicProperty<any>,
  getState(): any,
  setState?(state: any): void,
}

export class Group extends DynamicGroup {}

export const AllTypes = {
  SVGData,
  SVGText,
  Group,
  Position,
  Number,
  Label,
  Dimensions,
  Text,
  Pixels,
  Points,
  Boolean,
  None,
  Percentage,
  Color,
  Select,
  Template,
  Asset
};

export type LooseObject = {
  [key: string]: any
}

export type Position2D = {
  x: number,
  y: number
}

export type Dimensions2D = {
  width: number,
  height: number
}