import { Button as BaseButton, Icon } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const ButtonGroup = styled(BaseButton.Group)`
  display: flex;
`;

const Button = styled(BaseButton)`
  align-items: center;
  display: flex;
  font-size: 20px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 3px;
`;

export interface NavigationMenuProps { }

class NavigationMenu extends React.Component<NavigationMenuProps>{
  constructor(props: NavigationMenuProps) {
    super(props);
  }

  goBack = () => {
    const previousAd = store.creative.ads.previous;

    if (previousAd) {
      previousAd.navigateTo();
    }
  }

  goForward = () => {
    const nextAd = store.creative.ads.next;

    if (nextAd) {
      nextAd.navigateTo();
    }
  }

  render() {
    const { goBack, goForward } = this;
    const { previous, next } = store.creative.ads;

    return (
      <ButtonGroup>
        <Button disabled={!previous} onClick={goBack}>
          <Icon type="caret-left" />
        </Button>

        <Button disabled={!next} onClick={goForward}>
          <Icon type="caret-right" />
        </Button>
      </ButtonGroup>
    );
  }
};

export default observer(NavigationMenu);
