import Ad from './Ad';
import Creative from './Creative';
import DocumentCollection from './DocumentCollection';
import { firestore, db, FirestoreEvents, functionAuthRequest } from './Firebase';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData as FirestoreDocumentData, QueryDocumentSnapshot, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import Tearsheet, { TearsheetSchema } from './Tearsheet';
import { DocumentCollectionType } from './Types';
import FirebaseFunctions from './FirebaseFunctions';

export default class Tearsheets extends DocumentCollection<Tearsheet, TearsheetSchema>{
    
    constructor(parent: Creative) {
        super(parent, Tearsheet);
        this.query = () => this.collectionRef.orderBy('dateModified');
        this.init();
    }

    new = async (name: string, selectedAds: Ad[]) => {
        let selectedAdIDs = [];

        for (var ad of selectedAds) {
            selectedAdIDs.push(ad.id);
        }

        return await FirebaseFunctions.createNewTearsheet(this.parent, name, selectedAdIDs);
    }
}