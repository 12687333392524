import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Table, Input, Tooltip, Icon } from 'antd';
// import ColumnAds from '../../Tables/ColumnAds';
import AdListTableRow from './AdListTableRow';
// import AdListToolbar from './AdListToolbar';
import { Timeout } from '../../../../../utils/Types/common';
import { urlJoin } from 'url-join-ts';
import store from '../../../../../stores';
import AdsManager from '../../../../../stores/AdsManager';
import { identifier } from '@babel/types';
import Ad from '../../../../../stores/Ad';
import { subscribe } from 'stores/SubscribeUtil';
import { DocumentCollectionEvents } from 'stores/DocumentCollection';
import { delay } from 'utils';


const Container = styled.div`
  width: 500px;
  height: calc(100%);
  position: relative;
`;

const ChangedContainer = styled.div`
  display: block;
  padding: 0px 0px 7px;
  border-bottom: 1px solid #f2ebeb;
  background: #f0f2f5;
`;

const TableContainer = styled.div`
  border: 1px solid #e6e6e6;
  border-width: 1px 1px 0 0;
  display: flex;
  /* border: 1px solid #e4e4e4; */
  /* border-radius: 3px; */
  flex-grow: 1;
  flex-flow: column;
  background: #fff;
  width: 100%;
  height:100%;
  overflow: auto;
  
  .ant-table-thead > tr, .ant-table-tbody > tr {
    cursor: pointer;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-small {
    border-radius: 0px;
  }

  .ant-table-selection-column {
    display: none;
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    /* background: blue; */
  }
`;

const Notification = styled.div`
  background-color: #fffbe6;
  border-bottom: 1px solid #ffe58f;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding: 5px 3px 5px 8px;
  user-select: none;
  .anticon{
    font-size: 19px;
    color: #f0d374;
    margin-right: 8px;
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const TableHeader = styled.div`
    margin-bottom: 0;
    padding: 10px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;

    h1 {
      font-weight: 600;
      font-size: 20px;
      margin: 0px 0px 0px 5px;
    }

    button {
      margin-left: 10px;
    }
`;

const AdContainer = styled.div`
  position: relative;

  .ant-input {
    height: 28px;
    position: relative;
    top: 0px;
    margin-top: -1px;
    margin-bottom: 9px;
  }

  .ant-tag, .ant-tag a, .ant-tag {
    padding: 3px 9px;
    font-size: 13px;
    margin-bottom: 8px;
  }

`;

let mousePosition = { x: 0, y: 0 };
let mouseOffset = { x: 0, y: 0 };
let startMousePosition = { x: NaN, y: NaN };
let parentPosition = { x: 0, y: 0 };

type AdRowComponentRef = {
  id: string,
  store: Ad,
  y: number,
  dragging: boolean,
  isLeader: boolean,
  timeout: Timeout | null,
  get elem(): HTMLElement | null
}

interface AdListTableProps extends HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
}

class AdListTable extends React.Component<AdListTableProps> {
  canModify = false;
  selectedKeys = [];
  dragging = false;
  dragEngageDistance = 3; // pixels;
  dragTarget: AdRowComponentRef | undefined;
  dragIndex = 0;
  dragTargetId = '';
  dragTimeout: Timeout;
  adRowHeight = 32;
  adRowComponents: AdRowComponentRef[] = [];
  ads: AdsManager;
  loading: boolean;
  initialMousePosition: { x: number, y: number } | null = null;
  containerScrollTop: number = 0;
  containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  intervalId: number | null = null;
  // currentSelectedKey = '';

  constructor(props: AdListTableProps) {
    super(props);
    this.ads = store.creative.ads;
    // store.creative.ads.addEventListener(DocumentCollectionEvents.CHANGED, () => {
    //   this.forceUpdate();
    // });
    // subscribe(this, store.creative.ads);
  }

  componentDidMount() {
    this.setupScrollListener();
  }

  componentWillUnmount() {
    this.removeScrollListener();
  }

  setupScrollListener = () => {
    this.intervalId = window.setInterval(() => {
      if (this.containerRef.current) {
        this.containerRef.current.addEventListener('scroll', this.handleScroll);
        this.clearInterval();
      }
    }, 100);  // Check every 100ms
  }

  removeScrollListener = () => {
    this.clearInterval();
    if (this.containerRef.current) {
      this.containerRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  clearInterval = () => {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  handleScroll = () => {
    console.log('handleScroll');
    if (!this.dragging) {
      this.reflowAdRows();
    } else {
      this.updateDraggedObjectPosition();
    }
  }

  getScrollTop = (): number => {
    return this.containerRef.current?.scrollTop || 0;
  }

  updateScrollTop = () => {
    console.log('updateScrollTop');
    if (this.containerRef.current) {
      this.containerScrollTop = this.containerRef.current.scrollTop;
    }
    // requestAnimationFrame( this.updateScrollTop );
  }

  handleView = (id: string) => {
    let ad = store.creative.ads.getById(id);
    if (!ad) return;
    if (ad === this.ads.current) return;
    if (this.ads.current) this.ads.current.setViewingComments(false);

    ad.navigateTo();
    ad.reload();

  }

  renderAdRows = (ads: Ad[]) => {
    let elements: JSX.Element[] = [];
    this.adRowComponents = [];
    Object(window).adRowComponents = this.adRowComponents;
    ads.map((ad, index) => {
      let elem = React.createRef();
      let component = (<AdListTableRow
        id={ad.id}
        className="ad-row"
        dragging={false}
        key={ad.key} ad={ad}
        style={{ top: index * this.adRowHeight + 'px' }}
        /*onSelect={() => this.handleView(ad.id)}*/
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
      />);
      elements.push(component);
      this.adRowComponents.push({
        id: ad.id,
        store: ad,
        y: index * this.adRowHeight,
        dragging: false,
        isLeader: false,
        timeout: null,
        get elem() { return document.getElementById(ad.id) }
      });
    })

    return elements;
  }

  updateDragIndex = (clientY: number) => {
    const containerRect = this.containerRef.current?.getBoundingClientRect();
    if (!containerRect) return;

    const scrollTop = this.getScrollTop();
    const relativeY = clientY - containerRect.top;
    const notDragging = this.adRowComponents.filter(item => !item.dragging);
    this.dragIndex = Math.min(
      Math.max(0, Math.floor((relativeY + scrollTop) / this.adRowHeight)),
      notDragging.length
    );
  }

  reflowAdRows = (isRenderCall: boolean = false) => {
    const scrollTop = this.getScrollTop();
    const notDragging = this.adRowComponents.filter(item => !item.dragging)
      .sort((a, b) => a.store.data.index - b.store.data.index);
  
    notDragging.forEach((item, index) => {
      const targetIndex = index >= this.dragIndex ? index + 1 : index;
      if (item.elem) {
        if (isRenderCall) {
          // During render, use React's state to update positions
          item.y = targetIndex * this.adRowHeight;
        } else {
          // Direct DOM manipulation for drag operations
          item.elem.style.top = `${targetIndex * this.adRowHeight}px`;
        }
        item.elem.style.zIndex = '';
      }
    });
  
    if (!isRenderCall) {
      // Only update dragging items position during actual drag operations
      const dragging = this.adRowComponents.filter(item => item.dragging && !item.isLeader);
      dragging.forEach(item => {
        if (item.elem && this.dragTarget && this.dragTarget.elem) {
          item.elem.style.top = this.dragTarget.elem.style.top;
        }
      });
    }
  }

  updateIndexes = (insertIndex: number) => {
    let notDragging = [... this.adRowComponents.filter(item => item.dragging === false).sort((a, b) => (a.store.data.index > b.store.data.index) ? 1 : -1)];
    let currentlyDragging = this.adRowComponents.filter(item => item.dragging === true).sort((a, b) => (a.store.data.index > b.store.data.index) ? 1 : -1);
    notDragging.splice(insertIndex, 0, ...currentlyDragging);
    let newAdRowComponents = [...notDragging];

    for (let i = 0; i < newAdRowComponents.length; i++) {
      let item = newAdRowComponents[i];
      item.store.setIndexLocal(i);
    }

    this.adRowComponents = newAdRowComponents;
    store.creative.ads.updateIndexes(); // TODO: this needs to be re-written to properly batch save indexes. using firebase function? check code in adhub 2.1
  }

  handleDragStart = (e: MouseEvent) => {
    const scrollTop = this.getScrollTop();
    this.initialMousePosition = { x: e.clientX, y: e.clientY + scrollTop };
    let target = e.target as HTMLElement;
    this.dragTarget = this.getAdRowComponentById(target.id);
    if (store.creative.ads.filtering === true || !this.dragTarget) return;

    this.dragTarget.isLeader = true;
    startMousePosition = { x: e.clientX, y: e.clientY + scrollTop };

    document.addEventListener('mousemove', this.handleDragMove);
    document.addEventListener('mouseup', this.handleDragEnd);
  }

  handleDragEnd = (e: MouseEvent) => {
    document.removeEventListener('mousemove', this.handleDragMove);
    document.removeEventListener('mouseup', this.handleDragEnd);

    if (this.dragging) {
      this.updateIndexes(this.dragIndex);
      this.adRowComponents.forEach(item => {
        item.dragging = false;
        item.isLeader = false;
        item.elem?.classList.remove('dragging');
        if (item.timeout) clearTimeout(item.timeout);
      });
    } else if (this.dragTarget && this.dragTarget.id === (e.target as HTMLElement).id) {
      this.handleView(this.dragTarget.id);
    }

    this.resetDragState();
  }

  handleDragMove = (e: MouseEvent) => {
    if (!this.dragging) {
      let dragDistance = Math.abs(startMousePosition.y - (e.clientY + this.getScrollTop()));
      if (dragDistance > this.dragEngageDistance) {
        this.initiateDrag(e);
      }
      return;
    }

    this.updateDraggedObjectPosition();
    this.initialMousePosition = { x: e.clientX, y: e.clientY };
  }

  updateDraggedObjectPosition = () => {
    console.log('updateDraggedObjectPosition');
    if (this.dragTarget && this.dragTarget.elem && this.initialMousePosition) {
      const scrollTop = this.getScrollTop();
      const containerRect = this.containerRef.current?.getBoundingClientRect();
      if (!containerRect) return;

      const relativeY = this.initialMousePosition.y - containerRect.top;
      this.dragTarget.elem.style.top = `${relativeY + scrollTop - this.adRowHeight / 2}px`;

      this.updateDragIndex(this.initialMousePosition.y);
      this.reflowAdRows();
    }
  }

  initiateDrag = (e: MouseEvent) => {
    this.dragging = true;
    if (!this.dragTarget?.elem) return;
    this.dragTarget.dragging = true;
    this.dragTarget.elem.classList.add('dragging');
    this.dragTarget.elem.style.zIndex = '100';

    for (let item of this.adRowComponents) {
      let adData = store.creative.ads.getById(item.id);
      if (adData && adData.selected && item.isLeader === false) {
        item.dragging = true;
        if (item.elem) item.elem.style.zIndex = '99';
        item.timeout = setTimeout(() => {
          if (item.elem) item.elem.classList.add('dragging');
        }, 250);
      }
    }
  }

  private resetDragState() {
    console.log('resetDragState');
    this.dragging = false;
    this.dragIndex = NaN;
    this.reflowAdRows();

    this.dragTarget = undefined;
    mousePosition = { x: 0, y: 0 };
    mouseOffset = { x: 0, y: 0 };
    startMousePosition = { x: NaN, y: NaN };
    this.initialMousePosition = null;
  }

  getAdRowComponentById(id: string) {
    for (var item of this.adRowComponents) {
      // console.log(item);
      if (item.id === id) return item;
    }
  }

  getAdRowComponentByIndex(index: number) {
    for (var item of this.adRowComponents) {
      if (item.store.data.index === index) return item;
    }
  }

  render() {
    let ads = store.creative.ads;
    const filteredAds = [...ads.filtered];
    const filtering = ads.filtering;
    const loaded = ads.mounted;

    // console.log('##filteredAds', filteredAds.forEach(item => console.log('##' + item.properties.get('label')?.value, item.data.index)));

    this.reflowAdRows(true);
    return (
      <Container {...this.props}>
        {loaded && (
          <TableContainer ref={this.containerRef}>
            {filtering && (<Notification><Icon type="exclamation-circle" /> Filtered Results</Notification>)}
            <AdContainer>
              {/* { changed.length > 0 && (<ChangedContainer>{ this.renderAdRows(changed) }</ChangedContainer>) } */}
              {this.renderAdRows(filteredAds)}
            </AdContainer>
          </TableContainer>
        )}

      </Container>
    );
  }
}

export default observer(AdListTable);
