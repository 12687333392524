import React from 'react';
import { ActionButtonsContainer, Container, TitleLabel } from './TableHeader.styles';

export interface TableHeaderProps {
  actionButtons: JSX.Element | JSX.Element[];
  title: string;
}

class TableHeader extends React.Component<TableHeaderProps>{
  constructor(props: TableHeaderProps) {
    super(props);
  }

  render() {
    const { actionButtons, title } = this.props;

    return (
      <Container>
        <TitleLabel>{title}</TitleLabel>
        <ActionButtonsContainer>{actionButtons}</ActionButtonsContainer>
      </Container>
    );
  }
};

export default TableHeader;
