import { Icon as BaseIcon } from "antd";
import styled from "styled-components";

export const AvatarContainer = styled.div`
  position: relative;
`;

export const FloatingIconContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #000;
  bottom: 0;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 2px;
  position: absolute;
  right: 1px;
  width: 20px;
`;

export const Icon = styled(BaseIcon).attrs({})`
`;

export const Picture = styled.img`
  border-radius: 50%;
  height: 64px;
  object-fit: contain;
  width: 64px;
`;
