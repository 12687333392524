import { Container } from './MemberActionButtons.styles';
import DocumentStore from 'stores/DocumentStore';
import EditMemberButton from './EditMemberButton';
import React from 'react';
import RemoveMemberButton from './RemoveMemberButton';

export interface MemberActionButtonsProps {
  memberId: string;
  parentDocument: DocumentStore;
}

class MemberActionButtons extends React.Component<MemberActionButtonsProps>{
  constructor(props: MemberActionButtonsProps) {
    super(props);
  }

  render() {
    const { memberId, parentDocument } = this.props;

    return (
      <Container>
        <RemoveMemberButton
          parentDocument={parentDocument}
          memberId={memberId}
        />
        <EditMemberButton
          parentDocument={parentDocument}
          memberId={memberId}
        />
      </Container>
    );
  }
}

export default MemberActionButtons;
