import AssetsManager from './Assets';
import Store from './Store';
import { delay, getFilenameExtention } from '../utils';
import Asset from './Asset';

export default class AssetUploadManager extends Store {
    parent: AssetsManager;
    modalVisible = false;
    asset: Asset | undefined = undefined;
    file: File | undefined = undefined;
    fileName = '';
    loading = false;
    isUpdate = false;

    constructor(parent: AssetsManager) {
        super();
        this.parent = parent;
        this.init();
    }

    setCurrentFile = (file: File) => {
        this.file = file;
    }

    showUploadAssetModal = (file?: File, asset?: Asset) => {
        this.file = file;
        this.asset = asset;
        this.show(true);
    }

    show = (show: boolean = true) => {
        console.log('?')
        this.modalVisible = show;
        console.log(this.modalVisible);
    }

    hide = async () => {
        this.show(false);
        await delay(250);
        this.reset();
    }

    setFile = (file: File, fileName?: string) => {
        this.file = file;
        this.fileName = fileName || file.name;
        if(this.parent.getByNameInFolder(this.fileName)) {
            this.isUpdate = true;
        } else {
            this.isUpdate = false;
        }
    }

    setAsset = (asset: Asset) => {
        this.reset();
        this.asset = asset;
        this.fileName = asset.data.name;
        this.isUpdate = true;
    }

    handleConfirm = async (alternateFileName?: string) => {
        if (!this.file) return;
        this.loading = true;
        await this.parent.upload(this.file, alternateFileName);
        this.loading = false;
        this.hide();
    };

    reset = () => {
        this.file = undefined;
        this.fileName = '';
        this.loading = false;
        this.isUpdate = false;
    }
}