import firebase from 'firebase';
/**
 * @module FirebaseEmulators
 * @description
 * This module is responsible for configuring the Firebase SDK to use the
 * emulators when running locally.
 * */

/**
 * Splits a host:port string into a host and port number.
 * @param {string} hostPort The host:port string to split
 * @returns {[string, number]} The host and port number
 * */
function splitHostPort(hostPort: string): [string, number] {
    const c = hostPort.lastIndexOf(':');

    let host = '';
    let portStr = '';

    if (c >= -1) {
        host = hostPort.slice(0, c);
        portStr = hostPort.slice(c + 1);
    }

    if (!host) throw new Error('Missing hostname');
    if (!portStr) throw new Error('Missing port');

    if (!/^\d+$/.test(portStr)) {
        throw new Error('Port not an integer');
    }

    const portNum = +portStr;
    if (portNum < 0 || portNum > 0xffff) {
        throw new Error('Port number out of range');
    }

    return [host, portNum];
}

const LOG_PREFIX = 'Firebase emulators:';

/**
 * Applies the emulator configuration to the Firebase SDK.
 * @param {Record<string, string | undefined>} emulators The emulators to configure
 * @param {firebase.firestore.Firestore} firestore The Firestore instance to configure
 * @param {firebase.auth.Auth} auth The Auth instance to configure
 * @param {ReadonlyArray<firebase.storage.Storage>} storages The Storage instances to configure
 * */
export function applyEmulatorConfig(
    emulators: Record<string, string | undefined>,
    firestore: firebase.firestore.Firestore,
    auth: firebase.auth.Auth,
    storages: ReadonlyArray<firebase.storage.Storage>
) {
    const report: Record<string, string> = {};

    if (emulators.functions) {
        report.functions = emulators.functions;
        // We don't need to do anything here unless we ever switch to
        // callable functions, because the rest of the app is using
        // appConfig.functionsURL
        // https://firebase.google.com/docs/emulator-suite/connect_functions#instrument-functions
        //
        // Still, include it in the report we're building, otherwise the
        // logic at the end would say "none configured" which would be
        // misleading.
    }

    if (emulators.firestore) {
        report.firestore = emulators.firestore;
        const [host, port] = splitHostPort(emulators.firestore);
        firestore.useEmulator(host, port);
    }

    if (emulators.auth) {
        report.auth = emulators.auth;
        auth.useEmulator(`http://${emulators.auth}`);
    }

    if (emulators.storage) {
        report.storage = emulators.storage;
        const [host, port] = splitHostPort(emulators.storage);
        for (let b of storages) {
            b.useEmulator(host, port);
        }
    }

    if (Object.keys(report).length === 0) {
        console.log(`${LOG_PREFIX} none configured`);
    } else if (!!console.table) {
        console.log(LOG_PREFIX);
        console.table(report);
    } else {
        console.log(`${LOG_PREFIX} ${JSON.stringify(report, null, 2)}`);
    }
}
