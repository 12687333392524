import AdListToolbar from './AdListToolbar';
import AdListTable from './AdListTable'
import React from 'react';
import styled from 'styled-components';
import store from 'stores';
import { observer } from 'mobx-react';

const Container = styled.div`
  background-color: white;
  border-right: 1px solid rgb(242, 235, 235);
  display: grid;
  grid-template-rows: 50px 1fr;
  height: 100%;
`;

const WorkMessage = styled.div`
  position: absolute;
  top: 85px;
  left: 90px;
  background: white;
  height: 60px;
  width: 300px;
  z-index: 999;
  border-radius: 4px;
  padding: 20px 0px 20px;
  text-align: center;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0px 6px 16px rgb(0 0 0 / 10%);
`;

export interface AdListProps { };

class AdList extends React.Component<AdListProps>{
  constructor(props: AdListProps) {
    super(props);
  }

  render() {
    let { working, workMessage } = store.creative.ads;
    let workingStyle: React.CSSProperties = working ? {opacity: 0.5, pointerEvents: 'none'} : {};

    return (
      
      <Container>
        { (working ? <WorkMessage>{workMessage}{/*{Math.round(workPercent * 100) + '%'}*/}</WorkMessage> : null )}
        <AdListToolbar style={workingStyle}/>
        <AdListTable style={workingStyle}/>
      </Container>
    );
  }
}

export default observer(AdList);
