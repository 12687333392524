import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import { Input, Icon } from 'antd';
import store from '../../../stores';
import CommentsManager from '../../../stores/CommentsManager';
import Comment from './Comment';
import Ad from '../../../stores/Ad';
import Creative from '../../../stores/Creative';
import { PanelViews } from '../../../stores/CreativeTypes';
import { DocumentCollectionEvents } from '../../../stores/DocumentCollection';
import CommentStore from '../../../stores/Comment';
import TearsheetAd from 'stores/TearsheetAd';

const { TextArea } = Input;

const Container = styled.div`
    background: white;
    border-radius: 5px;
    border: 1px solid #ddd;
    position: absolute;
    height: 400px;
    width: 400px;
    right: 10px;
    bottom: 10px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    
    &.collapsed {
        height: 44px;
    }

    &.panel-open {
        right: 730px;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    color: #123;
    height: 400px;
    width: 400px;
`;

const NoCommentsPlaceholder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ddd;
    height: 100%;
    width: 100%;
`;

const UnauthenticatedPlaceholder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    flex-grow: 1;
    color: #ddd;
    width: 100%;
`;

const CommentsContainer = styled.div`
    display: flex;
    overflow-y: auto;
    flex-grow: 1;
    flex-flow: column;
    max-height: 318px;
    background: #f9f9f9;
    flex-shrink: 0;
    flex-basis: 0;
`;

const CommentInput = styled.textarea`
    height: 40px;
    border: 0;
    border-top: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
    padding: 8px 11px;
    resize: none;
    display: flex;
    font-size: 14px;
    outline: 0;

    ::placeholder {
        opacity: 0.5;
    }

    &:disabled {
        background: #f9f9f9;
    }

    &.collapsed {
        overflow-y: hidden;
    }

    :focus {
        outline-width: 0;
    }
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding: 14px;
    height: 45px;
    display: flex;
    align-items: center;
    display:flex;
    flex-flow: row;
    cursor: pointer;
    user-select: none;
    background: #ffffff;
    transition: all 0.1s;

    &.unread {
        background: #40a9ff;
        color: white;
    }
`;

const TitleText = styled.span`
    text-align: left;
    flex-grow: 1;
`;

const CommentState = {
    EnterPlaceholder: 1,
    Default: 0
}

type CommentsProps = {
    ad: Ad | TearsheetAd;
}

class Comments extends React.Component<CommentsProps> {
    loading = false;
    dispose = null;
    isViewing = false;
    ad: Ad | TearsheetAd;
    commentsContainer = React.createRef<HTMLDivElement>();
    refreshDelay = 10;
    scrollBottomLock = true;
    inputContent = '';
    inputElement = React.createRef<HTMLTextAreaElement>();
    enableSubmit = false;
    collapsed = true;
    displayState = CommentState.Default;
    datetimeRefreshRate = 60 * 1000;
    newCommentViewDelay = 2 * 1000;
    newCommentTimeout: NodeJS.Timeout | null = null;
    parentAd: Ad | null = null;

    constructor(props: CommentsProps) {
        super(props);

        this.ad = props.ad;

        if (!store.user.authenticated) this.displayState = CommentState.EnterPlaceholder;

        makeObservable(this, {
            loading: observable,
            collapsed: observable,
            displayState: observable,
            inputContent: observable,
            isViewing: observable,
        });
    }

    componentDidMount() {
        this.ad.comments.on(DocumentCollectionEvents.LOADED, this.handleCommentsLoaded);
        setInterval(() => {
            this.scrollToBottom();
        }, this.refreshDelay);

        setInterval(this.handleRefreshDatetime, this.datetimeRefreshRate);
    }

    componentWillUnmount() {
        if (this.newCommentTimeout) {
            clearTimeout(this.newCommentTimeout);
        }
    }

    triggerView = (override: boolean) => {
        if(this.ad instanceof Ad) {
            if (this.collapsed === false || override) {
                this.ad.updateViewHistory();
            }
        }
    }

    handleNewComment = () => {
        // this.triggerView();
    }

    handleCommentsLoaded = async () => {
        await delay(10);
        this.scrollToBottom();
    }

    handleRefreshDatetime = () => {
        if (this.ad.comments.loaded) this.ad.comments.updateCommentsDatetime();
    }

    handlePlaceholderName = (e: React.KeyboardEvent<HTMLInputElement>) => {
        this.displayState = CommentState.Default;
        store.user.setPlaceholderName(e.currentTarget.value);
        this.scrollBottomLock = true;
        this.scrollToBottom();
    }

    // handleChange = (e: React.ChangeEventHandler<HTMLTextAreaElement>) => {
    //     let content = e.target.value;
    //     let numLines = 1 + content.split(/\r\n|\r|\n/).length - 1;
    //     let lineHeight = 21
    //     if(numLines > 4) numLines = 4;
    //     e.currentTarget.style.height = ((21 * numLines) + 19) + 'px';
    //     this.scrollToBottom();
    // }

    // Method to adjust the height of a textarea element
    adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
        let content = textarea.value;
        let numLines = 1 + content.split(/\r\n|\r|\n/).length - 1;
        let lineHeight = 21
        if(numLines > 4) numLines = 4;
        textarea.style.height = ((21 * numLines) + 19) + 'px';

        this.scrollToBottom(); // Assuming this method handles scrolling logic
    };

    // Method to handle changes in the textarea
    handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        console.log('change', e.target.value);
        this.adjustTextareaHeight(e.target);
        // Include any additional logic needed for handling changes
    };

    // Method to handle the Enter key press events
    handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const textarea = e.currentTarget;
        const isShiftPressed = store.input.shift;
        const comment = textarea.value.trim();

        // Logic for handling newline insertion vs. comment submission
        if (e.key === 'Enter') {
            if (isShiftPressed) {
                // Append a newline character
                // textarea.value += '\n';
            } else {
                // Submit the comment if it's not empty
                if (comment === '') return;
                this.handleCommentSubmit(textarea.value);
                textarea.value = ''; // Clear the textarea after submission
                e.preventDefault();
            }

            // Adjust the textarea height and scroll to bottom after handling the event
            this.adjustTextareaHeight(textarea);
        }
    };

    // Method to submit a comment
    handleCommentSubmit = (comment: string) => {
        if (store.input.shift) return; // Double-check to prevent submission when Shift is pressed
        if (!comment) return; // Guard clause for empty comments

        this.addComment(comment); // Assuming this method correctly handles adding a comment
        this.scrollBottomLock = true;
        this.scrollToBottom(); // Scroll to the bottom of the comments container
    };


    handleCollapse = () => {
        if (this.collapsed) {
            this.collapsed = false;
            this.isViewing = true;
            this.ad.setViewingComments(true);
            // this.triggerView();
            // setTimeout(() => { this.inputElement.focus() } , 400);

        } else {
            this.collapsed = true;
            this.isViewing = false;
            this.ad.setViewingComments(false);
            // this.triggerView(true);
        }
    }

    scrollToBottom = async () => {
        await delay(150);
        let commentsContainer = this.commentsContainer.current;
        if (this.scrollBottomLock === false) return;
        if (!commentsContainer) return;
        commentsContainer.scrollTop = commentsContainer.scrollHeight;
    }

    addComment = (comment: string) => {
        if (comment === '') return;
        this.props.ad.comments.addComment(comment);
    }

    checkScroll = () => {
        let commentsContainer = this.commentsContainer.current;
        if (!commentsContainer) return;
        let scrollPosition = commentsContainer.offsetHeight + commentsContainer.scrollTop;
        let scrollHeight = commentsContainer.scrollHeight;
        if (scrollPosition == scrollHeight) {
            this.scrollBottomLock = true;
        } else {
            this.scrollBottomLock = false;
        }
    }

    renderComments = (comments: CommentStore[]) => {
        const elements:React.ReactNode[] = [];
        if (store.user.authenticated === false && !store.user.placeholderName) {
            return (
                <UnauthenticatedPlaceholder>
                    <Icon style={{ fontSize: "40px" }} type="user" />
                    <p style={{ color: '#ccc' }}>Enter your name to begin commenting.</p>
                    <Input onPressEnter={this.handlePlaceholderName} style={{ width: '80%' }} placeholder={'Your name'}></Input>
                </UnauthenticatedPlaceholder>
            )
        } else if (this.props.ad.comments.loading) {
            return <NoCommentsPlaceholder><Icon style={{ fontSize: "40px" }} type="loading" /></NoCommentsPlaceholder>
        } else if (comments.length === 0) {
            return <NoCommentsPlaceholder>No comments</NoCommentsPlaceholder>
        } else {
            comments.forEach(comment => {
                if(!comment) return;
                elements.push(<Comment key={comment.id} commentStore={comment} onCreated={this.handleNewComment}></Comment>)
            });
        }
        return elements;
    }

    render() {
        let currentStore = store.current as Creative;
        let collapsed = this.collapsed;
        let ad = this.props.ad;
        let comments = ad.comments;
        let panelOpen = currentStore.displayPanel === PanelViews.NONE ? false : true;
        console.log('currentStore.displayPanel', currentStore.displayPanel, panelOpen);
        let numComments = comments.all.length;
        let isRead = this.ad ? this.ad.isRead : true;

        console.log('Comments render', comments.all.length, ad.data.index);

        let titleContent: any = '';

        if (comments.loading) titleContent = (<Icon type="loading" />);
        if (comments.loaded) titleContent = "Comments (" + numComments + ")";
        if (collapsed === false) titleContent = "Comments";

        return (
            <Container {...this.props} className={(collapsed ? 'collapsed' : '') + (panelOpen ? ' panel-open' : '')}>
                <InnerContainer onClick={() => this.triggerView(true)}>
                    <Title className={isRead ? '' : 'unread'} onClick={this.handleCollapse}>
                        <TitleText>{titleContent}</TitleText>
                        <Icon type={collapsed ? "up" : "down"} />
                    </Title>
                    <CommentsContainer ref={this.commentsContainer} onScroll={this.checkScroll}>
                        {this.renderComments(comments.all)}
                    </CommentsContainer>
                    <CommentInput
                        className={collapsed ? 'collapsed' : ''}
                        ref={this.inputElement}
                        onChange={this.handleChange}
                        onKeyPress={this.handlePressEnter}
                        onFocus={() => { this.isViewing = true; this.ad.setViewingComments(true); }}
                        onBlur={() => { this.isViewing = false; this.ad.setViewingComments(false); }}
                        disabled={this.displayState === CommentState.EnterPlaceholder}
                        placeholder={this.displayState === CommentState.EnterPlaceholder ? '' : 'Write a comment'}
                    />
                </InnerContainer>
            </Container>
        )
    }
}

function delay(time: number, resolveValue = null) {
    return new Promise((resolve) => {
        setTimeout(resolve, time, resolveValue);
    });
}

export default observer(Comments);