/** 
 * @module Firebase
 * @description
 * This module provides functions for initializing the Firebase SDK and providing
 * a convenient interface for interacting with the Firebase services. Specifically,
 * calling Firebase functions using the `functionAuthRequest` function.
 * */
import { FirebaseError } from '@firebase/util'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

import * as config from '../config';
import { applyEmulatorConfig } from "../utils/FirebaseEmulators";
// import Document from './Document';
// import DocumentCollection from './DocumentCollection';


export enum FirestoreEvents {
  COLLECTION_ADDED = 'added',
  COLLECTION_REMOVED = 'removed',
  COLLECTION_MODIFIED = 'modified'
}

try {
  firebase.initializeApp(config.appConfig);
} catch (err: any) {
  if(err instanceof FirebaseError) {
    if (!/already exists/.test(err.message)) {
      console.error('Firebase initialization error', err.stack);
    }
  }
}

const auth = firebase.auth();
const db = firebase.firestore();
const storageService = firebase.app().storage(config.appConfig.storageBucket);
const hostingService = firebase.app().storage(config.appConfig.hostingBucket);

applyEmulatorConfig(
  config.emulators,
  db,
  auth, [
    storageService,
    hostingService,
  ]
);

const firestore = firebase.firestore;
const googleProvider = new firebase.auth.GoogleAuthProvider();
const storageRef = storageService.ref();
const hostingRef = hostingService.ref();

function isValidFirestorePath(path: string) {
  // this zigzag is a regex that tests for double slash
  return !(/\/\//g.test(path));
}

class AdHubRequest {
  token: string;
  data: any;
  context: string;
}

/** 
 * This function is responsible for making a request to a Firebase function
 * with the appropriate authentication token.
 * @param {string} functionName The name of the function to call
 * @param {any} data The data to send to the function
 * @param {Document | DocumentCollection<any, any>} store The store that this request is being made from
 * @returns {Promise<any>} The response from the function. If the function returns an error, it will be logged to the console.
 * */
async function functionAuthRequest(functionName: string, data: any, store: any/*Document | DocumentCollection<any, any>*/) {
  var request: AdHubRequest = new AdHubRequest();
  request.token = await getToken();
  request.data = data;
  if(store) {
    request.context = store.context;

    // DEBUG
    if (!isValidFirestorePath(request.context)) {
      console.error(
        '😨 Expect Firestore to complain: request.context is %o',
        request.context
      );
    }
  }
  
  var response: any = await urlRequest(config.appConfig.functionsURL + functionName, request, 'json');
  if(!response) return null;
  if (response.error) {
    if (response.error.message) {
      console.warn('Server Error: ' + response.error.message);
    } else {
      console.warn('Server Error: ' + JSON.stringify(response.error));
    }
  }
  return response;
}
/**
 * This function gets current user's authentication token.
 * @returns {Promise<string>} The current user's authentication token
 * */
async function getToken() {
  let result = await firebase.auth()?.currentUser?.getIdToken(/* forceRefresh */ true);
  if(!result) return '';
	else return result;
}

// below will be a detailed jsdoc comment breaking down in detail the functionality of the below function: urlRequest. do not use the language "is responsible for" in the description.
/** 
 * Creates a new XMLHttpRequest, sends an `AdHubRequest`, and returns a Promise that resolves with the response from the server. 
 * @param {string} url The url to send the request to
 * @param {AdHubRequest} request The request to send
 * @param {XMLHttpRequestResponseType} type The type of response to expect
 * @returns {Promise<any>} The response from the server
 * */
function urlRequest(url: string, request:AdHubRequest, type: XMLHttpRequestResponseType) {
	return new Promise(function (resolve, reject) {
    let res = resolve;
		var xhr = new XMLHttpRequest();
		xhr.onload = function (this: Function, event: ProgressEvent) {
      var response = xhr.response;
      if(typeof response === 'string') response = JSON.parse(response);
      this(response);
    }.bind(res);
    xhr.onerror = function(this: Function, err: ProgressEvent) {
      this({error: err});
    }.bind(res);
		xhr.open('POST', url);
    xhr.responseType = type;
    xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify(request));

	}).catch(function (this: Function, error: ProgressEvent) {
		console.log('URLRequest Error:', error);
	});
}


let global = Object(window);
global['dev'] = {};
global['dev'].firebase = firebase;
global['dev'].db = db;
global['dev'].firestore = firestore;
global['dev'].func = functionAuthRequest;
global['dev'].storageRef = storageRef;
global['dev'].hostingRef = hostingRef;

export {
  storageRef,
  hostingRef,
  firebase, 
  auth, 
  db, 
  firestore, 
  googleProvider, 
  functionAuthRequest, 
};