import DynamicGroup from './Group';
import { IDynamicProperty, Position2D } from './index';
import { DynamicPropertyType } from './DynamicPropertyType';
import Pixels from './Pixels';


export default class Position extends DynamicGroup {
  type = DynamicPropertyType.POSITION;

  constructor(parent: IDynamicProperty<any>, name: string, value: Position2D) {
    super(parent, name, value);
    // this.init();
  }

  setDefault = () => {
    this.add('x', DynamicPropertyType.PIXELS, 0);
    this.add('y', DynamicPropertyType.PIXELS, 0);
  };

  get x() {
    return this.get('x')?.value as number;
  }

  get y() {
    return this.get('y')?.value as number;
  }
}
