import { AddProjectButton, DocumentFolderView, HeaderButton, HeaderDropdown } from "components/UI";
import { observer } from "mobx-react";
import React from 'react';
import store from "stores";
import { breadcrumbsToTitle } from "utils";

export interface ClientProps { }

const CLIENT_VIEW_TITLE = 'Client';

class Client extends React.Component<ClientProps>{
  constructor(props: ClientProps) {
    super(props);
    document.title = CLIENT_VIEW_TITLE;
  }

  getProjectsTableHeaderButtons = () => {
    return [
      <HeaderDropdown
        disabled
        key="client.projectsTable.filtersDropdown"
        label="Filters"
        onSelect={() => { }}
        options={[]}
      />,
      <HeaderButton
        disabled
        key="client.projectsTable.applyFiltersButton"
        label="Save Filter"
        onClick={() => { }}
      />,
      <AddProjectButton
        key="client.projectsTable.addButton"
        parentDocument={store.client}
      />
    ]
  }

  render() {
    if(store.current && store.current.data.name) document.title = store.current.data.name;
    else document.title = CLIENT_VIEW_TITLE;
    
    const projectsTableHeaderButtons = this.getProjectsTableHeaderButtons();

    return (
      <DocumentFolderView
        childrenTableHeaderButtons={projectsTableHeaderButtons}
        childrenTableTitle="Projects"
        membersTableTitle="Sharing"
        parentDocument={store.client}
        tagsManagerTitle="Tags"
      />
    );
  }
}

export default observer(Client);
