import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 240px;
  padding: 32px;
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

export const FloatingIconContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #000;
  bottom: 0;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 2px;
  position: absolute;
  right: 1px;
  width: 20px;
`;

export const NameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const MetadataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 8px 0;
  width: 100%;
`;

export const Column = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const MainLabel = styled.h1`
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
`;

export const Title = styled(MainLabel)`
  font-size: 16px;
`;

export const Caption = styled.h2`
  color: #9a9a9a;
  font-size: 12px;
  margin: 0;
  text-transform: lowercase;
`;

export const Subtitle = styled(Caption)`
  color: #9a9a9a;
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
`;
