import { DocumentStatus, DocumentType, LooseObject, Member, MemberRoles } from "./Types";
import { functionAuthRequest, db } from './Firebase';
import FirebaseFunctions from './FirebaseFunctions';
import { DocumentReference, DocumentData } from '@firebase/firestore-types';
import DocumentFolder from './DocumentFolder';
import { DocumentSchema } from "./DocumentSchema";
import User, { UserSchema } from "./User";
import { StarredDocument, UserRoles } from "./UserTypes";
import Store from "./Store";
import { reaction } from "mobx";
import { DocumentEvents } from "./DocumentStore";
import Client from "./Client";
import Project from "./Project";
import Creative from "./Creative";
import { delay } from "utils";

class StarredDocuments extends Store {
    parent: User; // TODO: check if this any type works for members manager
    enabled = true;
    previousDocumentsRef: StarredDocument[] = [];
    currentDocumentsRef: StarredDocument[] = [];
    documents: DocumentFolder<any, any, any>[] = [];
    status: DocumentStatus = DocumentStatus.LOADING;
    loading = true;
    loaded = false;

    get all() {
        return [... this.documents];
    }

    get clients() {
        return this.all.filter(item => item.type === DocumentType.CLIENT) as DocumentFolder[];
    }

    get projects() {
        return this.all.filter(item => item.type === DocumentType.PROJECT) as DocumentFolder[];
    }

    get creatives() {
        return this.all.filter(item => item.type === DocumentType.CREATIVE) as DocumentFolder[];
    }

    constructor(parent: User) {
        super();
        this.parent = parent;
        this.init();
    }

    load = async () => {
        let docs = [];
        this.loading = true;
        this.status = DocumentStatus.LOADING;
        console.log('load starred documents');
        this.previousDocumentsRef = [ ... this.currentDocumentsRef ];
        this.currentDocumentsRef = [ ... this.parent.data.starredDocuments ];

        for(let starredRef of this.currentDocumentsRef) {
            let starredDoc = this.createDoc(starredRef.type, starredRef.path);
            if(!starredDoc) return console.log('User: Error creating starred document: ', starredRef.path);
            starredDoc.updatesEnabled = false;
            try {
                await starredDoc.load();
            } catch (e) {
                console.log('User: Error loading starred document: ', starredDoc.path, e);
                if(starredDoc.exists === false) {
                    // await this.remove(starredDoc);
                }
                continue;
            }
            docs.push(starredDoc);
        }
        console.log('User: Starred documents loaded: ', docs.length);
        this.documents = docs;
        this.loading = false;
        this.loaded = true;
        this.status = DocumentStatus.LOADED;
    }

    createDoc = (type: DocumentType, path: string) => {
        let starredDoc;
        if( type === DocumentType.CLIENT ) {
            starredDoc = new Client(this, db.doc(path));
        } else if ( type === DocumentType.PROJECT ) {
            starredDoc = new Project(this, db.doc(path));
        } else if ( type === DocumentType.CREATIVE ) {
            starredDoc = new Creative(this, db.doc(path));
        } else {
            starredDoc = new DocumentFolder(this, db.doc(path));
        }
        starredDoc.stub = true;
        return starredDoc;
    }

    setState(data: StarredDocument[]) {
        this.previousDocumentsRef = [ ... this.currentDocumentsRef ];
        this.currentDocumentsRef = [ ... data ];
    }

    update = async (data: StarredDocument[]) => {
        // if(!this.previousDocumentsRef) return;
        this.previousDocumentsRef = [ ... this.currentDocumentsRef ];
        this.currentDocumentsRef = [ ... data ];

        const newPaths = this.currentDocumentsRef.map(doc => doc.path);
        const prevPaths = this.previousDocumentsRef.map(doc => doc.path);
        
        let existingPaths = newPaths.filter(x => prevPaths.includes(x));
        let removedPaths = prevPaths.filter(x => !existingPaths.includes(x));
        let addedPaths = newPaths.filter(x => !existingPaths.includes(x));

        for(let starredDoc of this.documents) {
            if (removedPaths.includes(starredDoc.path)) {
                starredDoc.reset();
                this.documents.splice(this.documents.indexOf(starredDoc), 1);
            }
        }

        for(let addedPath of addedPaths) {
            let starredRef = this.currentDocumentsRef.find(doc => doc.path == addedPath);
            if(!starredRef) continue;
            let starredDoc = this.createDoc(starredRef.type, addedPath);
            if(!starredDoc) return console.log('User: Error creating starred document: ', addedPath);
            this.documents.push(starredDoc);
            starredDoc.updatesEnabled = false;
            await starredDoc.load();
        }
    }

    add = async (doc: DocumentFolder<any, any, any>) => {
        let { id, path, type } = doc;

        if (this.get(doc)) return;

        this.parent.data.starredDocuments.push({ path, id, type });
        await this.parent.save();
    }

    remove = async (doc: DocumentFolder<any, any, any>) => {
        let { path } = doc;

        if (this.get(doc) === undefined) return;

        this.parent.data.starredDocuments = this.currentDocumentsRef.filter(doc => doc.path !== path);
        await this.parent.save();
    }

    updateStarredDocuments = async () => {

    }

    get = (doc: DocumentFolder<any, any, any>) => {
        let { path } = doc;
        let starred = this.currentDocumentsRef.find(doc => doc.path === path);
        return starred;
    }

    getByType = (type: DocumentType) => {
        let starred = this.currentDocumentsRef.filter(doc => doc.type === type);
        return starred;
    }

    enable = () => {
        this.enabled = true;
    }

    disable = () => {
        this.enabled = false;
    }

    reset = () => {
        this.documents = [];
        this.currentDocumentsRef = [];
        this.previousDocumentsRef = [];
    }

    getById(id: string) {
        return this.documents.filter(doc => doc.id == id)[0];
    }
}

export default StarredDocuments;
