import { observer } from 'mobx-react';
import { PanelViews } from 'stores/CreativeTypes';
import DrawerLayout from 'components/Views/Creative/Drawers/DrawerLayout';
import React from 'react';
import store from 'stores';
import TemplatesDrawerHeader from './TemplatesDrawerHeader';
import TemplateSummaryRow from './TemplateSummaryRow';
import styled from 'styled-components';
import { Icon } from 'antd';

const DropIndicator = styled.div`
    position: absolute;
    flex-flow: column;
    pointer-events: none;
    height: calc(100% - 65px - 20px);
    width: calc(100% - 20px);
    border: 2px dashed rgba(0,0,0,0.05);
    border-radius: 7px;
    margin: 10px;
    font-size: 25px;
    color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.025);
    opacity: 0;
    transition: all 0.25s;

    .drop-icon{
        transform: rotate(90deg);
        font-size: 92px;
        opacity: 0.4;
    }
`;

export interface TemplatesDrawerProps { }

class TemplatesDrawer extends React.Component<TemplatesDrawerProps>{
  constructor(props: TemplatesDrawerProps) {
    super(props);
  }

  handleOnClose = () => {
    store.creative.setPanel(PanelViews.NONE);
  }

  render() {
    const { displayPanel } = store.creative;
    const { handleOnClose } = this;

    const templates = [...store.creative.templates.all];
    const fileDragging = store.ui.fileDragging;

    return (
      <DrawerLayout
        onClose={handleOnClose}
        title={<TemplatesDrawerHeader />}
        isVisible={displayPanel === PanelViews.TEMPLATES}
      >
        <DropIndicator style={{opacity: fileDragging ? 1 : 0}}>
            <Icon className='drop-icon' type="login" />
            <br />
            DROP FILES HERE
        </DropIndicator>
        {templates.map((template) => (
          <TemplateSummaryRow
            key={`templatesDrawer.template.${template.id}`}
            template={template}
          />
        ))}
      </DrawerLayout>
    );
  }
};

export default observer(TemplatesDrawer);
