import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores'
import styled from 'styled-components';
import UploadTemplateModal from './UploadTemplateModal';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
`;

const Label = styled.span`
  font-size: 16px; 
`;


export interface TemplatesDrawerHeaderProps { }

class TemplatesDrawerHeader extends React.Component<TemplatesDrawerHeaderProps>{
  isUploadTemplateModalVisible: boolean = false;

  constructor(props: TemplatesDrawerHeaderProps) {
    super(props);

    makeObservable(this, {
      isUploadTemplateModalVisible: observable,
    });
  }

  showUploadTemplateModal = () => {
    store.creative.templates.uploadManager.show();
  }

  render() {
    const { showUploadTemplateModal } = this;

    return (
      <Container>
        <Label>Templates</Label>
        <DrawerButton onClick={showUploadTemplateModal}>Upload</DrawerButton>
      </Container>
    );
  }
};

export default observer(TemplatesDrawerHeader);
