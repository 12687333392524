import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { AutoComplete, Input, Button, Icon, Select, Divider } from "antd";
import { IDynamicProperty } from "../../../../../stores/DynamicProperties/Types";
import SelectPropertyType from '../../../../../stores/DynamicProperties/Types/Select';

const { Option } = Select;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  width: 100%;
`;

type SelectInputProps = {
  property: IDynamicProperty<any>;
  style?: React.CSSProperties | undefined;
  disabled: boolean;
  editMode?: boolean;
  options?: string[];
}

class SelectInput extends React.Component<SelectInputProps> {
  showDropdown = false;
  clicked = false;
  property: SelectPropertyType;
  focused = false;

  constructor(props: SelectInputProps) {
    super(props);
    this.property = this.props.property as SelectPropertyType;
  }

  handleChange = async (value: any) => {
    this.property.set(value);
  };


  render() {
    let property = this.property;
    let name = property.name;
    let editMode = this.props.editMode;

    return (
      <Container key={name} style={this.props.style} /*className={this.search ? "hide-dropdown" : ""}*/>
        <Select
          style={{ width: "100%" }}
          value={this.property.value}
          onChange={(e: any) => this.handleChange(e)}
          // placeholder="Select an asset"
          disabled={this.props.disabled}
          onBlur={() => {
            this.showDropdown = false;
            this.focused = false;
            // document.onkeydown = (e) => {};
          }}
          onFocus={() => {
            this.clicked = true;
            this.focused = true;
          }}
        >
          {/* @ts-ignore */
            (
              this.props.options ? 
                this.props.options.map((item) => <Option key={item} value={item}>{item}</Option>) 
                :
                this.property.options.map((item) => <Option key={item} value={item}>{item}</Option>)
              )
          }
        </Select>
      </Container>
    );
  }
}

export default observer(SelectInput);
