import { Button, Breadcrumb as BaseBreadcrumb } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  padding-right: 20px;

  .ant-breadcrumb {
      font-size: 19px;
      font-family: 'Open Sans';
  }

  .ant-btn {
      &.selected {
      background: #a8a8a8;
      border-color: #989696;
      color: white;
      }
  }
`;

export const BreadcrumbContainer = styled.div``;

export const Breadcrumb = styled(BaseBreadcrumb)`
  line-height: 20px;
`;

export const EditButton = styled(Button)`
  box-shadow: none;
  font-size: 20px;
  padding: 0 10px;
`;

export const SheetButton = styled(EditButton)`
  margin-left: 5px;
  padding: 0 10px;

  .icon {
      position: relative;
      top: 1px;
  }
`;

export const SheetIcon = () => (
  <svg className="icon" viewBox="0 0 50 50" width="23px" height="23px" fill="currentColor">
    <path d="M 28.90625 1.96875 C 28.863281 1.976563 28.820313 1.988281 28.78125 2 L 11.5 2 C 9.585938 2 8 3.558594 8 5.46875 L 8 43.90625 C 8 46.160156 9.867188 48 12.125 48 L 37.875 48 C 40.132813 48 42 46.160156 42 43.90625 L 42 15.1875 C 42.027344 15.054688 42.027344 14.914063 42 14.78125 L 42 14.5 C 42.007813 14.234375 41.90625 13.972656 41.71875 13.78125 L 30.21875 2.28125 C 30.027344 2.09375 29.765625 1.992188 29.5 2 L 29.1875 2 C 29.097656 1.976563 29 1.964844 28.90625 1.96875 Z M 11.5 4 L 28 4 L 28 12.34375 C 28 14.355469 29.644531 16 31.65625 16 L 40 16 L 40 43.90625 C 40 45.074219 39.054688 46 37.875 46 L 12.125 46 C 10.945313 46 10 45.074219 10 43.90625 L 10 5.46875 C 10 4.644531 10.660156 4 11.5 4 Z M 30 4.9375 L 39.0625 14 L 31.65625 14 C 30.722656 14 30 13.277344 30 12.34375 Z M 17 24 L 17 38 L 33 38 L 33 24 Z M 19 26 L 24 26 L 24 28 L 19 28 Z M 26 26 L 31 26 L 31 28 L 26 28 Z M 19 30 L 24 30 L 24 32 L 19 32 Z M 26 30 L 31 30 L 31 32 L 26 32 Z M 19 34 L 24 34 L 24 36 L 19 36 Z M 26 34 L 31 34 L 31 36 L 26 36 Z " />
  </svg>
);
