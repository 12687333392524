const TemplateIcon = () => (
  <svg className="icon" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 86 86" fill="currentColor" preserveAspectRatio="xMinYMin meet">
    <rect x="432" y="110" width="13" height="13" />
    <path d="M40,72H13.5V25.5H40V72z M18.5,67H35V30.5H18.5V67z" />
    <path d="M84.99,13.05C84.85,6.38,79.39,1,72.69,1H13.31C6.61,1,1.15,6.38,1.01,13.05H1v0.26v6.74v52.64C1,79.48,6.52,85,13.31,85
      h59.38C79.48,85,85,79.48,85,72.69V20.05v-6.74v-0.26H84.99z M33.5,7c1.93,0,3.5,1.57,3.5,3.5S35.43,14,33.5,14S30,12.43,30,10.5
      S31.57,7,33.5,7z M23.5,7c1.93,0,3.5,1.57,3.5,3.5S25.43,14,23.5,14S20,12.43,20,10.5S21.57,7,23.5,7z M13.5,7
      c1.93,0,3.5,1.57,3.5,3.5S15.43,14,13.5,14S10,12.43,10,10.5S11.57,7,13.5,7z M78,72.69c0,2.93-2.38,5.31-5.31,5.31H13.31
      C10.38,78,8,75.62,8,72.69V20.05h70V72.69z"/>
    <rect x="45" y="26" width="28" height="5" />
    <rect x="45" y="37" width="28" height="5" />
    <rect x="45" y="59" width="28" height="13" />
  </svg>
);

export default TemplateIcon;
