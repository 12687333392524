import AddFolderButton from './AddFolderButton';
import CurrentPathBar from './CurrentPathBar';
import NavigateBackButton from './NavigateBackButton';
import React from 'react';
import styled from 'styled-components';
import UploadAssetButton from './UploadAssetButton';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding-right: 24px;
`;

const LeftContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
  justify-content: flex-start;
`;

const Label = styled.span`
  font-size: 16px; 
  padding-right: 13px;
`;

export interface AssetsDrawerHeaderProps { }

class AssetsDrawerHeader extends React.Component<AssetsDrawerHeaderProps>{
  constructor(props: AssetsDrawerHeaderProps) {
    super(props);
  }

  render() {

    return (
      <Container>
        <LeftContainer>
          <Label>Assets</Label>
          <UploadAssetButton />
          <AddFolderButton />
          <NavigateBackButton />
        </LeftContainer>

        <CurrentPathBar />
      </Container>
    );
  }
};

export default AssetsDrawerHeader;
