import React from 'react';
import styled from 'styled-components';
import UnreadBubbleIcon from './message-icon-unread.svg';
import WhiteBubbleIcon from './message-icon.svg';

export enum CommentsBubbleVariant {
  WHITE = 'WHITE',
  UNREAD = 'UNREAD',
}

const Container = styled.div`
  left: -27px;
  position: relative;
  top: -2px;
`;

const Icon = styled.img<{ variant: CommentsBubbleVariant }>`
  height: 27px;
  opacity: ${({ variant }) => variant === CommentsBubbleVariant.UNREAD ? 1 : 0.2};
  position: absolute;
  top: 1px;
  width: 30px;
  z-index: 0;
`;

const Label = styled.span<{ variant: CommentsBubbleVariant }>`
  color: ${({ variant }) => variant === CommentsBubbleVariant.UNREAD ? 'white' : 'black'};
  color: black;
  font-size: 13px;
  font-weight: bold;
  left: 0px;
  position: absolute;
  text-align: center;
  top: 1px;
  width: 30px;
`;

export interface CommentsBubbleProps {
  totalComments: number;
  variant?: CommentsBubbleVariant;
}

class CommentsBubble extends React.Component<CommentsBubbleProps>{
  constructor(props: CommentsBubbleProps) {
    super(props);
  }

  render() {
    const {
      totalComments,
      variant = CommentsBubbleVariant.WHITE,
    } = this.props;

    return (
      <Container>
        <Icon
          src={variant === CommentsBubbleVariant.UNREAD ? UnreadBubbleIcon : WhiteBubbleIcon}
          variant={variant}
        />
        <Label variant={variant}>{totalComments}</Label>
      </Container>

    );
  }
};

export default CommentsBubble;
