import { Button } from './GoogleSignInButton.styles';
import React from 'react';

export interface GoogleSignInButtonProps extends React.HTMLAttributes<HTMLButtonElement> { }

class GoogleSignInButton extends React.Component<GoogleSignInButtonProps> {
  constructor(props: GoogleSignInButtonProps) {
    super(props);
  }

  render() {
    return (
      <Button {...this.props}>
        <div className="icon"><img src="images/google-icon.svg" /></div>
        <div className="text">Sign in with Google</div>
      </Button>
    )
  }
}

export default GoogleSignInButton;
