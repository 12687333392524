import {
  VersionsDrawer
} from './Drawers';
import { observer } from 'mobx-react';
import AnimationStage from './AnimationStage';
import { ChatBox } from 'components/UI';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import { IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { AnimationPanelViews, AnimationPanelViews as PanelViews } from 'stores/Animation';
import UploadAssetModal from './Drawers/VersionsDrawer/UploadVersionModal';
import { DragEvent } from 'stores/UI';
import { getFilenameExtention } from 'utils';
import CommentsDrawer from './Drawers/CommentsDrawer';
import ExportDrawer from './Drawers/ExportDrawer';
import CanvasDrawer from './Drawers/CanvasDrawer';

const Container = styled.div.attrs({
  className: 'animation-root',
})`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export interface AnimationProps { }


class Animation extends React.Component<AnimationProps> {
  draggingReactionDisposer: IReactionDisposer;
  showUploadTemplateModal = false;
  showUploadAssetModal = false;
  currentDroppedFile: File;
  tempShowPanel: PanelViews | undefined;
  setTemplateFileDelegate: (file: File) => void;
  setAssetFileDelegate: (file: File) => void;

  constructor(props: AnimationProps) {
    super(props);

    store.ui.addEventListener(DragEvent.FILE_DROPPED, this.handleFileDropped);
    store.ui.addEventListener(DragEvent.FILE_DRAG_OVER, this.handleFileDragStart);
    store.ui.addEventListener(DragEvent.FILE_DRAG_OUT, this.handleFileDragEnd);

    makeObservable(this, {
      currentDroppedFile: observable,
      showUploadTemplateModal: observable,
      showUploadAssetModal: observable,
    });
  }

  componentWillUnmount() {
    if(this.draggingReactionDisposer) this.draggingReactionDisposer();
    store.ui.removeEventListener(DragEvent.FILE_DROPPED, this.handleFileDropped);
    store.ui.removeEventListener(DragEvent.FILE_DRAG_OVER, this.handleFileDragStart);
    store.ui.removeEventListener(DragEvent.FILE_DRAG_OUT, this.handleFileDragEnd);
    store.animation.reset();
  }

  handleFileDropped = (file: File) => {
    this.tempShowPanel = undefined;
    if(!file) return;
    // if(getFilenameExtention(file.name) === '.zip') store.animation.setPanel(PanelViews.TEMPLATES);
    else store.animation.setPanel(AnimationPanelViews.VERSIONS);

    // if (store.animation.displayPanel === PanelViews.VERSIONS) {
    //   store.animation.assets.uploadManager.setFile(file);
    //   store.animation.assets.uploadManager.show();
    // }
  }

  handleFileDragStart = () => {
    let { lastDisplayPanel, displayPanel, setPanel } = store.animation;

    return setPanel(PanelViews.VERSIONS);
  }

  handleFileDragEnd = () => {
    let { setPanel } = store.animation;
  }

  render() {
    if(store.current && store.current.data.name) document.title = store.current.data.name;
    const creative = store.animation;

    return (
      <>
        {creative && (
          <Container>
            <AnimationStage />

            <VersionsDrawer />
            <CommentsDrawer />
            <ExportDrawer />
            <CanvasDrawer />

            <UploadAssetModal 
              key={'UploadAssetModal'}
              // setFileDelegate={(func) => this.setAssetFileDelegate = func }
            />
            {/* check if currentAd exists. if so render ChatBox passing in the ad reference */}
            {/* { currentAd && <ChatBox ad={currentAd} /> } */}
          </Container>
        )}
      </>
    );
  }
}

export default observer(Animation);
