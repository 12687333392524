// EmptyPlaceholder.tsx
import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import UploadVersionButton from '../Drawers/VersionsDrawer/UploadVersionButton';

const PlaceholderContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  flex-flow: column;
  line-height: 40px;
`;

const IconLarge = styled(Icon)`
  font-size: 135px;
  margin-bottom: -20px;
  color: #dddee0;
`;

interface EmptyPlaceholderProps {
    onFileSelected: (file: File) => void;
}

class EmptyPlaceholder extends React.Component<EmptyPlaceholderProps> {
    render() {
        return (
            <PlaceholderContainer>
                <IconLarge type="login" style={{ transform: 'rotate(90deg)' }} />
                <br />
                Drag your lottie animation file here.
                <br />
                <UploadVersionButton onSelected={this.props.onFileSelected} />
            </PlaceholderContainer>
        );
    }
}

export default EmptyPlaceholder;