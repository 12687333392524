import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import App from './App';
import AppDebug from './AppDebug';
import reportWebVitals from './reportWebVitals';

console.log('BUILD HASH:', document?.querySelector('meta[name="buildinfo"]')?.attributes.getNamedItem('content')?.value.split(' ')[0])

ReactDOM.render(<App />, document.getElementById('root')); // Uncomment to test refactoring all app components
// ReactDOM.render(<AppDebug />, document.getElementById('root'));

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
