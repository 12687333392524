/**
 * Config file for the frontend, mostly for the Firebase client.
 *
 * This file is a replacement of hardcoded configuration with configuration
 * that is passed in at build time via `process.env` replacement. This is
 * orchestrated by the build task at `tools/src/tasks/build/front.ts`.
 * This new process aims to create a drop-in replacement that presents the
 * same interface to the rest of the app - a default exported const whose
 * value is an Object with two keys: `mode` which names the profile in use
 * (`dev`, `prod` or `local`), and the config object, stored against the
 * key nominated by `mode`.
 *
 * This replaces the old implementation where the exported Object had
 * multiple configurations stored against various keys, and `mode`
 * nominated the one to be considered active. Now, inactive configurations
 * are omitted.
 *
 * @module
 */

export interface FirebaseSDKConfig {
    projectId: string;
    appId: string;
    databaseURL: string;
    storageBucket: string;
    locationId?: string;
    apiKey: string;
    authDomain: string;
    messagingSenderId: string;
}

export interface AdHubClientConfigExtra {
    hostingBucket: string;
    scopes: string[];
    assetsStoragePath: string;
    functionsURL: string;
}

export type AdHubClientConfig = FirebaseSDKConfig & AdHubClientConfigExtra;

// begin env vars

// Work around a quirk/bug of the inclusion or minifcation process: It
// seems that each time `process.env.somekey` appears, it is replaced with
// a literal copy of the entire process.env object, with .somekey
// immediately accessed.
//
const processEnv = process.env;

// All the config data passed in by the build[1] can be found stored
// against a single env key. It's stored as a string containing JSON text,
// so needs to be JSON.parse()d.
//
// [1] see tools/src/tasks/build/front.ts#buildFrontend
//
const runtimeText =
    processEnv.REACT_APP_ADHUB_RUNTIME ||
    '<env.REACT_APP_ADHUB_RUNTIME not set>';

// Use of local emulators are configured separately through their own
// environment vars. If emulators are to be used, these variables will be
// set to a string 'host:port' nominating the emulator host. `host` will
// pretty much always be 'localhost'.
//
// See tools/src/gcutils/emulatorinfo.ts
//
export const emulators: Record<string, string | undefined> = {
    auth: processEnv.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST,
    database: processEnv.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST,
    extensions: processEnv.REACT_APP_EXTENSIONS_EMULATOR_HOST,
    firestore: processEnv.REACT_APP_FIRESTORE_EMULATOR_HOST,
    functions: processEnv.REACT_APP_FUNCTIONS_EMULATOR_HOST,
    hosting: processEnv.REACT_APP_HOSTING_EMULATOR_HOST,
    hub: processEnv.REACT_APP_FIREBASE_EMULATOR_HUB,
    logging: processEnv.REACT_APP_LOGGING_EMULATOR_HOST,
    pubsub: processEnv.REACT_APP_PUBSUB_EMULATOR_HOST,
    storage: processEnv.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST,
    ui: processEnv.REACT_APP_UI_EMULATOR_HOST,
};

// end env vars

const isUsingAnyEmulator = Object.keys(emulators).some((k) => !!emulators[k]);

const runtimeConfig = JSON.parse(runtimeText);

// Start with the web SDK config output by
// `firebase apps:sdkconfig`. This is the object that is passed to
// firebase.initializeApp().

// TODO: proper validation instead of 'as'
const sdkConfig = {
    ...runtimeConfig.ADHUB_FBSDK,
} as FirebaseSDKConfig;

// The frontend app expects a few extra properties on sdkConfig. First we
// build them separately from sdkConfig so we have a clear picture of what
// we're adding.

const hostingBucket = String(
    (runtimeConfig.ADHUB_USER_HOSTING_BUCKET || {}).name
);

const scopes: string[] = (runtimeConfig.ADHUB_SCOPES || []).map((s: any) =>
    String(s)
);

// added in 60d5832; should this be externally configured like the rest?
const assetsStoragePath = 'hostedstorage/creative2/assets/';

// This logic for figuring a region name from the locationId (or lack
// thereof) is taken from the 'firebase-tools' utility.
//
// @see firebase-tools/lib/functionsConfig.js#getAppEngineLocation
//
let firebaseRegion = String(sdkConfig.locationId || '');
if (firebaseRegion) {
    if (/\D$/.test(firebaseRegion)) {
        firebaseRegion += '1';
    }
} else {
    firebaseRegion = 'us-central1';
}

const functionsURL = emulators.functions
    ? `http://${emulators.functions}/${sdkConfig.projectId}/${firebaseRegion}/`
    : `https://${firebaseRegion}-${sdkConfig.projectId}.cloudfunctions.net/`;

const appConfigExtra: AdHubClientConfigExtra = {
    hostingBucket,
    scopes,
    assetsStoragePath,
    functionsURL,
};

export const appConfig: AdHubClientConfig = {
    ...sdkConfig,
    ...appConfigExtra,
};

// For the time being, to stay compatible with the old config.js, I'm
// limiting the value of configMode to one of the three recognized names
// in `src/stores/Firebase.js` - that is, 'local' | 'dev' | 'prod'. The
// logic is as follows:
//
// 1. If any local emulator is in use, then `configMode` is 'local'.
// 2. If `runtime.ADHUB_ALIAS` is 'prod', then `configMode` is also 'prod'.
// 3. Otherwise, `configMode` is 'dev'.
//
// 'dev' becomes a catch-all for any non-prod environment.
//
// Note that 'local' is no longer stored as a separate configuration - it's
// really some underlying config, but with one or both of Cloud Functions
// or Firestore pointed at their respective local emulators.
//
export const mode = isUsingAnyEmulator
    ? 'local'
    : runtimeConfig.ADHUB_ALIAS === 'prod'
    ? 'prod'
    : 'dev';
