import styled from 'styled-components';
import React from 'react';
import { Button, Popover } from 'antd';
import { IDynamicProperty } from '../../../../stores/DynamicProperties/Types';
import store from '../../../../stores';
import DynamicGroup from '../../../../stores/DynamicProperties/Types/Group';
import message from 'antd/lib/message';
import PropertyPopoverContent from './PropertyPopoverContent';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

// react commponent PropertyPopover class
const PopoverButton = styled(Button)`
    width: 100%;
    margin-top: 5px;

    &:first-child {
        margin-top: 0;
    };
`;

const RemoveButton = styled(PopoverButton)`
    width: 100%;
`;

type PropertyPopoverProps = {
    property: IDynamicProperty<any>;
    editMode: boolean;
}

class PropertyPopover extends React.Component<PropertyPopoverProps> {
    popoverVisible: boolean = false;
        
    constructor(props: PropertyPopoverProps) {
        super(props);
        makeObservable(this, {
            popoverVisible: observable,
        })
    }

    handlePopoverVisibleChange = (visible: boolean) => {       
        this.popoverVisible = visible;
    }

    render() {
        let {
            property,
            editMode,
        } = this.props;

        let popoverContent = <PropertyPopoverContent property={property} editMode={editMode} onClick={()=> this.popoverVisible = false} />;

        return (
            <Popover title={property.name} visible={this.popoverVisible} onVisibleChange={this.handlePopoverVisibleChange} trigger="hover" content={popoverContent}>{this.props.children}</Popover>
        );
    }
}

export default observer(PropertyPopover);