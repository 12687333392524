import { PanelViews } from 'stores/CreativeTypes';
import { BundleIcon, TemplateIcon } from 'utils/AlternateIcons';
import DrawerToolbarButton from './DrawerToolbarButton';
import EditButton from './EditButton';
import NavigationMenu from './NavigationMenu';
import React from 'react';
import ReloadButton from './ReloadButton';
import ScaleInput from './ScaleInput';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #f2ebeb;
  border-top: 1px solid #f2ebeb;
  display: flex;
  gap: 7px;
  padding: 0 7px;
  width: 100%;
`;


export interface AdViewToolbarProps {
  disabled?: boolean;
}

class AdViewToolbar extends React.Component<AdViewToolbarProps>{
  constructor(props: AdViewToolbarProps) {
    super(props);
  }

  render() {
    const { disabled } = this.props;

    return (
      <Container>
        <NavigationMenu />
        <ReloadButton />
        <ScaleInput />

        <EditButton />

        <DrawerToolbarButton
          disabled={disabled}
          panelType={PanelViews.ASSETS}
          label="Assets"
          iconType="snippets"
        />

        <DrawerToolbarButton
          disabled={disabled}
          panelType={PanelViews.TEMPLATES}
          label="Templates"
          iconComponent={TemplateIcon}
        />

        <DrawerToolbarButton
          disabled={disabled}
          panelType={PanelViews.TEARSHEETS}
          label="Tearsheets"
          iconType="file-done"
        />

        <DrawerToolbarButton
          disabled={disabled}
          panelType={PanelViews.BUNDLES}
          label="Bundles"
          iconComponent={BundleIcon}
        />
      </Container>
    );
  }
};

export default AdViewToolbar;
