import { DynamicPropertyDropdown } from 'components/UI';
import { observer } from 'mobx-react';
import { PanelViews } from 'stores/CreativeTypes';
import DrawerLayout from 'components/Views/Creative/Drawers/DrawerLayout';
import FiltersDrawerHeader from './FiltersDrawerHeader';
import NewFilterSetModal from './NewFilterSetModal';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 20px 24px;
`;

export interface FiltersDrawerProps { }

class FiltersDrawer extends React.Component<FiltersDrawerProps>{
  constructor(props: FiltersDrawerProps) {
    super(props);
  }

  handleOnClose = () => {
    store.creative.setPanel(PanelViews.NONE);
  }

  render() {
    const { displayPanel } = store.creative;
    const { handleOnClose } = this;

    const availableProperties = [...store.creative.ads.filter.all.getAllPropertiesNames()];

    return (
      <>
        <DrawerLayout
          isVisible={displayPanel === PanelViews.FILTER}
          onClose={handleOnClose}
          title={<FiltersDrawerHeader />}
        >
          <PropertiesContainer>
            {availableProperties.map((propertyName) => (
              <DynamicPropertyDropdown
                key={`creative.filtersDrawer.propertyDropdown.${propertyName}`}
                options={store.creative.ads.filter.all.properties.filter((prop) => prop.name === propertyName && prop.label && prop.value)}
              />
            ))}
          </PropertiesContainer>
        </DrawerLayout>

        <NewFilterSetModal />
      </>
    );
  }
};

export default observer(FiltersDrawer);
