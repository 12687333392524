import { observer } from 'mobx-react';
import CopyLinksButton from './CopyLinksButton';
import GoBackButton from './GoBackButton';
import NavigationMenu from './NavigationMenu';
import React from 'react';
import ReloadButton from './ReloadButton';
import ScaleInput from './ScaleInput';
import store from 'stores';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #f2ebeb;
  border-top: 1px solid #f2ebeb;
  display: flex;
  gap: 7px;
  padding: 0 7px;
  width: 100%;
`;

export interface AdViewToolbarProps { }

class AdViewToolbar extends React.Component<AdViewToolbarProps>{
  constructor(props: AdViewToolbarProps) {
    super(props);
  }

  render() {
    const { isMobile } = store.ui;

    return (
      <Container>
        {isMobile && <GoBackButton />}
        <NavigationMenu />
        <ReloadButton />
        <ScaleInput />
        {!isMobile && <CopyLinksButton />}
      </Container>
    );
  }
};

export default observer(AdViewToolbar);
