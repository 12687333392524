import { isMobileBrowser } from 'utils/Responsive';
import { ReactNode } from 'react';
import Cookies, { Cookie } from 'universal-cookie';
import Store from './Store';
import { AntDesignIconName } from './Types';

let cookies: Cookies = new Cookies();
let cookie: Cookie;

interface NavButton {
  id: string;
  icon: AntDesignIconName;
  label: string;
  onClick: () => void;
}

export type FileDropEvent = {
  altKey: boolean;
  bubbles: boolean;
  button: number;
  buttons: number;
  cancelable: boolean;
  clientX: number;
  clientY: number;
  ctrlKey: boolean;
  currentTarget: null;
  dataTransfer: DataTransfer;
  defaultPrevented: boolean;
  detail: number;
  eventPhase: number;
  getModifierState: Function;
  isDefaultPrevented: Function;
  isPropagationStopped: Function;
  isTrusted: boolean;
  metaKey: boolean;
  movementX: number;
  movementY: number;
  nativeEvent: DragEvent;
  pageX: number;
  pageY: number;
  relatedTarget: any;
  screenX: number;
  screenY: number;
  shiftKey: boolean;
  target: HTMLElement;
  timeStamp: number;
  type: string;
}

export enum ViewModes {
  LIST = 'list',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  FRAMELESS = 'frameless',
  NONE = 'none'
}

export enum DragEvent {
  FILE_DRAG_OVER = 'fileDragOver',
  FILE_DRAG_OUT = 'fileDragOut',
  FILE_DROPPED = 'fileDropped'
}

export enum DeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

class UI extends Store {
  collapsed = false;
  // title = '';
  view: ReactNode;
  viewName = '';
  viewMode = ViewModes.NONE;
  lastView: ReactNode;
  // views = Views;
  fileDragging = false;
  placeholderName = '';

  areBreadcrumbsVisible = true;
  navButtons: NavButton[] = [];

  deviceType: DeviceType | null = null;

  constructor() {
    super();
    this.init();
    this.syncToCookie();
    this.configureDeviceType();
    this.setWindowResizeListener();
  }

  get isMobile() {
    return this.deviceType === DeviceType.MOBILE;
  }

  syncToCookie = () => {
    cookie = cookies.get('ui');
    if (!cookie) {
      cookie = this.toObject();
      return;
    }
    if (cookie.collapsed) this.collapsed = cookie.collapsed;
  }

  toObject(): any {
    return JSON.parse(JSON.stringify(this));
  }

  toggleCollapse = () => {
    this.collapsed = !this.collapsed;
    this.saveToCookie();
  }

  setFileDragging = (value: boolean, data: any) => { // TODO: find what type data is actually handed to this function 
    this.fileDragging = value;
    if (value === true) this.dispatch(DragEvent.FILE_DRAG_OVER, data);
    if (value === false) this.dispatch(DragEvent.FILE_DRAG_OUT, data);
  }

  fileDropped = (event: FileDropEvent) => {
    console.log(event.dataTransfer.files.length);
    this.dispatch(DragEvent.FILE_DROPPED, event.dataTransfer.files[0]);
  }

  setTitle = (value: string) => {
    // this.title = value;
    this.saveToCookie();
  }

  setPlaceholderName = (name: string) => {
    this.placeholderName = name;
    this.saveToCookie();
  }

  setCollapsed = (value: boolean) => {
    this.collapsed = value;
    this.saveToCookie();
  }

  setView = (value: ReactNode, name: string) => {
    if (this.view === value) return;
    this.lastView = this.view;
    this.view = value;
    this.viewName = name;
    // this.title = value.title;
    this.saveToCookie();
  }

  saveToCookie = () => {
    cookie = this.toObject();
    cookies.set('ui', cookie, { sameSite: 'strict' });
  }

  showBreadcrumbs = () => {
    this.areBreadcrumbsVisible = true;
  }

  hideBreadcrumbs = () => {
    this.areBreadcrumbsVisible = false;
  }

  setViewMode = (targetViewMode: ViewModes) => {
    this.viewMode = targetViewMode;
  }

  handleWindowResize = () => {
    this.configureDeviceType();
  }

  setWindowResizeListener = () => {
    const { handleWindowResize } = this;
    window.addEventListener('resize', handleWindowResize);
  }

  setNavButtons(buttons: NavButton[]) {
    this.navButtons = buttons;
  }

  configureDeviceType = () => {
    const isMobile = isMobileBrowser();

    if (isMobile) {
      this.deviceType = DeviceType.MOBILE;
      return;
    }

    this.deviceType = DeviceType.DESKTOP;
  }
}

export default UI;