class Event {
    name:String;
    handler:Function;

    constructor(name:string, handler:Function){
        this.name = name;
        this.handler = handler;
    }
}
// below will be a jsdoc comment describing the class EventDispatcher and it's functionality in a clear, detailed, and concise manner.
/** 
 * This class provides the ability for any child class to dispatching events to listeners.
 * 
 * It provides the following methods:
 * 
 * - `on(eventName, handler)` - Adds a listener for the given event name
 * - `off(eventName, handler)` - Removes a listener for the given event name
 * - `dispatch(eventName, data)` - Dispatches an event with the given name and data
 * 
 * It also provides the following aliases:
 * 
 * - `addEventListener` - Alias for `on`
 * - `removeEventListener` - Alias for `off`
 * */
export default class EventDispatcher {
    eventsList:Array<Event> = [];

    /**
     * Adds a listener for the given event name
     * @param {string} eventName The name of the event to listen for
     * @param {Function} handler The function to call when the event is dispatched
     * */
    on(eventName:string, handler:Function) {
        for (let i in this.eventsList) {
            let event = this.eventsList[i];
            if (event.name === eventName && event.handler === handler) return;
        }
        this.eventsList.push(new Event(eventName, handler));
    };

    /**
     * Removes a listener for the given event name
     * @param {string} eventName The name of the event to stop listening for
     * @param {Function} handler The function to stop calling when the event is dispatched
    */
    off(eventName:string, handler:Function) {
        var events = [...this.eventsList];

        for (var i = this.eventsList.length; i >= 0; i--) {
            var event = this.eventsList[i];
            // TODO: find why sometimes there are undefined events
            if(!event) {
                events.splice(i, 1);
                continue;
            }
            if (event.name === eventName && event.handler === handler) {
                events.splice(i, 1);
                continue;
            } 
        }

        this.eventsList = events;
    };

    addEventListener = this.on;
    removeEventListener = this.off;

    /**
     * Dispatches an event with the given name and data
     * @param {string} eventName The name of the event to dispatch
     * @param {any} data The data to pass to the event handler, and any other listeners
     * */
    dispatch(eventName:string, data?:any) {
        this.eventsList.forEach(e => {
            if (e.name === eventName) e.handler(data);
        });
    };
}