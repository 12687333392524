import { Icon as BaseIcon, Button, Dropdown, Menu } from 'antd';
import Asset from 'stores/Asset';
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { UploadStates } from 'stores/Assets';
import store from 'stores';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
`;

const RestoreButton = styled(Button)`
  
`;

export interface FileRowButtonsProps {
  asset: Asset;
  selected: boolean;
}

class FileRowButtons extends React.Component<FileRowButtonsProps>{
  constructor(props: FileRowButtonsProps) {
    super(props);
  }

  handleDownload = async () => {
    const { downloadURL, filename } = this.props.asset;
    const image = await fetch(downloadURL);
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleRemove = () => {
    const { asset, selected } = this.props;
    const { animation } = store;

    animation.assets.remove(asset.id);
  }

  handleRestore = () => {
    const { asset } = this.props;
    const { animation } = store;

    animation.setCurrentAnimation(asset.id);
  }

  render() {
    const { asset, selected } = this.props;
    const { loading } = asset;

    UploadStates

    return (
      <Container>
        { !selected && <RestoreButton onClick={this.handleRestore} disabled={loading}>Restore</RestoreButton> }
        <Dropdown overlay={
          <Menu>
            <Menu.Item key="0" onClick={this.handleDownload} disabled={loading}>
              Download
            </Menu.Item>
            <Menu.Item key="1" onClick={this.handleRemove} disabled={loading}>
              Remove
            </Menu.Item>
          </Menu>
        } trigger={['click']}>
          <Button icon="ellipsis" />
        </Dropdown>
        
      </Container >
    );
  }
};

export default observer(FileRowButtons);
