import ArchiveBundleButton from './ArchiveBundleButton';
import Bundle from 'stores/Bundle';
import BundleLoadingSpinner from './BundleLoadingSpinner';
import BundleOptionsMenu from './BundleOptionsMenu';
import BundleReportButton from './BundleReportButton';
import BundleWarningIcon from './BundleWarningIcon';
import DownloadBundleButton from './DownloadBundleButton';
import GetBundleDownloadLinkButton from './GetBundleDownloadLinkButton';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 7px;
`;

const DateLabel = styled.div`
  font-size: 12px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  color: #666;
`;

const MainLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export interface BundleSummaryRowProps {
  bundle: Bundle;
}

class BundleSummaryRow extends React.Component<BundleSummaryRowProps>{
  constructor(props: BundleSummaryRowProps) {
    super(props);
  }

  render() {
    const { bundle } = this.props;
    const { name } = bundle.data;
    const { formattedDateModified } = bundle;

    return (
      <Container>
        <MainLabel>{name}</MainLabel>

        <ButtonsContainer>
          <DateLabel>{formattedDateModified}</DateLabel>
          <BundleLoadingSpinner bundle={bundle} />
          <BundleWarningIcon bundle={bundle} />
          <BundleReportButton bundle={bundle} />
          <GetBundleDownloadLinkButton bundle={bundle} />
          <DownloadBundleButton bundle={bundle} />
          <BundleOptionsMenu bundle={bundle} />
        </ButtonsContainer>
      </Container>
    );
  }
};

export default BundleSummaryRow;
