import AdListTable from './AdListTable'
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  border-right: 1px solid #f2ebeb;
  overflow-y: auto;
  display: grid;
  grid-template-rows: 50px 1fr;
  height: 100%;
`;

export interface AdListProps { };

class AdList extends React.Component<AdListProps>{
  constructor(props: AdListProps) {
    super(props);
  }

  render() {
    return (
      <Container>
        <AdListTable />
      </Container>
    );
  }
}

export default AdList;
