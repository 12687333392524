import { CommentsBubble } from 'components/UI';
import { CommentsBubbleVariant } from 'components/UI/CommentsBubble/CommentsBubble';
import { observer } from 'mobx-react';
import cn from 'classnames';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import TearsheetAd from 'stores/TearsheetAd';

const Container = styled.div`
  border-top: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 5px 8px;
  position: relative;
  transition: all .25s cubic-bezier(.11,.61,.44,.99);

  &:hover{
    background-color: #f4f4f4;
  }

  &.active{
    background-color: #e1f3ff;
    font-weight: bold;
  }
`;

export interface AdListTableProps { }

class AdListTable extends React.Component<AdListTableProps>{
  constructor(props: AdListTableProps) {
    super(props);
  }

  handleRowOnClick = (targetAd: TearsheetAd) => {
    const { tearsheet } = store;
    tearsheet.ads.getById(targetAd.id)?.navigateTo();
  }

  render() {
    const { tearsheet } = store;
    const { currentIndex } = tearsheet.ads;
    const { handleRowOnClick } = this;

    const ads = [...tearsheet.ads.all];

    return (
      <Container>
        {ads.map((ad) => (
          <Row
            className={cn({ 'active': ad.data.index === currentIndex })}
            key={`tearsheet.ads.${ad.id}`}
            onClick={() => handleRowOnClick(ad)}
          >
            {ad.label || '···'}

            {ad.numComments > 0 && (
              <CommentsBubble
                totalComments={ad.numComments}
                variant={ad.isRead ? CommentsBubbleVariant.WHITE : CommentsBubbleVariant.UNREAD}
              />
            )}
          </Row>
        ))}
      </Container>
    );
  }
};

export default observer(AdListTable);
