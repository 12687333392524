import { Button } from 'antd';
import React from 'react';

export interface HeaderButtonProps {
  className?: string;
  disabled?: boolean;
  label: string;
  onClick: () => void;
}

class HeaderButton extends React.Component<HeaderButtonProps>{
  constructor(props: HeaderButtonProps) {
    super(props);
  }

  render() {
    const {
      className,
      disabled,
      label,
      onClick,
    } = this.props;

    return (
      <Button
        className={className}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </Button>
    );
  }
};

export default HeaderButton;
