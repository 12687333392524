import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { AutoComplete, Input, Button, Icon, Select, Divider } from "antd";
import store from "../../../../../stores";
import { IDynamicProperty } from "../../../../../stores/DynamicProperties/Types";
import { AssetFileType } from "stores/Asset/Types";

const { Option } = Select;
const { TextArea } = Input;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  width: 100%;
`;

type AssetInputProps = {
  property: IDynamicProperty<any>;
  style?: React.CSSProperties | undefined;
  disabled: boolean;
  editMode?: boolean;
}

class AssetInput extends React.Component<AssetInputProps> {
  search = "";
  showDropdown = false;
  clicked = false;
  focused = false;
  value = null;
  property: IDynamicProperty<any>;

  constructor(props: AssetInputProps) {
    super(props);
    this.property = this.props.property;
  }

  handleChange = async (value: any, skipUpdate?:boolean) => {
    console.log('VAL', value);
    this.property.set(value);
  };

  render() {
    let property = this.property;
    let name = property.name;
    let value = property.value;

    if (this.focused) {
      value = this.property.value;
    }

    let options = store.creative.assets.all.filter(item => item.data.type !== AssetFileType.FOLDER).map((item) => {
      return (
        // @ts-ignore
        <Option key={item.id} value={item.id}>
          {item.data.path}
        </Option>
      );
    });

    options.unshift(
      // @ts-ignore
      <Option key={'none'} value={''}>
        {'Select None'}
      </Option>
    );

    // if (customFilter && customFilter.test) {
    //   options = options.filter(item => {
    //     return customFilter.test(item.props.path);
    //   });
    // }

    return (
      <Container key={name} className={this.search ? "hide-dropdown" : ""} style={this.props.style}>
        <Select
          showSearch
          style={{ width: "100%" }}
          value={store.creative.assets.getById(this.property.value)?.id}
          onChange={(e: any) => this.handleChange(e)}
          placeholder="Select an asset"
          disabled={this.props.disabled}
          onBlur={() => {
            this.showDropdown = false;
            this.focused = false;
            // document.onkeydown = (e) => {};
          }}
          onFocus={() => {
            this.clicked = true;
            this.focused = true;
            // document.onkeydown = (e) => { this.handleKeyPress(e) };
          }}
          filterOption={(input, option) => {
            let thisOption = option.props.children as string;
            thisOption.toLowerCase();
            return thisOption.indexOf(input.toLowerCase()) >= 0
          }
          }
        >
          {options}
        </Select>
      </Container>
    );
  }
}

export default observer(AssetInput);

