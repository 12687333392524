const BundleIcon = () => (
  <svg className="icon" version="1.1" id="Layer_1" x="0px" y="0px" width="25px" height="24px" viewBox="0 0 101.4 91" fill="currentColor" preserveAspectRatio="xMinYMin meet">
    <path d="M91.1,55.5c0-6.7-3.4-12.5-8.6-16V21.8c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1v-0.8c0,0,0-0.1,0-0.1c0-0.8-0.4-1.5-1.1-1.8
		L42.6,0.2C42.2,0,41.7,0,41.2,0.1C40.8,0,40.3,0,39.9,0.2L1.1,18.8c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.6,0.4-0.9,1-0.9,1.7
		v1v40.7v1c0,0.8,0.4,1.5,1.1,1.8l38.7,18.6c0.3,0.1,0.6,0.2,0.9,0.2c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3,0.1,0.5,0.1
		c0.3,0,0.6-0.1,0.8-0.2c0,0,0,0,0,0L63,73.9c2.4,1.1,5.1,1.8,7.9,1.8c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0,0.6,0
		c10.5,0,19.1-8.6,19.1-19.1c0-0.2,0-0.4,0-0.6C91,55.8,91.1,55.7,91.1,55.5z M38.7,77.2L5,61V25.3l6,2.9v19.7v1
		c0,0.7,0.4,1.4,1,1.7c0.5,0.3,1,0.3,1.5,0.2c0.5,0.1,1,0.1,1.5-0.2l4.9-2.7l7.3,9.8c0.4,0.5,1,0.8,1.6,0.8c0.2,0,0.3,0,0.5-0.1
		c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.6-0.1c0.8-0.3,1.4-1,1.4-1.9v-1V38.2l6.9,3.3V77.2z M44.5,23.7L29.3,31l-9.6-4.6l15.2-7.3
		L44.5,23.7z M16.1,30.6l10.8,5.2v12.3l-4-5.4c-0.4-0.5-1-0.8-1.6-0.8c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.3-0.1-0.5-0.1
		c-0.3,0-0.7,0.1-1,0.3l-3.2,1.8V30.6z M35.5,34L52,26c0.7-0.3,1.1-1,1.1-1.8c0-0.2,0-0.3-0.1-0.5c0-0.2,0.1-0.3,0.1-0.5
		c0-0.8-0.4-1.5-1.1-1.8l-15.8-7.6c-0.4-0.2-0.9-0.2-1.4-0.1c-0.5-0.1-0.9-0.1-1.4,0.1l-19.9,9.7l-4.9-2.3L41.2,5.5l32.5,15.7
		l-13.3,6.4l-19.2,9.2L35.5,34z M51.9,56.2c-0.9,6.4,6,14.2,6,14.2l-14.2,6.8V41.5L64,31.8l13.5-6.5v12c-1.7-0.5-3.6-0.8-5.4-0.8
		c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0c-10.5,0-19.1,8.6-19.1,19.1c0,0.2,0,0.3,0,0.5C51.9,56.1,51.9,56.1,51.9,56.2 M51.9,56.2
		 M56.9,56c0.3-7.9,6.6-14.3,14.6-14.6C79.4,41.7,85.8,48.1,86,56c-0.3,7.9-6.6,14.3-14.6,14.6C63.5,70.3,57.2,64,56.9,56z"/>
    <path d="M80.9,48.9c-0.5-0.5-1.2-0.6-1.8-0.4c-0.7-0.2-1.5,0-2,0.6l-7.1,8.1l-2.5-2.5c-0.5-0.5-1.2-0.7-1.9-0.5
		c-0.7-0.2-1.4,0-1.9,0.5c-0.5,0.5-0.7,1.2-0.5,1.9c-0.2,0.7,0,1.4,0.5,1.9l4.6,4.5c0.4,0.4,0.9,0.6,1.4,0.6c0,0,0,0,0.1,0
		c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3,0.1,0.5,0.1c0,0,0,0,0.1,0c0.6,0,1.1-0.3,1.4-0.7l9-10.3c0.5-0.5,0.6-1.2,0.4-1.8
		C81.7,50.2,81.5,49.4,80.9,48.9z"/>
  </svg>
);

export default BundleIcon;
