import { TagsManager as BaseTagsManager } from 'components/UI';
import { observer } from 'mobx-react';
import React from 'react';
import type DocumentFolder from 'stores/DocumentFolder';

export interface TagsManagerProps {
  parentDocument: DocumentFolder;
  title?: string;
};

class TagsManager extends React.Component<TagsManagerProps>{
  constructor(props: TagsManagerProps) {
    super(props);
  }

  render() {
    const { parentDocument, title = "Tags" } = this.props;

    return (
      <BaseTagsManager
        activeTags={parentDocument.data?.tags}
        addTag={parentDocument?.addTag}
        removeTag={parentDocument?.removeTag}
        title={title}
      />
    );
  }
};

export default observer(TagsManager);
