import { Button as BaseButton, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { SelectState } from 'stores/AdsManager/Types';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  padding: 0 10px;
`;

export interface SelectionCheckboxProps { }

class SelectionCheckbox extends React.Component<SelectionCheckboxProps>{
  constructor(props: SelectionCheckboxProps) {
    super(props);
  }

  handleCheckToggle() {
    const ads = store.creative.ads;
    const selectionStatus = ads.selectState;

    if (selectionStatus === SelectState.ALL) {
      ads.selectNone();
    }

    if (selectionStatus === SelectState.PARTIAL) {
      ads.selectAll();
    }

    if (selectionStatus === SelectState.NONE) {
      ads.selectAll();
    }
  }

  render() {
    const { handleCheckToggle } = this;
    const selectionStatus = store.creative.ads.selectState;

    return (
      <Button onClick={handleCheckToggle}>
        <Checkbox
          checked={selectionStatus === SelectState.ALL}
          indeterminate={selectionStatus === SelectState.PARTIAL}
        />
      </Button>
    );
  }
};

export default observer(SelectionCheckbox);
