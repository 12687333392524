import { reaction } from 'mobx';
import { firestore, FirestoreEvents, storageRef, db, functionAuthRequest } from './Firebase';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import Template, { TemplateSchema } from './Template';
import root from './index';
import { appConfig as config } from '../config';
import { urlJoin } from 'url-join-ts';
import DocumentCollection from './DocumentCollection';
import Creative from './Creative';
import { DocumentCollectionType, DocumentStatus, ProcessingStatus } from './Types';
import FirebaseFunctions from './FirebaseFunctions';
import { DynamicPropertyData, Group as DynamicPropertyGroup } from './DynamicProperties/Types';
import { PropertyConflict } from './DynamicProperties/Manager';
import TemplateUploadManager from './TemplateUploadManager';
import { deepCopy } from '../utils';

enum TemplateStatus {
    COMPLETE = 'complete',
    // TODO: Find out all statuses for templates.
}

enum UploadStates {
    UPLOADING = 'uploading',
    UPLOAD_FAILED = 'upload_failed',
    NONE = '',
}

export default class Templates extends DocumentCollection<Template, TemplateSchema> {
    uploadState = UploadStates.NONE;
    baseURL = 'https://' + config.hostingBucket;
    uploadConflicts: PropertyConflict[] = [];
    uploadHasConflicts = false;
    uploadManager = new TemplateUploadManager(this);

    get allReady() {
        return this.all.filter(item => item.data.processing === false);
    }

    get processing() {
        return this.all.filter(item => item.data.processing === true);
    }

    constructor(parent: Creative) {
        super(parent, Template);
        this.init();
    }

    upload = async (templateName: string, selectedFile: File, isUpdate: boolean, replaceAssets: boolean) => {
        let firstTemplate = this.all.length === 0;
        let template = this.getByName(templateName);

        const nowMillis = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000000);
        const path = `templates/${root.creative.id}/${nowMillis}_${randomNumber}/${selectedFile.name}`;
        let fileRef = storageRef.child(path);
        try {
            await storageRef.child(path).put(selectedFile); //create a child directory called images, and place the file inside this directory
        } catch (e) {
            // Handle unsuccessful uploads
            console.log('Template upload failed:', e);
            throw 'Template upload failed.';
        }

        if (!template) {
            let templateProperties = new TemplateSchema();
            templateProperties.name = templateName;
            templateProperties.status = ProcessingStatus.COMPLETE; // This must be complete so that we can create a document reference before calling replaceCreativeTemplate.
            templateProperties.revision = 0;

            template = await this.add(templateProperties);
            template.data.id = template.docRef.id;
            template.data.status = ProcessingStatus.PENDING;
            replaceAssets = true;
        }

        try {
            let response = await functionAuthRequest('replaceCreativeTemplate', {
                id: template.id,
                name: templateName,
                storage: path,
                replaceExtractedAssets: replaceAssets
            }, root.creative);
        } catch (error) {
            // Handle unsuccessful uploads
            console.log('Templates: Update template failed:', JSON.stringify(error));
            throw 'Templates: Update template failed.';
            // this.remove(templateName);
        }

        await this.checkTemplateReady(template);
        await template.docRef.update({ storage: fileRef.fullPath });
        await template.load();

        let creative = this.parent as Creative;

        let templateProps = deepCopy(template.data.dynamicProperties) as DynamicPropertyData[];
        let templatePropGroup = new DynamicPropertyGroup(null, 'Template Properties', templateProps);

        let conflictedProps = creative.dynamicProperties.findTypeConflicts(templatePropGroup);
        let missingProps = creative.dynamicProperties.findMissing(templatePropGroup);
        this.uploadConflicts = conflictedProps; // TODO: template new, prop conflicts - fix this!
        this.uploadHasConflicts = conflictedProps.length > 0 ? true : false;

        if (firstTemplate) {
            console.log(`Templates: ${templateName}: First template finished processing. Setting default dynamic properties.`);
            creative.setDefaultDynamicProperties(template);
            await creative.saveDynamicProperties();
            await creative.save();
        }

        if (!isUpdate) {
            creative.ads.newFromTemplate(template); // <-- START HERE
        }

        return templateName;
    }

    clearConflicts = () => {
        this.uploadConflicts = [];
        this.uploadHasConflicts = false;
    }

    remove = async (id: string) => {
        this.status = DocumentStatus.LOADING;

        let template = this.getById(id);
        var response = await functionAuthRequest('deleteCreativeTemplateById', {
            id: id
        }, root.creative);
        template?.setStatus(ProcessingStatus.PENDING);
        if (response.result === 'success') {
            console.log('Templates: Successfully removed template', template?.data.name);
        } else {
            template?.delete();
        }

        this.status = DocumentStatus.NONE;
        return id;
    }

    getHostedURL = (id: string) => {
        let template = this.getByName(id);
        if (template) {
            return urlJoin(this.baseURL, template.data.prefix, template.data.entry);
        } else {
            return '';
        }
    }

    checkTemplateReady = (template: Template) => {
        return new Promise((resolve, reject) => {
            if (template.data.status === ProcessingStatus.COMPLETE) {
                setTimeout(() => resolve(true), 100);
            }
            let statusDisposer = reaction(() => template.data.status, (status, reaction) => {
                if (template.data.status === ProcessingStatus.COMPLETE) {
                    statusDisposer();
                    setTimeout(() => resolve(true), 100);
                }
            });
        });
    }

    reset = () => {
        super.reset();
        this.uploadState = UploadStates.NONE;
        this.baseURL = '';
        this.uploadConflicts = [];
        this.uploadHasConflicts = false;
        this.uploadManager.reset();
    }
}