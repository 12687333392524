import {
  Button as BaseButton,
  Dropdown,
  Icon,
  Menu,
  message,
} from 'antd';
import { observer } from 'mobx-react';
import { urlJoin } from 'url-join-ts';
import { ViewModes } from 'components/Enums';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import type TearsheetAd from 'stores/TearsheetAd';

const Button = styled(BaseButton)`
  align-items: center;
  display: flex;
  font-size: 14px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 3px;
`;

export interface CopyLinksButtonProps { }

class CopyLinksButton extends React.Component<CopyLinksButtonProps>{
  constructor(props: CopyLinksButtonProps) {
    super(props);
  }

  getBaseUrl = (ad: TearsheetAd, labeled?: boolean) => {

    if(!ad) {
      console.error('Tearsheet: Ad not found');
      return;
    }

    const label = ad.data.label;
    const index = String(ad.data.index + 1);

    let url = `${urlJoin(
      window.location.origin,
      'tearsheet',
      store.router.params?.clientID,
      store.router.params?.projectID,
      store.router.params?.creativeID,
      store.router.params?.tearsheetID,
      index)
      }?mode=${ViewModes.FRAMELESS}`;

    if (labeled) {
      url = `${label} - ${url}`;
    }

    return url;
  }

  copyLabeledLinksToClipboard = async () => {
    const { getBaseUrl } = this;

    const ads = [...store.tearsheet.ads.all];
    let copyString = '';

    for (let i = 0; i < ads.length; i++) {
      let newLine = i < ads.length - 1 ? '\n' : '';
      copyString += getBaseUrl(ads[i], true) + newLine;
    }

    await navigator.clipboard.writeText(copyString);
    message.success('Ad links copied.');
  }

  copyURLsToClipboard = async () => {
    const { getBaseUrl } = this;

    const ads = [...store.tearsheet.ads.all];
    let copyString = '';

    for (let i = 0; i < ads.length; i++) {
      let newLine = i < ads.length - 1 ? '\n' : '';
      copyString += getBaseUrl(ads[i], false) + newLine;
    }

    copyString += '\n';

    await navigator.clipboard.writeText(copyString);
    message.success('Ad links copied.');
  }

  render() {
    const {
      copyLabeledLinksToClipboard,
      copyURLsToClipboard,
    } = this;

    return (
      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item onClick={copyLabeledLinksToClipboard}>
              Labeled
            </Menu.Item>

            <Menu.Item onClick={copyURLsToClipboard}>
              URLs
            </Menu.Item>
          </Menu>
        )}
      >
        <Button>
          <Icon type="link" />
          Copy Links
        </Button>
      </Dropdown>
    );
  }
};

export default observer(CopyLinksButton);
