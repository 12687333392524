import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Bar = styled.span`
  border-radius: 5px;
  border: 1px solid #eaeaea;
  color: #aba8a8;
  font-size: 14px;
  font-weight: normal;
  height: 100%;
  min-height: 32px;
  padding: 4px 8px;
  width: 100%;
`;

export interface CurrentPathBarProps { }

class CurrentPathBar extends React.Component<CurrentPathBarProps>{
  constructor(props: CurrentPathBarProps) {
    super(props);
  }

  render() {
    const { creative } = store;
    const currentPath = creative.assets.currentFolder;

    return (
      <Bar>
        {`/${currentPath}`}
      </Bar>
    );
  }
};

export default observer(CurrentPathBar);
