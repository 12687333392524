import { NavHeader, NavSidebar } from 'components/UI';
import { observer } from 'mobx-react';
import MainContent from 'components/MainContent';
import React from 'react';
import SimpleHeader from 'components/SimpleHeader';
import store from 'stores';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  
  p {
    margin-top: 10px;
  }
`;

class DesktopLayout extends React.Component {
  constructor(props: {}) {
    super(props);
  }

  render() {
    const { authenticated } = store.user;

    if (!authenticated) {
      return (
        <>
          <Container>
            <SimpleHeader />
            <MainContent />
          </Container>
        </>
      );
    }

    return (
      <>
        <NavSidebar />
        <Container>
          <NavHeader />
          <MainContent />
        </Container>
      </>
    );
  }
};

export default observer(DesktopLayout);
