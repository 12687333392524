import {
  AssetsDrawer,
  BundlesDrawer,
  PropertiesDrawer,
  FiltersDrawer,
  TearsheetsDrawer,
  TemplatesDrawer,
} from './Drawers';
import { observer } from 'mobx-react';
import AdList from './AdList';
import AdView from './AdView';
import { ChatBox } from 'components/UI';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import { IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { PanelViews } from 'stores/CreativeTypes';
import UploadAssetModal from './Drawers/AssetsDrawer/UploadAssetModal';
import UploadTemplateModal from './Drawers/TemplatesDrawer/UploadTemplateModal';
import { DragEvent } from 'stores/UI';
import { getFilenameExtention } from 'utils';

const Container = styled.div.attrs({
  className: 'creative-root',
})`
  display: grid;
  grid-template-columns: 500px 1fr;
  height: 100%;
  position: relative;
`;

export interface CreativeProps { }


class Creative extends React.Component<CreativeProps> {
  draggingReactionDisposer: IReactionDisposer;
  showUploadTemplateModal = false;
  showUploadAssetModal = false;
  currentDroppedFile: File;
  tempShowPanel: PanelViews | undefined;
  setTemplateFileDelegate: (file: File) => void;
  setAssetFileDelegate: (file: File) => void;

  constructor(props: CreativeProps) {
    super(props);

    store.ui.addEventListener(DragEvent.FILE_DROPPED, this.handleFileDropped);
    store.ui.addEventListener(DragEvent.FILE_DRAG_OVER, this.handleFileDragStart);
    store.ui.addEventListener(DragEvent.FILE_DRAG_OUT, this.handleFileDragEnd);

    makeObservable(this, {
      currentDroppedFile: observable,
      showUploadTemplateModal: observable,
      showUploadAssetModal: observable,
    });
  }

  componentWillUnmount() {
    if(this.draggingReactionDisposer) this.draggingReactionDisposer();
    store.ui.removeEventListener(DragEvent.FILE_DROPPED, this.handleFileDropped);
    store.ui.removeEventListener(DragEvent.FILE_DRAG_OVER, this.handleFileDragStart);
    store.ui.removeEventListener(DragEvent.FILE_DRAG_OUT, this.handleFileDragEnd);
    store.creative.reset();
  }

  handleFileDropped = (file: File) => {
    this.tempShowPanel = undefined;
    if(!file) return;
    if(getFilenameExtention(file.name) === '.zip') store.creative.setPanel(PanelViews.TEMPLATES);
    else store.creative.setPanel(PanelViews.ASSETS);

    if (store.creative.displayPanel === PanelViews.TEMPLATES) {
      store.creative.templates.uploadManager.setFile(file);
      store.creative.templates.uploadManager.show();
    }

    if (store.creative.displayPanel === PanelViews.ASSETS) {
      store.creative.assets.uploadManager.setFile(file);
      store.creative.assets.uploadManager.show();
    }
  }

  handleFileDragStart = () => {
    let { lastDisplayPanel, displayPanel, templates, setPanel } = store.creative;
    let hasTemplates = templates.all.length !== 0;
    let showingFileDrawer = displayPanel === PanelViews.ASSETS || displayPanel === PanelViews.TEMPLATES;

    let showTemplatesPanel = hasTemplates === false && showingFileDrawer === false;
    let showAssetsPanel = hasTemplates && showingFileDrawer === false;

    if (showTemplatesPanel) {
      return setPanel(PanelViews.TEMPLATES);
    }
    if (showAssetsPanel) {
      return setPanel(PanelViews.ASSETS);
    }
  }

  handleFileDragEnd = () => {
    let { setPanel } = store.creative;
  }

  render() {
    if(store.current && store.current.data.name) document.title = store.current.data.name;
    const creative = store.creative;
    const currentAd = creative?.ads?.current;

    return (
      <>
        {creative && (
          <Container>
            <AdList />
            <AdView />

            <AssetsDrawer />
            <PropertiesDrawer />
            <BundlesDrawer />
            <FiltersDrawer />
            <TearsheetsDrawer />
            <TemplatesDrawer />

            <UploadAssetModal 
              key={'UploadAssetModal'}
              // setFileDelegate={(func) => this.setAssetFileDelegate = func }
            />
            <UploadTemplateModal 
              key={'UploadTemplateModal'} 
            />
            {/* check if currentAd exists. if so render ChatBox passing in the ad reference */}
            { currentAd && <ChatBox ad={currentAd} /> }
          </Container>
        )}
      </>
    );
  }
}

export default observer(Creative);
