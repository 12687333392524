import { DynamicPropertyData, IDynamicProperty } from './DynamicProperties/Types';
import { DynamicPropertyType } from './DynamicProperties/Types/DynamicPropertyType';
import DynamicGroup, { DynamicGroupEvent, DynamicGroupStatus } from './DynamicProperties/Types/Group';
import Ad from './Ad';
import Store from './Store';
import AdsManager from './AdsManager';
import store from './index';
import { LooseObject } from './Types';

export const FILTER_MANAGER_DEFAULT_SET_NAME = 'customSet';

/** 
 * @class FilterManager
 * @description
 * The FilterManager manages the filters that are applied to the list of Ads in a Creative.
 * It also keeps track of the current set of filters, and handles the saving 
 * and loading of sets of filters. It also keeps track of the list of all available filters.
 * 
 * The FilterManager is a store, which means that is keeps track of its own state, 
 * and can be observed for changes.
 * */
export default class FilterManager extends Store {
  all = new DynamicGroup(null, 'allAvailableProperties', []);
  currentSet = new DynamicGroup(null, 'emptyPlaceholder', []);
  customSet = new DynamicGroup(null, FILTER_MANAGER_DEFAULT_SET_NAME, []);
  sets = new DynamicGroup(null, 'filterSets', []);
  parent: AdsManager;

  newSetPreliminarName = '';
  showNewSetModal = false;

  constructor(parent: AdsManager, data?: DynamicPropertyData[]) {
    super();
    this.parent = parent;
    this.currentSet = this.customSet;
    this.init();
  }

  get setNames() { return this.sets.properties.map(set => set.name) }

  setCurrentSetByName = (name: string) => {
    let set = this.sets.getByName(name);
    if (set) {
      this.setQueryParam(name);
      this.currentSet = set as DynamicGroup;
    }
  }

  setQueryParam = (name?: string | undefined) => {
    let queryParams = store.router.queryParams as LooseObject;
    if (!name) {
      delete queryParams.filterSetName;
    } else {
      queryParams.filterSetName = name;
    }
  }

  setCurrentSet = (set: DynamicGroup) => {
    this.setQueryParam(set.name);
    this.currentSet = set;
  }

  resetToCustomSet = () => {
    // this.clearCustomSet();
    this.setQueryParam();
    this.currentSet = this.customSet;
  }

  resetCurrentSet = () => {
    this.setQueryParam();
    this.currentSet.reset();
  }

  clearCustomSet = () => {
    this.setQueryParam();
    this.currentSet.reset();
  }

  saveCurrentSetAs = (name: string) => {
    if (this.setNames.includes(name)) {
      throw new Error(`Filter Set "${name}" already exists`);
    } else {
      let newGroup = this.sets.addGroup(name, [... this.currentSet.properties]);
      this.setQueryParam(name);
      this.setCurrentSet(newGroup);
      this.customSet.reset();
    }
  }

  parseFilterPropertiesFromAd = (ad: Ad) => {
    let ignore: string[] = [
      DynamicPropertyType.GROUP,
      DynamicPropertyType.SVGText,
      DynamicPropertyType.DIMENSIONS,
      DynamicPropertyType.LABEL
    ];

    for (let property of ad.properties.properties) {
      if (ignore.includes(property.type)) continue;

      let propertyExists = this.checkPropertyExists(property);

      if (!propertyExists) this.all.add(property.name, property.type, property.value);
    }
  }

  addProperty = (property: IDynamicProperty<any>) => {
    let propertyExists = this.checkPropertyExists(property);
    let isAlreadySelected = this.checkIsAlreadySelected(property);

    if (!propertyExists) return;
    if (!isAlreadySelected) {
      this.currentSet.add(property.name, property.type, property.value);
    }
  }

  addPropertyMultiple = (properties: IDynamicProperty<any>[]) => {
    for (let property of properties) {
      this.addProperty(property);
    }
  }

  removeProperty = (property: IDynamicProperty<any>) => {
    this.currentSet.removeProperty(property);
  }

  getFilteredAds = (ads: Ad[]) => {
    let filteredAds: Ad[] = [];
    let setProperties = [...this.currentSet.properties];

    if (setProperties.length === 0) return ads;
    
    const propertyNamesInSet = [...new Set(setProperties.map(item => item.name))];

    for (let ad of ads) {
      let propertyMatches = 0;
      
      for (let propertyName of propertyNamesInSet) {
        let properties = setProperties.filter(property => property.name === propertyName);
        let match = false;
        

        for (let property of properties) {
          let adProperty = ad.properties.getByName(property.name);
          if(adProperty?.value === property.value) {
            match = true;
            break;
          }
        }

        if(match) propertyMatches++;
      }

      if(propertyMatches === propertyNamesInSet.length) filteredAds.push(ad);
    }

    return filteredAds
  }

  checkPropertyExists = (property: IDynamicProperty<any>) => {
    return this.all.checkExists(property);
  }

  checkIsAlreadySelected = (property: IDynamicProperty<any>) => {
    return this.currentSet.checkExists(property);
  }

  openNewSetModal = (preliminarName?: string) => {
    this.showNewSetModal = true;
    this.newSetPreliminarName = preliminarName || '';
  }

  closeNewSetModal = () => {
    this.showNewSetModal = false;
    this.newSetPreliminarName = '';
  }
}