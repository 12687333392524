import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import { StarredTable, ViewsLayout } from '../../UI';
import { Container } from './Dashboard.styles';

export interface DashboardProps { }

class Dashboard extends React.Component<DashboardProps>{
  constructor(props: DashboardProps) {
    super(props);
  }

  render() {
    document.title = 'Dashboard';

    const isLoading = store.user.starred.loading;
    const hasBeenLoaded = store.user.starred.loaded;

    const starredClients = [...store.user.starred.clients];
    const starredCreatives = [...store.user.starred.creatives];
    const starredProjects = [...store.user.starred.projects];

    return (
      <ViewsLayout
        isLoading={isLoading && !hasBeenLoaded}
      >
        <Container>
          <StarredTable
            items={starredClients}
            onItemClick={(record) => {
              record.navigateTo();
            }}
            title="Starred Clients"
          />
          <StarredTable
            items={starredProjects}
            onItemClick={(record) => {
              record.navigateTo();
            }}
            title="Starred Projects"
          />
          <StarredTable
            items={starredCreatives}
            onItemClick={(record) => {
              record.navigateTo();
            }}
            title="Starred Creatives"
          />
        </Container>
      </ViewsLayout>
    );
  }
}

export default observer(Dashboard);
