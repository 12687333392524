import { observer } from 'mobx-react';
import React from 'react';
import DocumentStore from 'stores/DocumentStore';


interface LinkProps {
    document: DocumentStore<any, any>;
    url?: string;
}

class Link extends React.Component<LinkProps> {
    constructor(props: LinkProps) {
        super(props);
    }

    render() {
        let { navigateTo } = this.props.document;

        return (
            <a href={'/'+this.props.url} onClick={navigateTo}>{this.props.children}</a>
        )
    }
}

export default observer(Link);
