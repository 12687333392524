import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Creative from '../Creative';
import Animation from '../Animation';
import { DocumentType } from 'stores/Types';
import store from 'stores';

const Container = styled.div`
  margin: 0;
  padding: 0;
  height: 100%;
  background: #f0f2f5;
`;

class Document extends React.Component {
  componentDidMount() {
    document.title = '';
  }

  getDocumentView() {
    if (store.documentType === DocumentType.CREATIVE) {
      console.log('Creative VIEW');
      return <Creative />;
    }
    if (store.documentType === DocumentType.ANIMATION) {
      console.log('Animation VIEW');
      return <Animation />;
    }
    return <></>;
  }

  render() {
    return (
      <Container>
        {this.getDocumentView()}
      </Container>
    );
  }
}

export default observer(Document);