import { FormModal } from 'components/UI';
import { Input, message as AlertTriggerer } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import type DocumentFolder from 'stores/DocumentFolder';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { DocumentType } from 'stores/Types';

const ADD_CLIENT_MODAL_TITLE = 'Add Client';
const ADD_CLIENT_SUBMIT_BUTTON_LABEL = 'Add';

export interface AddClientModalProps {
  isVisible: boolean;
  onClose: () => void;
  parentDocument: DocumentFolder;
};

type valuesType = {
  title: string;
};

class AddClientModal extends React.Component<AddClientModalProps>{
  isLoading: boolean = false;

  constructor(props: AddClientModalProps) {
    super(props);

    makeObservable(this, {
      isLoading: observable,
    });
  }

  turnOnLoader = () => {
    this.isLoading = true;
  }

  turnOffLoader = () => {
    this.isLoading = false;
  }

  handleFormSubmit = async (originalValues: unknown, formController: WrappedFormUtils<unknown>) => {
    const { parentDocument } = this.props;
    const values = originalValues as valuesType;

    const { turnOffLoader, turnOnLoader } = this;

    try {
      turnOnLoader();

      await parentDocument.documents.add({
        name: values.title,
        type: DocumentType.CLIENT
      });

      formController.resetFields();
      this.props.onClose();

    } catch (e) {
      AlertTriggerer.error((<span>Something went wrong. Please <b>try again</b>...</span>));
      console.error('Error creating the new client:', e);
    }
    finally {
      turnOffLoader();
    }
  }

  render() {
    const { isLoading } = this;
    const { isVisible, onClose } = this.props;
    const { handleFormSubmit } = this;

    return (
      <FormModal
        isLoading={isLoading}
        isVisible={isVisible}
        onClose={onClose}
        onSubmit={handleFormSubmit}
        resetOnClose
        submitText={ADD_CLIENT_SUBMIT_BUTTON_LABEL}
        title={ADD_CLIENT_MODAL_TITLE}>
        {
          ({ formController: { getFieldDecorator } }) => (
            <>
              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  rules: [
                    { required: true, message: 'A client name is required.' },
                    { pattern: /^\S.*$/, message: 'The client name cannot start with a space.' },
                  ]
                })(<Input disabled={isLoading} />)}
              </Form.Item>
            </>
          )
        }
      </FormModal>
    );
  }
}

export default observer(AddClientModal);