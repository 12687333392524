import {
  Button as BaseButton,
  Dropdown,
  Icon,
  Input,
  Menu,
  message,
  Modal,
} from 'antd';
import { makeAutoObservable, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import { SelectState } from 'stores/AdsManager/Types';
import { PanelViews } from 'stores/CreativeTypes';
import styled from 'styled-components';
import { removeExtraSpaces, removeSpecialCharacters } from 'utils';

const MENU_BUTTON_LABEL = 'Actions';

const Button = styled(BaseButton)`
  padding: 0 10px;
`;

export interface SelectionMenuProps { }

class SelectionMenu extends React.Component<SelectionMenuProps>{
  confirmRemoveAdVisible = false;
  confirmMakeBundleVisible = false;
  confirmMakeTearsheetVisible = false;
  loading = false;
  bundleName = '';
  tearsheetName = '';

  constructor(props: SelectionMenuProps) {
    super(props);
    makeObservable(this, {
      confirmRemoveAdVisible: observable,
      confirmMakeBundleVisible: observable,
      confirmMakeTearsheetVisible: observable,
      loading: observable,
      bundleName: observable,
      tearsheetName: observable,
    });
  }

  handleNewTearsheet = async () => {
    this.loading = true;
    let response = await store.creative.tearsheets.new(this.tearsheetName, store.creative.ads.selected);
    this.loading = false;
    console.log('response', response);
    if(response.error) {
      message.error(response.error);
    } else {
      message.success('Tearsheet created successfully');
    }
    this.confirmMakeTearsheetVisible = false;
    store.creative.setPanel(PanelViews.TEARSHEETS);
  }

  handleRemoveAds = async () => {
    this.loading = true;
    await store.creative.ads.removeMultipleAds(store.creative.ads.selected);
    this.confirmRemoveAdVisible = false;
    this.loading = false;
  }

  handleDuplicateAds = () => {
    let ads = store.creative.ads.selected.map(ad => { return ad.cloneData() });
    let insertIndex = ads[ads.length - 1].index + 1;
    store.creative.ads.addMultipleAds(ads, insertIndex);
  }

  handleBundleAds = async () => {
    this.loading = true;
    this.bundleName = removeExtraSpaces(this.bundleName);
    await store.creative.bundles.new(this.bundleName, store.creative.ads.selected);
    store.creative.setPanel(PanelViews.BUNDLES);
    this.confirmMakeBundleVisible = false;
    this.loading = false;

  }

  handleCSVExport = () => {
    let ads = store.creative.ads.selected;
    store.creative.ads.getCSV();
  }

  handleCSVImport = async () => {
    try {
      await store.creative.ads.importCSV();
    } catch (err: any) {
      message.error(err.message, 10);
    };
  }

  handleSaveSelected = async () => {
    await store.creative.ads.updateMultiple(store.creative.ads.selected);
    message.success('Ads successfully saved.', 5);
  }

  handleRevertSelected = () => {
    store.creative.ads.selected.forEach(ad => ad.properties.revert());
  }

  handleSelectChanged = () => {
    store.creative.ads.selectChanged();
  }

  render() {
    let selectState = store.creative.ads.selectState;
    let noAdsSelected = selectState === SelectState.NONE;
    return (
      <>
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item disabled={store.creative.ads.length === 0} onClick={() => this.handleSelectChanged()}>
                Select Changed
              </Menu.Item>
              <Menu.Item disabled={noAdsSelected} onClick={() => this.handleSaveSelected()}>
                Save Selected
              </Menu.Item>
              <Menu.Item disabled={noAdsSelected} onClick={() => this.handleRevertSelected()}>
                Revert Selected
              </Menu.Item>
              <Menu.Item disabled={noAdsSelected} onClick={() => this.confirmRemoveAdVisible = true}>
                Remove Selected
              </Menu.Item>
              <Menu.Item disabled={noAdsSelected} onClick={() => this.handleDuplicateAds()}>
                Duplicate Selected
              </Menu.Item>
              <Menu.Item disabled={noAdsSelected} onClick={() => this.confirmMakeBundleVisible = true}>
                Create Bundle
              </Menu.Item>
              <Menu.Item disabled={noAdsSelected} onClick={() => this.confirmMakeTearsheetVisible = true}>
                Create Tearsheet
              </Menu.Item>
              <Menu.Item disabled={noAdsSelected} onClick={this.handleCSVExport}>
                Download CSV
              </Menu.Item>
              <Menu.Item onClick={this.handleCSVImport}>
                Import CSV
              </Menu.Item>
            </Menu>
          )}
        >
          <Button>
            <Icon type="down" />
            {MENU_BUTTON_LABEL}
          </Button>
        </Dropdown>
        <Modal
          title="Remove ads"
          visible={this.confirmRemoveAdVisible}
          onOk={this.handleRemoveAds}
          confirmLoading={this.loading}
          onCancel={() => { this.confirmRemoveAdVisible = false; this.loading = false; }}
          okText="Yes"
          cancelText="No"
        >
          <p>{`Are you sure you want to permanently remove ${store.creative.ads.selected.length} ad${store.creative.ads.selected.length > 1 ? 's' : ''}?`}</p>
        </Modal>
        <Modal
          title="Create Bundle"
          visible={this.confirmMakeBundleVisible}
          okButtonProps={{ disabled: this.bundleName ? false : true }}
          onOk={this.handleBundleAds}
          confirmLoading={this.loading}
          onCancel={() => { this.confirmMakeBundleVisible = false; this.loading = false; }}
          okText="Create"
          cancelText="Cancel"
        >
          <Input placeholder='Enter a bundle name' value={this.bundleName} onChange={e => this.bundleName = removeSpecialCharacters(e.target.value)}></Input>
        </Modal>
        <Modal
          title="Create Tearsheet"
          visible={this.confirmMakeTearsheetVisible}
          okButtonProps={{ disabled: this.tearsheetName ? false : true }}
          onOk={this.handleNewTearsheet}
          confirmLoading={this.loading}
          onCancel={() => { this.confirmMakeTearsheetVisible = false; this.loading = false; }}
          okText="Create"
          cancelText="Cancel"
        >
          <Input placeholder='Enter a bundle name' value={this.tearsheetName} onChange={e => this.tearsheetName = removeSpecialCharacters(e.target.value)}></Input>
        </Modal>
      </>
    );
  }
};

export default observer(SelectionMenu);
