import AdList from './AdList'
import Header from './Header'
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  display: grid;
  grid-template-rows: 60px 1fr;
  height: 100vh;
`;

class ListView extends React.Component {
  constructor(props: {}) {
    super(props);
  }

  render() {
    return (
      <Container>
        <Header />
        <AdList />
      </Container>
    );
  }
};

export default ListView;
