import DocumentStore from "./DocumentStore";
import DocumentCollection from "./DocumentCollection";
import { functionAuthRequest } from "./Firebase";
import { UserRoles } from "./UserTypes";

/**
 * A utility class for calling all available Firebase Functions.
 */
class FirebaseFunctions {
    /**
     * Removes a member from a DocumentStore. If the user calling this function has write access to the DocumentStore, they can remove any member.
     * @param {DocumentStore} parent The DocumentStore to remove the member from
     * @param {string} uid The uid of the user to remove
     * @returns {Promise<void>}
     * */
    removeMember = async (parent: DocumentStore<any, any>, uid: string) => {
        return await functionAuthRequest('removeMember', {
            uid: uid
        }, parent);
    }
    
    /**
     * Adds a member to a DocumentStore with the provided role: `edit` or `view`. If the user calling this function has `edit` access to the DocumentStore, they can add any member.
     * */
    addMember = async (parent: DocumentStore<any, any>, uid: string, role: UserRoles ) => {
        await functionAuthRequest('addMember', {
            uid: uid,
            role: role
        }, parent);
    }
    
    /**
     * Updates a member's role in a DocumentStore. If the user calling this function has `edit` access to the DocumentStore, they can update any member.
     * @param {DocumentStore} parent The DocumentStore to update the member's role in
     * @param {string} uid The uid of the user to update
     * @param {UserRoles} role The role to update the user to
     * @returns {Promise<void>}
     * */
    updateMember = async (parent: DocumentStore<any, any>, uid: string, role: UserRoles ) => {
        await functionAuthRequest('updateMember', {
            uid: uid,
            role: role
        }, parent);
    }
    
    /**
     * This signals to the server that a new asset has been created. The backend will then properly manage and process the asset.
     * @param {DocumentStore} parent The DocumentStore to add the asset to
     * @param {string} assetId The id of the new asset
     * @returns {Promise<void>}
     * */
    newAsset = async (parent: DocumentStore<any, any>, assetId: string) => {
        await functionAuthRequest('newAsset', {
            asset: assetId,
        }, parent);
    }

    /**
     * Creates a new BundleTask in the provided DocumentStore for the provided list of Ad IDs. This BundleTask will then initializae on the backend, packaging up all the provided ads into zips for trafficking.
     * @param {DocumentStore} parent The DocumentStore to create the BundleTask in
     * @param {string} name The name of the BundleTask
     * @param {string[]} adIds The list of Ad IDs to bundle
     * @returns {Promise<void>}
     * */
    createBundleTask = async (parent: DocumentStore<any, any>, name: string, adIds: string[]) => {
        await functionAuthRequest('createBundleTask', {
            name: name,
            selectedAdIDs: adIds
        }, parent);
    }

    /**
     * Creates a new Tearsheet in the provided DocumentStore for the provided list of Ad IDs.
     * @param {DocumentStore} parent The DocumentStore to create the Tearsheet in
     * @param {string} name The name of the Tearsheet
     * @param {string[]} adIds The list of Ad IDs to add to the Tearsheet
     * @returns {Promise<void>}
     * */
    createNewTearsheet = async (parent: DocumentStore<any, any>, name: string, adIds: string[]) => {
        return await functionAuthRequest('createNewTearsheet', {
            name: name,
            selectedAdIDs: adIds
        }, parent);
    }

    /**
     * Replaces the provided CreativeTemplate (via id) with the CreativeTemplate at the provided storage path. If replaceAssets is true, the assets stored for this template in the current Creative are replace with the new assets found in the new CreativeTemplate.
     * @param {DocumentStore} parent The DocumentStore to replace the CreativeTemplate in
     * @param {string} id The id of the CreativeTemplate to replace
     * @param {string} name The name of the new CreativeTemplate
     * @param {string} storagePath The storage path of the new CreativeTemplate
     * @param {boolean} replaceAssets Whether or not to replace the assets in the current Creative with the new assets found in the new CreativeTemplate
     * @returns {Promise<void>}
     * */
    replaceCreativeTemplate = async (parent: DocumentStore<any, any>, id:string, name: string, storagePath: string, replaceAssets = true) => {
        await functionAuthRequest('replaceCreativeTemplate', {
            id: id,
            name: name, // TODO: is name required here?
            storage: storagePath,
            replaceExtractedAssets: replaceAssets
        }, parent);
    }

    /**
     * Creates a new CreativeTemplate in the provided Creative with the provided name and storage path.
     * @param {DocumentStore} parent The Creative to create the CreativeTemplate in
     * @param {string} name The name of the new CreativeTemplate
     * @param {string} storagePath The storage path of the new CreativeTemplate
     * @returns {Promise<void>}
     * */
    newCreativeTemplate = async (parent: DocumentStore<any, any> | DocumentCollection<any, any>, name: string, storagePath: string) => {
        await functionAuthRequest('newCreativeTemplate', {
            name: name,
            storage: storagePath
        }, parent);
    }
    
    /**
     * Deletes the CreativeTemplate with the provided id from the provided Creative.
     * @param {DocumentStore} parent The Creative to delete the CreativeTemplate from
     * @param {string} id The id of the CreativeTemplate to delete
     * @returns {Promise<void>}
     * */
    deleteCreativeTemplateById = async (parent: DocumentStore<any, any>, id: string) => {
        await functionAuthRequest('deleteCreativeTemplateById', {
            id: id
        }, parent);
    }

    /**
     * Creates a new Tearsheet in the provided Creative with the provided list of Ad IDs.
     * 
     * @param {DocumentStore} parent The Creative to create the Tearsheet in
     * @param {string} name The name of the Tearsheet
     * @param {string[]} adIds The list of Ad IDs to add to the Tearsheet
     * @param {string} clientId The id of the Client
     * @param {string} projectId The id of the Project
     * @param {string} creativeId The id of the Creative
     * @returns {Promise<void>}
     * */
    createTearsheet = async (parent: DocumentStore<any, any>, name: string, adIds: string[], clientId: string, projectId: string, creativeId: string ) => {
        let request = {
            name: name,
            clientID: clientId,
            projectID: projectId,
            creativeID: creativeId,
            selectedAdIDs: adIds
        };
        await functionAuthRequest('createTearsheet', request, parent);
    }

    /**
     * Updates the ad index (or "order") of the provided list of Ad IDs in the provided Creative. This is used to reorder the Ads in the Creative.
     * The list of Ad IDs and their new indexes should be provided as a list of tuples, where the first element is the Ad ID and the second element is the new index.
     * 
     * @param {DocumentStore} parent The Creative to update the Ad indexes in
     * @param {[string, number][]} adIndexes The list of Ad IDs and their new indexes in the form of a list of tuples: [Ad ID, new index]
     * @returns {Promise<void>}
     * */
    updateAdIndexes = async (parent: DocumentStore<any, any>, adIndexes: [string, number][]) => {
        let request = { adIndexes };
        await functionAuthRequest('updateAdIndexes', request, parent);
    }
}

export default new FirebaseFunctions();