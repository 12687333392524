import { firestore, FirestoreEvents, storageRef, db, functionAuthRequest } from './Firebase';
import Bundle, { BundleSchema } from './Bundle';
import { appConfig as config } from '../config';
import { urlJoin } from 'url-join-ts';
import DocumentCollection from './DocumentCollection';
import { DocumentCollectionType } from './Types';
import Creative from './Creative';
import root from './index';
import Ad from './Ad';

enum UploadStates {
    UPLOADING = 'uploading',
    UPLOAD_FAILED = 'upload_failed',
    NONE = ''
}

export default class BundlesManager extends DocumentCollection<Bundle, BundleSchema> {
    uploading = false;
    currentFolder = '';
    uploadError = false;
    uploadErrorMessage = '';
    uploadState = UploadStates.NONE;
    baseURL = 'https://' + config.hostingBucket;
    query = () => this.collectionRef.orderBy('createTime');

    constructor(parent: Creative) {
        super(parent, Bundle);
        this.init();
    }

    new = async (bundleName: string, selectedAds: Array<Ad>) => {
        let adIDs: Array<string> = [];

        selectedAds.forEach(item => adIDs.push(item.id));

        await functionAuthRequest('createBundleTask', {
            name: bundleName,
            selectedAdIDs: adIDs
        }, root.creative);
    }

    delete = async (id: string) => {
        let bundle = this.getById(id);
        if (!bundle) return;
        const fileRef = storageRef.child(bundle.data.compositeArchivePath); // TODO: Check that this is the right reference. used to be bundle.storage
        await bundle.delete();
        await fileRef.delete();
    }

    getByStoragePath = (path: string) => {
        for (let item of this.all) {
            if (item.data.compositeArchivePath === path) return item;
        }
    }


    getByPath = (path: string) => { // TODO: this might not actually return whats needed.
        for (let item of this.all) {
            if (item.path === path) return item;
        }
    }


    getByName = (name: string) => {
        for (let item of this.all) {
            if (item.data.name === name) {
                return item;
            }
        }
    }
}

