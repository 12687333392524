import { Button, Input, Icon } from 'antd';
import styled from "styled-components";

export const Container = styled.div`
  padding: 8px;
`;

export const SearchInput = styled(Input)`
  display: block;
  margin-bottom: 8px;
  width: 188px;
`;

export const ApplyFilterButton = styled(Button).attrs({
  icon: "search",
  size: "small",
  type: "primary",
})`
  margin-right: 8px;
  width: 90px;
`;

export const ResetFiltersButton = styled(Button).attrs({
  size: "small",
})`
  width: 90px;
`;

type SearchIconProps = {
  active: boolean;
}
export const SearchIcon = styled(({ active, ...props }) => <Icon {...props} type="search" />) <SearchIconProps>`
  color: ${({ active }) => active ? '#1890ff' : 'inherit'};
`;
