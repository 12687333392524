import {
  buildColumn,
  TableColumnFilterType,
  TableColumnSortType,
} from 'components/UI/Table/Table/ColumnBuilder';
import { format } from 'date-fns';
import { observer } from "mobx-react";
import { Select, Switch } from "antd";
import { subscribe } from "stores/SubscribeUtil";
import { RoleSelect, Table, ViewsLayout, Toggle } from "components/UI";
import { timestampToDate } from "utils/Dates";
import { UserRoles } from "stores/UserTypes";
import React from "react";
import store from "stores";
import styled from "styled-components";
import User from "stores/User";
import { basicTextFilterer } from 'components/UI/Table/Table/ColumnBuilder/ColumnBuilder.service';

const ADMIN_VIEW_TITLE = 'Admin';
const ADMIN_TABLE_TITLE = 'All Users';

interface AdminProps { };

class Admin extends React.Component<AdminProps>{
  constructor(props: AdminProps) {
    super(props);
  }

  componentDidMount() {
    document.title = ADMIN_VIEW_TITLE;
  }

  getTableColumns() {
    return [
      buildColumn<User>({
        sourceData: (rowData) => rowData.data.displayName,
        sortType: TableColumnSortType.Alphabetical,
        filterer: (search, row) => basicTextFilterer(search, row.data.displayName),
        filterType: TableColumnFilterType.Search,
        title: 'Name',
      }),
      buildColumn<User>({
        sourceData: (rowData) => rowData.data.email,
        sortType: TableColumnSortType.Alphabetical,
        filterer: (search, row) => basicTextFilterer(search, row.data.email),
        filterType: TableColumnFilterType.Search,
        title: 'E-mail',
      }),
      buildColumn<User>({
        sourceData: (rowData) => rowData.data.dateModified,
        render: (rowData) => ('nanoseconds' in rowData.data.dateModified) ? format(timestampToDate(rowData.data.dateModified), 'MM/dd/yyyy h:mm:ss aa') : '-',
        sortType: TableColumnSortType.FirestampDate,
        title: 'Modified',
      }),
      buildColumn<User>({
        sourceData: (rowData) => rowData.data.activated,
        render: (rowData) => <Toggle store={rowData} />,
        sortType: TableColumnSortType.Alphabetical,
        title: 'Activated',
        width: 50,
      }),
      buildColumn<User>({
        sourceData: (rowData) => rowData.data.role,
        sortType: TableColumnSortType.Alphabetical,
        title: 'RoleSelect',
        width: 50,
        render: (record) => <RoleSelect store={record} />
      }),
    ]
  };

  render() {
    const data = [...store.admin.users.all];
    const isLoading = store.admin.users.loading;

    const columns = this.getTableColumns();

    return (
      <ViewsLayout title={ADMIN_VIEW_TITLE}>
        <Table
          columnsDefinition={columns}
          data={data}
          headerButtons={<></>}
          isLoading={isLoading}
          loadingLabel=""
          title={ADMIN_TABLE_TITLE}
        />
      </ViewsLayout>
    );
  }
};

export default observer(Admin);
