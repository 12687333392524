import { Icon as BaseIcon } from 'antd';
import Bundle from 'stores/Bundle';
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const Icon = styled(BaseIcon)`
  align-self: center;
  padding-right: 12px;
`;

export interface BundleLoadingSpinnerProps {
  bundle: Bundle;
}

class BundleLoadingSpinner extends React.Component<BundleLoadingSpinnerProps>{
  constructor(props: BundleLoadingSpinnerProps) {
    super(props);
  }

  render() {
    const { bundle } = this.props;
    const { loading } = bundle;

    if (!loading) {
      return null;
    }

    return (
      <Icon type="loading" />
    );
  }
};

export default observer(BundleLoadingSpinner);
