import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { AutoComplete, Input, Button, Icon } from 'antd';
import store from '../../../../../stores';
import { IDynamicProperty } from '../../../../../stores/DynamicProperties/Types';

const { Option } = AutoComplete;
const { TextArea } = Input;

// const Container = styled.div`
//     display: flex;
//     align-items: center;
//     margin-bottom: 7px;
// `;

const Container = styled.div<{
    changed: boolean,
}>`
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    ${({ changed }) => changed && `
    textarea {
        background: #e1f3ff;
    }`}
`;

const PropertyInput = styled(TextArea)`
    flex-grow: 1;
    &.beforeLabel {
        .ant-input {
            border-radius: 0 5px 5px 0 !important;
        }
    }

    &.afterLabel {
        .ant-input {
            border-radius: 5px 0 0 5px !important;
        }
    }

    &.bothLabel {
        .ant-input {
            border-radius: 0 !important;
        }
    }
`;

type MultiLineTextInputProps = {
    property: IDynamicProperty<any>;
    style?: React.CSSProperties | undefined;
    disabled: boolean;
    editMode: boolean | undefined;
}

class MultiLineTextInput extends React.Component<MultiLineTextInputProps> {
    search = '';
    showDropdown = false;
    clicked = false;
    typed = false;
    focused = false;
    property: IDynamicProperty<any>;

    constructor(props: MultiLineTextInputProps) {
        super(props);
        this.property = props.property;
        this.property.subscribe(this);
    }

    handleChange = async (event: any) => {
        let value = event.target.value;
        this.property.set(value);
    }

    render() {
        let property = this.property;
        let label = this.property.name;
        let editMode = this.props.editMode;
        let key = label;

        return (
            <Container key={key} style={this.props.style} changed={property.getChanged()} className={this.search ? "hide-dropdown" : ""}>
                <PropertyInput
                    key={key}
                    disabled={this.props.disabled}

                    placeholder="Enter custom value"
                    style={{ width: '100%' }}
                    onChange={(e) => this.handleChange(e)}
                    value={this.property.value}

                    autoSize={{ minRows: 1, maxRows: 5 }}

                    onBlur={() => {
                        this.showDropdown = false;
                        this.focused = false;

                    }}
                    onFocus={() => {
                        this.clicked = true;
                        this.focused = true;

                    }}
                >
                </PropertyInput>
            </Container>
        );
    }
}

export default observer(MultiLineTextInput);
