import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores';
import Template from 'stores/Template';
import UploadTemplateModal from './UploadTemplateModal';

export interface UpdateTemplateButtonProps {
  template: Template;
}

class UpdateTemplateButton extends React.Component<UpdateTemplateButtonProps>{
  isUpdateTemplateModalVisible: boolean = false;

  constructor(props: UpdateTemplateButtonProps) {
    super(props);

    makeObservable(this, {
      isUpdateTemplateModalVisible: observable,
    });
  }

  showUpdateTemplateModal = () => {
    store.creative.templates.uploadManager.setTemplate(this.props.template);
    store.creative.templates.uploadManager.show();
  }

  render() {
    const { showUpdateTemplateModal } = this;
    const { isUpdateTemplateModalVisible } = this;

    const { template } = this.props;
    const { name } = template.data;
    const { loading } = template;

    return (
      <>
        <DrawerButton
          disabled={loading}
          onClick={showUpdateTemplateModal}
        >
          Update
        </DrawerButton>
      </>
    );
  }
};

export default observer(UpdateTemplateButton);
