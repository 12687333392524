import { IDynamicProperty } from ".";
import { DynamicPropertyType } from './DynamicPropertyType';
import { DynamicProperty, DynamicPropertyEvent } from "./Property";
import store from "../../";
import Ad from "../../Ad";
import Creative from "../../Creative";

export default class Label extends DynamicProperty<string> {
  type = DynamicPropertyType.LABEL;
  value = '';
  rendered = '';
  default = () => 'Untitled';

  constructor(parent: IDynamicProperty<any>, name: string, value: string) {
    super(parent, name, value);
    // this.init();
    let root = this.getRoot();
    root.on(DynamicPropertyEvent.CHANGED, this.render)
    this.render();
  }

  getString = () => {
    return this.value;
  }

  setDefault = () => {
    this.setInitialValue('Untitled');
  }

  getPreviewData = () => {
    return this.getString();
  }

  onChanged(value: string) {
    this.render();
    this.dispatch(DynamicPropertyEvent.CHANGED, this);
  }

  render = () => {
    if (store.creative?.ads?.current && this.value) { // is this group loaded into a creative?
      let adProperties = store.creative.ads.current.properties.getPreviewData();
      let adData = this.adToStringObject(store.creative.ads.current);
      let allProperties = { ...adProperties, ...adData };
      this.rendered = this.parseTemplate(this.value, allProperties);
    } else { // if not then load values from the group only.
      let properties = this.getRoot().getPreviewData();
      this.rendered = this.parseTemplate(this.value, properties);
    }
  }

  adToStringObject(ad: Ad) {
    let data = ad.data;
    let result = {} as any;

    for (let [key, value] of Object.entries(data)) {
      if (typeof value === 'number') {
        result[key] = value.toString();
        continue;
      }
      if (typeof value === 'boolean') {
        result[key] = value.toString();
        continue;
      }
      if (typeof value === 'string') {
        result[key] = value;
        continue;
      }
    }

    result.id = ad.id;
    result.key = ad.id;

    return { ad: result };
  }

  // parseCreativeProperties(label: string, creative: Creative) {
  //   let result = label;
  //   for (let [key, value] of Object.entries(creative.data)) {
  //     if(typeof value === "number") result = result.split('{{creative.' + key + '}}').join(value.getString());
  //     if(typeof value === "string") result = result.split('{{creative.' + key + '}}').join(value);
  //   }
  //   result = result.split('{{creative.id}}').join(creative.id)
  //   return result;
  // }

  parseTemplate(content: string, values: any) {
    // console.log('parseTemplate', content, values);
    // This will search the string for `{{name}}`
    // The 1st param to the callback is the entire match (`{{name}}`)
    // and the 2nd is the first capture group (`name`).
    return content.replace(/{{(.+?)}}/g, function (match, prop) {
      // Convert keys like `phones.primary` into an array of paths
      // then "reduce" that array into one value.
      // This lets us go through the object and drill down
      // until we get the value we want.
      
      let reduced = prop.split('.').reduce(function (obj: any, key: string) {
        return obj && obj[key] ? obj[key] : undefined;
      }, values);

      if(
        reduced === undefined ||
        typeof reduced === 'object' ||
        reduced === null
      ) reduced = '{{' + prop + '}}';

      return reduced;
    });
  }

  setUsingCSV = (value: string) => {
    this.set( value );
  }
}