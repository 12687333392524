import * as Firestore from '@firebase/firestore-types';
import { useImperativeHandle } from 'react';
import { firestore, db, functionAuthRequest, FirestoreEvents } from './Firebase';
import type DocumentFolder from './DocumentFolder';
// import { Dimensions } from './DynamicProperties/Types';

type DocumentFolderType = DocumentFolder<any, any, any>;
export type DocumentFolderClassRef = new (...args: any[]) => DocumentFolderType;

export enum MemberRoles {
    EDIT = 'edit',
    VIEW = 'view',
}

export const MemberRolesLabel: { [T in MemberRoles]: string } = {
    edit: 'Editor',
    view: 'Viewer',
}

export enum MutexStates {
    LOCKED = 1,
    UNLOCKED = 0,
}

export type Newable<T> = { new(...args: any[]): T; };
export type LooseObject = {
    [key: string]: any
}

export class LogProperties {
    level: LogLevel = LogLevel.LOG;
    levelName: LogLevelName = LogLevelName.LOG;
    message: string = '';
    time: number = NaN;
}

export enum LogLevel {
    DEBUG = 100,
    LOG = 200,
    INFO = 300,
    WARNING = 400,
    ERROR = 500
}

export enum LogLevelName {
    DEBUG = 'DEBUG',
    LOG = 'LOG',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export type Member = {
    role: MemberRoles,
    uid: string
}

export enum DocumentType {
    ANIMATION = 'animation',
    PROJECT = 'project',
    ASSET = 'asset',
    TEMPLATE = 'template',
    CLIENT = 'client',
    CREATIVE = 'dynamic',
    DEFAULT = 'document',
    AD = 'ad',
    COMMENT = 'comment',
    TEARSHEET = 'tearsheet',
    TEARSHEET_AD = 'tearsheetAd',
    BUNDLE = 'bundle',
    BUNDLE_ELEMENT = 'bundleElement',
    BREADCRUMB = 'breadcrumb',
    USER = 'user',
    NONE = '',
}

export enum DocumentCollectionType {
    AD = 'ads',
    ANIMATION = 'animations',
    TEMPLATE = 'templates',
    ASSET = 'assets',
    PROJECT = 'projects',
    CLIENT = 'clients',
    CREATIVE = 'creatives',
    DEFAULT = 'children',
    COMMENTS = 'comments',
    TEARSHEET = 'tearsheets',
    BUNDLE = 'creativeBundles',
    BUNDLE_ELEMENT = 'creativeBundleElements',
    USER = 'users',
    NONE = '',
}

export type PublicInfo = {
    name: string
}

export type Breadcrumb = {
    name: string,
    id: string,
    url: string,
    path: string,
    type: string
}

export enum DocumentStatus {
    NONE = 'none',
    LOADED = 'loaded',
    LOADING = 'loading',
    ERROR = 'error',
    LOCAL = 'local'
}

export enum ProcessingStatus {
    NEW = 'new',
    BLOCKED = 'blocked',
    PENDING = 'pending',
    CHANGING = 'changing',
    COMPLETE = 'complete',
    ERROR = 'error',
    NOTSET = 'notset',
    NONE = '',
}

export enum CreativeType {
    DYNAMIC = 'dynamic',
    CREATIVE = 'creativeV1',
}

export type FilterProperty = {
    name: string;
    values: any[];
}

export type FilterSet = {
    name: string;
    filterProperties: FilterProperty[];
}

export type DocumentTimestamp = {
    seconds: number,
    nanoseconds: number,
}

export type AntDesignIconName = 
'step-backward'
| 'step-forward' 
| 'fast-backward' 
| 'fast-forward' 
| 'shrink' 
| 'arrows-alt' 
| 'down' 
| 'up' 
| 'left' 
| 'right' 
| 'caret-up' 
| 'caret-down' 
| 'caret-left' 
| 'caret-right' 
| 'up-circle' 
| 'down-circle' 
| 'left-circle' 
| 'right-circle' 
| 'double-right' 
| 'double-left' 
| 'vertical-left' 
| 'vertical-right' 
| 'vertical-align-top' 
| 'vertical-align-middle' 
| 'vertical-align-bottom' 
| 'forward' 
| 'backward' 
| 'rollback' 
| 'enter' 
| 'retweet' 
| 'swap' 
| 'swap-left' 
| 'swap-right' 
| 'arrow-up' 
| 'arrow-down' 
| 'arrow-left' 
| 'arrow-right' 
| 'play-circle' 
| 'up-square' 
| 'down-square' 
| 'left-square' 
| 'right-square' 
| 'login' 
| 'logout' 
| 'menu-fold' 
| 'menu-unfold' 
| 'border-bottom' 
| 'border-horizontal' 
| 'border-inner' 
| 'border-outer' 
| 'border-left' 
| 'border-right' 
| 'border-top' 
| 'border-verticle' 
| 'pic-center' 
| 'pic-left' 
| 'pic-right' 
| 'radius-bottomleft' 
| 'radius-bottomright' 
| 'radius-upleft' 
| 'radius-upright' 
| 'fullscreen' 
| 'fullscreen-exit' 
| 'question' 
| 'question-circle' 
| 'plus' 
| 'plus-circle' 
| 'pause' 
| 'pause-circle' 
| 'minus' 
| 'minus-circle' 
| 'plus-square' 
| 'minus-square' 
| 'info' 
| 'info-circle' 
| 'exclamation' 
| 'exclamation-circle' 
| 'close' 
| 'close-circle' 
| 'close-square' 
| 'check' 
| 'check-circle' 
| 'check-square' 
| 'clock-circle' 
| 'warning' 
| 'issues-close' 
| 'stop' 
| 'edit' 
| 'form' 
| 'copy' 
| 'scissor' 
| 'delete' 
| 'snippets' 
| 'diff' 
| 'highlight' 
| 'align-center' 
| 'align-left' 
| 'align-right' 
| 'bg-colors' 
| 'bold' 
| 'italic' 
| 'underline' 
| 'strikethrough' 
| 'redo' 
| 'undo' 
| 'zoom-in' 
| 'zoom-out' 
| 'font-colors' 
| 'font-size' 
| 'line-height' 
| 'dash' 
| 'small-dash' 
| 'sort-ascending' 
| 'sort-descending' 
| 'drag' 
| 'ordered-list' 
| 'unordered-list' 
| 'radius-setting' 
| 'column-width' 
| 'column-height' 
| 'area-chart' 
| 'pie-chart' 
| 'bar-chart' 
| 'dot-chart' 
| 'line-chart' 
| 'radar-chart' 
| 'heat-map' 
| 'fall' 
| 'rise' 
| 'stock' 
| 'box-plot' 
| 'fund' 
| 'sliders' 
| 'android' 
| 'apple' 
| 'windows' 
| 'ie' 
| 'chrome' 
| 'github' 
| 'aliwangwang' 
| 'dingding' 
| 'weibo-square' 
| 'weibo-circle' 
| 'taobao-circle' 
| 'html5' 
| 'weibo' 
| 'twitter' 
| 'wechat' 
| 'youtube' 
| 'alipay-circle' 
| 'taobao' 
| 'skype' 
| 'qq' 
| 'medium-workmark' 
| 'gitlab' 
| 'medium' 
| 'linkedin' 
| 'google-plus' 
| 'dropbox' 
| 'facebook' 
| 'codepen' 
| 'code-sandbox' 
| 'amazon' 
| 'google' 
| 'codepen-circle' 
| 'alipay' 
| 'ant-design' 
| 'ant-cloud' 
| 'aliyun' 
| 'zhihu' 
| 'slack' 
| 'slack-square' 
| 'behance' 
| 'behance-square' 
| 'dribbble' 
| 'dribbble-square' 
| 'instagram' 
| 'yuque' 
| 'alibaba' 
| 'yahoo' 
| 'reddit' 
| 'sketch' 
| 'account-book' 
| 'alert' 
| 'api' 
| 'appstore' 
| 'audio' 
| 'bank' 
| 'bell' 
| 'book' 
| 'bug' 
| 'bulb' 
| 'calculator' 
| 'build' 
| 'calendar' 
| 'camera' 
| 'car' 
| 'carry-out' 
| 'cloud' 
| 'code' 
| 'compass' 
| 'contacts' 
| 'container' 
| 'control' 
| 'credit-card' 
| 'crown' 
| 'customer-service' 
| 'dashboard' 
| 'database' 
| 'dislike' 
| 'environment' 
| 'experiment' 
| 'eye-invisible' 
| 'eye' 
| 'file-add' 
| 'file-excel' 
| 'file-exclamation' 
| 'file-image' 
| 'file-markdown' 
| 'file-pdf' 
| 'file-ppt' 
| 'file-text' 
| 'file-unknown' 
| 'file-word' 
| 'file-zip' 
| 'file' 
| 'filter' 
| 'fire' 
| 'flag' 
| 'folder-add' 
| 'folder' 
| 'folder-open' 
| 'frown' 
| 'funnel-plot' 
| 'gift' 
| 'hdd' 
| 'heart' 
| 'home' 
| 'hourglass' 
| 'idcard' 
| 'insurance' 
| 'interaction' 
| 'layout' 
| 'like' 
| 'lock' 
| 'mail' 
| 'medicine-box' 
| 'meh' 
| 'message' 
| 'mobile' 
| 'money-collect' 
| 'pay-circle' 
| 'notification' 
| 'phone' 
| 'picture' 
| 'play-square' 
| 'printer' 
| 'profile' 
| 'project' 
| 'pushpin' 
| 'property-safety' 
| 'read' 
| 'reconciliation' 
| 'red-envelope' 
| 'rest' 
| 'rocket' 
| 'safety-certificate' 
| 'save' 
| 'schedule' 
| 'security-scan' 
| 'setting' 
| 'shop' 
| 'shopping' 
| 'skin' 
| 'smile' 
| 'sound' 
| 'star' 
| 'switcher' 
| 'tablet' 
| 'tag' 
| 'tags' 
| 'tool' 
| 'thunderbolt' 
| 'trophy' 
| 'unlock' 
| 'usb' 
| 'video-camera' 
| 'wallet' 
| 'apartment' 
| 'audit' 
| 'barcode' 
| 'bars' 
| 'block' 
| 'border' 
| 'branches' 
| 'ci' 
| 'cloud-download' 
| 'cloud-server' 
| 'cloud-sync' 
| 'cloud-upload' 
| 'cluster' 
| 'coffee' 
| 'copyright' 
| 'deployment-unit' 
| 'desktop' 
| 'disconnect' 
| 'dollar' 
| 'download' 
| 'ellipsis' 
| 'euro' 
| 'exception' 
| 'export' 
| 'file-done' 
| 'file-jpg' 
| 'file-protect' 
| 'file-sync' 
| 'file-search' 
| 'fork' 
| 'gateway' 
| 'global' 
| 'gold' 
| 'history' 
| 'import' 
| 'inbox' 
| 'key' 
| 'laptop' 
| 'link' 
| 'line' 
| 'loading-3-quarters' 
| 'loading' 
| 'man' 
| 'menu' 
| 'monitor' 
| 'more' 
| 'number' 
| 'percentage' 
| 'paper-clip' 
| 'pound' 
| 'poweroff' 
| 'pull-request' 
| 'qrcode' 
| 'reload' 
| 'safety' 
| 'robot' 
| 'scan' 
| 'search' 
| 'select' 
| 'shake' 
| 'share-alt' 
| 'shopping-cart' 
| 'solution' 
| 'sync' 
| 'table' 
| 'team' 
| 'to-top' 
| 'trademark' 
| 'transaction' 
| 'upload' 
| 'user-add' 
| 'user-delete' 
| 'usergroup-add' 
| 'user' 
| 'usergroup-delete' 
| 'wifi' 
| 'woman';

// export type FBDateModified = {
//     seconds: number,
//     nanoseconds: number,
// }