import root from './index';
import { DocumentCollectionType, DocumentStatus, DocumentType, FilterProperty, FilterSet } from './Types';
import DocumentCollection from './DocumentCollection';
import Creative from './Creative';
import Tearsheet from './Tearsheet';
import TearsheetAd, { TearsheetAdSchema } from './TearsheetAd';

/**
 * Ads Data Store
 * 
 * Filtering: Filtering generally works by scanning all ads and collecting all the possible values on a per "property" basis. 
 * 
 * current - The currently selected ad.
 * filterValues - Values that are currently selected for a specific dynamic data property. This can be multiple.
 * filterProperties - All the collective values that exist in the entire data set for this specific property. If 200 ads use a productImage of 'foo.jpg', and another 100 use 'bar.jpg, this will return an array of ['ball.jpg', 'bar.jpg']
 */

// TODO: is this needed? doesn't seem like it.
// const state = {
//     noTemplate: 'NO_TEMPLATES',
//     noAds: 'NO_ADS'
// }

export default class TearsheetAdsManager extends DocumentCollection<TearsheetAd, TearsheetAdSchema> { // TODO: this should extend Document and use a type variable to pass in child type. 
    query = () => this.collectionRef.orderBy('index');
    currentIndex: number;

    constructor(parent: Creative | Tearsheet) {
        super(parent, TearsheetAd);
        this.init();
    }

    get last() {
        return this.children[this.children.length - 1];
    }

    get first() {
        return this.children[0];
    }

    get current() {
        for (var i in [...this.children]) {
            if (this.children[i].active) return this.children[i];
        }
    }

    selectNext = () => {
        if (this.loaded) {
            // get array index of currently selected ad, then select the next ad in the array
            let currentIndex = this.children.findIndex(ad => ad.active);
            if (currentIndex === this.children.length - 1) {
                this.children[0].navigateTo();
            } else {
                this.children[currentIndex + 1].navigateTo();
            }
        }
    }

    selectPrevious = () => {
        if (this.loaded) {
            // get array index of currently selected ad, then select the previous ad in the array
            // also make sure that we don't go below 0
            let currentIndex = this.children.findIndex(ad => ad.active);
            if (currentIndex === 0) {
                this.children[this.children.length - 1].activate();
                this.currentIndex = this.children[this.children.length - 1].data.index;
            } else {
                this.children[currentIndex - 1].activate();
                this.currentIndex = this.children[currentIndex - 1].data.index;
            }
        }
    }

    setActiveByIndex = (adIndex: number) => {
        if (this.loaded) {
            // check if the adIndex exists in the list of ads
            let adIndexExists = false;
            this.children.forEach(ad => {
                if (ad.data.index === adIndex) {
                    adIndexExists = true;
                }
            });
            if (!adIndexExists) {
                this.children[0].activate();
                this.currentIndex = this.children[0].data.index;
                return;
            }
            this.children.forEach(ad => {
                if (ad.data.index === adIndex) {
                    if (!ad.active) {
                        ad.activate();
                        this.currentIndex = adIndex;
                    }
                } else {
                    ad.deactivate();
                }
            });
        }
    }
}