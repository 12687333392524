import { Container } from "./StarSwitch.styles";
import { Icon } from "antd";
import { observer } from "mobx-react";
import React from "react";

export interface Starrable {
  isStarred: boolean;
  toggleStarred: () => void;
}

export interface StarSwitchProps {
  store: Starrable;
}

class StarSwitch extends React.Component<StarSwitchProps>{
  constructor(props: StarSwitchProps) {
    super(props);
  }

  render() {
    const { store } = this.props;
    let starred = store?.isStarred;

    return (
      <Container
        onClick={(e) => {
          e.stopPropagation();
          store.toggleStarred();
        }}
        onMouseUp={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Icon type="star" theme={starred ? "filled" : "outlined"} />
      </Container>
    );
  }
}

export default observer(StarSwitch);
