import { observer } from 'mobx-react';
import { TearsheetViewMobileContentMode } from 'stores/Tearsheet';
import AdListMobile from './ListView';
import AdView from 'components/Views/Tearsheet/Shared/AdView';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const AdViewMobile = styled(AdView)`
  display: grid;
  grid-template-rows: 60px 1fr;
  height: 100%;
`;

const VisibilityWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => visible ? 'initial' : 'none'};
`;

class TearsheetMobileView extends React.Component {
  constructor(props: {}) {
    super(props);
  }

  render() {
    if(!store.tearsheet) return <></>;
    let mobileContentMode = TearsheetViewMobileContentMode.LIST;
    let params = store.router.params as any;

    if(params && params.adIndex) {
      mobileContentMode = TearsheetViewMobileContentMode.VIEW;
    } else {
      mobileContentMode = TearsheetViewMobileContentMode.LIST;
    }

    return (
      <>
        <VisibilityWrapper visible={mobileContentMode === TearsheetViewMobileContentMode.LIST}>
          <AdListMobile />
        </VisibilityWrapper>

        <VisibilityWrapper visible={mobileContentMode === TearsheetViewMobileContentMode.VIEW}>
          <AdViewMobile />
        </VisibilityWrapper>
      </>
    );
  }
};

export default observer(TearsheetMobileView);
