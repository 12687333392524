import { AddDocumentButton, DocumentFolderView, HeaderButton, HeaderDropdown } from "../../UI";
import { observer } from "mobx-react";
import React from 'react';
import store from "../../../stores";
import { breadcrumbsToTitle } from "../../../utils/";
import CreativeDocument from "stores/Creative";
import AnimationDocument from "stores/Animation";

export interface ProjectProps { }

class Project extends React.Component<ProjectProps>{
  constructor(props: ProjectProps) {
    super(props);
  }

  getCreativesTableHeaderButtons = () => {
    return [
      <HeaderDropdown
        disabled
        key="project.creativesTable.filtersDropdown"
        label="Filters"
        onSelect={() => { }}
        options={[]}
      />,
      <HeaderButton
        disabled
        key="project.creativesTable.applyFiltersButton"
        label="Save Filter"
        onClick={() => { }}
      />,
      <AddDocumentButton
        key="project.creativesTable.addCreativeButton"
        parentDocument={store.project}
        documentType={CreativeDocument}
        documentTypeName="Creative"
      />,
      // <AddDocumentButton
      //   key="project.creativesTable.addAnimationButton"
      //   parentDocument={store.project}
      //   documentType={AnimationDocument}
      //   documentTypeName="Animation"
      // />
    ]
  }

  render() {
    if(store.current && store.current.data.name) document.title = store.current.data.name;
    const creativesTableHeaderButtons = this.getCreativesTableHeaderButtons();

    return (
      <DocumentFolderView
        childrenTableHeaderButtons={creativesTableHeaderButtons}
        childrenTableTitle="Documents"
        membersTableTitle="Sharing"
        parentDocument={store.project}
        tagsManagerTitle="Tags"
      />
    );
  }
}

export default observer(Project);
