export const VideoExtensions = ['.mpg', '.mp4', '.ogv', '.mov', '.webm'];
export const AudioExtensions = ['.aac', '.mp3', '.oga', '.ogg', '.wav'];
export const ImageExtensions = ['.apng', '.bmp', '.gif', '.ico', '.cur', '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg', '.tif', '.tiff', '.webp'];
export const TextExtensions = ['.js', '.css', '.html', '.svg', '.txt', '.htm', '.scss', '.csv', '.less'];
export const FontExtensions = ['.ttf', '.otf', '.woff', '.woff2'];

export const FileTypeExtensions = {
    IMAGE: ImageExtensions,
    VIDEO: VideoExtensions,
    AUDIO: AudioExtensions,
    FONT: FontExtensions,
    TEXT: TextExtensions,
}

export enum AssetFileType {
    FILE = 'file',
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    FOLDER = 'folder',
    FONT = 'font',
    TEXT = 'text',
    LOTTIE = 'lottie',
    JSON = 'json',
    NONE = ''
}

export type AssetMetadata = {
    bucket: string,
    generation: string,
    metageneration: string,
    fullPath: string,
    name: string,
    size: number,
    timeCreated: string,
    updated: string,
    md5Hash: string,
    cacheControl: string,
    contentDisposition: string,
    contentEncoding: string,
    contentLanguage: string,
    contentType: string,
    customMetadata: Object,
};