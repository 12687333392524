import React from 'react';
import styled from 'styled-components';
import store from 'stores';
import { observer } from 'mobx-react';
import { appConfig as config } from '../../../../../config';
import { message } from 'antd';
import { IReactionDisposer, reaction } from 'mobx';
import { urlJoin } from 'url-join-ts';
import { deepCopy } from 'utils';
import TearsheetAd from 'stores/TearsheetAd';
import { AdEvents } from 'stores/Ad';

const Container = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  height: 100%;
  overflow: auto;
  width: 100%;
  
  #adview {
    transform-origin: 0 0;
  }
`;

const AdContainer = styled.div`
  overflow: auto;
  height: 100%;
  border:1px solid red;
  width: 100%;

  #adview {
    transform-origin: 0 0;
  }
`;

export interface TearsheetAdViewProps { };

class AdViewFrame extends React.Component<TearsheetAdViewProps>{
  adLoading = false;
  currentAdFrame: HTMLIFrameElement | null = null;
  container: HTMLDivElement | null = null;
  adSwitchedDisposer: IReactionDisposer;
  currentAd: TearsheetAd | undefined;
  lastIndex: number = NaN;


  constructor(props: TearsheetAdViewProps) {
    super(props);

    window.addEventListener('message', this.sendDynamicData);

    this.adSwitchedDisposer = reaction(() => store.tearsheet.ads.current, () => {
      console.log('AdViewFrame adSwitchedDisposer', store.tearsheet.ads.current);
      if(this.currentAd) {
        this.currentAd?.removeEventListener(AdEvents.RELOAD, this.handleReload);
      }
      this.currentAd = store.tearsheet.ads.current;
      this.currentAd?.addEventListener(AdEvents.RELOAD, this.handleReload);
      this.renderAdFrame();
    });

    this.adSwitchedDisposer = reaction(() => store.router.params?.adIndex, () => {
      this.renderAdFrame();
    });
  }

  componentWillUnmount() {
    this.adSwitchedDisposer();
    window.removeEventListener('message', this.sendDynamicData);
  }

  componentDidMount() {
    console.log('AdViewFrame componentDidMount', store.tearsheet.ads.current);
    console.log('AdViewFrame container', this.container);
  }

  renderAdFrame = async () => {
    let ad = store.tearsheet.ads.current;
    if (!ad) return;
    this.adLoading = true;
    // if(this.ad.changed === false) await this.ad.reload();
    let iframe = document.createElement('iframe');

    this.currentAdFrame = iframe;

    let dynamicData = ad.data.dynamicData;

    let cacheBusterString = '?session=' + new Date().getTime();
    let previewModeString = '&isAdHubPreview=true';
    var adPath = dynamicData.template;
    let width = dynamicData.dimensions?.split('x')[0] + 'px';
    let height = dynamicData.dimensions?.split('x')[1] + 'px';
    let frameName = 'adframe' + ad.id;

    iframe.style.display = 'none';
    iframe.name = 'adframe';

    iframe.src = urlJoin('https://', config.hostingBucket, adPath + cacheBusterString + previewModeString);
    console.log('AdViewFrame iframe src', iframe.src);
    iframe.style.display = 'block';
    iframe.style.width = width;
    iframe.style.height = height;
    iframe.style.border = '0';
    iframe.id = frameName;
    iframe.onload = () => {
      this.adLoading = false;
    }
    console.log('AdViewFrame container', this.container);
    if (this.container) {
      this.container.innerHTML = '';
      this.container.appendChild(iframe);
    }

    if (!adPath) return;

    let adExists = await this.checkUrlExists(adPath);
    if (!adExists) {
      message.error((<span>Files missing for: <b>{ad.data.dynamicData.variant}</b></span>));
      iframe.style.display = 'none';
    }
  }

  handleReload = async () => {
    console.log('AdView: handleReload');
    if (this.currentAdFrame) {
      this.adLoading = true;
      this.currentAdFrame.style.display = 'none';
      // if(this.store.tearsheet.ads.current.changed === false) await this.ad.reload();
      this.renderAdFrame();
    }
  }

  checkUrlExists = (url: string) => {
    return new Promise(function (resolve, reject) {
      var xobj = new XMLHttpRequest();
      //xobj.overrideMimeType("application/json");
      xobj.open('GET', url, true); // Replace 'my_data' with the path to your file
      xobj.onreadystatechange = function () {
        if (xobj.status == 0) return;
        if (xobj.status == 404) {
          // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
          resolve(false);
        } else if (xobj.status == 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      xobj.send(null);
    });
  }

  sendDynamicData = (e: MessageEvent) => {
    if (e.data === 'requestDynamicData') {
      let ad = store.tearsheet.ads.current;
      if (!ad) return;
      this.currentAdFrame?.contentWindow?.postMessage(deepCopy(ad.previewData), '*');
    }
  }

  render() {
    var viewScale = store.tearsheet.viewScale;
    var viewWidth = 1;
    var viewHeight = 1;

    if (store.tearsheet.ads.current) {
      viewWidth = Number(store.tearsheet.ads.current.data.dynamicData.dimensions?.split('x')[0]) * viewScale;
      viewHeight = Number(store.tearsheet.ads.current.data.dynamicData.dimensions?.split('x')[1]) * viewScale;
    }

    return (
      <Container>
        {/* <AdContainer> */}
        <div id="adview"
          style={{
            transform: 'scale(' + viewScale + ')',
            transition: 'all 0.25s',
            width: viewWidth + 'px',
            height: viewHeight + 'px'
          }}
          ref={elem => this.container = elem}
          className="container"></div>
        {/* </AdContainer> */}
      </Container>
    );
  }
}

export default observer(AdViewFrame);
