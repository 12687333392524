import { Icon as BaseIcon } from 'antd';
import Asset from 'stores/Asset';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import styled from 'styled-components';

const Icon = styled(BaseIcon)`
  font-size: 20px; 
  padding-top: 4px;
`;

export interface DownloadFileButtonProps {
  asset: Asset;
}

class DownloadFileButton extends React.Component<DownloadFileButtonProps>{
  constructor(props: DownloadFileButtonProps) {
    super(props);
  }

  handleDownload = async (url: string, filename: string) => {
    const image = await fetch(url);
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const { asset } = this.props;
    const { downloadURL, filename } = asset;

    return (
      <DrawerButton disabled={asset.loading} onClick={() => { this.handleDownload(downloadURL, filename) }}>
        <Icon type="cloud-download" />
      </DrawerButton>
    );
  }
};

export default DownloadFileButton;
