import { Layout } from './Profile.styles';
import { observer } from 'mobx-react';
import { ProfileCard } from 'components/UI';
import React from 'react';
import store from 'stores';

export interface ProfileViewProps { }

class ProfileView extends React.Component<ProfileViewProps>{
  constructor(props: ProfileViewProps) {
    super(props);
  }

  render() {
    document.title = 'Profile';

    const userData = { ...store.user.data };
    return (
      <Layout>
        <ProfileCard userData={userData} />
      </Layout>
    );
  }
}

export default observer(ProfileView);
