import { Button as BaseButton, Icon } from 'antd';
import { observer } from 'mobx-react';
import { PanelViews } from 'stores/CreativeTypes';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  align-items: center;
  font-size: 16px;
  padding-bottom: 0;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 5px;

  &.selected {
    background: #a8a8a8;
    border-color: #989696;
    color: white;
  }
`;

export interface FiltersButtonProps { }

class FiltersButton extends React.Component<FiltersButtonProps>{
  constructor(props: FiltersButtonProps) {
    super(props);
  }

  openPropertiesFilterDrawer = () => {
    store.creative.setPanel(PanelViews.FILTER);
    // store.creative.ads.loadFilterProperties();
  }

  render() {
    const { displayPanel } = store.creative;
    const isDrawerOpen = displayPanel === PanelViews.FILTER;

    const { openPropertiesFilterDrawer } = this;

    return (
      <Button
        className={isDrawerOpen ? 'selected' : ''}
        onClick={openPropertiesFilterDrawer}
      >
        <Icon type="filter" />
      </Button>
    );
  }
};

export default observer(FiltersButton);
