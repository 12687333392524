import { MobxRouter } from 'mobx-router';
import { observer } from 'mobx-react';
import React from 'react';
import store from '../stores/index';
import styled from 'styled-components';

const Container = styled.div`
  /* background: #f0f2f5; */
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
  width: 100%;
`;

interface Props extends React.HTMLAttributes<any> { };

class MainContent extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Container {...this.props}>
        <MobxRouter store={store} />
      </Container>
    )
  }
}

export default observer(MainContent);
