import root from './index';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { DocumentCollectionType, DocumentStatus, DocumentType, LooseObject } from './Types';
import DocumentStore from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import AdsManager from './AdsManager';
import Creative from './Creative';
import { deepCopy, documentPathToParams, nowTimestamp, nullTimestamp } from '../utils';
import { firestore } from './Firebase';
import CommentsManager from './CommentsManager';
import { appConfig as config } from '../config';
import { urlJoin } from 'url-join-ts';
import { AdEvents } from './Ad';

export class TearsheetAdSchema extends DocumentSchema {
    dynamicData: Partial<{label: string, dimensions: string, template: string, variant: string}> = {};
    id: string = '';
    index = NaN;
    label = '';
    dimensions = '';
    type = DocumentType.TEARSHEET_AD;
    numComments: number = 0;
    lastModified: FirestoreTimestamp | FieldValue = firestore.FieldValue.serverTimestamp();
}

export default class TearsheetAd extends DocumentStore<TearsheetAdSchema> {
    schema = new TearsheetAdSchema();
    type = DocumentType.AD;
    collectionType = DocumentCollectionType.AD;
    
    active = false;
    isRead = true;
    selected = false;
    selectedLastModified = nullTimestamp();
    comments:CommentsManager = new CommentsManager(this);

    get dynamicProperties() {
        return this.data.dynamicData;
    }

    get previewData() {
        return deepCopy(this.transformDynamicData(this.data.dynamicData));
    }
    
    get currentUserUID() {
        return root.user.id;
    }

    get numComments() {
        return this.data.numComments;
    }

    constructor(parent: AdsManager, docRef: DocumentReference, data: TearsheetAdSchema){
        super(parent, docRef, data);
        this.comments.enable();
        this.init({
            properties: false,
            comments: false
        });
    }

    get dimensions() {
        return this.data.dimensions;
    }

    navigateTo = () => {
        let params = documentPathToParams<{adIndex:string, creativeID:string, projectID:string, clientID:string, tearsheetID: string}>(this.docRef.path);
        root.router.goTo(root.routes.tearsheet, { ... params, adIndex: String(this.data.index + 1) });
    }

    get parentCreative() {
        return this.parent.parent as Creative;
    }
    
    get label() {
        return this.data.label;
    }

    transformDynamicData(dynamicData: any) {
        let data = { ...dynamicData };
        for (var property in data) {
            let value = data[property];
            if (value.indexOf && value.indexOf('hostedstorage/') > -1) {
                data[property] = urlJoin('https://', config.hostingBucket, value);
            }
            // } else if(value[0] === '/'){
            //     data[property] = urlJoin(creative.assetsURL, value);
            // }
        }
        return data;
    }

    selectToggle() {
        this.selected = !this.selected;
    }

    select(skipUpdate: boolean = false) {
        this.selected = true;
        this.selectedLastModified = nowTimestamp();
        if (skipUpdate) return;
    }

    deselect(skipUpdate: boolean) {
        this.selected = false;
        this.selectedLastModified = nowTimestamp();
        if (skipUpdate) return;
    }


    activate = async () => {
        this.active = true;
        if(this.comments.loaded === false) await this.comments.load();
    }

    reload = () => {
        console.log('ad reload');
        this.dispatch(AdEvents.RELOAD);
    }

    deactivate() {
        this.active = false;
    }
}