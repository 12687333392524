import { observer } from 'mobx-react';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import React from 'react';
import store from 'stores';

class Tearsheet extends React.Component {
  constructor(props: {}) {
    super(props);
  }

  render() {
    const { isMobile } = store.ui;

    if (isMobile) {
      return <MobileView />;
    }

    return <DesktopView />
  }
}

export default observer(Tearsheet);
