import Store from '../../Store';
import DynamicPropertiesManager from '../Manager';
import { DynamicPropertyData, IDynamicProperty } from '../Types';
import { DynamicPropertyType } from './DynamicPropertyType';

export enum DynamicPropertyStatus {
  READY = 'ready',
  LOADING = 'loading'
}

export enum DynamicPropertyEvent {
  CHANGED = 'changed',
  REVERTED = 'reverted',
}

export class DynamicProperty<T> extends Store implements IDynamicProperty<T> {
  value: T | typeof undefined;
  initialValue: T | typeof undefined;
  default: () => T;
  name: string;
  type: DynamicPropertyType = DynamicPropertyType.DEFAULT;
  reserved: boolean = false;
  status = DynamicPropertyStatus.LOADING
  parent: IDynamicProperty<any> | null | undefined;
  subscribers: any[] = [];

  get changed() {
    return this.getChanged();
  }

  constructor(parent?: IDynamicProperty<any> | null, name?: string, value?: any, reserved: boolean = false) {
    super();
    this.parent = parent;
    // console.log('name', name, 'value', value);
    if(name !== undefined) this.name = name;
    if(value !== undefined || value !== null) {
      this.value = value;
      this.initialValue = value;
    } else {
      console.log('DEFAULT VALUE', this.default());
      this.value = this.default();
      this.initialValue = this.default();
    }
    if(reserved) this.reserved = reserved;
    this.addEventListener(DynamicPropertyEvent.CHANGED, this.broadcastToSubscribers);
  }

  get = () => {
    return this.value;
  }
  
  set = (value: T, silent = false, setInitialValue = false) => {
    this.value = this.transform(value);
    // this.onUpdate(value);
    if(setInitialValue) this.initialValue = this.value;
    if(silent === false) this.dispatch(DynamicPropertyEvent.CHANGED, this);
  }

  get fullpath () {
    // if parent exists, return parent path + this.name
    let parent = this.parent;
    if(this.parent) return this.parent.fullpath + '.' + this.name;
    else return '';
    
  }

  get label() {
    return this.getString();
  }

  broadcastToSubscribers = () => {
    this.subscribers.forEach(subscriber => {
      if(subscriber.forceUpdate) subscriber.forceUpdate();
    });
  };

  subscribe = (component: any) => {
    if(!component) return;
    if(!this.subscribers.includes(component)) {
      this.subscribers.push(component);
    }
  }

  unsubscribe = (component: any) => {
    if(!component) return;
    if(this.subscribers.includes(component)) {
      this.subscribers.splice(this.subscribers.indexOf(component), 1);
    }
  }

  getLabel = () => {
    return this.label;
  }

  getChanged = () => {
    return this.value !== this.initialValue;
  }

  transform = (value: any) => {
    return value;
  }

  setInitialValue = (value: T) => {
    value = this.transform(value);
    this.initialValue = value;
    this.value = value;
    this.onInit(value);
  }

  resetChanged = () => {
    this.initialValue = this.value;
    this.dispatch(DynamicPropertyEvent.CHANGED, this);
  };

  setUsingCSV = (value: string) => {
    
  }

  throwCSVImportError = (message: string) => {
    let root = this.getRoot() as DynamicPropertiesManager;
    if(!root) return;
    let adLabel = root.get('label')?.value;
    let adId = root.parentAd ? root.parentAd.id : '';
    let propertyPath = this.fullpath.replace(adId + '.', '');
    throw new Error(`CSV Import Error: Ad "${adLabel}" (${adId}): Property "${propertyPath}": ${message}`);
  }

  setReserved = (isReserved: boolean) => {
    this.reserved = isReserved;
  }

  onSave = () => {
    this.initialValue = this.value;
  }

  revert = () => {
    this.value = this.initialValue;
    this.dispatch(DynamicPropertyEvent.CHANGED, this.value);
    this.dispatch(DynamicPropertyEvent.REVERTED, this.value);
  }

  defaults = ():any | undefined => { return undefined }
  
  reset = () => {
    this.value = undefined;
    // if(this.defaults() !== undefined) {
    //   this.value = this.default();
    // }
  }

  rename = (name: string) => {
    this.name = name;
    this.dispatch(DynamicPropertyEvent.CHANGED, this.value);
  }

  onInit = (value: T) => {}
  
  onUpdate = (value: T) => {}

  clear = () => {
    this.value = undefined;
  }

  getFlattened = ():any => {
    return this.value;
  }

  getString = (): string => {  
    if(typeof this.value === 'string') return this.value;
    if(typeof this.value === 'number') return this.value.toString();
    else return '';
  }

  getPreviewData = (): any => {
    return this.getString();
  }

  getCSVData = (): string | number => {
    return this.getString();
  }

  getObject = (): any => {
    return this.value;
  }

  getRoot = (): IDynamicProperty<any> => {
    if (this.parent) {
      return this.parent.getRoot();
    } else {
      return this;
    }
  }

  getState = () => {
    let value = this.value;

    return {
      value: value,
      name: this.name,
      reserved: this.reserved,
      type: this.type
    } as DynamicPropertyData;
  }
}