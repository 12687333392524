import root from './index';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import DynamicPropertiesManager from './DynamicProperties/Manager';
import { DocumentCollectionType, DocumentStatus, DocumentType, LooseObject } from './Types';
import DocumentStore from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import { DynamicPropertyData } from './DynamicProperties/Types';
import Dimensions from "./DynamicProperties/Types/Dimensions";
import { DynamicPropertyEvent } from './DynamicProperties/Types/Property';
import AdsManager from './AdsManager';
import Creative from './Creative';
import { deepCopy, deepObjectCompare, documentPathToParams, nowTimestamp, nullTimestamp, useDebounce } from '../utils';
import { firestore } from './Firebase';
import CommentsManager from './CommentsManager';
import store from './index';
import { PanelViews } from './CreativeTypes';
import { DynamicPropertyType } from './DynamicProperties/Types/DynamicPropertyType';
import SVGText from './DynamicProperties/Types/SVGText';

export class AdSchema extends DocumentSchema {
    dynamicData: DynamicPropertyData[] = [];
    index = NaN;
    name = '';
    label = '';
    type = DocumentType.AD;
    numComments: number = 0;
    dateModified: FirestoreTimestamp | FieldValue = firestore.FieldValue.serverTimestamp();
}

export enum AdEvents {
    RELOAD = 'adreload',
    CHANGED = 'adchanged',
}

export default class Ad extends DocumentStore<AdSchema> {
    schema = new AdSchema();
    type = DocumentType.AD;
    collectionType = DocumentCollectionType.AD;
    oldDynamicProperties: DynamicPropertyData;
    viewHistory: LooseObject = {};
    
    active = false;
    isRead = true;
    selected = false;
    selectedLastModified = nullTimestamp();
    comments:CommentsManager = new CommentsManager(this);

    properties = new DynamicPropertiesManager(this, 'Ad');
    
    get currentUserUID() {
        return root.user.id;
    }

    get numComments() {
        return this.data.numComments;
    }

    constructor(parent: AdsManager, docRef: DocumentReference, data: AdSchema){
        super(parent, docRef, data);
        this.comments.enable();
        this.init({
            properties: false,
            comments: false
        });
    }

    get dimensions() {
        let dimenstionsProperty = this.data.dynamicData.find( i => i.name === 'dimensions')?.value as DynamicPropertyData[];
        
        return { 
            width: dimenstionsProperty?.find( i => i.name === 'width')?.value || 0,
            height: dimenstionsProperty?.find( i => i.name === 'height')?.value || 0
        };
    }

    get template() {
        let templateId = this.data.dynamicData.find( i => i.name === 'template')?.value || '';
        return root.creative.templates.getById(templateId);
    }

    navigateTo = () => {
        let params = documentPathToParams<{adID:string, creativeID:string, projectID:string, clientID:string}>(this.docRef.path);
        root.router.goTo(root.routes.ad, params, root.router.queryParams as any);
    }

    get parentCreative() {
        return this.parent.parent as Creative;
    }

    // get changed() { // TODO: will likely need to rewire the UI to the dynamic properties manager.
    //     return this.properties.changed;
    // }

    get label() {
        return this.data.dynamicData.find( i => i.name === 'label')?.value || '';
    }

    cloneData = () => {
        let data = deepCopy(this.data) as AdSchema;
        data.numComments = 0;
        data.dateModified = new AdSchema().dateModified;
        return data as AdSchema;
    }

    onPropertyModified = useDebounce( () => {
        this.dispatch(AdEvents.CHANGED);
    }, 10);

    onUpdated = (data: AdSchema, oldData: AdSchema) => {
        // if(this.active === true) return;
        let dynamicPropertiesChanged = deepObjectCompare(data.dynamicData, oldData?.dynamicData) === false;
        if(dynamicPropertiesChanged) {
            this.properties.setState(data.dynamicData);
            this.properties.resetChanged();
        }
    }
    
    onReady = async () => {
        if(this.active === true) return;
        this.properties.setState(deepCopy(this.data.dynamicData));
    }

    onBeforeSave = async () => {
        // this.saveDynamicProperties(false);
    }

    saveDynamicProperties = async (syncToDB = true) => {
        this.data.dynamicData = this.properties.getData();
        this.properties.resetChanged();
        if(syncToDB) await this.save();
    }

    selectToggle() {
        this.selected = !this.selected;
        this.selectUpdate();
    }

    updateViewHistory = () => {
        let uid = root.user.id;
        let localViewHistory = { ...this.viewHistory };
        localViewHistory[uid] = { seconds: Math.ceil(new Date().getTime() / 1000) };
        this.viewHistory = localViewHistory;

        let remoteViewHistory: LooseObject = {};
        remoteViewHistory[`viewHistory.${uid}`] = firestore.FieldValue.serverTimestamp();

        this.docRef.update(remoteViewHistory);
    }

    select(skipUpdate: boolean = false) {
        this.selected = true;
        this.selectedLastModified = nowTimestamp();
        if (skipUpdate) return;
        this.selectUpdate();
    }

    deselect(skipUpdate: boolean) {
        this.selected = false;
        this.selectedLastModified = nowTimestamp();
        if (skipUpdate) return;
        this.selectUpdate();
    }

    setIndex(index: number) {
        this.data.index = index;
    }

    setIndexLocal(index: number) {
        this.data.index = index;
    }

    activate = async () => {
        // this.properties.setState(this.data.dynamicData);
        this.properties.addEventListener(DynamicPropertyEvent.CHANGED, this.onPropertyModified);
        this.active = true;
        if(this.comments.loaded === false) await this.comments.load();
    }

    renderFonts = async () => {
        for (const prop of this.properties.properties) {
            if(prop.type !== DynamicPropertyType.SVGText) continue;
            let textProp = prop as SVGText;
            await textProp.renderTextPaths?.(true);
        }
    }

    deactivate = () => {
        if(this.active === false) return;
        this.active = false;
        // this.properties.reset();
        this.properties.removeEventListener(DynamicPropertyEvent.CHANGED, this.onPropertyModified);
    }

    reload = () => {
        console.log('ad reload');
        this.dispatch(AdEvents.RELOAD);
    }

    selectUpdate() {
        // this.parent.selectUpdate(self, this.selected);
        // root.creative.ads.selectUpdate(self); // TODO: fix this
    }

    onReset = () => {
        // this.properties = new DynamicPropertiesManager(this);
    }
}