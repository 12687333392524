import React, { JSXElementConstructor, ReactComponentElement, ReactElement } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { AutoComplete, Input, Button, Icon, Select, Popover } from 'antd';
import store from '../../../../../stores';
import { IDynamicProperty } from '../../../../../stores/DynamicProperties/Types';
import { DynamicPropertyType } from '../../../../../stores/DynamicProperties/Types/DynamicPropertyType';
import AssetInput from './AssetInput';
import ColorInput from './ColorInput';
import InputGroup from './InputGroup';
import LabelInput from './LabelInput';
import MultiLineTextInput from './MultiLineTextInput';
import SelectInput from './SelectInput';
import SVGTextInput from './SVGTextInput';
import SwitchInput from './SwitchInput';
import TemplateInput from './TemplateInput';
import TextInput from './TextInput';
import DynamicGroup from '../../../../../stores/DynamicProperties/Types/Group';
import { camelCaseToTitle } from '../../../../../utils/String';
import PropertyPopoverContent from '../PropertyPopoverContent';
import { makeObservable, observable } from 'mobx';
import PropertyPopover from '../PropertyPopover';

const Container = styled.div<{
    changed: boolean,
}>`
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    ${({ changed }) => changed && `
    .ant-select-selection {
        background: #e1f3ff;
    }`}
`;

const PropertyLabel = styled.div`
    width: 115px;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    margin-right: 10px;
`;

const BeforeLabel = styled.div`
    font-size: 12px;
    font-weight: 500;
    padding: 0 11px;
    background: #ffffff;
    border: 1px solid #d6d6d6;
    height: 32px;
    margin-right: -3px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

`;

type InputContainerProps = {
    property: IDynamicProperty<any> | undefined;
    addonBefore?: string;
    addonAfter?: string;
    style?: React.CSSProperties | undefined;
    disabled: boolean;
    editMode: boolean;
    disableActions?: boolean;
    onRemove: (propertyName: string) => void;
}

class InputContainer extends React.Component<InputContainerProps> {
    property: IDynamicProperty<any> | undefined;
    popoverVisible: boolean = false;

    constructor(props: InputContainerProps) {
        super(props);
        this.property = props.property;
        this.property?.subscribe(this);

        makeObservable(this, {
            popoverVisible: observable,
        })
    }

    render() {
        if(!this.property) {
            return <></>;
        }
        let property = this.property;
        let label = property.name;
        let addonBefore = this.props.addonBefore;
        let addonAfter = this.props.addonAfter;
        let addonClass = '';
        let editMode = this.props.editMode;
        

        if (property.unit) addonAfter = property.unit;
        if (addonBefore) addonClass += 'addonBefore'

        let fullWidthInput = {width: '540px'};
        let halfWidthInput = {width: '190px', marginRight: '10px'};

        let inputElements: {type: DynamicPropertyType, inputElement: ReactElement }[] = [
            { type: DynamicPropertyType.ASSET,      inputElement: <AssetInput editMode={editMode} style={fullWidthInput} property={property} disabled={this.props.disabled} />},
            { type: DynamicPropertyType.SVGText,    inputElement: <SVGTextInput editMode={editMode} onRemove={ propertyName => this.props.onRemove(propertyName) } property={property as DynamicGroup} disabled={this.props.disabled} />},
            { type: DynamicPropertyType.GROUP,      inputElement: <InputGroup editMode={editMode} onRemove={ propertyName => this.props.onRemove(propertyName) } property={property as DynamicGroup} disabled={this.props.disabled} />},
            { type: DynamicPropertyType.TEXT,       inputElement: <TextInput editMode={editMode} style={fullWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.TEMPLATE,   inputElement: <TemplateInput editMode={editMode} style={fullWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.COLOR,      inputElement: <TextInput editMode={editMode} style={halfWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.SELECT,     inputElement: <SelectInput editMode={editMode} style={halfWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.NUMBER,     inputElement: <TextInput editMode={editMode} style={halfWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.BOOLEAN,    inputElement: <SwitchInput editMode={editMode} style={halfWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.DIMENSIONS, inputElement: <InputGroup editMode={editMode} onRemove={ propertyName => this.props.onRemove(propertyName) } property={property as DynamicGroup} disabled={this.props.disabled} />},
            { type: DynamicPropertyType.LABEL,      inputElement: <TextInput editMode={editMode} style={fullWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.PERCENTAGE, inputElement: <TextInput editMode={editMode} style={halfWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.POSITION,   inputElement: <InputGroup editMode={editMode} onRemove={ propertyName => this.props.onRemove(propertyName) } property={property as DynamicGroup} disabled={this.props.disabled} />},
            { type: DynamicPropertyType.PIXELS,     inputElement: <TextInput editMode={editMode} style={halfWidthInput} property={property} disabled={this.props.disabled} /> },
            { type: DynamicPropertyType.POINTS,     inputElement: <TextInput editMode={editMode} style={halfWidthInput} property={property} disabled={this.props.disabled} /> },
        ];

        let InputElement = inputElements.find(x => x.type === property.type)?.inputElement;

        let isGroupInputType = InputElement?.props.property instanceof DynamicGroup;
        let showLabel = label && !isGroupInputType;

        return (
            <Container key={label} style={this.props.style} changed={property.getChanged() && property instanceof DynamicGroup !== true}>
                {showLabel ? <PropertyPopover property={property} editMode={editMode}><PropertyLabel>{camelCaseToTitle(label)}</PropertyLabel></PropertyPopover> : <span></span>}
                {addonBefore ? <BeforeLabel>{addonBefore}</BeforeLabel> : <span></span>}
                {(this.props.children || InputElement)}
            </Container>
        );
    }
}

export default observer(InputContainer);
