import { Button as BaseButton, Icon } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  align-items: center;
  display: flex;
  font-size: 20px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 3px;
`;

export interface NavigationMenuProps { }

class ReloadButton extends React.Component<NavigationMenuProps>{


  constructor(props: NavigationMenuProps) {
    super(props);
  }

  reload = () => {
    store.tearsheet.ads.current?.reload();
  }

  render() {
    const { tearsheet } = store;
    const { current } = tearsheet.ads;
    const isLoading = current?.loading;

    const { reload } = this;

    return (
      <Button onClick={reload}>
        {isLoading && <Icon type="loading" />}
        {!isLoading && <Icon type="reload" />}
      </Button>
    );
  }
};

export default observer(ReloadButton);
