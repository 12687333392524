import React from 'react';
import styled from 'styled-components';

export enum AdHubLogoVariant {
  BLACK = 'black',
  WHITE = 'white',
}

const Container = styled.div<{ variant: AdHubLogoVariant }>`
  align-items: center;
  color: ${({ variant }) => variant};
  display: flex;
  flex-direction: column;
  font-size: 24px;
  justify-content: center;
  width: 100%;
`;

const TopLabel = styled.span`
  font-size: 1.45em;
  font-weight: 900;
  left: 1px;
  line-height: 0.95em;
  position: relative;
  top: -3px;
`;

const BottomLabel = styled.span`
  font-size: 1em;
  font-weight: 900;
  line-height: 0.83em;
`;

const SimpleLabel = styled.span`
  font-size: 1.16em;
  font-weight: 900;
  line-height: 0.95em;
`;

export interface AdHubLogoProps {
  className?: string;
  collapsed?: boolean;
  variant?: AdHubLogoVariant;
}

class AdHubLogo extends React.Component<AdHubLogoProps>{
  constructor(props: AdHubLogoProps) {
    super(props);
  }

  render() {
    const {
      className,
      collapsed,
      variant = AdHubLogoVariant.WHITE
    } = this.props;

    return (
      <Container
        className={className}
        variant={variant}
      >
        {collapsed && (
          <>
            <TopLabel>{'AD'}</TopLabel>
            <BottomLabel>{' HUB'}</BottomLabel>
          </>
        )}

        {!collapsed && (
          <SimpleLabel>{'ADHUB'}</SimpleLabel>
        )}
      </Container>
    );
  }
};

export default AdHubLogo;
