import {
  buildColumn,
  TableColumnFilterType,
  TableColumnSortType,
} from 'components/UI/Table/Table/ColumnBuilder';
import { AddMemberButton } from 'components/UI/DocumentFolderView/AddMember';
import { basicTextFilterer } from 'components/UI/Table/Table/ColumnBuilder/ColumnBuilder.service';
import { MemberActionButtons } from 'components/UI/DocumentFolderView/MemberActionButtons';
import { observer } from 'mobx-react';
import { Table } from 'components/UI/Table';
import { TableVariants } from 'components/UI/Table/TableWrapper/TableWrapper';
import { UserSchema } from 'stores/User';
import { UserThumbnail } from 'components/UI';
import DocumentStore, { DocumentEvents } from 'stores/DocumentStore';
import React from 'react';
import store from 'stores';

export interface MembersTableProps {
  parentDocument: DocumentStore;
  title?: string;
}

class MembersTable extends React.Component<MembersTableProps>{
  constructor(props: MembersTableProps) {
    super(props);
  }

  componentDidMount() {
    const { parentDocument } = this.props;
    parentDocument.members.addEventListener(DocumentEvents.CHANGED, this.forceUpdate);
  }

  componentWillUnmount() {
    this.props.parentDocument.members.removeEventListener(DocumentEvents.CHANGED, this.forceUpdate);
  }

  getTableColumns() {
    const { parentDocument } = this.props;

    return [
      buildColumn<UserSchema>({
        render: (rowData) => {
          let photoURL = store.usersCache.getPhoto(rowData);
          return (<UserThumbnail src={photoURL} />)
        },
        title: '',
        width: 32,
      }),
      buildColumn<UserSchema>({
        defaultSortOrder: 'ascend',
        filterer: (search, row) => basicTextFilterer(search, row.displayName),
        filterType: TableColumnFilterType.Search,
        sortType: TableColumnSortType.Alphabetical,
        sourceData: (rowData) => rowData.displayName,
        title: 'Name',
      }),
      buildColumn<UserSchema>({
        sourceData: (rowData) => rowData.email,
        sortType: TableColumnSortType.Alphabetical,
        title: 'Email',
      }),
      buildColumn<UserSchema>({
        render: (rowData) => rowData.documentRole,
        title: 'Role',
      }),
      buildColumn<UserSchema>({
        render: (rowData) => <MemberActionButtons parentDocument={parentDocument} memberId={rowData.uid} />,
        title: '',
        width: 32,
      }),
    ]
  };

  getTableHeaderButtons() {
    const { parentDocument } = this.props;
    return [
      <AddMemberButton
        parentDocument={parentDocument}
        key="membersTable.addButton"
      />,
    ];
  };

  render() {
    const { parentDocument, title = 'Sharing' } = this.props;

    const columns = this.getTableColumns();
    const headerButtons = this.getTableHeaderButtons();

    const members = parentDocument.members.users.map(item => ({
      ...item.data,
      key: `members.${item.data.email}`,
    }));

    return (
      <Table
        columnsDefinition={columns}
        data={members}
        headerButtons={headerButtons}
        size="small"
        title={title}
        variant={TableVariants.FLAT}
      />
    );
  }
}

export default observer(MembersTable);
