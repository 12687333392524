import { firestore, storageRef, hostingRef, db, functionAuthRequest } from './Firebase';
import AssetUploadManager from './AssetUploadManager';
import Asset, { AssetSchema } from './Asset';
import { AssetFileType } from './Asset/Types';
import root from './index';
import { appConfig as config } from '../config';
import { urlJoin } from 'url-join-ts';
import DocumentCollection from './DocumentCollection';
import { DocumentCollectionType, DocumentType, ProcessingStatus } from './Types';
import { DocumentReference } from '@firebase/firestore-types';
import { nowTimestamp } from '../utils';
// import Creative from './Creative';
import FirebaseFunctions from './FirebaseFunctions';
import { makeObservable, observable, reaction } from 'mobx';
import Animation from './Animation';
import Creative from './Creative';

export enum UploadStates {
    UPLOADING = 'uploading',
    UPLOAD_FAILED = 'upload_failed',
    UPLOAD_COMPLETE = 'upload_complete',
    NONE = ''
}

class AssetsManager extends DocumentCollection<Asset, AssetSchema> {
    currentFolder = '';
    uploadState = UploadStates.NONE;
    baseURL = 'https://' + config.hostingBucket;
    files: Asset[] = [];
    uploadManager = new AssetUploadManager(this);
    // age: NaN,

    constructor(parent: any){
        super(parent, Asset);
        this.init();
    }

    get allReady() {
        return this.all.filter(item => item.processing === false);
    }

    get folderContext() {
        let list: Asset[] = [];
        let folders = this.children.filter(store => {
            let item = store.data;
            let path = '/root/' + item.path;
            let trailingSlash = this.currentFolder ? '/' : '';
            let currentFolder = '/root/' + this.currentFolder + trailingSlash;
            let isFolder = item.fileType === 'folder';
            let folderRoot = path.split(currentFolder)[1];
            let isInFolderRoot = folderRoot ? folderRoot.indexOf('/') === -1 : false;

            return (isFolder && isInFolderRoot);
        });
        let files = this.all.filter(store => {
            let item = store.data;
            let path = '/root/' + item.path;
            let trailingSlash = this.currentFolder ? '/' : '';
            let currentFolder = '/root/' + this.currentFolder + trailingSlash;
            let isFile = item.fileType === AssetFileType.FILE;
            let folderRoot = path.split(currentFolder)[1];
            let isInFolderRoot = folderRoot ? folderRoot.indexOf('/') === -1 : false;

            return (isFile && isInFolderRoot);
        });

        list = list.concat(folders);
        list = list.concat(files);

        return list;
    }

    get allFiles() {
        let files = this.all.filter(item => {
            return item.data.fileType === AssetFileType.FILE;
        });
        return files;
    }


    get default() {
        for (let item of this.all) {
            if (item.default === true) return item;
        }
    }

    get newest () {
        return this.allFiles.reduce((a, b) => a.data.dateModified.seconds > b.data.dateModified.seconds ? a : b);
    }

    new = (docRef: DocumentReference, dataProps: AssetSchema) => {
        let newComment = new Asset(this, docRef, dataProps);
        return newComment;
    }

    getItemsInFolder = (folderPath: string) => {
        let list: any[] = [];
        let folders = this.all.filter(item => {
            let path = '/root/' + item.path;
            let trailingSlash = folderPath ? '/' : '';
            let currentFolder = '/root/' + folderPath + trailingSlash;
            let isFolder = item.data.fileType === AssetFileType.FOLDER;
            let folderRoot = path.split(currentFolder)[1];
            let isInFolderRoot = folderRoot ? folderRoot.indexOf('/') === -1 : false;

            return (isFolder && isInFolderRoot);
        });
        let files = this.all.filter(item => {
            let path = '/root/' + item.path;
            let trailingSlash = folderPath ? '/' : '';
            let currentFolder = '/root/' + folderPath + trailingSlash;
            let isFile = item.data.fileType === AssetFileType.FILE;
            let folderRoot = path.split(currentFolder)[1];
            let isInFolderRoot = folderRoot ? folderRoot.indexOf('/') === -1 : false;

            return (isFile && isInFolderRoot);
        });

        list = list.concat(folders);
        list = list.concat(files);

        return list;
    }

    getAllItemsInFolder = (folderPath: string) => {
        let files = this.all.filter(item => item.data.path.indexOf(folderPath) === 0);
        return files;
    }

    reload = async () => {
        await this.load();
    }

    setCurrentFolder = (folderPath: string) => {
        this.currentFolder = folderPath;
        this.files = this.folderContext;
    }

    navigateFolderUp = () => {
        let parts = this.currentFolder.split('/');
        parts.splice(parts.length - 1, 1)
        this.currentFolder = parts.join('/');;
    }

    upload = async (selectedFile: File, alternateFileName?:string /* TODO: what is this type? */) => {
        console.log('Uploading asset:', selectedFile.name, alternateFileName);
        let creative = root.current as Animation | Creative;
        this.uploadState = UploadStates.UPLOADING;
        const fileName = alternateFileName || selectedFile.name;
        const filePath = urlJoin(this.currentFolder, fileName);
        const creativePath = creative.simplifiedPath();
        const storagePath = urlJoin(config.assetsStoragePath, creativePath, String(Date.now()) + filePath);
        const fileRef = storageRef.child(storagePath);
        const existingAsset = this.getByPath(filePath);
        let newDocRef: Asset;
        
        try {
            await fileRef.put(selectedFile); //create a child directory called images, and place the file inside this directory
        } catch (error) {
            this.uploadState = UploadStates.UPLOAD_FAILED;
            if (existingAsset) existingAsset.setLoading(false);

            console.log('Asset upload failed:', error);
            throw 'Asset upload failed.';
        }

        if (!existingAsset) {
            console.log('Creating new asset');
            let schema = new AssetSchema();
            newDocRef = await this.addLocal({ ... schema, // TODO: will need to check to make sure this local version actually works
                name: fileName,
                path: filePath,
                type: AssetFileType.FILE,
                fileType: AssetFileType.FILE,
                storage: storagePath,
                status: ProcessingStatus.NONE,
                dateModified: nowTimestamp(),
            });

            await newDocRef.save();
            newDocRef.setLoading(true);
            await FirebaseFunctions.newAsset(creative, newDocRef.id);
        } else {
            console.log('Updating existing asset');
            existingAsset.updateLocal({
                name: fileName,
                path: filePath,
                type: AssetFileType.FILE,
                fileType: AssetFileType.FILE,
                storage: storagePath,
                status: ProcessingStatus.NONE,
                dateModified: nowTimestamp(),
            });
            await existingAsset.save();
            existingAsset.setLoading(true);
            await FirebaseFunctions.newAsset(creative, existingAsset.id);
        }
        this.uploadState = UploadStates.UPLOAD_COMPLETE;

        console.log('Asset processing success.')
    }

    newFolder = async (folderName: string) => {
        let currentFolder = this.currentFolder ? this.currentFolder + '/' : '';
        let path = currentFolder + folderName;
        await this.add({
            type: AssetFileType.FOLDER,
            path: path
        });
    }

    // TODO: call a remove firebase function.

    getByStoragePath = (storage: string) => {
        for (let item of this.all) {
            if (item.data.storage === storage) return item;
        }
    }

    getByPath = (path: string) => {
        for (let item of this.all) {
            if (item.data.path === path) return item;
        }
    }

    getHostedURL = (id: string) => {
        let asset = this.getById(id);
        if (asset) {
            return urlJoin(this.baseURL, asset.data.storage);
        } else {
            return '';
        }
    }

    getByNameInFolder = (name: string) => {
        let assets = this.folderContext;

        for (let asset of assets) {
            if (asset.data.name === name) return asset;
        }
    }

    removeFolder = async (path: string) => {
        let assets = this.getAllItemsInFolder(path);
        for (let asset of assets) {
            await asset.delete();
        }
    }

    remove = async (id: string) => {
        let asset = this.getById(id);
        if(!asset) {
            console.log('Asset not found:' + id);
            return;
        }

        await asset.delete();        
        return id;
    }

    reset = () => {

    }

}

export default AssetsManager;