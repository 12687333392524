import { InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Input = styled(InputNumber)`
  width: 80px;
    
  .ant-input-number-handler-wrap {
    opacity: 1;
  }

  .ant-input-number-input {
    pointer-events: none;
    text-align: center;
    text-indent: -19px;
  }
`;

export interface ScaleInputProps { }

class ScaleInput extends React.Component<ScaleInputProps>{
  constructor(props: ScaleInputProps) {
    super(props);
  }

  handleScaleChange = (value: number) => {
    store.creative.setViewScale(value / 100);
  }

  render() {
    const { handleScaleChange } = this;
    const { viewScale } = store.creative;

    return (
      <Input
        defaultValue={viewScale * 100}
        formatter={value => `${value}%`}
        max={400}
        min={10}
        onChange={(e) => e && handleScaleChange(e)}
        parser={value => (value || '').replace('%', '')}
        step={10}
      />
    );
  }
};

export default observer(ScaleInput);
