import { observer } from 'mobx-react';
import { PanelViews } from 'stores/CreativeTypes';
import BundleSummaryRow from './BundleSummaryRow';
import DrawerLayout from 'components/Views/Creative/Drawers/DrawerLayout';
import React from 'react';
import store from 'stores';

export interface BundlesDrawerProps { }

class BundlesDrawer extends React.Component<BundlesDrawerProps>{
  constructor(props: BundlesDrawerProps) {
    super(props);
  }

  handleOnClose = () => {
    store.creative.setPanel(PanelViews.NONE);
  }

  render() {
    const { displayPanel } = store.creative;
    const { handleOnClose } = this;

    const bundles = [...store.creative.bundles.allAvailable];

    return (
      <DrawerLayout
        isVisible={displayPanel === PanelViews.BUNDLES}
        onClose={handleOnClose}
        title="Bundles"
      >
        {bundles.map((bundle) => (
          <BundleSummaryRow
            bundle={bundle}
            key={`bundlesDrawer.bundle.${bundle.id}`}
          />
        ))}
      </DrawerLayout>
    );
  }
};

export default observer(BundlesDrawer);
