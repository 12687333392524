import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Icon, Slider, Tooltip, Dropdown, Menu } from 'antd';
import store from 'stores';
import { AntDesignIconName } from 'stores/Types';
import { SliderValue } from 'antd/lib/slider';
import { reaction } from 'mobx';

const ControlsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    position: relative;
    width: calc(100% - 20px);
    margin: 10px;
`;

const ControlButton = styled(Icon)`
    font-size: 24px;
    padding: 10px;
    color: black;
    cursor: pointer;
`;

const PlayPauseButton = styled(ControlButton)`
    border-right: 1px solid #D9D9D9;
`;

const LoopButton = styled(ControlButton)`
    border-left: 1px solid #D9D9D9;
    width: 50px;
`;

const SpeedButton = styled.div`
    padding: 10px;
    color: black;
    cursor: pointer;
    border-left: 1px solid #D9D9D9;
    font-size: 12px;  // Adjust this as needed
    width: 50px;  // Adjust this width as needed
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const ProgressSlider = styled(Slider)`
    flex-grow: 1;
    margin: 10px 5px;

    .ant-slider-rail {
        background-color: rgba(255, 255, 255, 0.3);
    }

    .ant-slider-track {
        background-color: #D9D9D9;
    }

    .ant-slider-handle {
        border-color: #595959 !important;
        background: #595959;
        -webkit-user-select: none;  /* Safari */
        -moz-user-select: none;     /* Firefox */
        -ms-user-select: none;      /* IE10+/Edge */
        user-select: none;          /* Standard */
        
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    .ant-slider-step {
        background-color: #D9D9D9;
        border-radius: 8px;
    }
`;

const TimeDisplay = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    color: black;
    margin: 0 15px;
    cursor: pointer;
    user-select: none;
`;

interface PlayerControlsProps { }

class PlayerControls extends React.Component<PlayerControlsProps> {

    constructor(props: PlayerControlsProps) {
        super(props);
        reaction(() => store.input.spacebar, () => {
            if(store.input.spacebar) store.animation.player.togglePlayPause();
        });
    }

    handleSliderChange = (value: SliderValue) => {
        const animation = store.animation.player.instance;
        store.animation.player.setIsPlaying(false);

        if (animation && typeof value === 'number') {
            let frame = Math.round(animation.totalFrames * (value / 100));
            animation.goToAndStop(frame, true);
        }
    };

    handleSpeedChange = (speed: number) => {
        store.animation.player.setSpeed(speed);
    };

    formatTooltip = (value: number) => {
        return store.animation.player.currentTimeDisplay;
    };

    renderSpeedMenu = () => (
        <Menu>
            {store.animation.player.speedOptions.map(speed => (
                <Menu.Item key={speed} onClick={() => this.handleSpeedChange(speed)}>
                    {speed}x
                </Menu.Item>
            ))}
        </Menu>
    );

    render() {
        const loaded = store.animation.assets.loaded;
        const player = store.animation.player;
        const isPlaying = player.isPlaying;

        const playPauseIconName: AntDesignIconName = isPlaying ? 'pause-circle' : 'play-circle';
        const loopIconName: AntDesignIconName = player.loop ? 'redo' : 'undo';
        let frameFraction = 100 / player.totalFrames;

        return (
            <ControlsContainer>
                <PlayPauseButton
                    type={playPauseIconName}
                    onClick={player.togglePlayPause}
                />
                <TimeDisplay onClick={player.toggleTimeDisplay}>{player.currentTimeDisplay}</TimeDisplay>
                <ProgressSlider
                    value={player.progress}
                    onChange={this.handleSliderChange}
                    tipFormatter={this.formatTooltip}
                    tooltipPlacement="top"
                    min={0}
                    max={100}
                    step={frameFraction}
                    disabled={!loaded}
                    onAfterChange={() => { player.setIsPlaying(false) }}
                />
                <TimeDisplay onClick={player.toggleTimeDisplay}>{player.durationDisplay}</TimeDisplay>
                <LoopButton
                    type={loopIconName}
                    onClick={player.toggleLoop}
                />
                <Dropdown overlay={this.renderSpeedMenu()} placement="topCenter" trigger={['click']}>
                    <SpeedButton>{player.speed}x</SpeedButton>
                </Dropdown>
            </ControlsContainer>
        );
    }
}

export default observer(PlayerControls);