import styled from 'styled-components';

export default styled.div`
    display: flex;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    flex-grow: 1;
    flex-flow: column;
    background: #fff;
    margin-bottom: 10px;

    &.pagination-disabled {
        border-bottom: 0;
    }

    .ant-table-placeholder {
    border-bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    }

    .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
    }

    .ant-table-small {
    border-radius: 0;
    border-width: 0 0 1px 0;
    }

    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
    }

    .ant-pagination.ant-table-pagination {
    margin: 16px;
    }

    .photo {
    width: 55px;
    }
`;