import { observer } from 'mobx-react';
import { ViewModes } from 'stores/UI';
import AdViewFrame from './AdViewFrame';
import AdViewToolbar from './AdViewToolbar';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Container = styled.div`
  border-right: 1px solid #f2ebeb;
  display: grid;
  grid-template-rows: 50px 1fr;
  height: 100%;
`;

export interface CreativeAdViewProps {
  className?: string;
};

class AdView extends React.Component<CreativeAdViewProps>{
  constructor(props: CreativeAdViewProps) {
    super(props);
  }

  render() {
    const { ui } = store;
    const { className } = this.props;

    const framelessMode = ui.viewMode === ViewModes.FRAMELESS;

    if (framelessMode) {
      return (
        <AdViewFrame />
      );
    }

    return (
      <Container className={className}>
        <AdViewToolbar />
        <AdViewFrame />
      </Container>
    );
  }
}

export default observer(AdView);
