import { Layout } from 'antd';
import styled from 'styled-components';

export const Container = styled(Layout.Header)`
  background: #fff;
  padding: 0;
  left: 200px;
  width: 100%;
  transition: all .2s;
  display: flex;
  flex-flow:row;
  align-items: center;

  &.collapsed {
    left: 80px;
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
    display: flex;
    align-items: center;
  }

  .content {
    display: flex;
    flex-grow: 1;
    font-size: 18px;
    font-family: 'Open Sans';
  }

  .profile-button {
    margin-right: 8px;
  }
`;