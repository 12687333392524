import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { nowTimestamp } from '../utils';
import { FolderSchema } from './DocumentFolderTypes';
import type DocumentFolder from './DocumentFolder';
import { DocumentCollectionType, DocumentStatus, DocumentType, FilterProperty, FilterSet, LooseObject } from './Types';

export enum PanelViews {
    AD = 'ad',
    ASSETS = 'assets',
    BUNDLES = 'bundles',
    FILTER = 'filter',
    TEARSHEETS = 'tearsheets',
    TEMPLATES = 'templates',
    NONE = 'none',
}

// interface CreativeSchemaProxy extends DocumentSchema {}; // TODO: To get around DocumentSchema not being ready when extending it. use: export class CreativeSchema implements CreativeSchemaProxy

export class CreativeSchema extends FolderSchema {
    type = DocumentType.CREATIVE;
    archived = false;
    dynamicProperties: any[] = [];
    filters: { name: string, filters: FilterProperty[] }[] = [];
    filterSets: any[] = [];
    author: string = '';
    name: string = '';
    notes: string = '';
    dateModified: FirestoreTimestamp | FieldValue = nowTimestamp();
    tags: string[] = [];
}

export class CreativePublicSchema {
    type = DocumentType.PROJECT;
    name: string = '';
}

export type CreativeParams = {
    clientID: string,
    projectID: string,
    creativeID: string
}