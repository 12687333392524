import {
  Button as BaseButton,
  Icon,
  Input,
  message as AlertTriggerer,
} from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import BaseFormModal from 'components/UI/FormModal';
import FormItem from 'antd/lib/form/FormItem';
import MembersTable from '../DocumentFolderView/MembersTable';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import TagsManager from 'components/UI/DocumentFolderView/TagsManager';

const FormModal = styled(BaseFormModal)`
  .ant-modal-body{
    max-height: 70vh;
    overflow: auto;
    padding: 13px;
  }

  .ant-form-item{
    margin-bottom: 4px;
  }

  .ant-form-item-label{
    line-height: 1.5;
    margin-bottom: 4px;
  }
`;

const Button = styled(BaseButton)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
`;

const BigIcon = styled(Icon)`
  font-size: 20px;
`;

export interface EditDocumentButtonProps { }

class EditDocumentButton extends React.Component<EditDocumentButtonProps>{
  isLoading: boolean = false;
  isModalVisible: boolean = false;

  constructor(props: EditDocumentButtonProps) {
    super(props);

    makeObservable(this, {
      isLoading: observable,
      isModalVisible: observable,
    })
  }

  turnOnLoader = () => {
    this.isLoading = true;
  }

  turnOffLoader = () => {
    this.isLoading = false;
  }

  openModal = () => {
    this.isModalVisible = true;
  }

  closeModal = () => {
    this.isModalVisible = false;
  }

  submitUpdate = async (values: unknown, formController: WrappedFormUtils<unknown>) => {
    const document = store.current;
    const title = (values as { title: string }).title;
    const { closeModal, turnOnLoader, turnOffLoader } = this;

    try {
      turnOnLoader();

      await document.updateName(title);
      formController.resetFields();
      closeModal();
    }
    catch (e) {
      AlertTriggerer.error((<span>Something went wrong. Please <b>try again</b>...</span>));
      console.error('Error updating the document:', e);
    }
    finally {
      turnOffLoader();
    }
  }

  render() {
    const document = store.current;
    const { isLoading, isModalVisible } = this;
    const { closeModal, openModal, submitUpdate } = this;

    return (
      <>
        <Button onClick={openModal}>
          <BigIcon type="edit" style={{ fontSize: 20 }} />
        </Button>

        <FormModal
          cancelText="Done"
          isVisible={isModalVisible}
          onClose={closeModal}
          isLoading={isLoading}
          onSubmit={submitUpdate}
          submitText="Save"
          title="Edit Properties"
          width={760}
        >
          {({ formController: { getFieldDecorator } }) => (
            <>
              <FormItem label="Title">
                {getFieldDecorator('title', {
                  initialValue: document.data.name,
                  rules: [
                    { required: true, message: 'A name is required.' },
                    { pattern: /^\S.*$/, message: 'The name cannot start with a space.' },
                  ]
                })(<Input />)}
              </FormItem>
              
              { document.members.enabled && (<MembersTable
                parentDocument={document}
                title="Sharing"
              />)}

              { document.data?.tags && (<TagsManager
                // @ts-expect-error
                parentDocument={document}
                title="Tags"
              />)}

            </>
          )}
        </FormModal>
      </>
    );
  }
};

export default observer(EditDocumentButton);
