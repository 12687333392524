import { Button, message } from 'antd';
import Bundle from 'stores/Bundle';
import React from 'react';

export interface CopyBundleLabeledUrlsButtonProps {
  bundle: Bundle;
}

type LinkType = {
  label: string;
  url: string;
}

class CopyBundleLabeledUrlsButton extends React.Component<CopyBundleLabeledUrlsButtonProps>{
  constructor(props: CopyBundleLabeledUrlsButtonProps) {
    super(props);
  }

  getUrls = (): LinkType[] => {
    const { bundle } = this.props;
    const { elements } = bundle;

    let links: LinkType[] = [];

    elements.all.forEach((element) => {
      element.data.builds.forEach((build) => {
        if (build.buildType === "dist") {
          build.manifest.forEach((manifest) => {
            if (manifest.path.includes('index.html')) {
              links.push({
                label: element.data.label,
                url: `${bundle.baseURL}/${manifest.storage}`
              });
            }
          });
        }
      });
    });

    return links;
  }

  copyFileLinksToClipboard = async () => {
    const { getUrls } = this;
    const linksAsText = getUrls().map((link) => `${link.label} - ${link.url}`);
    const textToBeSentToClipboard = linksAsText.join('\n');

    await navigator.clipboard.writeText(textToBeSentToClipboard);
    message.success('Ad links copied.');
  }

  render() {
    const { copyFileLinksToClipboard } = this;

    return (
      <Button onClick={copyFileLinksToClipboard}>
        {'Copy URLs (Labeled)'}
      </Button>
    )
  }
};

export default CopyBundleLabeledUrlsButton;
