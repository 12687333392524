import { Icon as BaseIcon } from 'antd';
import Asset from 'stores/Asset';
import React from 'react';
import styled from 'styled-components';

const Icon = styled(BaseIcon)`
  font-size: 22px;
  opacity: 0.6;
`;

export interface AssetSummaryRowProps {
  asset: Asset;
}

class AssetSummaryRow extends React.Component<AssetSummaryRowProps>{
  constructor(props: AssetSummaryRowProps) {
    super(props);
  }

  render() {
    const { asset } = this.props;
    const { type } = asset.data;

    if (type === 'file') {
      return <Icon type="file" />;
    }

    if (type === 'folder') {
      return <Icon type="folder" />;
    }

    return null;
  }
};

export default AssetSummaryRow;
