import { AddClientButton, DocumentFolderView, HeaderButton, HeaderDropdown } from "components/UI";
import { observer } from "mobx-react";
import React from 'react';
import store from "stores";
import { breadcrumbsToTitle } from "utils";

export interface ClientProps { }

const CLIENTS_VIEW_TITLE = 'Clients';

class Clients extends React.Component<ClientProps>{
  constructor(props: ClientProps) {
    super(props);
    document.title = CLIENTS_VIEW_TITLE;
  }

  getClientsTableHeaderButtons = () => {
    return [
      <HeaderDropdown
        disabled
        key="client.projectsTable.filtersDropdown"
        label="Filters"
        onSelect={() => { }}
        options={[]}
      />,
      <HeaderButton
        disabled
        key="client.projectsTable.applyFiltersButton"
        label="Save Filter"
        onClick={() => { }}
      />,
      <AddClientButton
        key="client.projectsTable.addButton"
        parentDocument={store.clients}
      />
    ]
  }

  render() {
    if(store.current && store.current.data.name) document.title = store.current.data.name;
    else document.title = CLIENTS_VIEW_TITLE;
    
    const clientsTableHeaderButtons = this.getClientsTableHeaderButtons();

    return (
      <DocumentFolderView
        childrenTableHeaderButtons={clientsTableHeaderButtons}
        childrenTableTitle="Clients"
        membersTableTitle="Sharing"
        parentDocument={store.clients}
        tagsManagerTitle="Tags"
      />
    );
  }
}

export default observer(Clients);
