type GetClickAndControllClickHandlersInput = {
  onClick: (e: React.MouseEvent) => void;
  onMiddleClick?: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  onMouseUp?: (e: React.MouseEvent) => void;
}

type GetClickAndControllClickHandlersOutput = {
  onClick: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  onMouseUp?: (e: React.MouseEvent) => void;
}


export const getClickHandlersWithAutoControlKey =
  ({
    onClick,
    onMiddleClick,
    onMouseDown,
    onMouseUp,
  }: GetClickAndControllClickHandlersInput): GetClickAndControllClickHandlersOutput => {
    const handledOnClick = () => { };

    const handledOnMouseDown = (e: React.MouseEvent) => {
      e.preventDefault();
      onMouseDown?.(e);
    }

    const handledOnMouseUp = (e: React.MouseEvent) => {
      if (onMiddleClick && (e.button === 1 || e.ctrlKey || e.metaKey)) {
        onMiddleClick?.(e);
        onMouseUp?.(e);
        return;
      }

      onClick(e);
      onMouseUp?.(e);
    }


    return ({
      onClick: handledOnClick,
      onMouseDown: handledOnMouseDown,
      onMouseUp: handledOnMouseUp,
    });
  };