import {
  Form,
  Input,
  message as AlertTriggerer,
} from 'antd';
import { FormModal } from 'components/UI';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import type DocumentFolder from 'stores/DocumentFolder';
import React from 'react';
import { DocumentType } from 'stores/Types';

const ADD_PROJECT_MODAL_TITLE = 'Add Project';
const ADD_PROJECT_SUBMIT_BUTTON_LABEL = 'Add';

export interface AddProjectModalProps {
  isVisible: boolean;
  onClose: () => void;
  parentDocument: DocumentFolder;
};

class AddProjectModal extends React.Component<AddProjectModalProps>{
  isLoading: boolean = false;

  constructor(props: AddProjectModalProps) {
    super(props);

    makeObservable(this, {
      isLoading: observable,
    });
  }

  turnOnLoader = () => {
    this.isLoading = true;
  }

  turnOffLoader = () => {
    this.isLoading = false;
  }

  handleFormSubmit = async (values: unknown, formController: WrappedFormUtils<unknown>) => {
    const targetProjectName = (values as { name: string; }).name;
    const { parentDocument } = this.props;
    const { turnOffLoader, turnOnLoader } = this;

    try {
      turnOnLoader();

      await parentDocument.documents.add({
        name: targetProjectName,
        type: DocumentType.PROJECT
      });

      formController.resetFields();
      this.props.onClose();

    } catch (e) {
      AlertTriggerer.error((<span>Something went wrong. Please <b>try again</b>...</span>));
      console.error('Error creating the new project:', e);
    }
    finally {
      turnOffLoader();
    }
  }

  render() {
    const { isLoading } = this;
    const { isVisible, onClose } = this.props;
    const { handleFormSubmit } = this;

    return (
      <FormModal
        isLoading={isLoading}
        isVisible={isVisible}
        onClose={onClose}
        onSubmit={handleFormSubmit}
        resetOnClose
        submitText={ADD_PROJECT_SUBMIT_BUTTON_LABEL}
        title={ADD_PROJECT_MODAL_TITLE}>
        {
          ({ formController: { getFieldDecorator } }) => (
            <>
              <Form.Item label="Project name">
                {getFieldDecorator('name', {
                  rules: [
                    { required: true, message: 'A project name is required.' },
                    { pattern: /^\S.*$/, message: 'The project name cannot start with a space.' },
                  ]
                })(<Input disabled={isLoading} />)}
              </Form.Item>
            </>
          )
        }
      </FormModal>
    );
  }
}

export default observer(AddProjectModal);