import { firestore, firebase, db, functionAuthRequest } from './Firebase';
import { urlJoin } from 'url-join-ts';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import moment from 'moment'; // TODO: switch out moment with a more modern library.
import color from 'color-convert';
import seedrandom from 'seedrandom';
import { DocumentCollectionType, DocumentStatus, DocumentTimestamp, DocumentType, LooseObject } from './Types';
import root from './index';
import DocumentStore from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import CommentsManager from './CommentsManager';
import { nullTimestamp } from '../utils';
import { UserSchemaPublic } from './User';
import { timestampToDate } from 'utils/Dates';
import { UserRoles } from './UserTypes';


export class CommentSchema extends DocumentSchema {
    timestamp: DocumentTimestamp = nullTimestamp();
    lastModified: DocumentTimestamp = nullTimestamp();
    placeholderName = '';
    owner = '';
    content = '';
    ownerMetadata: UserSchemaPublic;
}

class Comment extends DocumentStore<CommentSchema> {
    schema = new CommentSchema();
    collectionType = DocumentCollectionType.COMMENTS;

    uid = '';
    editing = false;
    selected = false;
    ownerDisplayName = '';
    ownerPhotoURL = '';
    datetime = '';
    thumbnailColor = '';
    parent: CommentsManager;

    constructor(parent: CommentsManager, docRef: DocumentReference, data: CommentSchema) {
        super(parent, docRef, data);
        this.init();
    }

    get placeholderName() {
        return root.ui.placeholderName || '';
    }

    get edited() {
        console.log('this.data.lastModified',this.data.lastModified);
        const lastModified = toMillis(this.data.lastModified);
        const timestamp = toMillis(this.data.timestamp);
        return lastModified > timestamp;
    }

    get canEdit() {
        return root.user.data.uid === this.data.owner || root.user.role === UserRoles.ADMIN || root.user.role === UserRoles.MANAGER;
    }

    onReady = async () => {
        this.updateDatetime();
        await this.updateOwnerInfo();
        if(this.data.placeholderName !== '' && this.data.owner === '') this.thumbnailColor = this.randomColorFromString(this.data.placeholderName, 50, 90);
    }

    onLoad = async () => {
        this.status = DocumentStatus.LOADING;

        this.updateDatetime();
        await this.updateOwnerInfo();
        if(this.data.placeholderName !== '' && this.data.owner === '') this.thumbnailColor = this.randomColorFromString(this.data.placeholderName, 50, 90);

        this.status = DocumentStatus.LOADED;
    }

    onUpdate = (data: CommentSchema) => {
        this.updateDatetime();
        this.updateOwnerInfo();
    }

    edit = async (content: string) => {
        console.log('edit',content);
        this.data.content = content;
        this.data.lastModified = firestore.Timestamp.now();
        this.save();
    }

    randomColorFromString = (seed: string, saturation: number, lightness: number) => {
        return '#' + color.hsl.hex([360 * seedrandom(seed)(), saturation, lightness]);
    }

    setContent = (content: string) => {
        this.data.content = content;
        this.data.lastModified = firestore.Timestamp.now();
    }

    disableEditing = () => {
        this.editing = false;
    }

    toggleEditing = () => {
        this.editing = !this.editing;
    }

    selectToggle = () => {
        this.selected = !this.selected;
    }

    select = (skipUpdate: boolean) => {
        this.selected = true;
        if (skipUpdate) return;
    }

    deselect = (skipUpdate: boolean) => {
        this.selected = false;
        if (skipUpdate) return;
    }

    delete = async () => {
        await this.docRef.delete();
    }

    updateOwnerInfo = async () => {
        if (this.data.placeholderName === '' && this.data.owner !== '') {
            const userCache = { ...this.parent?.userCache };
            const cachedOwnerInfo = userCache[this.data.owner];
            if (cachedOwnerInfo) {
                this.ownerDisplayName = cachedOwnerInfo.displayName;
                this.ownerPhotoURL = cachedOwnerInfo.photoURL;
            } else {
                const data = await root.user.getPublicUserData(this.data.owner);
                this.ownerDisplayName = data.displayName;
                this.ownerPhotoURL = data.photoURL;

                this.parent?.cacheUser(this.data.owner, data);
            }
        } else {
            this.ownerDisplayName = this.placeholderName;
        }
    }

    updateDatetime = () => {
        const now = new Date().getTime();
        // const utcDay = 86400000;
        const utcHour = 3600000;
        const timestamp = toMillis(this.data.timestamp);
        console.log('------------------updateDatetime',this.data.timestamp, timestamp);
        console.log('timestamp',timestamp);


        if (now - timestamp > utcHour) {
            this.datetime = moment(timestamp).subtract(new Date().getTimezoneOffset()).format('MMM Do h:mm a');
        } else {
            this.datetime = moment(timestamp).fromNow();
        }
    }

    reset = () => {
        this.editing = false;
        this.selected = false;
        this.ownerDisplayName = '';
        this.ownerPhotoURL = '';
        this.datetime = '';
        this.thumbnailColor = '';
    }
}

function toMillis(timestamp: FirestoreTimestamp | DocumentTimestamp) {
    const milliseconds = Math.round((timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000));
    return milliseconds;
}

export default Comment;