import { DynamicProperty } from './Property';
import { IDynamicProperty } from './index';
import { DynamicPropertyType } from './DynamicPropertyType';


export default class Boolean extends DynamicProperty<boolean> {
  type = DynamicPropertyType.BOOLEAN;
  value = false;
  default = () => false;

  constructor(parent: IDynamicProperty<any>, name: string, value: boolean) {
    super(parent, name, value);
    // this.init();
  }

  setDefault = () => {
    this.setInitialValue(false);
  }

  toggle = () => {
    this.value = !this.value;
  };

  getString = () => {
    return this.value ? 'true' : 'false';
  };

  getPreviewData = () => {
    return this.value;
  };

  setUsingCSV = (value: string) => {
    if (value !== 'true' && value !== 'false') {
      this.throwCSVImportError(`Invalid boolean value: ${value}`);
    } else {
      this.set(value === 'true');
    }
  };
}
