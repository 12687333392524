// import { Store, state } from 'atomx-state';
import { firestore, db, functionAuthRequest, FirestoreEvents } from './Firebase';
import { DocumentReference, CollectionReference, QuerySnapshot, Query, DocumentData, QueryDocumentSnapshot, FieldValue } from '@firebase/firestore-types';
import Comment, { CommentSchema } from './Comment';
import root from './index';
import { DocumentCollectionType, DocumentStatus, DocumentTimestamp, LooseObject } from './Types';
import DocumentStore from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import DocumentCollection from './DocumentCollection';
import { UserSchemaPublic } from './User';

export class CommentMetadataSchema extends DocumentSchema {
    numComments = 0;
    commentsDateAdded: LooseObject = {};
    viewHistory: LooseObject = {};
}

class CommentsManager extends DocumentCollection<Comment, CommentSchema> {
    status: DocumentStatus = DocumentStatus.NONE;
    query = () => this.collectionRef.orderBy('timestamp');

    editing = '';
    placeholderName = '';
    userCache: LooseObject = {};
    collapsed = true;
    parent: DocumentStore<any>;
    enabled = false;

    constructor(parent: DocumentStore<any>) {
        super(parent, Comment);
        this.parent = parent;
        this.init();
    }

    get all() {
        // sort children by timestamp seconds
        let comments = [... this.children];
        let sorted = comments.sort((a, b) => {
            const aTimestamp = toMillis(a.data.timestamp);
            const bTimestamp = toMillis(b.data.timestamp);
            return aTimestamp - bTimestamp
        });
        return sorted;
    }

    get newest() {
        let comments = [... this.children];
        let newest = comments.sort((a, b) => {
            const aTimestamp = toMillis(a.data.timestamp);
            const bTimestamp = toMillis(b.data.timestamp);
            return bTimestamp - aTimestamp
        });
        return newest[0];
    }

    enable = () => {
        this.enabled = true;
    }

    disable = () => {
        this.enabled = false;
    }

    setState = (state: any) => {
        // TODO: flesh this out.
        // numComments = 0;
        // commentsDateAdded: LooseObject = {};
        // viewHistory: LooseObject = {};
    }

    getState = () => {
        // TODO: flesh this out.
        // numComments = 0;
        // commentsDateAdded: LooseObject = {};
        // viewHistory: LooseObject = {};
    }

    updateCommentsDatetime() {
        for (let comment of this.children) {
            comment.updateDatetime();
        }
    }

    getComment(uid: string) {
        for (let comment of this.children) {
            if (comment.uid === uid) {
                return comment;
            }
        }
    }

    addComment = async (content: string) => {
        let placeholderName = root.user.placeholderName;
        console.log('placeholderName', placeholderName);
        if (placeholderName) {
            await this.add({
                timestamp: firestore.Timestamp.now(),
                lastModified: firestore.Timestamp.now(),
                placeholderName: placeholderName,
                content: content
            })
        } else {
            await this.add({
                timestamp: firestore.Timestamp.now(),
                lastModified: firestore.Timestamp.now(),
                owner: root.user.id,
                content: content
            })
        }
    }

    // updateViewHistory = () => {
    //     let uid = root.user.id;
    //     let localViewHistory = { ...this.viewHistory };
    //     localViewHistory[uid] = { seconds: Math.ceil(new Date().getTime() / 1000) };
    //     this.parent.viewHistory = localViewHistory;

    //     let remoteViewHistory: LooseObject = {};
    //     remoteViewHistory[`viewHistory.${uid}`] = firestore.FieldValue.serverTimestamp();

    //     this.
    // }

    cacheUser(uid: string, data: UserSchemaPublic) {
        const userCache = { ... this.userCache };
        userCache[uid] = data;
        this.userCache = userCache;
    }

    deleteAll = async () => {
        for (let comment of this.children) {
            await comment.delete();
        }
    }

    delete = async (uid: string) => {
        const comment = this.getComment(uid);
        if (comment) {
            await comment.delete();
        }
    }

    reset() {
        this.status = DocumentStatus.NONE;
        this.editing = '';
        this.userCache = {};
        this.collapsed = true;
        super.reset();
    }
}

function toMillis(timestamp: DocumentTimestamp) {
    const milliseconds = Math.round((timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000));
    return milliseconds;
}

export default CommentsManager;