import { Icon, Input as BaseInput, Tag } from "antd";
import styled from "styled-components";

type WithIsVisible = {
  visible: boolean;
}

export const Button = styled(({ visible, ...props }) => <Tag {...props} />) <WithIsVisible>`
  align-items: center;
  background: #fff;
  border-style: dashed;
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  font-size: 13px;
  gap: 4px;
  margin: 0;
  padding: 3px 9px;
  width: fit-content;
`;

export const Input = styled(BaseInput)`
  height: 28px;
  width: 80px;
`;

export const PlusIcon = styled(Icon).attrs({
  type: 'plus',
})`
  margin-bottom: 2px;
`;