import { FILTER_MANAGER_DEFAULT_SET_NAME } from 'stores/FilterManager';
import { observer } from 'mobx-react';
import FiltersToolbarButton from './FiltersToolbarButton';
import React from 'react';
import store from 'stores';

export interface SaveActiveFilterSetButtonProps { };

class SaveActiveFilterSetButton extends React.Component<SaveActiveFilterSetButtonProps>{
  constructor(props: SaveActiveFilterSetButtonProps) {
    super(props);
  }

  saveChanges = () => {
    store.creative.save();
  }

  render() {
    const { saveChanges } = this;
    const currentSetName = store.creative.ads.filter.currentSet.name;

    const isDisabled = !currentSetName || currentSetName === FILTER_MANAGER_DEFAULT_SET_NAME;

    return (
      <FiltersToolbarButton
        disabled={isDisabled}
        label="Save"
        onClick={saveChanges}
      />
    );
  }
};

export default observer(SaveActiveFilterSetButton);
