import { IDynamicProperty } from ".";
import { DynamicPropertyType } from './DynamicPropertyType';
import { DynamicProperty } from "./Property";

export default class Select extends DynamicProperty<string> {
  value = '';
  options: string[] = [];
  type = DynamicPropertyType.SELECT;
  default = () => 'Option 1';

  constructor(parent: IDynamicProperty<any>, name: string, value: string){
    super(parent, name, value);
    // this.init();
  }

  setDefault = () => {
    this.setInitialValue('Option 1');
    this.options = ['Option 1', 'Option 2', 'Option 3'];
  }

  setUsingCSV = (value: string) => {
    this.set(value);
  }

  reset = () => {
    this.value = '';
    this.options = [];
  }
}