export const camelCaseToTitle = (camelCase: string) => {
    // return camelCase
    // .replace(/([A-Z])/g, (match) => ` ${match}`)
    // .replace(/^./, (match) => match.toUpperCase())
    // .trim();

    camelCase = camelCase.replaceAll('_', ' ').replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").replace(/\s\s+/g, ' ');
    return camelCase[0].toUpperCase() + camelCase.slice(1);
}

export const pathToIdSegments = (path: string) => {
    // Trim leading and ending slashes from the path
    const trimmedPath = path.replace(/^\/|\/$/g, '');

    // Extracting IDs, ensuring no leading or ending slashes in the output
    const ids = trimmedPath.match(/[^\/]+\/([^\/]+)/g)?.map(segment => segment.split('/')[1]) || [];
    const idsTransformed = ids.join('/');
    return idsTransformed.split('/');;
}

export const pathToTypeSegments = (path: string) => {
    // Trim leading and ending slashes from the path
    const trimmedPath = path.replace(/^\/|\/$/g, '');
    // Extracting static segments, also ensuring no leading or ending slashes in the output
    const segmentsTransformed = trimmedPath.split('/').filter((_, index) => index % 2 === 0).join('/');
    return segmentsTransformed.split('/');
}