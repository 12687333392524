import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const HeaderContainer = styled.div`
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  min-height: 53px;
  padding: 10px;
`;


export const TitleLabel = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0px 0px 5px;
`;
