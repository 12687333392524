import { FILTER_MANAGER_DEFAULT_SET_NAME } from 'stores/FilterManager';
import { observer } from 'mobx-react';
import { Popconfirm } from 'antd';
import FiltersToolbarButton from './FiltersToolbarButton';
import React from 'react';
import store from 'stores';

export interface RemoveActiveFilterSetButtonProps { };

class RemoveActiveFilterSetButton extends React.Component<RemoveActiveFilterSetButtonProps>{
  constructor(props: RemoveActiveFilterSetButtonProps) {
    super(props);
  }

  removeSet = () => {
    store.creative.ads.filter.sets.removeProperty(store.creative.ads.filter.currentSet);
    store.creative.save();

    store.creative.ads.filter.resetToCustomSet();
  }

  render() {
    const { removeSet } = this;
    const currentSetName = store.creative.ads.filter.currentSet.name;

    const isDisabled = !currentSetName || currentSetName === FILTER_MANAGER_DEFAULT_SET_NAME;

    return (
      <Popconfirm
        cancelText="No"
        okText="Yes"
        onConfirm={removeSet}
        title="Delete current filter?"
      >
        <FiltersToolbarButton
          disabled={isDisabled}
          label="Remove"
        />
      </Popconfirm>
    );

  }
};

export default observer(RemoveActiveFilterSetButton);
