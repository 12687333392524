import { AddButton, Container } from './EmptyContentBox.styles';
import React from 'react';

interface BaseEmptyContentBoxProps {
  isLoading?: boolean;
  loadingLabel?: string;
  noDataLabel?: string;
}

interface WithAddButtonEmptyContentBoxProps extends BaseEmptyContentBoxProps {
  addLabel: string;
  addRecordOnClick: () => void;
  disabled?: boolean;
}

export type EmptyContentBoxProps = BaseEmptyContentBoxProps | WithAddButtonEmptyContentBoxProps;

class EmptyContentBox extends React.Component<EmptyContentBoxProps>{
  constructor(props: EmptyContentBoxProps) {
    super(props);
  }

  render() {
    const { isLoading, } = this.props;
    const loadingLabel = this.props.loadingLabel ?? 'Loading';
    const noDataLabel = this.props.noDataLabel ?? 'No data';

    return (
      <Container>
        {!isLoading && noDataLabel}
        {isLoading && loadingLabel}

        {'addRecordOnClick' in this.props && (
          <AddButton
            disabled={this.props.disabled}
            onClick={this.props.addRecordOnClick}
            type="primary"
          >
            {this.props.addLabel}
          </AddButton>
        )}
      </Container>
    );
  }
}

export default EmptyContentBox;
