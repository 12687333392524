import { Form, Icon as BaseIcon, Input, message } from 'antd';
import { FormModal } from 'components/UI';
import { makeObservable, observable } from 'mobx';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const Icon = styled(BaseIcon)`
  padding-top: 3px;
  font-size: 20px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

type FormValues = {
  name: string;
}

export interface AddFolderButtonProps { }

class AddFolderButton extends React.Component<AddFolderButtonProps>{
  isCreateNewFolderModalOpen: boolean = false;
  isLoading: boolean = false;

  constructor(props: AddFolderButtonProps) {
    super(props);

    makeObservable(this, {
      isCreateNewFolderModalOpen: observable,
      isLoading: observable,
    });
  }

  showCreateNewFolderModal = () => {
    this.isCreateNewFolderModalOpen = true;
  }

  hideCreateNewFolderModal = () => {
    this.isCreateNewFolderModalOpen = false;
  }

  turnOnLoaderSpinner = () => {
    this.isLoading = true;
  }

  turnOffLoaderSpinner = () => {
    this.isLoading = false;
  }

  handleFormSubmit = async (originalValues: unknown, formController: WrappedFormUtils<unknown>) => {
    const values = originalValues as FormValues;

    const {
      hideCreateNewFolderModal,
      turnOnLoaderSpinner,
      turnOffLoaderSpinner
    } = this;

    turnOnLoaderSpinner();

    try {
      const { creative } = store;
      const { name } = values;
      await creative.assets.newFolder(name);

      formController.resetFields();
      hideCreateNewFolderModal();
    }
    catch (error) {
      message.error('Folder creation failed.');
      console.error('Error creating new folder:', error);
    }
    finally {
      turnOffLoaderSpinner();
    }
  }

  render() {
    const {
      handleFormSubmit,
      hideCreateNewFolderModal,
      showCreateNewFolderModal,
    } = this;

    const {
      isLoading,
      isCreateNewFolderModalOpen,
    } = this;

    return (
      <>
        <DrawerButton onClick={showCreateNewFolderModal}>
          <Icon type="folder-add" />
        </DrawerButton>
        <FormModal
          isLoading={isLoading}
          isVisible={isCreateNewFolderModalOpen}
          onClose={hideCreateNewFolderModal}
          onSubmit={handleFormSubmit}
          resetOnClose
          submitText='Add'
          title='New Folder'
        >
          {({ formController: { getFieldDecorator } }) => (
            <>
              <FormItem>
                {getFieldDecorator('name', {
                  rules: [
                    { required: true, message: 'A folder name is required.' },
                    { pattern: /^\S.*$/, message: 'The folder name cannot start with a space.' },
                  ]
                })(
                  <Input
                    disabled={isLoading}
                    placeholder='Type a folder name'
                  />
                )}
              </FormItem>
            </>
          )}
        </FormModal>
      </>
    );
  }
};

export default observer(AddFolderButton);
