import { makeObservable, observable, action, computed } from 'mobx';
import { AnimationItem, RendererType } from 'lottie-web';

export default class AnimationPlayerStore {
    progress: number = 0;
    durationSeconds: number = 0;
    currentTimeSeconds: number = 0;
    instance: AnimationItem | null = null; // reference to the lottie-web instance
    isPlaying: boolean = false;
    showFrames: boolean = false;
    showTimeWithFrames: boolean = false;
    currentFrame = NaN;

    loop = true;
    autoplay = true;
    renderer: RendererType = 'svg';
    speed = 1;

    speedOptions = [2, 1, 0.5, 0.25];

    constructor() {
        if (this.autoplay) {
            this.setIsPlaying(true);
        }

        makeObservable(this, {
            progress: observable,
            durationSeconds: observable,
            currentTimeSeconds: observable,
            instance: observable,
            currentTimeMinutes: computed,
            durationMinutes: computed,
            isPlaying: observable,
            currentFrame: observable,
            loop: observable,
            autoplay: observable,
            renderer: observable,
            speed: observable,
            updateProgress: action,
            updateAnimationDuration: action,
            updateCurrentTime: action,
            updateAnimationInstance: action,
            updateCurrentFrame: action,
            setIsPlaying: action,
            setLoop: action,
            setRenderer: action,
            setConfig: action,
            togglePlayPause: action,
            play: action,
            pause: action,
            showFrames: observable,
            showTimeWithFrames: observable,
            toggleTimeDisplay: action,
            currentTimeDisplay: computed,
            durationDisplay: computed,
            progressInFrames: computed,
            setProgressInFrames: action,
            currentTimeWithFrames: computed,
        });
    }

    get progressInFrames(): number {
        return Math.round(this.currentFrame);
    }

    get totalFrames() {
        const animation = this.instance;
        if (animation) {
            return animation.totalFrames;
        } else {
            return NaN;
        }
    }

    get currentTimeWithFrames(): string {
        const animation = this.instance;
        if (!animation) return "0:00:00";
    
        const totalSeconds = Math.floor(this.currentTimeSeconds);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const frames = Math.round((this.currentTimeSeconds - totalSeconds) * animation.frameRate);
    
        return `${minutes}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
    }

    setProgressInFrames = (frame: number) => {
        const animation = this.instance;
        if (animation) {
            this.currentFrame = frame;
            animation.goToAndStop(frame, true);
            this.updateCurrentTime(frame / animation.frameRate);
            this.updateProgress((frame / animation.totalFrames) * 100);
        }
    }

    toggleTimeDisplay = () => {
        this.showFrames = !this.showFrames;
        console.log('toggleTimeDisplay', this.showFrames);
    }

    toggleLoop = () => {
        if(!this.instance) return;
        this.loop = !this.loop;
        this.instance.loop = this.loop;
    }

    setLoop = (loop: boolean) => {
        if(!this.instance) return;
        this.loop = loop;
        this.instance.loop = loop;
    }

    get currentTimeDisplay(): string {
        if (this.showFrames) {
            return Math.round(this.currentFrame).toString();
        } else { // Add this new condition
            return this.currentTimeWithFrames;
        }
        // } else if (this.showTimeWithFrames) { // Add this new condition
        //     return this.currentTimeWithFrames;
        // } else {
        //     return this.currentTimeMinutes;
        // }
    }

    get durationDisplay(): string {
        if (this.showFrames) {
            return Math.round(this.totalFrames).toString();
        } else {
            return this.durationMinutes;
        }
    }

    togglePlayPause = () => {
        const instance = this.instance;
        if (instance) {
            if (instance.isPaused) {
                this.play();
            } else {
                this.pause();
            }
        }
    };

    play = () => {
        if(!this.instance) return;
        if(this.currentFrame >= this.instance.totalFrames) {
            this.setProgressInFrames(0);
        }
        this.instance.play();
        this.setIsPlaying(true);
    }

    pause = () => {
        if(!this.instance) return;
        this.instance.pause();
        this.setIsPlaying(false);
    }

    setConfig = (config: { loop?: boolean, autoplay?: boolean, renderer?: RendererType }) => {
        if (config.loop !== undefined) {
            this.loop = config.loop;
        }
        if (config.autoplay !== undefined) {
            this.autoplay = config.autoplay;
        }
        if (config.renderer !== undefined) {
            this.renderer = config.renderer;
        }
    }

    setIsPlaying = (isPlaying: boolean) => {
        this.isPlaying = isPlaying;
    }

    setRenderer = (renderer: RendererType) => {
        if(this.instance) this.instance.renderer(renderer);
        this.renderer = renderer;
    }

    setSpeed = (speed: number) => {
        if (this.instance) {
            this.instance.setSpeed(speed);
            this.speed = speed;
        }
    }

    updateCurrentFrame = (frame: number) => {
        this.currentFrame = frame;
    }

    updateProgress = (progress: number) => {
        this.progress = progress;
    }

    updateAnimationDuration = (duration: number) => {
        this.durationSeconds = duration;
    }

    updateCurrentTime = (time: number) => {
        this.currentTimeSeconds = time;
    }

    updateAnimationInstance = (instance: AnimationItem | null) => {
        this.instance = instance;
    }

    get currentTimeMinutes(): string {
        const minutes = Math.floor(this.currentTimeSeconds / 60);
        const seconds = Math.floor(this.currentTimeSeconds % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    get durationMinutes(): string {
        const minutes = Math.floor(this.durationSeconds / 60);
        const seconds = Math.floor(this.durationSeconds % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    reset = () => {
        // Reset progress and time-related properties
        this.updateProgress(0);
        this.updateCurrentTime(0);
        this.updateCurrentFrame(0);
        this.updateAnimationDuration(0);

        // Reset playback state
        this.setIsPlaying(false);
        
        // Reset animation instance
        if (this.instance) {
            this.instance.destroy();
        }
        this.updateAnimationInstance(null);

        // Reset configuration to default values
        this.setLoop(true);

        // Reset display options
        this.showFrames = false;
        this.showTimeWithFrames = false;


        if (this.speed !== 1) {
            this.setSpeed(1); // Assuming 1 is at index 1
        }
    }
}