import { urlJoin } from 'url-join-ts';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { appConfig as config } from '../config';
import { nullTimestamp } from '../utils'
import DocumentStore from './DocumentStore';
import { DocumentSchema } from './DocumentSchema';
import { DocumentCollectionType, DocumentStatus, DocumentType, MutexStates, ProcessingStatus } from './Types';
import BundlesManager from './BundlesManager';
import BundleElement, { Build, BundleElementSchema } from './BundleElement';
import DocumentCollection from './DocumentCollection';
import { makeObservable, reaction } from 'mobx';
import moment from 'moment';

class ArchiveTypes {
    dist = '';
    source = '';
}

export class BundleSchema extends DocumentSchema {
    adIds: Array<string> = [];
    compositeArchivePath: string = '';
    compositeArchivePathsByType = new ArchiveTypes();
    createTime = nullTimestamp();
    creative: string = '';
    errors: Array<any> = []; // TODO: find the structure for this.
    initiatingUser: string = '';
    modifiedTime = nullTimestamp();
    mutex = MutexStates.UNLOCKED;
    name: string = '';
    pendingCount = 0;
    stagePrefix: string = '';
    status: ProcessingStatus = ProcessingStatus.NONE;
    archived: boolean = false;
    taskId: string = '';
}

export default class Bundle extends DocumentStore<BundleSchema> {
    type = DocumentType.BUNDLE
    collectionType = DocumentCollectionType.BUNDLE;
    schema = new BundleSchema();

    report = '';
    elements = new DocumentCollection<BundleElement, BundleElementSchema>(this, BundleElement);
    warning = false;

    baseURL = 'https://' + config.hostingBucket;

    constructor(parent: BundlesManager, docRef: DocumentReference, data: BundleSchema){
        super(parent, docRef, data);
        this.init();
        this.elements.init();
        reaction(() => this.data.status, () => {
            // if (this.data.status === ProcessingStatus.ERROR || this.data.status === ProcessingStatus.COMPLETE) this.loadReportElements();
        });
    }

    get context() {
        return this.parent.context; // TODO: is this right?
    }

    get loading() {
        return this.data.status !== ProcessingStatus.COMPLETE;
    }

    get downloadURL() {
        if (this.data.compositeArchivePath) {
            // maintaining support for older bundles.
            return urlJoin(this.baseURL, this.data.compositeArchivePath.replace(/ /g, "%20"));
        } else {
            return urlJoin(this.baseURL, this.data.compositeArchivePathsByType.dist.replace(/ /g, "%20"));
        }
    }

    get sourceURL() {
        return urlJoin(this.baseURL, this.data.compositeArchivePathsByType.source.replace(/ /g, "%20"));
    }

    get formattedDateModified() {
        return moment(this.data.modifiedTime.toDate()).format('MMMM D, YYYY, h:mm A');
    }

    loadReportElements = async () => {
        this.status = DocumentStatus.LOADING;

        // await this.elements.load();
        // this.checkElementsForIssues(this.elements.all);
        // this.status = DocumentStatus.LOADED;
    }

    checkElementsForIssues(elements: Array<BundleElement>) {
        console.log(`Bundle ${this.data.name}: Checking for issues...`);
        if (!elements) return;
        for (let element of elements) {
            let distData: Build | null = null;
            if (!element.data.builds) continue;
            for (let build of element.data.builds) {
                if (build.buildType === 'dist') {
                    distData = build;
                    break;
                }
            }

            if (!distData) continue;

            let zipSize = distData.archiveActualSize / 1024;
            let zipMaxSize = distData.archiveMaxSize / 1000;
            let zipOverSize = zipSize > zipMaxSize;

            let imageSize = element.data.lossyScreenshotActualSize / 1024;
            let imageMaxSize = element.data.lossyScreenshotMaxSize / 1000;
            let imageOverSize = imageSize > imageMaxSize;

            if (zipOverSize || imageOverSize) {
                this.warning = true;
            }
        }
    }


    onUpdate = (data: BundleSchema) => {
        if ( this.status === DocumentStatus.ERROR || this.status === DocumentStatus.LOADED ) { 
            // this.loadReportElements();
        }
    }

    onReady = () => {
        // if(this.data.status === ProcessingStatus.ERROR || this.data.status === ProcessingStatus.COMPLETE) this.loadReportElements();
    }


    getRootFromPath(path: string) {
        path = '/' + path;
        let pathDepth = path.split('/').length - 1;
        return path.split('/')[pathDepth];
    }


    getDownloadURL() {
        return urlJoin(this.baseURL, this.data.compositeArchivePath.replace(/ /g, "%20"));
    }

}

