import { observer } from 'mobx-react';
import { PanelViews } from 'stores/CreativeTypes';
import DrawerLayout from 'components/Views/Creative/Drawers/DrawerLayout';
import React from 'react';
import store from 'stores';
import TearsheetSummaryRow from './TearsheetSummaryRow';

export interface TearsheetsDrawerProps { }

class TearsheetsDrawer extends React.Component<TearsheetsDrawerProps>{
  constructor(props: TearsheetsDrawerProps) {
    super(props);
  }

  handleOnClose = () => {
    store.creative.setPanel(PanelViews.NONE);
  }

  render() {
    const { displayPanel } = store.creative;
    const { handleOnClose } = this;

    const tearsheets = [...store.creative.tearsheets.allAvailable];

    return (
      <DrawerLayout
        isVisible={displayPanel === PanelViews.TEARSHEETS}
        onClose={handleOnClose}
        title="Tearsheets"
      >
        {tearsheets.map((tearsheet) => (
          <TearsheetSummaryRow
            key={`creative.tearsheetsDrawer.tearsheet.${tearsheet.id}`}
            tearsheet={tearsheet}
          />
        ))}
      </DrawerLayout>
    );
  }
};

export default observer(TearsheetsDrawer);
