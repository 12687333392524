import { IDynamicProperty } from '.';
import { DynamicPropertyType } from './DynamicPropertyType';
import AssetStore, { AssetSchema } from '../../Asset';
import { DynamicProperty, DynamicPropertyEvent } from "./Property";
import store from '../../';
import { urlJoin } from 'url-join-ts';

export default class Asset extends DynamicProperty<string> {
  type = DynamicPropertyType.ASSET;
  value = '';
  default = () => '';
  path: string;
  reserved = false;
  id: string;

  get absoluteURL() {
    return this.store ? urlJoin('https://', this.store.absoluteURL) : '';
  }

  get exists() {
    return store.creative?.assets?.getById(this.value) ? true : false;
  }

  get store() {
    return store.creative?.assets?.getById(this.value);
  }

  get data(): Partial<AssetSchema> {
    return this.store ? this.store.data : {};
  }
  
  get label(){
    return this.store?.data.path || '';
  }

  constructor(parent: IDynamicProperty<any>, name: string, value: string) {
    super(parent, name, value);
    this.value = value;
    this.id = value;
    
    // this.init();
  }

  getString = () => {
    return this.absoluteURL;
  }

  getPreviewData = () => {
    return this.getString();
  }

  setUsingCSV = (data: string) => {
    let asset = store.creative.assets.getById(data);
    // console.log('ASSET SETTING', this.name, data, asset);
    if(!asset) asset = store.creative.assets.getByPath(data);
    if(!asset) {
      console.warn(`CSV Import: Ad "${this.getRoot().get('label').value}": Invalid or missing asset for property ${this.name}`);
    }
    if(asset) this.set(asset.id);
  }

  getCSVData = () => {
    return this.data.path || '';
  }
}