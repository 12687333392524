import { Tooltip } from 'antd';
import React from 'react';
import { WithClassName } from 'utils/Types/common';
import { Container, Image } from './UserThumbnail.styles';

export interface UserThumbnailProps extends WithClassName {
  containerClassName?: string;
  src: string;
  tooltipMessage?: string;
}

class UserThumbnail extends React.Component<UserThumbnailProps>{
  constructor(props: UserThumbnailProps) {
    super(props);
  }

  render() {
    const {
      className,
      containerClassName,
      src,
      tooltipMessage,
    } = this.props;

    return (
      <Container className={containerClassName}>
        {tooltipMessage && (
          <Tooltip title={tooltipMessage}>
            <Image className={className} src={src} />
          </Tooltip>
        )}

        {!tooltipMessage && (
          <Image className={className} src={src} />
        )}
      </Container>
    );
  }
};

export default UserThumbnail;
