import { Form, Input } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormModal } from 'components/UI';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';

export interface NewFilterSetModalProps { };

class NewFilterSetModal extends React.Component<NewFilterSetModalProps>{
  constructor(props: NewFilterSetModalProps) {
    super(props);
  }

  handleOnClose = () => {
    store.creative.ads.filter.closeNewSetModal();
  }

  handleOnSubmit = (originalValues: unknown, formController: WrappedFormUtils<unknown>) => {
    const { handleOnClose } = this;
    const setName = (originalValues as { name: string }).name;

    store.creative.ads.filter.saveCurrentSetAs(setName);
    store.creative.save();
    formController.resetFields();

    handleOnClose();
  }

  render() {
    const { handleOnClose, handleOnSubmit } = this;
    const isVisible = store.creative.ads.filter.showNewSetModal;

    return (
      <FormModal
        isVisible={isVisible}
        onClose={handleOnClose}
        onSubmit={handleOnSubmit}
        resetOnClose
        submitText='Create'
        title='New Filter'
      >
        {
          ({ formController: { getFieldDecorator } }) => (
            <>
              <Form.Item label="Filter Name">
                {getFieldDecorator('name', {
                  initialValue: store.creative.ads.filter.newSetPreliminarName,
                  rules: [
                    { required: true, message: 'A filter name is required.' },
                    { pattern: /^\S.*$/, message: 'The filter name cannot start with a space.' },
                  ]
                })(<Input />)}
              </Form.Item>
            </>
          )
        }
      </FormModal>
    );
  }
};

export default observer(NewFilterSetModal);
