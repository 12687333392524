import { RouteLink } from 'components/UI';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores';
import Tearsheet from 'stores/Tearsheet';

export interface ViewTearsheetButtonProps {
  tearsheet: Tearsheet;
}

class ViewTearsheetButton extends React.Component<ViewTearsheetButtonProps>{
  constructor(props: ViewTearsheetButtonProps) {
    super(props);
  }

  viewTearsheet = () => {
    const { tearsheet } = this.props;
    tearsheet.navigateTo();
  }

  render() {
    const { tearsheet } = this.props;

    return (
      <RouteLink
        route={store.routes.tearsheet}
        params={{
          ...tearsheet.params,
          adIndex: '1',
        }}
      >
        <DrawerButton>
          View
        </DrawerButton>
      </RouteLink>
    );
  }
};

export default ViewTearsheetButton;
