import { ColumnProps } from "antd/lib/table";
import React from "react";

// Sortable column types
export enum TableColumnSortType {
  Alphabetical = 'TABLE_SORTER_TYPE_ALPHABETICAL',
  Custom = 'TABLE_SORTER_TYPE_CUSTOM',
  FirestampDate = 'TABLE_SORTER_FIRESTAMP_DATE',
  NoSort = 'TABLE_NO_SORT',
}

type TableNonCustomSorterType =
  false |
  null |
  TableColumnSortType.Alphabetical |
  TableColumnSortType.NoSort |
  TableColumnSortType.FirestampDate ;

type TableCustomSorterType = TableColumnSortType.Custom;

type SorterFunction<T> = (a: T, b: T) => 1 | -1;

type ColumnBasicSortingParams = {
  sortType?: TableNonCustomSorterType;
  sorter?: undefined;
}
type ColumnCustomSortingParams<T> = {
  sortType: TableCustomSorterType;
  sorter: SorterFunction<T>;
}

type BuildColumnSortingArgs<T> =
  ColumnBasicSortingParams |
  ColumnCustomSortingParams<T>;

// Filterable column types
export enum TableColumnFilterType {
  Options = 'TABLE_FILTERER_TYPE_OPTIONS',
  Search = 'TABLE_FILTERER_TYPE_SEARCH',
}

type ColumnNoFilteringParams = {
  filterer?: undefined;
  filterType?: undefined;
}

type ColumnSearchFilteringParams<T> = {
  filterer: (searchValue: string, recordToBeFiltered: T) => boolean;
  filterType: TableColumnFilterType.Search;
  options?: undefined,
  placeholder?: string;
}

export type ColumnFilteringOption = {
  text: string;
  value: string;
}

type ColumnOptionsFilteringParams<T> = {
  filterer: (activeOptionValue: string, recordToBeFiltered: T) => boolean;
  filterType: TableColumnFilterType.Options;
  options: ColumnFilteringOption[];
}

type BuildColumnFilteringArgs<T> =
  ColumnNoFilteringParams |
  ColumnSearchFilteringParams<T> |
  ColumnOptionsFilteringParams<T>;

// Principal column types
type BuildColumnBaseArgs<T> = Omit<ColumnProps<T>,
  'dataIndex' |
  'onFilter' |
  'render' |
  'sorter' |
  'title'
> &
  BuildColumnSortingArgs<T> &
  BuildColumnFilteringArgs<T> &
{
  title: string;
}

type DataSourceType<T> = (rowData: T) => T[keyof T];
type RenderType<T> = (rowData: T) => React.ReactNode;

export type BuildColumnArgs<T> = BuildColumnBaseArgs<T>
  & (
    {
      sourceData: DataSourceType<T>;
      render?: RenderType<T>;
    }
    | {
      sourceData?: DataSourceType<T>;
      render: RenderType<T>;
    }
    | {
      sourceData: DataSourceType<T>;
      render: RenderType<T>;
    }
  )
