import { Container } from './Home.styles';
import { GoogleSignInButton } from 'components/UI';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';

export interface HomeProps { }

class Home extends React.Component {
  constructor(props: HomeProps) {
    super(props);
  }

  render() {
    document.title = 'Home';
    return (
      <Container>
        <div className="center-box">
          <div className="logo">ADHUB</div>
          <GoogleSignInButton onClick={store.user.login} />
        </div>
      </Container>
    )
  }
}

export default observer(Home);
