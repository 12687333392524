import {
  Form,
  Input,
  message as AlertTriggerer,
  Radio,
} from 'antd';
import { FormModal } from 'components/UI'
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { UserRoles } from 'stores/UserTypes';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import DocumentStore from 'stores/DocumentStore';
import React from 'react';

const EDIT_MEMBER_MODAL_TITLE = 'Save';

export interface EditMemberModalProps {
  isVisible: boolean;
  memberId: string;
  onClose: () => void;
  parentDocument: DocumentStore;
}

class EditMemberModal extends React.Component<EditMemberModalProps>{
  isLoading: boolean = false;

  constructor(props: EditMemberModalProps) {
    super(props);

    makeObservable(this, {
      isLoading: observable,
    });
  }

  turnOnLoader = () => {
    this.isLoading = true;
  }

  turnOffLoader = () => {
    this.isLoading = false;
  }

  handleFormSubmit = async (values: unknown, formController: WrappedFormUtils<unknown>) => {
    const { memberId, parentDocument } = this.props;
    const newRole = (values as { role: UserRoles }).role;

    const { turnOffLoader, turnOnLoader } = this;

    try {
      turnOnLoader();

      await parentDocument.members.edit(memberId, newRole);

      formController.resetFields();
      this.props.onClose();

    } catch (e) {
      AlertTriggerer.error((<span>Something went wrong. Please <b>try again</b>...</span>));
      console.error('Error creating the new member:', e);
    }
    finally {
      turnOffLoader();
    }
  }

  render() {
    const { isLoading } = this;
    const { isVisible, memberId, onClose, parentDocument } = this.props;
    const { handleFormSubmit } = this;

    return (
      <FormModal
        isLoading={isLoading}
        isVisible={isVisible}
        onClose={onClose}
        onSubmit={handleFormSubmit}
        resetOnClose
        submitText={EDIT_MEMBER_MODAL_TITLE}
        title={EDIT_MEMBER_MODAL_TITLE}
      >
        {
          ({ formController: { getFieldDecorator } }) => (
            <>
              <Form.Item label="User Email">
                <Input
                  disabled
                  value={parentDocument.members.getUserByMemberId(memberId)?.data?.email || ''}
                />
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('role', {
                  initialValue: parentDocument.members.getUserByMemberId(memberId)?.data?.role,
                })(
                  <Radio.Group>
                    <Radio value="edit">Can Edit</Radio>
                    <Radio value="view">Can View</Radio>
                  </Radio.Group>,
                )}
              </Form.Item>
            </>
          )
        }
      </FormModal>
    );
  }
};

export default observer(EditMemberModal);
