import styled from "styled-components";

export const BasicVariantContainer = styled.div`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  display: flex;
  flex-flow: column;
  flex-grow: 1;

  .ant-table-placeholder {
    align-items: center;
    border-bottom: 0;
    display: flex;
    justify-content: center;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ant-pagination.ant-table-pagination {
    margin: 16px;
  }
`;

export const FlatVariantContainer = styled.div`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  margin-bottom: 10px;

  &.pagination-disabled {
    border-bottom: 0;
  }

  .ant-table-placeholder {
    align-items: center;
    border-bottom: 0;
    display: flex;
    justify-content: center;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-small {
    border-radius: 0;
    border-width: 0 0 1px 0;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ant-pagination.ant-table-pagination {
    margin: 16px;
  }
`;
