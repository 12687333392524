import { firestore, db, auth, googleProvider } from './Firebase';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { DocumentType } from './Types';

export type FirebaseUser = typeof auth.currentUser;

export type StarredDocument = {
    path: string,
    id: string,
    type: DocumentType,
}

export enum UserEvents {
    UPDATED = 'updated',
    LOGIN_SUCCESS = 'loginSuccess',
    LOGGED_OUT = 'loggedOut',
    ERROR = 'error',
}

export enum UserStatus {
    LOGGED_IN = 'loggedin',
    LOGGED_OUT = 'loggedout',
    DISCONNECTED = 'disconnected',
    ERROR = 'error',
    CONNECTED = 'connected'
}

export enum UserRoles {
    DEFAULT = 'default', // Only has access to documents they are a member of.
    ADMIN = 'admin', // Has access to entire database, including the ability to add/remove/modify users.
    MANAGER = 'manager' // Has access to all clients.
}
