import { observer } from 'mobx-react';
import { ViewModes } from 'stores/UI';
import AdList from './AdList';
import AdView from 'components/Views/Tearsheet/Shared/AdView';
import ChatBox from '../../../UI/ChatBox';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Container = styled.div.attrs({
  className: 'creative-root',
})`
  display: grid;
  grid-template-columns: 500px 1fr;
  height: 100%;
  position: relative;
`;

export interface TearsheetDesktopViewProps { }

class TearsheetDesktopView extends React.Component<TearsheetDesktopViewProps> {
  constructor(props: TearsheetDesktopViewProps) {
    super(props);
  }

  render() {
    const { tearsheet, ui } = store;
    const framelessMode = ui.viewMode === ViewModes.FRAMELESS;
    const currentAd = store.tearsheet?.ads?.current;

    if (!tearsheet) {
      return null;
    }

    if (framelessMode) {
      return (
        <AdView />
      );
    }

    return (
      <Container style={store.user.authenticated ? {} : { top: '64px', height: 'calc(100% - 64px)' }}>
        <AdList />
        <AdView />

        { currentAd && <ChatBox ad={currentAd} /> }
      </Container>
    );
  }
}

export default observer(TearsheetDesktopView);
