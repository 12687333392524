import { Layout } from 'antd';
import styled from 'styled-components';

export const Nav = styled(Layout.Sider)`
  .ant-menu-item {
    font-size: 18px !important;
  }

  .anticon {
    margin-left: -2px !important;
    font-size: 20px !important;
  }
`;

export const LogoContainer = styled.div`
  align-items: center;
  color: white;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  line-height: 18px;
  margin: 16px;
  text-align: center;
`;
