import { observer } from 'mobx-react';
import { AnimationPanelViews, AnimationPanelViews as PanelViews } from 'stores/Animation';
import React from 'react';
import store from 'stores';
import Drawer from './Drawer';


export interface ExportDrawerProps { }

class ExportDrawer extends React.Component<ExportDrawerProps>{
  constructor(props: ExportDrawerProps) {
    super(props);
    // subscribe(this, store.animation.assets);
  }

  handleOnClose = () => {
    store.animation.setPanel(PanelViews.NONE);
  }

  render() {
    const { animation } = store;

    return (
        <Drawer 
          label="Export"
          panelName={AnimationPanelViews.EXPORT}
        >
        </Drawer>
    );
  }
};

export default observer(ExportDrawer);
