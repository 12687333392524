import { DocumentReference } from '@firebase/firestore-types';
import { nullTimestamp } from '../utils';
import Bundle from './Bundle';
import BundlesManager from './BundlesManager';
import { DocumentSchema } from './DocumentSchema';
import DocumentStore from './DocumentStore';
import { DocumentType, ProcessingStatus, MutexStates, DocumentCollectionType } from './Types';

export class BuildLog {
    level = 200;
    levelName = 'LOG';
    message = '';
}

type Manifest = {
    path: string;
    storage: string;
}

export class Build {
    archiveActualSize = 0;
    archiveContentSize = 0;
    archiveMaxSize = 0;
    archivePath = '';
    archiveStorage = '';
    buildType = '';
    entry = '';
    manifest: Manifest[] = []
    stagePrefix = '';
}


export class BundleElementSchema extends DocumentSchema {
    adId = '';
    archivesCreated = true;
    buildLog: Array<BuildLog> = []; // TODO: define structure
    builds: Array<Build> = []; // TODO: define structure
    createTime = nullTimestamp();
    creative = '';
    dimensions = [NaN, NaN];
    errors = []; // TODO: define structure
    label = '';
    losslessScreenshotPath = '';
    lossyScreenshotActualSize = NaN;
    lossyScreenshotMaxSize = NaN;
    lossyScreenshotPath = '';
    modifiedTime = nullTimestamp();
    mutex = MutexStates.UNLOCKED;
    screenshotScale = 1;
    stagePrefix = '';
    status = ProcessingStatus.NONE;
}

export default class BundleElement extends DocumentStore<BundleElementSchema> {
    type = DocumentType.BUNDLE_ELEMENT;
    collectionType = DocumentCollectionType.BUNDLE_ELEMENT;
    stagingURL = '';
    archiveURL = '';
    staticBackupURL = '';
    losslessBackupURL = '';
    
    schema = new BundleElementSchema();

    constructor(parent: BundlesManager, docRef: DocumentReference, data: BundleElementSchema) {
        super(parent, docRef, data);
        this.init();
    }

    onReady = () => {
        let bundle = this.parent.parent as Bundle
        if(!this.data.builds) return;
        let build = this.data.builds.find(build => build.buildType === 'dist');
        if(!build) return;
        let indexFile = build.manifest.find(file => file.path === 'index.html');
        if(!indexFile) return;

        this.stagingURL = bundle.baseURL + '/' + escape(indexFile.storage);
        this.archiveURL = bundle.baseURL + '/' + escape(build.archiveStorage);
        this.staticBackupURL = bundle.baseURL + '/' + escape(this.data.lossyScreenshotPath);
        this.losslessBackupURL = bundle.baseURL + '/' + escape(this.data.losslessScreenshotPath);
    }
}