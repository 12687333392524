import { Button as BaseButton, Icon } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const ButtonGroup = styled(BaseButton.Group)`
  display: flex;
`;

const Button = styled(BaseButton)`
  align-items: center;
  display: flex;
  font-size: 20px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 3px;
`;

export interface NavigationMenuProps { }

class NavigationMenu extends React.Component<NavigationMenuProps>{
  constructor(props: NavigationMenuProps) {
    super(props);
  }

  goBack = () => {
    store.tearsheet.ads.selectPrevious();
  }

  goForward = () => {
    store.tearsheet.ads.selectNext();
  }

  render() {
    const { goBack, goForward } = this;
    const { currentIndex, length } = store.tearsheet.ads;
    const atLastIndex = store.tearsheet.ads.last?.data.index === currentIndex;
    const atFirstIndex = store.tearsheet.ads.first?.data.index === currentIndex;

    return (
      <ButtonGroup>
        <Button disabled={atFirstIndex} onClick={goBack}>
          <Icon type="caret-left" />
        </Button>

        <Button disabled={atLastIndex} onClick={goForward}>
          <Icon type="caret-right" />
        </Button>
      </ButtonGroup>
    );
  }
};

export default observer(NavigationMenu);
