export enum DynamicPropertyType { // TODO: possibly needed for hydration? or use static property on class is good enough? export as object instead?
    ASSET = 'Asset',
    SVGText = 'SVGText',
    SVGData = 'SVGData',
    GROUP = 'Group',
    TEXT = 'Text',
    TEMPLATE = 'Template',
    COLOR = 'Color',
    SELECT = 'Select',
    NUMBER = 'Number',
    BOOLEAN = 'Boolean',
    DIMENSIONS = 'Dimensions',
    LABEL = 'Label',
    PERCENTAGE = 'Percentage',
    POSITION = 'Position',
    PIXELS = 'Pixels',
    POINTS = 'Points',
    NONE = 'None',
    DEFAULT = '',
  }