import { DocumentCollectionType, DocumentType, LooseObject, Member, MemberRoles, PublicInfo } from "./Types";
import { db } from './Firebase';
import type DocumentStore from './DocumentStore';
import Breadcrumb from "./Breadcrumb";
import Store from "./Store";
import { pathToIdSegments, pathToTypeSegments } from "utils/String";


class BreadcrumbManager extends Store {
    breadcrumbs: Breadcrumb[] = [];
    parent: DocumentStore<any, any>;

    get all() {
        return this.breadcrumbs;
    }

    constructor(parent: DocumentStore) {
        super();
        this.parent = parent;
        this.init();
    }

    /**
     * Loads the members of the parent document.
     * */
    load = async () => {
        // console.log('loadBreadcrumbs', this.docRef.path, this.constructor.name);
        if (!this.parent.docRef) return [];
        let path = this.parent.docRef.path;
        let idSegments = pathToIdSegments(path);
        let collectionTypeSegments = pathToTypeSegments(path);
        let breadcrumbs: Breadcrumb[] = [];

        for (let i = 0; i < idSegments.length; i++) {
            let collectionType = collectionTypeSegments[i];
            let id = idSegments[i];
            let type = DocumentType.DEFAULT;
            for (let [key, value] of Object.entries(DocumentCollectionType)) {
                if (value === collectionType) {
                    type = Object(DocumentType)[key];
                    break;
                }
            }

            let path = segmentsToPath(idSegments.slice(0, i + 1), collectionTypeSegments.slice(0, i + 1)); 
            let docRef = db.doc(path).collection('public').doc('info');
            if(type === DocumentType.TEARSHEET) docRef = db.doc(path);
            let snapshot = await docRef.get();
            let url = 'view/' + segmentsToPath(idSegments.slice(0, i + 1));
            let newBreadcrumb = new Breadcrumb(this, docRef, snapshot.data() as PublicInfo);
            newBreadcrumb.setUrl(url);
            newBreadcrumb.setType(type);
            newBreadcrumb.load();

            breadcrumbs.push(newBreadcrumb);
        }

        function segmentsToPath(idSegments: string[], typeSegments?: string[]) {
            // join every other segment with a slash. no trailing or leading slashes
            let path = '';
            for (let i = 0; i < idSegments.length; i++) {
                if (typeSegments) {
                    path += typeSegments[i] + '/' + idSegments[i];
                } else {
                    path += idSegments[i];
                }
                if (i < idSegments.length - 1) {
                    path += '/';
                }
            }
            return path;
        }

        this.breadcrumbs = breadcrumbs;
    }

    update = async () => {
        this.load();
    }



    reset = () => {
        this.breadcrumbs = [];
    }

}

export default BreadcrumbManager;
