import { Dropdown, Icon, Menu } from 'antd';
import { MenuButton } from './OptionsMenu.styles';
import React from 'react';
import DocumentFolder from 'stores/DocumentFolder';

export interface OptionsMenuProps {
  documentId: string;
  parentDocument: DocumentFolder;
}

class OptionsMenu extends React.Component<OptionsMenuProps>{
  constructor(props: OptionsMenuProps) {
    super(props);
  }

  handleArchive = () => {
    const { documentId, parentDocument } = this.props;
    parentDocument.documents.getById(documentId)?.archive();
  }

  render() {
    const { handleArchive } = this;
    const { documentId, parentDocument } = this.props;
    const isArchiveEnabled = parentDocument.documents.getById(documentId)?.members.canModify;

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item
                disabled={!isArchiveEnabled}
                onClick={handleArchive}
              >
                <Icon type="down-square" />
                Archive
              </Menu.Item>
            </Menu>
          )}>

          <MenuButton>
            <Icon type="ellipsis" />
          </MenuButton>

        </Dropdown>
      </div>
    );
  }
}

export default OptionsMenu;
