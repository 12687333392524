import { observer } from 'mobx-react';
import { AnimationPanelViews, AnimationPanelViews as PanelViews } from 'stores/Animation';
import React from 'react';
import store from 'stores';
import Drawer from './Drawer';


export interface CanvasDrawerProps { }

class CanvasDrawer extends React.Component<CanvasDrawerProps>{
  constructor(props: CanvasDrawerProps) {
    super(props);
    // subscribe(this, store.animation.assets);
  }

  handleOnClose = () => {
    store.animation.setPanel(PanelViews.NONE);
  }

  render() {
    const { animation } = store;

    return (
        <Drawer 
          label="Canvas"
          panelName={AnimationPanelViews.CANVAS}
        >
        </Drawer>
    );
  }
};

export default observer(CanvasDrawer);
