import { observer } from 'mobx-react';
import FiltersToolbarButton from './FiltersToolbarButton';
import React from 'react';
import store from 'stores';

export interface NewFilterSetButtonProps { };

class NewFilterSetButton extends React.Component<NewFilterSetButtonProps>{
  constructor(props: NewFilterSetButtonProps) {
    super(props);
  }

  handleOnClick = () => {
    store.creative.ads.filter.openNewSetModal();
  }

  render() {
    const { handleOnClick } = this;

    return (
      <FiltersToolbarButton
        label="New"
        onClick={handleOnClick}
      />
    );
  }
};

export default observer(NewFilterSetButton);
