import { Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import Asset from 'stores/Asset';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores';

export interface RemoveFolderButtonProps {
  asset: Asset;
}

class RemoveFolderButton extends React.Component<RemoveFolderButtonProps>{
  constructor(props: RemoveFolderButtonProps) {
    super(props);
  }

  removeFolder = () => {
    const { asset } = this.props;
    const { creative } = store;
    creative.assets.removeFolder(asset.data.path);
  }

  render() {
    const { removeFolder } = this;
    const { asset } = this.props;

    return (
      <Popconfirm
        disabled={asset.loading}
        cancelText='No'
        okText='Yes'
        onConfirm={removeFolder}
        placement='bottomRight'
        title='Are you sure you want to remove this folder and files inside the folder?'
      >
        <DrawerButton>
          Remove
        </DrawerButton>
      </Popconfirm>
    );
  }
};

export default observer(RemoveFolderButton);
