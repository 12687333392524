import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Input, InputNumber, Button, Icon, Select, message, Popover } from 'antd';
import AssetInput from './AssetInput';
import MultiLineTextInput from './MultiLineTextInput';
import SelectInput from './SelectInput';
import SwitchInput from './SwitchInput';
import TextInput from './TextInput';
import { AllTypes, IDynamicProperty } from '../../../../../stores/DynamicProperties/Types';
import DynamicGroup from '../../../../../stores/DynamicProperties/Types/Group';
import InputContainer from './InputContainer';
import { makeObservable, observable } from 'mobx';
import { camelCaseToTitle } from 'utils/String';
import PropertyPopover from '../PropertyPopover';

const { Option } = Select;

const Container = styled.div`
    display: flex;
    width:100%;
    margin-bottom:10px;
    margin-top:18px;
    padding: 10px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding-bottom: 3px;
    position: relative;
`;

const GroupContainer = styled.div`
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:100%;
    margin-bottom: 17px;
    /* flex-wrap: wrap; */
    /* flex-flow:row; */
`;

const GroupLabel = styled.div`
    background: white;
    display: flex;
    position: absolute;
    font-size: 13px;
    font-weight: bold;
    top: -17px;
    left: 8px;
    align-items: center;
    justify-content: center;
    padding: 5px 6px;
`;

const ExpandButton = styled(Button)`
    width: calc(100% + 2px);
    display: flex;
    height: 20px;
    position: absolute;
    bottom: -1px;
    left: -1px;
    background: #f0f0f0;
    justify-content: center;
    line-height: 16px;
    border-radius: 0 0 5px 5px;
    font-size: 12px;

    &:active, &:hover, &:visited, &:focus {
        background: #f0f0f0;
        border-color: #d9d9d9;
    } 
`;

const TextContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:100%;
`;

const PropertiesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:100%;
    height: 278px;
    transition: all 0.5s;
    overflow: hidden;

    &.collapsed {
        height: 0;
    }
`;



type SVGTextInputGroupProps = {
    property: DynamicGroup,
    disabled: boolean,
    editMode?: boolean,
    style?: React.CSSProperties,
    className?: string,
    onRemove: (propertyName: string) => void;
};

class SVGTextInputGroup extends React.Component<SVGTextInputGroupProps> {
    value = null;
    collapsed = true;
    property: DynamicGroup;
    properties: IDynamicProperty<any>[];
    popoverVisible: boolean = false;

    constructor(props: SVGTextInputGroupProps) {
        super(props);
        this.property = props.property;
        this.property.subscribe(this);
        this.properties = this.property.properties;
        makeObservable(this, {
            collapsed: observable,
            popoverVisible: observable,
        })
    }

    toggleCollapse = () => {
        this.collapsed = !this.collapsed;
    }

    defaultInput = (propertyName: string, style: React.CSSProperties) => {
        let property = this.property.get(propertyName);
        if(!property) return <></>;
        return (
            <InputContainer 
                key={property.name} 
                style={style}
                property={property} 
                disabled={this.props.disabled} 
                editMode={false}
                disableActions={true}
                onRemove={()=>{}}
            />
        );
    };

    selectInput = (propertyName: string, style: React.CSSProperties, options: string[]) => {
        let property = this.property.get(propertyName);
        if(!property) return <></>;
        return (
            <InputContainer 
                key={property.name} 
                style={style}
                property={property} 
                disabled={this.props.disabled} 
                editMode={false} 
                disableActions={true}
                onRemove={()=>{}}
            >
                <SelectInput property={property} options={options} disabled={this.props.disabled} editMode={this.props.editMode} />
            </InputContainer>
        );
    }

    multiLineTextInput = (propertyName: string, style: React.CSSProperties) => {
        let property = this.property.get(propertyName);
        if(!property) return <></>;
        return (
            <InputContainer 
                key={property.name} 
                style={style}
                property={property} 
                disabled={this.props.disabled} 
                editMode={false} 
                disableActions={true}
                onRemove={()=>{}}
            >
                <MultiLineTextInput style={{width: '540px'}} property={property} disabled={this.props.disabled} editMode={this.props.editMode} />
            </InputContainer>
        );
    };

    handlePopoverVisibleChange = (visible: boolean) => {       
        this.popoverVisible = visible;
    }

    render() {
        let editMode = this.props.editMode || false;
        let propertyName = this.props.property.name;

        let topElements: ReactElement[] = [
            this.defaultInput('font', { width: '100%' }),
            this.multiLineTextInput('text', { width: '100%' }),
            this.defaultInput('color', { width: '32.2%' }),
            this.defaultInput('fontSize', { width: '32.2%' }),
            this.selectInput('textAlign', { width: '32.2%' }, ['left', 'center', 'right'])
        ];

        let bottomElements: ReactElement[] = [
            this.selectInput('verticalAlign', { width: '32.2%'}, ['top', 'middle', 'bottom']),
            this.defaultInput('letterSpacing', { width: '32.2%' }),
            this.defaultInput('lineHeight', { width: '32.2%' }),
            this.defaultInput('top', { width: '24.5%' }),
            this.defaultInput('left', { width: '24.5%' }),
            this.defaultInput('width', { width: '24.5%' }),
            this.defaultInput('height', { width: '24.5%' }),
            this.defaultInput('offsetX', { width: '49.5%' }),
            this.defaultInput('offsetY', { width: '49.5%' }),
            this.defaultInput('dropshadowColor', { width: '32.2%' }),
            this.defaultInput('dropshadowBlurX', { width: '32.2%' }),
            this.defaultInput('dropshadowBlurY', { width: '32.2%' }),
            this.defaultInput('dropshadowOffsetX', { width: '32.2%' }),
            this.defaultInput('dropshadowOffsetY', { width: '32.2%' }),
            this.defaultInput('dropshadowOpacity', { width: '32.2%' }),
            this.defaultInput('strokeColor', { width: '32.2%' }),
            this.selectInput('strokeLinecap', { width: '32.2%' }, ['butt', 'round', 'square']),
            this.defaultInput('strokeWidth', { width: '32.2%' }),
            this.defaultInput('strokeOpacity', { width: '32.2%' }),
            this.defaultInput('maskRows', { width: '32.2%' }),
            this.defaultInput('boundingBox', { width: '32.2%' }),
        ];

        return (
            <Container style={this.props.style}>
                {this.property.name && (<PropertyPopover property={this.property} editMode={editMode}><GroupLabel>{camelCaseToTitle(this.property.name)}</GroupLabel></PropertyPopover>)}
                <GroupContainer key={this.property.name} className={'single'}>
                    <TextContainer>{topElements}</TextContainer>
                    <PropertiesContainer className={this.collapsed ? 'collapsed' : ''}>{bottomElements}</PropertiesContainer>
                </GroupContainer>
                <ExpandButton onClick={this.toggleCollapse}>{this.collapsed ? '+ More' : '- Less'}</ExpandButton>
            </Container>
        );
    }
}

export default observer(SVGTextInputGroup);
