import { CreativeType, DocumentFolderClassRef, DocumentType } from 'stores/Types';
import { FormItem } from './AddDocumentModal.styles';
import { FormModal } from 'components/UI';
import { Input, message as AlertTriggerer } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import type DocumentFolder from 'stores/DocumentFolder';
import React from 'react';
import store from 'stores';
import Creative from 'stores/Creative';

export interface AddCreativeModalProps {
  isVisible: boolean;
  onClose: () => void;
  parentDocument: DocumentFolder;
  documentType: DocumentFolderClassRef;
  documentTypeName: string;
};

type valuesType = {
  title: string;
};

class AddCreativeModal extends React.Component<AddCreativeModalProps>{
  isLoading: boolean = false;
  documentTypeName: string = '';

  constructor(props: AddCreativeModalProps) {
    super(props);

    this.documentTypeName = props.documentTypeName;

    makeObservable(this, {
      isLoading: observable,
    });
  }

  turnOnLoader = () => {
    this.isLoading = true;
  }

  turnOffLoader = () => {
    this.isLoading = false;
  }

  handleFormSubmit = async (originalValues: unknown, formController: WrappedFormUtils<unknown>) => {
    const { parentDocument, documentType } = this.props;
    const values = originalValues as valuesType;
    const { turnOnLoader, turnOffLoader } = this;

    try {
      turnOnLoader();

      await parentDocument.documents.addFolder(documentType, {
        name: values.title,
        memberKeys: store.project.data.memberKeys,
        members: store.project.data.members,
      });

      formController.resetFields();
      this.props.onClose();

    } catch (error) {
      AlertTriggerer.error((<span>Something went wrong. Please <b>try again</b>...</span>));
      console.error('Error creating the new creative:', error);
    }
    finally {
      turnOffLoader();
    }
  }

  render() {
    const { isLoading, documentTypeName } = this;
    const { isVisible, onClose } = this.props;
    const { handleFormSubmit } = this;

    return (
      <FormModal
        isLoading={isLoading}
        isVisible={isVisible}
        onClose={onClose}
        onSubmit={handleFormSubmit}
        resetOnClose
        submitText={`Create`}
        title={`New ${documentTypeName}`}
      >
        {
          ({ formController: { getFieldDecorator } }) => (
            <>
              <FormItem label="Title">
                {getFieldDecorator('title', {
                  rules: [
                    { required: true, message: 'A document name is required.' },
                    { pattern: /^\S.*$/, message: 'The document name cannot start with a space.' },
                  ]
                })(<Input disabled={isLoading} />)}
              </FormItem>
            </>
          )
        }
      </FormModal>
    );
  }
}

export default observer(AddCreativeModal);