import styled from "styled-components";

export const Container = styled.div`
  background-color: gray;
  border-radius: 100px;
  border: 1px solid #bdbdbd;
  height: 32px;
  margin-right: -7px;
  overflow: hidden;
  width: 32px;
  z-index: 1;
`;

export const Image = styled.img`
  height: 30px;
  width: 30px;
`;