import { Layout, Breadcrumb, Icon } from 'antd';
import { NavBreadcrumb } from 'components/UI';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const { Header } = Layout;

const Container = styled(Header)`
  background: #fff;
  padding: 0;
  position: absolute;
  width: 100%;
  transition: all .2s;
  display: flex;
  flex-flow:row;
  align-items: center;
  left: 0px;

  .content {
    display: flex;
    flex-grow: 1;
    font-size: 18px;
    font-family: 'Open Sans';
  }

  .profile-button {
    margin-right: 8px;
  }
`;

const Logo = styled.div`
  display: flex;
  height: 48px;
  margin: 16px;
  justify-content: center;
  color: black;
  text-align: center;
  font-size: 20px;
  line-height: 18px;
  font-weight: bold;
  align-items: center;

  .logo-text {
    display: flex;
    font-size: 28px;
    line-height: 23px;
    font-weight: 900;
  }
`;

interface Props extends React.HTMLAttributes<any> { }

class NavHeader extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <Container {...this.props} className='frame-element'>
        <Logo>
          <div className='logo-text'>
            <span>ADHUB</span>
          </div>
        </Logo>
        <div className="content">

          {store.current && store.current.loaded ? (
            <NavBreadcrumb
              breadcrumbs={store.current.breadcrumbs}
            />
          ) : (<div></div>)
          }
        </div>
      </Container>
    )
  }
}

export default observer(NavHeader);
