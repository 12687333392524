import React from 'react';
import styled from 'styled-components';
import Tearsheet from 'stores/Tearsheet';
import RemoveTearsheetButton from './RemoveTearsheetButton';
import ViewTearsheetButton from './ViewTearsheetButton';
import firebase from 'firebase';
import moment from 'moment';

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 7px;
`;

const MainLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const DateLabel = styled.div`
  font-size: 12px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  color: #666;
`;

export interface TearsheetSummaryRowProps {
  tearsheet: Tearsheet;
}

class TearsheetSummaryRow extends React.Component<TearsheetSummaryRowProps>{
  constructor(props: TearsheetSummaryRowProps) {
    super(props);
  }

  render() {
    const { tearsheet } = this.props;
    const { name } = tearsheet.data;
    const { formattedDateModified } = tearsheet;

    return (
      <Container>
          <MainLabel>{name}</MainLabel>

        <ButtonsContainer>
          <DateLabel>{formattedDateModified}</DateLabel>
          <ViewTearsheetButton tearsheet={tearsheet} />
          <RemoveTearsheetButton tearsheet={tearsheet} />
        </ButtonsContainer>
      </Container>
    );
  }
};

export default TearsheetSummaryRow;