import { Button, message } from 'antd';
import Bundle from 'stores/Bundle';
import React from 'react';

export interface CopyBundleUrlsButtonProps {
  bundle: Bundle;
}

class CopyBundleUrlsButton extends React.Component<CopyBundleUrlsButtonProps>{
  constructor(props: CopyBundleUrlsButtonProps) {
    super(props);
  }

  getUrls = (): string[] => {
    const { bundle } = this.props;
    const { elements } = bundle;

    let urls: string[] = [];

    elements.all.forEach((element) => {
      element.data.builds.forEach((build) => {
        if (build.buildType === "dist") {
          build.manifest.forEach((manifest) => {
            if (manifest.path.includes('index.html')) {
              urls.push(`${bundle.baseURL}/${manifest.storage}`);
            }
          });
        }
      });
    });

    return urls;
  }

  copyFileLinksToClipboard = async () => {
    const { getUrls } = this;
    const textToBeSentToClipboard = getUrls().join('\n');

    await navigator.clipboard.writeText(textToBeSentToClipboard);
    message.success('Ad links copied.');
  }

  render() {
    const { copyFileLinksToClipboard } = this;

    return (
      <Button onClick={copyFileLinksToClipboard}>
        Copy URLs
      </Button>
    )
  }
};

export default CopyBundleUrlsButton;
