import { Button, Input, PlusIcon } from './NewTagButton.styles';
import { Input as AntdInput } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Tag } from 'components/UI/TagsManager';
import React from 'react';

export const NEW_TAG_BUTTON_DEFAULT_LABEL = 'New Tag';

export interface NewTagButtonProps {
  label?: string;
  onSubmit: (newTag: Tag) => void;
}

class NewTagButton extends React.Component<NewTagButtonProps>{
  inputRef = React.createRef<AntdInput>();
  isInEditMode: boolean = false;
  newTagDraft: string = '';

  constructor(props: NewTagButtonProps) {
    super(props);

    makeObservable(this, {
      isInEditMode: observable,
      newTagDraft: observable,
    });
  }

  turnOnEditMode = () => {
    this.isInEditMode = true;
    setTimeout(() => this.inputRef.current?.focus(), 50);
  }

  turnOffEditMode = () => {
    this.isInEditMode = false;
    this.newTagDraft = '';
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.newTagDraft = e.target.value;
  }

  handleInputSubmit = () => {
    const { onSubmit } = this.props;

    onSubmit(this.newTagDraft);

    this.turnOffEditMode();
    this.resetInput();
  }

  resetInput = () => {
    this.newTagDraft = '';
  }

  render() {
    const { label = NEW_TAG_BUTTON_DEFAULT_LABEL } = this.props;
    const { isInEditMode, newTagDraft } = this;
    const {
      handleInputChange,
      handleInputSubmit,
      inputRef,
      turnOffEditMode,
      turnOnEditMode,
    } = this;

    return (
      <>
        <Button
          visible={!isInEditMode}
          onClick={turnOnEditMode}
        >
          <PlusIcon />
          {label}
        </Button>

        {isInEditMode && (
          <Input
            onBlur={turnOffEditMode}
            onChange={handleInputChange}
            onPressEnter={handleInputSubmit}
            ref={inputRef}
            type="text"
            value={newTagDraft}
          />
        )}
      </>
    );
  }
}

export default observer(NewTagButton);
