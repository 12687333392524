import { DocumentReference, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { firestore, db, functionAuthRequest, FirestoreEvents } from './Firebase';
import { DocumentType } from './Types';
import root from './index';
import DocumentStore from './DocumentStore';
import { createDocumentUrl, documentPathToParams } from 'utils';
import DocumentCollection from './DocumentCollection';
import DocumentFolderCollection from './DocumentFolderCollection';
import {
    FolderSchema,
    FolderPublicSchema,
    FolderParams
} from './DocumentFolderTypes';
import BreadcrumbManager from './BreadcrumbManager';
import { Route } from 'mobx-router';
import { Routes } from './Routes';

type AnyRoute = Route<any, any, any>;
/**
 * A DocumentFolder is a DocumentStore that contains other DocumentStores and DocumentCollections. These documents are stored in the `documents` property.
 * @class DocumentFolder
 * @extends DocumentStore
 * @param {DocumentFolderCollection} parent - The parent DocumentFolderCollection
 * @param {DocumentReference} docRef - The DocumentReference for the DocumentFolder
 * @param {FolderSchema} data - The data for the DocumentFolder
 * @param {boolean} stub - Whether the DocumentFolder is a stub or not
 */
export default class DocumentFolder<Params = FolderParams, Schema extends FolderSchema = FolderSchema, PublicSchema = FolderPublicSchema> extends DocumentStore<Schema, PublicSchema> {
    // schema = new FolderSchema();
    // publicSchema = new FolderPublicSchema();
    static type: string = 'folder';
    static typeLabel: String = 'Folder';
    stub = false;
    breadcrumbs: BreadcrumbManager = new BreadcrumbManager(this);
    get name() {
        return this.data?.name;
    }
    // childrenType = DocumentType.PROJECT;

    documents: DocumentFolderCollection = new DocumentFolderCollection(this, DocumentStore);

    get defaults() { // TODO: Setup DocumentStore to support this method of getting defaults so that it returns a new instance of the schema. Useful for dynamic values, like getting a newTimestamp on each instance.
        let data = new FolderSchema();
        let parentCollection = this.parent as DocumentFolderCollection;
        let collectionOwner = parentCollection.parent ? parentCollection.parent as DocumentFolder<any, FolderSchema, FolderPublicSchema> : null;
        if (parentCollection && collectionOwner) {
            data.members = collectionOwner.data.members;
            data.memberKeys = collectionOwner.data.memberKeys;
        }
        data.author = root.user.id;
        return data as Schema;
    }

    /**
     * Returns the params for the DocumentFolder. This is a combination of the parent DocumentFolderCollection's params and the DocumentFolder's id.
     * @returns {FolderParams} The params for the DocumentFolder
     * */
    get params(): Params {
        return documentPathToParams<Params>(this.docRef.path)
    }

    // below will be a detailed jsdoc comment breaking down in detail the functionality of the below method: url
    /**
     * Returns the url for the DocumentFolder.
     * @returns {string} The url for the DocumentFolder
     * */
    get url(): string {
        return createDocumentUrl(this.params as FolderParams);
    }

    /**
     * Returns the tags for the DocumentFolder's documents as an array of strings.
     * @returns {string[]} The tags for the DocumentFolder's documents
     * */
    get documentsTags() {
        let allTags = this.documents.all.map(p => p.data.tags).flat() as string[];
        let uniqueTags = [... new Set(allTags)];
        return uniqueTags;
    }

    /**
     * Returns whether the DocumentFolder is starred or not. This checks the user's starred collection for the DocumentFolder.
     * @returns {boolean} Whether the DocumentFolder is starred or not
     * */
    get isStarred(): boolean {
        if (!root) return false;
        if (!root.user) return false;
        if (!root.user.starred) return false;
        return root.user.starred.get(this) ? true : false;
    }
    
    constructor(parent: any, docRef?: DocumentReference, data?: Schema) {
        super(parent, docRef, data);
        // this.init();
        // reaction(() => this.status, (val) => { console.log('Folder status:', val) });
        // reaction(() => this.isStarred, (val) => { console.log('Folder starred?:', val) });
    }
    
    /**
     * Toggles whether the DocumentFolder is starred or not. This checks the user's starred collection for the DocumentFolder and adds or removes it accordingly.
     * */
    toggleStarred = () => {
        console.log('toggleStarred');
        if (this.isStarred) {
            root.user.starred.remove(this);
        } else {
            root.user.starred.add(this);
        }
    }
    
    /**
     * Adds the DocumentFolder to the user's starred collection.
     * */
    addStarred = () => {
        root.user.starred.add(this);
    }
    
    /**
     * Removes the DocumentFolder from the user's starred collection.
     * */
    removeStarred = () => {
        root.user.starred.remove(this);
    }
    
    /**
     * Adds a tag string to the DocumentFolder's tags array located in the document `data` property.
     * @param {string} tagName - The tag name to add to the DocumentFolder's tags array
     * */
    addTag = async (tagName: string) => {
        let tags = [... this.data.tags];
        if (tags.indexOf(tagName) > -1) return;
        tags.push(tagName);
        this.data.tags = tags;
        await this.save();
    }
    
    /**
     * Removes a tag string from the DocumentFolder's tags array located in the document `data` property.
     * @param {string} tagName - The tag name to remove from the DocumentFolder's tags array
     * */
    removeTag = (tagName: string) => {
        let tags = this.data.tags.filter(tag => tag !== tagName);
        this.data.tags = tags;
        this.save();
    }
    
    
    /**
     * Navigates to the DocumentFolder's route based on the DocumentFolder's type.
     * */
    // navigateTo = () => {
    //     console.log()
    //     root.setDocumentType(this.data.type as DocumentType);
    //     let docTypeSerialized: string = this.data.type;
        
    //     // Handle the special case for CREATIVE
    //     if (docTypeSerialized === DocumentType.CREATIVE || docTypeSerialized === DocumentType.ANIMATION) {
    //         docTypeSerialized = 'creative';
    //     }

    //     let routes = root.routes as Routes;
    //     const route = routes[docTypeSerialized as keyof typeof routes];
        
    //     if (route) {
    //         console.log('Navigating to route:', route);
    //         // Use a type assertion here
    //         root.router.goTo(route as AnyRoute, this.params);
    //     } else {
    //         console.error(`No route found for document type: ${docTypeSerialized}`);
    //         // Handle the error case, perhaps navigate to a default route
    //     }
    // }
    
    /**
     * This overrides the base `onLoad` method and is called when the DocumentFolder is loaded. This method loads the DocumentFolder's members and documents collections.
     * @param {FolderSchema} data - The current data of the document when it's loaded
     * */
    onLoad = async (data: FolderSchema) => {
        // this.projects.query = this.creatives.collectionRef.orderBy('dateModified', 'desc');
        await this.breadcrumbs.load();
        if (this.stub === false) {
            await this.members.load();
            await this.documents.load();
        }
    }

    onUpdated = (data: Schema, oldData: Schema) => {
        console.log('DocumentFolder updated', data, oldData);
        this.members.update();
    }
    
    onReady = async () => { }
    
    /**
     * This overrides the base `onReset` method and is called when the Document `reset()` method is called. This method resets the DocumentFolder's members, collections, and breadcrumbs.
     * */
    onReset = () => {
        this.members.reset();
        this.documents.reset();
        this.breadcrumbs.reset();
    }
    
    /**
     * Archives the DocumentFolder by setting the `archived` property to `true` and saving the data to Firestore.
     * */
    archive = async () => {
        this.data.archived = true;
        await this.save();
    }
    
    /**
     * Unarchives the DocumentFolder by setting the `archived` property to `false` and saving the data to Firestore.
     * */
    unarchive = async () => {
        this.data.archived = false;
        await this.save();
    }
    
    /**
     * Toggles whether the DocumentFolder is archived or not. This checks the DocumentFolder's `archived` property and calls the `archive` or `unarchive` methods accordingly.
     * */
    toggleArchive = async () => {
        if (this.data.archived) {
            return this.unarchive();
        }

        return this.archive();
    }
}