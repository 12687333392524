export function getUnit(value: string) {
    let unitString = value.match(/[a-zA-Z%]+/g)?.pop();
    let unitValue = parseFloat(value);
    if (!unitString || isNaN(unitValue)) return null;

    let unit = {
        value: parseFloat(value),
        type: unitString
    };

    return unit;
}