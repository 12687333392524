import React, { PropsWithChildren } from 'react';
import {
  Container,
  HeaderContainer,
  TitleLabel,
} from "./CardWrapper.styles";

export interface CardWrapperProps {
  title: string;
};

class CardWrapper extends React.Component<PropsWithChildren<CardWrapperProps>>{
  constructor(props: CardWrapperProps) {
    super(props);
  }

  render() {
    const { children, title } = this.props;

    return (
      <Container>
        <HeaderContainer>
          <TitleLabel>{title}</TitleLabel>
        </HeaderContainer>
        {children}
      </Container>
    );
  }
};

export default CardWrapper;
