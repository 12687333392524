import { Icon } from 'antd';
import color from 'color-convert';
import React from 'react';
import seedrandom from 'seedrandom';
import styled from 'styled-components';

const Container = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  border-radius: 50%;
  border: 1px solid #ededed;
  color: rgba(0, 0, 0, 0.14);
  font-size: 24px;
  height: 32px;
  overflow: hidden;
  text-align: center;
  width: 32px;
`;

export interface AvatarThumbnailProps {
  className?: string;
  colorSeed: string;
}

class AvatarThumbnail extends React.Component<AvatarThumbnailProps>{
  constructor(props: AvatarThumbnailProps) {
    super(props);
  }

  getRandomColor = (seed: string, saturation: number, lightness: number) => {
    return '#' + color.hsl.hex([360 * seedrandom(seed)(), saturation, lightness]);
  }

  render() {
    const { className, colorSeed } = this.props;
    const { getRandomColor } = this;

    const color = getRandomColor(colorSeed, 50, 90);

    return (
      <Container className={className} color={color}>
        <Icon type="user" />
      </Container>
    );
  }
};

export default AvatarThumbnail;
