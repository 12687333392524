import { Button, Form, Modal } from "antd";
import { FormComponentProps, FormProps } from 'antd/lib/form';
import { ModalProps } from 'antd/lib/modal';
import { WrappedFormUtils } from "antd/lib/form/Form";
import React from 'react';

const FORM_MODAL_CANCEL_BUTTON_DEFAULT_LABEL = 'Cancel';
const FORM_MODAL_SUBMIT_BUTTON_DEFAULT_LABEL = 'Submit';

type ChildrenAsCallback = {
  formController: WrappedFormUtils<unknown>
}

export interface FormModalProps extends
  FormComponentProps,
  Pick<FormProps, 'layout'>,
  Omit<ModalProps, 'onCancel' | 'onOk' | 'okText' | 'title' | 'visible'> {
  children: (formMetadata: ChildrenAsCallback) => React.ReactNode;
  className?: string;
  formClassName?: string;
  isLoading?: boolean;
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: unknown, formController: WrappedFormUtils<unknown>) => void;
  resetOnClose?: boolean;
  submitText?: string;
  title: string;
  width?: number;
}

class FormModal extends React.Component<FormModalProps>{
  constructor(props: FormModalProps) {
    super(props);
  }

  getFooterButtons = () => {
    const {
      cancelText = FORM_MODAL_CANCEL_BUTTON_DEFAULT_LABEL,
      footer,
      form: formController,
      isLoading,
      onClose,
      resetOnClose,
      submitText = FORM_MODAL_SUBMIT_BUTTON_DEFAULT_LABEL,
      title,
    } = this.props;

    const { handleSubmit } = this;

    if (footer) {
      return footer;
    }

    return [
      <Button
        key={`formModal.${title}.cancelButton`}
        onClick={() => {
          if (resetOnClose) {
            formController.resetFields();
          }

          onClose();
        }}
      >
        {cancelText}
      </Button>,
      <Button
        htmlType="submit"
        key={`formModal.${title}.submitButton`}
        loading={isLoading}
        onClick={handleSubmit}
        type="primary"
      >
        {submitText}
      </Button>,
    ]
  }

  handleSubmit = () => {
    const { form: formController, onSubmit } = this.props;

    formController.validateFields((err, values) => {
      if (err) {
        return;
      }

      onSubmit(values, formController);
    });
  }

  handleSubmitFromFormEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.handleSubmit();
  }

  render() {
    const {
      className,
      children,
      form: formController,
      formClassName,
      isVisible,
      layout,
      onClose,
      title,
      width = 520,
    } = this.props;

    const {
      getFooterButtons,
      handleSubmitFromFormEvent,
    } = this;

    return (
      <Modal
        className={className}
        footer={getFooterButtons()}
        onCancel={onClose}
        title={title}
        visible={isVisible}
        width={width}
      >
        <Form
          className={formClassName}
          layout={layout}
          onSubmit={handleSubmitFromFormEvent}
        >
          {children({
            formController,
          })}
        </Form>
      </Modal>
    );
  }
}

export default Form.create<FormModalProps>()(FormModal);
