import { Button as BaseButton } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  font-size: 16px;
  padding: 0px 9px;
`;

export interface FiltersToolbarButtonProps {
  disabled?: boolean;
  label: string;
  onClick?: () => void;
}

class FiltersToolbarButton extends React.Component<FiltersToolbarButtonProps>{
  constructor(props: FiltersToolbarButtonProps) {
    super(props);
  }

  render() {
    const { disabled, label, onClick } = this.props;

    return (
      <Button
        disabled={disabled}
        onClick={onClick}>
        {label}
      </Button>
    );
  }
};

export default FiltersToolbarButton;
