import { $mobx, isObservable, makeObservable, observable } from "mobx";
import DocumentStore from "stores/DocumentStore";
import Store from "stores/Store";

interface IEqualsComparer<T> {
  (a: T, b: T): boolean;
}

type CreateObservableOptions = {
  name?: string;
  equals?: IEqualsComparer<any>;
  deep?: boolean;
  defaultDecorator?: Annotation;
  proxy?: boolean;
  autoBind?: boolean;
}

type AnnotationMapEntry = Annotation | true | false;
type AnnotationsMap<T, AdditionalFields extends PropertyKey> = {
    [P in Exclude<keyof T, "toString">]?: AnnotationMapEntry;
} & Record<AdditionalFields, AnnotationMapEntry>;

type Annotation = {
  annotationType_: string;
  make_(adm: any, key: PropertyKey, descriptor: PropertyDescriptor, source: object): any;
  extend_(adm: any, key: PropertyKey, descriptor: PropertyDescriptor, proxyTrap: boolean): boolean | null;
  options_?: any;
};

const annotationsSymbol = Symbol("annotationsSymbol");
const objectPrototype = Object.prototype;

/**
 * Credit @stephenh - https://gist.github.com/stephenh/77f62941913203a871d0e284ea779fe9
 * 
 * A purposefully-limited version of `makeAutoObservable` that supports subclasses.
 *
 * There is valid complexity in supporting `makeAutoObservable` across disparate/edge-casey
 * class hierarchies, and so mobx doesn't support it out of the box. See:
 * https://github.com/mobxjs/mobx/discussions/2850#discussioncomment-1203102
 *
 * So this implementation adds a few limitations that lets us get away with it. Specifically:
 *
 * - We always auto-infer a key's action/computed/observable, and don't support user-provided config values
 * - Subclasses should not override parent class methods (although this might? work)
 * - Only the "most child" subclass should call `makeSimpleAutoObservable`, to avoid each constructor in
 *   the inheritance chain potentially re-decorating keys.
 *
 * See https://github.com/mobxjs/mobx/discussions/2850
 */
// export function makeSimpleAutoObservable(target: any, overrides: AnnotationMapEntry | any, options:CreateObservableOptions = {}): void {
export function makeSimpleAutoObservable(target: any, overrides: AnnotationMapEntry | any = {}, options:CreateObservableOptions = {}): void {
  // These could be params but we hard-code them
  // const overrides = {} as any;

  // Make sure nobody called makeObservable/etc. previously (eg in parent constructor)
  if (isObservable(target)) {
    // console.warn("Target must not be observable");
    return;
  }

  let annotations = target[annotationsSymbol];
  if (!annotations) {
    annotations = {};
    let current = target;
    while (current && current !== objectPrototype) {
      Reflect.ownKeys(current).forEach((key) => {
        // if(target.constructor.name === 'Creative')  console.log('makeSimpleAutoObservable current', current, key);
        // console.log('key', key, overrides, overrides ? overrides[key] : '');
        if (key === $mobx || key === "constructor") return;
        // if (typeof key === "string" && overrides && overrides[key] === false) return;
        if (overrides && overrides[key] === false) return;
        // if(key === 'eventList') return;
        // if(target.constructor.name === 'Creative')  console.log('makeSimpleAutoObservable current', current, key);

        if(!overrides) annotations[key] = true;
        else if( key in overrides ) annotations[key] = overrides[key];
        else annotations[key] = true;
      });
      current = Object.getPrototypeOf(current);
    }
    // Cache if class
    const proto = Object.getPrototypeOf(target);
    if (proto && proto !== objectPrototype) {
      Object.defineProperty(proto, annotationsSymbol, { value: annotations });
    }
  }

  // console.log('makeSimpleAutoObservable', target.constructor.name);
  // if(target.constructor.name === 'Creative') console.log('makeSimpleAutoObservable', target.constructor.name, annotations);

  return makeObservable(target, annotations, options);
}