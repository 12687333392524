import AdViewToolbar from './AdViewToolbar';
import React from 'react';
import styled from 'styled-components';
import { reaction } from 'mobx';
import store from 'stores';
import { PanelViews } from 'stores/CreativeTypes';
import AdViewFrame from './AdViewFrame';
import { observer } from 'mobx-react';

const Container = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  height: 100%;
`;

export interface CreativeAdViewProps { };

class AdView extends React.Component<CreativeAdViewProps>{
  constructor(props: CreativeAdViewProps) {
    super(props);
  }

  render() {
    return (
      <Container>
        <AdViewToolbar  disabled={store.creative.mounted === false} />
        <AdViewFrame />
      </Container>
    );
  }
}

export default observer(AdView);
