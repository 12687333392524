import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { AutoComplete, Input, Button, Icon } from 'antd';
import store from '../../../../../stores';
import { IDynamicProperty } from '../../../../../stores/DynamicProperties/Types';
import { DynamicPropertyType } from '../../../../../stores/DynamicProperties/Types/DynamicPropertyType';
import { KeyCode } from '../../../../../utils/Types/UITypes';
import { DynamicPropertyEvent } from 'stores/DynamicProperties/Types/Property';
import { makeAutoObservable, makeObservable, observable, reaction } from 'mobx';

const { Option } = AutoComplete;
const { TextArea } = Input;

const Container = styled.div<{
    showUnits: boolean,
    changed: boolean,
    error: boolean,
}>`
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    transition: all;
    ${({ showUnits }) => showUnits && `
    .ant-select-auto-complete.ant-select .ant-input {
        border-radius: 3px 0px 0px 3px !important;
    }`}
    ${({ error }) => {
        if (error) {
            let bgColor = '#ffbcbc';
            return `
            .ant-select-selection.ant-select-selection--single {
                background:${bgColor};
            }`
        }
    }}
`;

const PropertyInput = styled(AutoComplete)`
    flex-grow: 1;
    &.beforeLabel {
        .ant-input {
            border-radius: 0 5px 5px 0 !important;
        }
    }

    &.afterLabel {
        .ant-input {
            border-radius: 5px 0 0 5px !important;
        }
    }

    &.bothLabel {
        .ant-input {
            border-radius: 0 !important;
        }
    }
`;

const AfterLabel = styled.div`
    color: #a2a2a2;
    padding: 0 11px;
    background: #f3f3f3;
    border: 1px solid #d6d6d6;
    height: 32px;
    margin-left: -3px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

`;

type TextInputProps = {
    property: IDynamicProperty<any>;
    style?: React.CSSProperties | undefined;
    disabled: boolean;
    editMode?: boolean;
}

class TextInput extends React.Component<TextInputProps> {
    search = '';
    showDropdown = false;
    clicked = false;
    typed = false;
    currentAd = null;
    propertyValue = null;
    focused = false;
    text: string = '';
    initialValue = 0;
    focusedValue = 0;
    inputError = false;
    changeTimeout = null;
    changeTimeoutDelay = 5;
    property: IDynamicProperty<any>;

    constructor(props: TextInputProps) {
        super(props);
        this.property = props.property;
        this.property.subscribe(this);
        makeObservable(this, { 
            text: observable,
            inputError: observable,
            focused: observable,
        });
    }

    handleChange = async (value: any, skipUpdate = false) => {
        // console.log('handleChange', value, skipUpdate);
        this.text = value;
        let isNumericType = this.isNumericType(this.property.type);

        if (isNumericType) {
            // console.log('isNumericType true,', value, this.checkValidNumber(value));
            let isValidNumber = this.checkValidNumber(value);
            if (isValidNumber === false) {
                this.inputError = true;
                if(value === '') {
                    this.property.set(this.property.initialValue);
                }
            } else {
                this.inputError = false;
                if(skipUpdate === false) {
                    console.log('TextInput setting property', value, Number(value));
                    this.property.set(Number(value));
                }
                this.initialValue = Number(value);
            }
        } else {
            if(skipUpdate === false) this.property.set(value);
        }

        // console.log('handleChange', value.charAt(0) === '-');
        // if (isNumericType) {
        //     if (value.charAt(value.length - 1) === '.') {
        //         value = this.formatNumber(value) + '.';
        //     } else if (value.charAt(0) === '-') {
        //         value = this.formatNumber(value);
        //     } else {
        //         value = this.formatNumber(value);
        //     }
        // }

        // // this.text = value;

        // if(isNumericType){
        //     if(!value && value !== 0) {
        //         this.property.set(NaN);
        //     } else {
        //         this.property.set(Number(value));
        //     }
        // } else {
        //     this.property.set(value);
        // }
    }

    checkValidNumber(value: string) {
        let isValid = true;

        if(value === '') {
            isValid = false;
        }
        
        if(Number.isNaN(Number(value)) === true){
            isValid = false;
        };
        
        if(value.indexOf('.') === value.length - 1){
            isValid = false;
        }
        
        return isValid;
    }


    getNumPadding(value: string) {
        let padCount = 0;
        let decimalIndex = value.indexOf('.');
        for (let i = decimalIndex + 1; i < value.length; i++) {
            let char = value[i];
            if (char != '0') padCount = 0;
            else padCount++;
        }
        return padCount;
    }

    setNumPadding(value: string, padding: number) {
        let numPeriods = value.split('.').length - 1
        let newValue = value;
        if (numPeriods === 0 && padding > 0) newValue = newValue + '.';
        for (let i = 0; i < padding; i++) {
            newValue = newValue + '0';
        }
        return newValue;
    }

    formatNumber(value: string) {
        let negativeChar = '';
        if (value.charAt(0) === '-') negativeChar = '-';
        return negativeChar + String(value).replace(/\.(?![^.]+$)|[^0-9.]/g, '');
    }

    transformValue(value: string) {
        let newValue = value;

        if (this.isNumericType(this.property.type)) {
            newValue = String(newValue).replace(/\.(?![^.]+$)|[^0-9.]/g, '');
            return newValue;
        } else {
            return String(newValue);
        }
    }

    isNumericType = (type: DynamicPropertyType) => {
        return (
            type === DynamicPropertyType.NUMBER ||
            type === DynamicPropertyType.PIXELS ||
            type === DynamicPropertyType.POINTS ||
            type === DynamicPropertyType.PERCENTAGE
        );
    }

    handleKeyPress = (e: KeyboardEvent) => {
        e = e || window.event;

        let nudgeSize = 1;

        if (this.focused === false) return;
        if (this.isNumericType(this.property.type) === false) return;

        if (e.altKey) {
            nudgeSize = 0.1;
        } else {
            if (e.shiftKey) {
                nudgeSize = 10;
            } else {
                nudgeSize = 1;
            }
        }

        let change = '';
        let value = String(this.property.value);

        let wholeString = value.split('.')[0];
        let decString = value.split('.')[1] || '0';

        // if (e.keyCode == '38') {
        if (e.key === KeyCode.ArrowUp) {
            if (this.isNumericType(this.property.type)) {
                // UP
                e.preventDefault();
                if (nudgeSize == 1 || nudgeSize == 10) {
                    change = String(Number(wholeString) + nudgeSize) + '.' + decString;
                } else {
                    let num = Number(decString.charAt(0));
                    if (num === 9) {
                        change = String(Number(wholeString) + 1) + '.' + setCharAt(decString, 0, '0');
                    } else {
                        change = wholeString + '.' + setCharAt(decString, 0, String(num + 1));
                    }
                }
            }
            if (change.indexOf('.0') === change.length - 2) change = change.split('.')[0];

            this.handleChange(change);
        }
        // else if (e.keyCode == '40') {
        else if (e.key === KeyCode.ArrowDown) {
            if (this.isNumericType(this.property.type)) {
                // DN
                e.preventDefault();
                if (nudgeSize == 1 || nudgeSize == 10) {
                    change = String(Number(wholeString) - nudgeSize) + '.' + decString;
                } else {
                    let num = Number(decString.charAt(0));
                    if (num === 0) {
                        change = String(Number(wholeString) - 1) + '.' + setCharAt(decString, 0, '9');
                    } else {
                        change = wholeString + '.' + setCharAt(decString, 0, String(num - 1));
                    }
                }
            }
            if (change.indexOf('.0') === change.length - 2) change = change.split('.')[0];
            this.handleChange(change);
        }

        function setCharAt(str: string, index: number, chr: string) {
            if (index > str.length - 1) return str;
            return str.substr(0, index) + chr + str.substr(index + 1);
        }
    }

    componentDidMount() {
        this.text = String(this.property.value);
        this.property.on(DynamicPropertyEvent.CHANGED, ()=>{
            if(this.focused === true) return;
            this.text = String(this.property.value);
            this.handleChange(this.text, true);
        });
        this.property.on(DynamicPropertyEvent.REVERTED, ()=>{
            this.text = String(this.property.value);
            this.handleChange(this.text, true);
        });

        reaction(() => this.focused, (focused) => {
            if(focused === false && this.inputError) {
                if(this.text === '') {
                    this.property.set(this.focusedValue);
                } else {
                    this.property.set(this.initialValue);
                }
            }
        });
    }

    update() {
        // let currentAd = store.creative.ads.current;
        // this.propertyValue = currentAd.dynamicData[this.property.name];
    }

    render() {
        let property = this.property;
        let name = property.name;
        // let value = this.text;
        let inputText: string = this.text;
        // let label = this.property.name;
        // let autocompleteElements = store.creative.ads.filter.all.properties;

        // //console.log('TextInput: Name: ' + name + ' value: ' + value, ' this.property.value: ' + this.property.value);

        // TODO: rebuild autocomplete feature.
        // for (var v in values) {
        //     let value = values[v];
        //     let key = v + 'autocomplete';
        //     if(value)
        //         autocompleteElements.push(<Option key={key} value={value}>{value}</Option>);
        // }

        // TODO: what to do about {...extras} that was on autocomplete element.
        // if (this.focused) {
        //     value = this.property.value;
        // }

        // if(value === 0) inputText = '0';
        // else if(value === null || value === undefined || Number.isNaN(value)) inputText = '';
        // else inputText = String(value);
        //console.log('TextInput inputText', inputText, ' this.property.value: ' + this.property.value);

        return (
            <Container key={name} style={this.props.style} changed={property.getChanged()} error={this.inputError} showUnits={ property.unit ? true : false } /*className={this.search ? "hide-dropdown" : ""*/>
                <PropertyInput
                    key={name}
                    disabled={this.props.disabled}
                    //dataSource={autocompleteElements}
                    placeholder="Enter custom value"
                    style={{ width: '100%' }}
                    onChange={(value) => this.handleChange(value)}
                    value={inputText}
                    // filterOption={(inputText, option) => {
                    //     // console.log('input value', inputText);
                    //     if(this.clicked) { return true }

                    //     if(inputText === '')  { return false }
                    //     else { return option.props.children.toUpperCase().indexOf(inputText.toUpperCase()) !== -1 }
                    // }}
                    onBlur={() => {
                        this.showDropdown = false;
                        this.focused = false;
                        document.onkeydown = (e) => { };
                    }}
                    onFocus={() => {
                        this.clicked = true;
                        this.focused = true;
                        if(this.inputError === false) {
                            this.initialValue = this.property.value;
                            this.focusedValue = this.property.value;
                        }
                        document.onkeydown = (e) => { this.handleKeyPress(e) };
                    }}
                // onSelect={(val)=>{this.handleChange(val); this.clicked = true;}}
                //onSearch={this.handleChange}
                >
                </PropertyInput>
                {property.unit ? <AfterLabel>{property.unit}</AfterLabel> : <span></span>}
            </Container>
        );
    }
}

export default observer(TextInput);
