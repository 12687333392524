import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import Asset from 'stores/Asset';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import UploadAssetModal from './UploadAssetModal';
import store from 'stores';

export interface UpdateFileButtonProps {
  asset: Asset;
}

class UpdateFileButton extends React.Component<UpdateFileButtonProps>{
  isUploadAssetModalVisible: boolean = false;
  setAssetDelegate: (asset: Asset) => void;

  constructor(props: UpdateFileButtonProps) {
    super(props);

    makeObservable(this, {
      isUploadAssetModalVisible: observable,
    });
  }

  showUploadAssetModal = () => {
    const { asset } = this.props;
    store.creative.assets.uploadManager.setAsset(asset);
    store.creative.assets.uploadManager.show();
  }

  render() {
    const {
      showUploadAssetModal,
    } = this;

    const { asset } = this.props;

    return (
      <>
        <DrawerButton disabled={asset.loading} onClick={showUploadAssetModal}>
          Update
        </DrawerButton>
      </>
    );
  }
};

export default observer(UpdateFileButton);
