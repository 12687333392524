import { format } from 'date-fns';
import { timestampToDate } from 'utils/Dates';
import type DocumentFolder from 'stores/DocumentFolder';

export const basicTextFilterer = (search: string, target?: string) => {
    return !!(target || '').toLocaleLowerCase().includes(search.toLowerCase());
};

export const basicTimestampDateRenderer = (rowData: DocumentFolder) => {
    if (!rowData.data.dateModified) {
        return '-';
    }

    if (!('nanoseconds' in rowData.data.dateModified)) {
        return '-';
    }

    return format(
        timestampToDate(rowData.data.dateModified),
        'MM/dd/yyyy h:mm:ss aa'
    );
};
