import { observer } from 'mobx-react';
import { PanelViews } from 'stores/CreativeTypes';
import DrawerLayout from 'components/Views/Creative/Drawers/DrawerLayout';
import InputGroup from '../../DynamicProperties/Inputs/InputGroup';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import PropertiesDrawerToolbar from './PropertiesDrawerToolbar';
import { makeObservable, observable } from 'mobx';
import DynamicPropertiesManager from 'stores/DynamicProperties/Manager';
import { PropertyManagerTypes } from 'stores/Creative';
import { DynamicGroupEvent } from 'stores/DynamicProperties/Types/Group';
import Template from 'stores/DynamicProperties/Types/Template';

let RootGroup = styled(InputGroup)`
  border: none;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export interface PropertyDrawerProps { }

class PropertyDrawer extends React.Component<PropertyDrawerProps>{

  constructor(props: PropertyDrawerProps) {
    super(props);
    store.creative.dynamicProperties.addEventListener(DynamicGroupEvent.ADDED, this.onPropertyAdded);
  }

  onPropertyAdded = () => {
    // ant design wasn't playing nice, so this is a hack to get around that for now.
    const drawerBody = document.querySelector('.properties-drawer-ref')?.parentElement?.parentElement;
    setTimeout(() => {
      if(drawerBody) {

        let scrollHeight = drawerBody.scrollHeight;
        drawerBody.scrollTo(0, scrollHeight);
      }
    }, 0);
  }

  getPropertyManagers = () => {
    // let managers = new Map<TabModes, DynamicPropertiesManager>();
    // managers.set(TabModes.AD, store.creative.currentAd.properties);
      
  }

  handleOnClose = () => {
    store.creative.setPanel(PanelViews.NONE);
  }

  handleTabChange = (tabMode: PropertyManagerTypes) => {
    store.creative.setCurrentPropertyManager(tabMode);
  }

  componentWillUnmount() {
    store.creative.dynamicProperties.unsubscribe(this);
    store.creative.ads.current?.properties.unsubscribe(this);
    store.creative.ads.current?.template?.properties.unsubscribe(this);
  }

  render() {
    store.creative.dynamicProperties.subscribe(this);
    store.creative.ads.current?.properties.subscribe(this);
    store.creative.ads.current?.template?.properties.subscribe(this);

    const { displayPanel } = store.creative;
    const { handleOnClose } = this;
    const currentAd = store.creative.ads.current;
    const templateProp = currentAd?.properties.get('template') as Template;
    const template = templateProp?.store;

    const adProperties = currentAd?.properties;
    const templateProperties = template?.properties;
    const creativeProperties = store.creative.dynamicProperties;

    let currentPropertiesManager = store.creative.currentPropertyManager;
    let tabMode = store.creative.currentPropertyManager?.name as PropertyManagerTypes;

    return (
        <DrawerLayout
          isVisible={displayPanel === PanelViews.AD}
          onClose={handleOnClose}
          title={<PropertiesDrawerToolbar tabMode={tabMode} currentPropertiesManager={currentPropertiesManager} handleTabChange={ e => this.handleTabChange(e)} property={store.creative.dynamicProperties}/>}
        >
          <div className='properties-drawer-ref'></div>
          {tabMode === PropertyManagerTypes.AD && <RootGroup hideLabel={true} onRemove={ propertyName => {}} property={adProperties} disabled={store.creative.loading} editMode={false} />}
          {tabMode === PropertyManagerTypes.TEMPLATE && <RootGroup hideLabel={true} onRemove={ propertyName => {}} property={templateProperties} disabled={store.creative.loading} editMode={false} />}
          {tabMode === PropertyManagerTypes.CREATIVE && <RootGroup hideLabel={true} onRemove={ propertyName => {}} property={creativeProperties} disabled={store.creative.loading} editMode={true} />}
        </DrawerLayout>
    );
  }
};

export default observer(PropertyDrawer);