import { DynamicProperty } from './Property';
import { getUnit } from './utils';
import { IDynamicProperty } from './index';
import { DynamicPropertyType } from './DynamicPropertyType';

export default class Pixels extends DynamicProperty<number> {
  type = DynamicPropertyType.PIXELS;
  value = 0;
  default = () => 0;
  unit = 'px';

  constructor(parent: IDynamicProperty<any>, name: string, value: number) {
    if(value !== undefined && typeof value === 'string' && isNaN(value) === false) {
      value = parseFloat(value);
    } else {
      value = 0;
    }
    super(parent, name, value);

    // this.init();
  }

  setDefault = () => {
    this.setInitialValue(0);
  }

  toNumber() {
    return this.value;
  }

  getString = () => {
    if(!this.value && this.value !== 0) return '0' + this.unit;
    return this.value + this.unit;
  };

  getPreviewData = () => {
    return this.getString();
  };

  getObject = () => {
    return this.getString();
  };

  setUsingCSV = (value: string) => {
    let newValue = getUnit(value);
    // console.log('PIXEL SETTING', this.name, value, newValue?.value);
    if (newValue && newValue.type === this.unit) {
      this.set(newValue.value);
    } else {
      this.throwCSVImportError(`Invalid number. Value: ${value}`);
    }
  };
}
