import { Button, Input } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`;

const InvisibleInput = styled.input`
  display: none !important;
`;

export interface FileInputProps {
  accept?: string;
  buttonClassName?: string;
  className?: string;
  disabled?: boolean;
  file?: File | null;
  onChange?: (file: File | null) => void;
  placeholder?: string;
}

class FileInput extends React.Component<FileInputProps>{
  inputRef: React.RefObject<HTMLInputElement> | null = null;

  constructor(props: FileInputProps) {
    super(props);

    this.inputRef = React.createRef();
  }

  handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, } = this.props;

    if (e.target.files?.[0]) {
      onChange?.(e.target.files[0]);
    }
  }

  showFileDialog = () => {
    let inputElem = this.inputRef?.current;
    if(!inputElem) return;
    inputElem.value = '';
    inputElem.click?.();
  }

  render() {
    const {
      accept,
      buttonClassName,
      className,
      file,
      placeholder = 'Select a file from your computer',
    } = this.props;

    const {
      handleFileSelection,
      inputRef,
      showFileDialog,
    } = this;

    return (
      <Container>
        <InvisibleInput
          accept={accept}
          onChange={handleFileSelection}
          ref={inputRef}
          type="file"
        />

        <Input
          className={className}
          disabled
          placeholder={placeholder}
          value={file?.name}
        />

        <Button
          className={buttonClassName}
          onClick={showFileDialog}
        >
          Browse
        </Button>
      </Container>
    );
  }
}

export default FileInput;
