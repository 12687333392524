import { Button } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import DocumentStore from 'stores/DocumentStore';
import EditMemberModal from './EditMemberModal';
import React from 'react';

export const EDIT_MEMBER_BUTTON_LABEL = 'Edit';

export interface EditMemberButtonProps {
  memberId: string;
  parentDocument: DocumentStore;
}

class EditMemberButton extends React.Component<EditMemberButtonProps>{
  isModalVisible: boolean = false;

  constructor(props: EditMemberButtonProps) {
    super(props);

    makeObservable(this, {
      isModalVisible: observable,
    });
  }

  showModal = () => {
    this.isModalVisible = true;
  }

  hideModal = () => {
    this.isModalVisible = false;
  }

  render() {
    const { memberId, parentDocument } = this.props;
    const { canModify: editionEnabled } = parentDocument;
    const { isModalVisible, hideModal, showModal } = this;

    return (
      <>
        <Button
          disabled={!editionEnabled}
          onClick={showModal}
        >
          {EDIT_MEMBER_BUTTON_LABEL}
        </Button>

        {editionEnabled && (
          <EditMemberModal
            isVisible={isModalVisible}
            memberId={memberId}
            onClose={hideModal}
            parentDocument={parentDocument}
          />
        )}
      </>
    );
  }
}

export default observer(EditMemberButton);
