import { DynamicPropertyData } from './Types';
import * as Types from './Types';
import { deepCopy } from '../../utils';
import DynamicGroup, { DynamicGroupEvent, DynamicGroupStatus } from './Types/Group';
import { DynamicProperty, DynamicPropertyStatus } from './Types/Property';
import Creative from '../Creative';
import SVGText from './Types/SVGText';
import Ad from '../Ad';
import Template from '../Template';

export class PropertyConflict {
  original: Types.IDynamicProperty<any>;
  new: Types.IDynamicProperty<any>;

  constructor(original: Types.IDynamicProperty<any>, newProp: Types.IDynamicProperty<any>) {
    this.original = original;
    this.new = newProp;
  }
}

export default class DynamicPropertiesManager extends DynamicGroup {
  dateLoaded = NaN;
  dateModified = NaN;
  status = DynamicPropertyStatus.READY;
  originalData: DynamicPropertyData[] = [];
  lable = '';
  parentAd: Ad | Creative | Template;

  constructor(parentAd: Ad | Creative | Template, name?: string) {
    super(null);
    if(name) this.name = name;
    this.parentAd = parentAd;
  }

  get all() {
    let values = this.value || [];
    return [...values];
  }

  get types() {
    return Types;
  }

  get reservedProperties() {
    let reserved = [];
    for (let item of this.properties) {
      if (item.reserved === true) {
        reserved.push({ ...item });
      }
    }
    return reserved;
  }

  get fullpath () {
    // if current ad, return ad id;

    if(this.parentAd) return this.parentAd.id;
    else return '';   
  }

  get flattened() {
    return this.getPreviewData();
  }

  setDateModified = (date: number) => {
    this.dateModified = date;
  }

  get = (name: string) => {
    for (let item of this.properties) {
      // console.log('item.name', item);
      if (item.name === name) return item;
    }
    // return new Types.None(this, 'Null', null);
  }

  findTypeConflicts = (newGroup: DynamicGroup) => {
    let conflicts = [];
    for (let property of newGroup.properties){
      let matchingProperty = this.get(property.name);
      if(matchingProperty && matchingProperty.type !== property.type){
        conflicts.push(new PropertyConflict(matchingProperty, property));
      }
    }

    return conflicts;
  }
  
  findConflicts = (newGroup: DynamicGroup) => {
    let conflicts = [];
    for (let property of newGroup.properties){
      let matchingProperty = this.get(property.name);
      if(matchingProperty && matchingProperty.value !== property.value){
        conflicts.push(new PropertyConflict(matchingProperty, property));
      }
    }

    return conflicts;
  }

  findMissing = (newGroup:DynamicGroup) => {
    let missingProperties = [];
    let properties = [...this.properties];
    for (let newProperty of newGroup.properties) {
      let match = false;
      for (let property of properties) {
        if (property.name === newProperty.name) {
          match = true;
          break;
        }
      }
      if (match === false) {
        missingProperties.push(newProperty);
      }
    }

    return new Types.Group(null, 'missing', missingProperties);
  }

  getData = () => {
    return this.getState().value;
  }

  setData = (data: DynamicPropertyData[]) => {
    this.setState(data);
  }

  getMerged = (newGroup: DynamicGroup) => {
    console.log(`DynamicProperties: getMerged of ${newGroup.properties.length} properties.`);
    let currentProperties = [ ... this.properties ];
    for (let i = 0; i < newGroup.properties.length; i++) {
      let newProperty = newGroup.properties[i];
      var match = null;

      for (let p = 0; p < currentProperties.length; p++) {
        let currentProperty = currentProperties[p];

        if (currentProperty.name === newProperty.name) {
          match = currentProperty;
          currentProperties[p].type = newProperty.type;
          currentProperties[p].value = newProperty.value;
        }
      }
      if (!match) {
        currentProperties.push(newProperty)
      }
    }

    return currentProperties;
  }

  getCSVRowObject = () => {
    let ad = this.parentAd as Ad;
    let data = {} as any;
    data = {
      id: ad.data.index + 1,
      adID: ad.id,
    };

    for (let property of this.properties) {
      data[property.name] = property.getCSVData();
    }
    return data;
  }

  // set current values to the ones passed in. only set values to properties that currently exist. 
  setMultiple = (newGroup: DynamicGroup | DynamicPropertiesManager) => {
    let currentProperties = [...this.properties];
    for (let i = 0; i < currentProperties.length; i++) {
      let currentProperty = currentProperties[i];

      for (let p = 0; p < newGroup.properties.length; p++) {
        let newProperty = newGroup.properties[p];
        if (newProperty.name === currentProperty.name) {
          if(newProperty.type !== currentProperty.type){
            console.log(`DynamicProperties: Type conflict for ${newProperty.name}.`);
          } else {
            currentProperties[i].set(newProperty.value);
          }
        }
      }
    }
    this.properties = currentProperties;
  }

  checkNameAvailable = (name: string) => {
    for (let item of this.properties) {
      if (name === item.name) return false;
    }
    return true;
  }

  getFlattenedData = () => {
    return this.getPreviewData();
  }
}