import { Button as BaseButton } from "antd";
import styled from "styled-components";

const DrawerButton = styled(BaseButton)`
  align-items: center;
  font-size: 16px;
  padding: 0px 9px;
`;

export default DrawerButton;
