import root from './';
// import Router, { route, Params, Query } from './Router';
import { UserStatus } from './UserTypes';
import { createDocumentPath, getDocumentType } from '../utils';
import {
    Client,
    Clients,
    Dashboard,
    Project,
    Admin,
    Login,
    Profile,
    Creative,
    Tearsheet
} from 'components/Views';
import { Route } from 'mobx-router';
import { redirect, validateAuth } from 'utils/Router';
import { handleRouteOnEnter } from 'utils/Router/RoutesHandlers';
import { DocumentType } from './Types';
import Document from 'components/Views/Document/Document';

type RootStore = typeof root;

// mobx router example:
// dashboard: new Route({
//     path: '/dashboard/:username/:tab',
//     component: <div></div>,
//     onEnter: (route, params: { username: string; tab?: string; }, store: RootStore, queryParams: {test: string}) => {
//         console.log('routes: clients');
//         store.ui.setView(route.component);
//     },
//     onParamsChange: (route, params, store: RootStore, queryParams) => {
//         console.log('routes: clients: params changed to', params, queryParams);
//     }
// }),

// MobX Router Events:
// beforeExit
// onExit
// beforeEnter
// onEnter
// onParamsChange

const routes = {
    dashboard: new Route({
        path: '/dashboard',
        component: <Dashboard />,
        onEnter: async (route, params, store: RootStore, queryParams) => {
            console.log('routes: dashboard');
            await handleRouteOnEnter({
                disableBreadcrumbs: true,
                requiresAuth: true,
            });

            await store.user.starred.load();
            root.setCurrent();
        }
    }),
    clients: new Route({
        path: '/clients',
        component: <Clients />,
        onEnter: async (route, params, store: RootStore, queryParams) => {
            console.log('routes: clients');
            await handleRouteOnEnter({
                requiresAuth: true,
            });

            if(store.clients?.mounted === false) store.loadClients();
            else store.setCurrent(store.clients);
        }
    }),
    profile: new Route({
        path: '/profile',
        component: <Profile />,
        onEnter: async () => {
            console.log('routes: profile');
            await handleRouteOnEnter({
                disableBreadcrumbs: true,
                requiresAuth: true,
            });
        }
    }),
    adWithFilter: new Route({
        path: '/view/:clientID/:projectID/:creativeID/:adID/:filterName',
        component: <div>clients</div>,
        onEnter: async () => {
            console.log('routes: adWithFilter');
            await handleRouteOnEnter({
                requiresAuth: true,
            });
        }
    }),
    ad: new Route({
        path: '/view/:clientID/:projectID/:creativeID/:adID',
        component: <Document />,
        onEnter: async (route, params: { clientID: string; projectID: string, creativeID: string, adID: string }, store: RootStore, queryParams: { filterSetName: string }) => {
            await handleRouteOnEnter({
                requiresAuth: true,
            });

            console.log('routes: ad');
            console.log('params.creativeID', params.creativeID, 'store.creative?.id', store.creative?.id, 'store.creative', store.creative);
            // if (params.creativeID !== store.creative?.id || !store.creative) {
            if (store.creative === undefined || store.creative?.mounted === false) {
                let creativeParams = { clientID: params.clientID, projectID: params.projectID, creativeID: params.creativeID };
                await store.loadCreative(createDocumentPath(creativeParams), queryParams);
            }
            store.creative.ads.setCurrent(params.adID);
            if (queryParams && queryParams.filterSetName) store.creative.ads.filter.setCurrentSetByName(queryParams.filterSetName);
        },
        onParamsChange: (route, params, store: RootStore, queryParams) => {
            console.log('routes: ad: params changed to', params, queryParams);
            store.creative.ads.setCurrent(params.adID);
        }
    }),
    creative: new Route({
        path: '/view/:clientID/:projectID/:creativeID',
        component: <Document />,
        onEnter: async (route, params: { clientID: string; projectID: string, creativeID: string }, store: RootStore, queryParams: { filterSetName: string }) => {
            await handleRouteOnEnter({
                requiresAuth: true,
            });
            console.log('routes: document');

            let type: DocumentType = await getDocumentType(createDocumentPath(params));

            console.log('routes: document. type:', type);

            if(type === DocumentType.CREATIVE) await store.loadCreative(createDocumentPath(params), queryParams);
            else if(type === DocumentType.ANIMATION) await store.loadAnimation(createDocumentPath(params), queryParams);
            
        },
        onParamsChange: (route, params, store: RootStore, queryParams) => {
            store.creative.ads.setCurrent();
        }
    }),
    tearsheet: new Route({
        path: '/tearsheet/:clientID/:projectID/:creativeID/:tearsheetID/:adIndex',
        component: <Tearsheet />,
        onEnter: async (route, params: { clientID: string; projectID: string, creativeID: string, tearsheetID: string, adIndex: string }, store: RootStore, queryParams) => {
            console.log('routes: tearsheet');
            
            await handleRouteOnEnter({
                requiresAuth: false,
            });

            await store.loadTearsheet(createDocumentPath({ clientID: params.clientID, projectID: params.projectID, creativeID: params.creativeID, tearsheetID: params.tearsheetID }));
            store.tearsheet.ads.setActiveByIndex(parseInt(params.adIndex) - 1);
        },
        onParamsChange: (route, params, store: RootStore, queryParams) => {
            store.tearsheet.ads.setActiveByIndex(parseInt(params.adIndex) - 1);
        }
    }),
    tearsheetRoot: new Route({
        path: '/tearsheet/:clientID/:projectID/:creativeID/:tearsheetID',
        component: <Tearsheet />,
        onEnter: async (route, params: { clientID: string; projectID: string, creativeID: string, tearsheetID: string }, store: RootStore, queryParams) => {
            console.log('routes: tearsheetRoot');
            redirect(routes.tearsheet, { ...params, adIndex: '1' });
        }
    }),
    project: new Route({
        path: '/view/:clientID/:projectID',
        component: <Project />,
        onEnter: async (route, params: { clientID: string; projectID: string }, store: RootStore, queryParams) => {
            console.log('routes: project');
            await handleRouteOnEnter({
                requiresAuth: true,
            });

            if(store.project?.id !== params.projectID) store.loadProject(createDocumentPath(params));
            else store.setCurrent(store.project);
        }
    }),
    client: new Route({
        path: '/view/:clientID',
        component: <Client />,
        onEnter: async (route, params: { clientID: string }, store: RootStore, queryParams) => {
            console.log('routes: client');
            await handleRouteOnEnter({
                requiresAuth: true,
            });

            if(store.client?.id !== params.clientID) store.loadClient(createDocumentPath(params));
            else store.setCurrent(store.client);
        }
    }),
    admin: new Route({
        path: '/admin',
        component: <Admin />,
        onEnter: async (route, params, store: RootStore, queryParams) => {
            await handleRouteOnEnter({
                requiresAuth: true,
            });
            console.log('routes: admin');

            store.admin.load();
            root.setCurrent();
            // store.ui.setView(route.component, 'admin'); 
        }
    }),
    home: new Route({
        path: '/',
        component: <div>home</div>,
        onEnter: async () => {
            console.log('routes: home');
            try {
                await validateAuth(false);
                redirect(routes.dashboard);
            }
            catch (error) {
                redirect(routes.notAuthenticated);
            }
        }
    }),
    notAuthenticated: new Route({
        path: '/login',
        component: <Login />,
        onEnter: (route, params, store: RootStore, queryParams) => {
            console.log('routes: login');
            if (store.user.authenticated === false) {
                console.log('routes: login: NOT authenticated');
                // store.ui.setView(route.component, 'login');
            } else {
                redirect(routes.clients, params);
            }
        }
    }),
    notFound: new Route({
        path: '/*',
        component: <div>404</div>,
        onEnter: async (route, params) => {
            console.log('routes: notFound');
            try {
                await validateAuth(false);
                redirect(routes.clients, params);
            }
            catch (error) {
                redirect(routes.notAuthenticated, {});
            }
        }
    })
}

export type Routes = typeof routes;

export default routes;