import { CardWrapper } from 'components/UI';
import { Container } from './TagsManager.styles';
import { NewTagButton, TruncatedTag } from 'components/UI';
import React from 'react';

export type Tag = string;

export interface TagsManagerProps {
  activeTags: Tag[];
  addTag: (tag: Tag) => void;
  removeTag: (tag: Tag) => void;
  title?: string;
};

class TagsManager extends React.Component<TagsManagerProps>{
  constructor(props: TagsManagerProps) {
    super(props);
  }

  render() {
    const { activeTags, addTag, removeTag, title = 'Tags' } = this.props;

    return (
      <CardWrapper title={title}>
        <Container>
          {activeTags.map((tag) => (
            <TruncatedTag
              key={`tagManager.tag.${tag}`}
              label={tag}
              onRemove={() => removeTag(tag)}
            />
          ))}

          <NewTagButton
            onSubmit={addTag}
          />
        </Container>
      </CardWrapper>
    );
  };
};

export default TagsManager;
