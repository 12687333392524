import { Icon as BaseIcon } from 'antd';
import Template from 'stores/Template';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import styled from 'styled-components';

const Icon = styled(BaseIcon)`
  font-size: 20px; 
  padding-top: 4px;
`;

export interface DownloadFileButtonProps {
  template: Template;
}

class DownloadFileButton extends React.Component<DownloadFileButtonProps>{
  constructor(props: DownloadFileButtonProps) {
    super(props);
  }

  render() {
    const { template } = this.props;
    const { downloadURL } = template;

    return (
      <DrawerButton disabled={template.loading} href={downloadURL}>
        <Icon type="cloud-download" />
      </DrawerButton>
    );
  }
};

export default DownloadFileButton;
