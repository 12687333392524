import { Button } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import AddProjectModal from './AddProjectModal';
import type DocumentFolder from 'stores/DocumentFolder';
import React from 'react';

export const ADD_PROJECT_BUTTON_LABEL = 'Add Project';

export interface AddProjectButtonProps {
  parentDocument: DocumentFolder;
};

class AddProjectButton extends React.Component<AddProjectButtonProps>{
  isModalVisible: boolean = false;

  constructor(props: AddProjectButtonProps) {
    super(props);

    makeObservable(this, {
      isModalVisible: observable,
    });
  }

  showModal = () => {
    this.isModalVisible = true;
  }

  hideModal = () => {
    this.isModalVisible = false;
  }

  render() {
    const { parentDocument } = this.props;
    const { canModify: creationEnabled } = parentDocument;
    const { isModalVisible, hideModal, showModal } = this;

    return (
      <>
        <Button
          disabled={!creationEnabled}
          onClick={showModal}
        >
          {ADD_PROJECT_BUTTON_LABEL}
        </Button>

        {creationEnabled && (
          <AddProjectModal
            isVisible={isModalVisible}
            onClose={hideModal}
            parentDocument={parentDocument}
          />
        )}
      </>
    )
  }
}

export default observer(AddProjectButton);
