import styled from 'styled-components';

export const Container = styled.button`
    padding:0;
    border-radius: 3px;
    background-color: #fff;
    color: #262626;
    cursor: pointer;
    overflow: hidden;
    border: none;
    color: #555;
    height: 35px;
    display: flex;
    align-items: center;

    &:hover {}

    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #bdbdbd;
        height: 35px;
        width: 35px;
        border-radius: 35px;
        background: #efefef;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .name {
        display: flex;
        justify-content: center;
        height: 35px;
        align-items: center;
        margin: 0 18px 0 15px;
        font-size: 18px;
        font-weight: 400;
    }
`;

export const PopoverContent = styled.div`
    overflow: hidden;
    color: #555;
    display: flex;
    align-items: center;
    .name {
        font-size: 20px;
    }
    .email {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .info {
        margin: 0 0 0 17px;

        .view-profile {
            margin: 0 10px 0 0;
        }
    }
    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px transparent;
        height: 64px;
        width: 64px;
        border-radius: 64px;
        border: 1px solid #bdbdbd;
        background: #efefef;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }
`;