import { DesktopLayout, MobileLayout } from 'components/Layouts'
import { Layout } from 'antd';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import { ViewModes } from './components/Enums';
import MainContent from './components/MainContent';
import React from 'react';
import store from './stores/index';
import styled from 'styled-components';
import { FileDropEvent } from 'stores/UI';

type Timeout = ReturnType<typeof setTimeout>;

// @ts-expect-error
window.store = store;
// @ts-expect-error
window.reaction = reaction;

const RootContainer = styled.div`
  height: 100%;
`;

const Container = styled(Layout)`
  /* margin: 20px; */
  height: 100%;
  width: 100%;
  p {
    margin-top: 10px;
  }
`;

interface AppProps extends React.HTMLAttributes<HTMLElement> { }

class App extends React.Component<AppProps> {
  dragOverTimeout: Timeout;
  dragOutTimeout: Timeout;
  dragInteractionDelay = 100;
  draggingOver = false;

  constructor(props: AppProps) { // TODO: props should be properly typed
    super(props);
    // window.store = store;

    window.addEventListener('keydown', store.input.handleKeyDown);
    window.addEventListener('keyup', store.input.handleKeyUp);
    window.addEventListener('mousedown', store.input.handleMouseDown);
    window.addEventListener('mouseup', store.input.handleMouseUp);
  }

  handleFileDrop = (e: any) => { // TODO: Move this and related html to a separate component that handles drag/drop events.
    e.preventDefault();
    e.stopPropagation();
    store.ui.setFileDragging(false, e);
    store.ui.fileDropped(e);
  }

  handleFileDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'copy';
    }

    if (this.draggingOver === true) return;
    this.draggingOver = true;
    clearTimeout(this.dragOverTimeout);
    clearTimeout(this.dragOutTimeout);

    this.dragOverTimeout = setTimeout(() => {
      if (store.ui.fileDragging === false) {
        store.ui.setFileDragging(true, e);
      }
    }, this.dragInteractionDelay)
  }

  handleFileDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.draggingOver === false) return;
    this.draggingOver = false;
    clearTimeout(this.dragOverTimeout);
    clearTimeout(this.dragOutTimeout);

    this.dragOutTimeout = setTimeout(() => {
      if (store.ui.fileDragging === true) {
        store.ui.setFileDragging(false, e);
      }
    }, this.dragInteractionDelay)
  }

  render() {
    const { isMobile, viewMode } = store.ui;

    if (viewMode === ViewModes.NONE) {
      return (<div></div>)
    }

    if (viewMode === ViewModes.FRAMELESS) {
      return (
        <MainContent />
      )
    }

    return (
      <RootContainer {... this.props}>
        <Container // TODO: find what type is expected for these event handlers.
          onDragOver={(e: any) => this.handleFileDragOver(e)}
          onDragEnter={(e: any) => this.handleFileDragOver(e)}
          onDragLeave={(e: any) => this.handleFileDragOut(e)}
          onDrop={(e: any) => this.handleFileDrop(e)}
        >
          {!isMobile && <DesktopLayout />}
          {isMobile && <MobileLayout />}
        </Container>
      </RootContainer>
    );
  }
}

export default observer(App);