import { Button as BaseButton, Icon } from 'antd';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  align-items: center;
  display: flex;
  font-size: 20px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 3px;
`;

export interface NavigationMenuProps { }

interface NavigationMenuState {
  isLoading: boolean;
}

class ReloadButton extends React.Component<NavigationMenuProps, NavigationMenuState>{
  constructor(props: NavigationMenuProps) {
    super(props);

    this.state = {
      isLoading: false,
    }
  }

  reload = () => {
    store.creative.ads.current?.reload();
  }

  render() {
    const { isLoading } = this.state;
    const { reload } = this;
    const currentAd = store.creative.ads.current;
    const disableReload = currentAd ? true : false;

    return (
      <Button disabled={disableReload} onClick={reload}>
        {isLoading && <Icon type="loading" />}
        {!isLoading && <Icon type="reload" />}
      </Button>
    );
  }
};

export default ReloadButton;
