import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import firebase from 'firebase/app';
import { Breadcrumb, DocumentType } from 'stores/Types';
import { Timeout } from './Types/common';
import { db } from 'stores/Firebase';

const { Timestamp, FieldValue } = firebase.firestore;

/**
 * Takes a filename in the form of a string and returns the file extension.
 * @param {string} filename - The filename
 * @returns {string} The file extension
 * */
export function getFilenameExtention(filename: string) {
  let extension = filename.match(/\.[0-9a-z]{1,5}$/i);
  if (extension) return extension[0];
}

/**
 * Converts a timestamp in seconds to a timestamp in milliseconds
 * @param {number} timestampSeconds - The timestamp in seconds
 * @returns {number} The timestamp in milliseconds
 * */
export function timestampToUTC(timestampSeconds: number) {
  return timestampSeconds * 1000;
}
/**
 * Returns a FieldValue.serverTimestamp() object. This is a wrapper for the firebase.firestore.FieldValue.serverTimestamp() method.
 * @returns {FieldValue} A FieldValue.serverTimestamp() object
 * */
export function fieldValueTimestamp() {
  return FieldValue.serverTimestamp()
}

/**
 * Returns a new Timestamp object that is set to null. This is a wrapper for the firebase.firestore.Timestamp.fromDate() method.
 * @returns {Timestamp} A new Timestamp object that is set to null
 * */
export function nullTimestamp() {
  return new Timestamp(NaN, NaN);
  // return  { seconds: NaN, nanoseconds: NaN, isEqual: () => false, toDate: () => new Date(NaN), toMillis: () => NaN, valueOf: () => '' };
}

// below will be a jsdoc comment, with types, for the below function nowTimestamp
/**
 * Returns a new Timestamp object that is set to the current time. This is a wrapper for the firebase.firestore.Timestamp.fromDate() method.
 * @returns {Timestamp} A new Timestamp object that is set to the current time
 * */
export function nowTimestamp() {
  let now = Math.round(Date.now() / 1000);
  return new Timestamp(now, 0);
  // return { seconds: Date.now() / 1000, nanoseconds: 0, isEqual: (other: Timestamp) => other.seconds === Date.now() / 1000, toDate: () => new Date(Date.now()), toMillis: () => Date.now(), valueOf: () => Date.now() };
}

/**
 * Returns an array with all duplicate values removed.
 * @param {any[]} a - The array to remove duplicates from
 * @returns {any[]} The array with all duplicate values removed
 * */
export function uniq(a: any[]) {
  return a.sort().filter(function (item, pos, ary) {
    return !pos || item != ary[pos - 1];
  })
}

/**
 * Returns a promise that resolves after a specified amount of time.
 * @param {number} time - The amount of time to wait before resolving the promise
 * @param {any} resolveValue - The value to resolve the promise with
 * @returns {Promise} A promise that resolves after a specified amount of time
 * */
export function delay(time: number, resolveValue = null) {
  return new Promise((resolve) => {
    setTimeout(resolve, time, resolveValue);
  });
}

/**
 * Parses a more loosely formatted JSON string than JSON.parse(). It parses the string as if the object reads like a literal JavaScript object.
 * @example
 * looseJsonParse('{a: 1, b: 2}') // returns { a: 1, b: 2 }
 * @param {string} obj - The string to parse
 * @returns {any} The parsed object
 * */
export function looseJsonParse(obj: Object) {
  var value;
  try {
    value = Function('"use strict";return (' + obj + ')')();
  } catch (e) {
    return obj;
  }
  
  return value;
}

/**
 * Deep copies an object or array;
 * @param {any} inObject - The object or array to deep copy
 * @returns {any} The deep copied object or array
 * */
export function deepCopy<T>(inObject: any):T {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = Object(inObject)[key];
    if (isDocumentReference(value)) { // This is a Firestore Document reference, so do not deep copy.
      Object(outObject)[key] = value;
      continue;
    }

    // Recursively (deep) copy for nested objects, including arrays
    Object(outObject)[key] = deepCopy(value)
  }

  return outObject as T;
}

// below will be a jsdoc comment, with types, for the below function isDocumentReference
/**
 * Returns true if the object is a Firestore DocumentReference.
 * */
export function isDocumentReference(value: any) {
  return value && value.firestore && value._delegate && value._userDataWriter
}

/**
 * Returns true if the object is a class.
 * @param {any} obj The object to check.
 * @returns {boolean} True if the object is a class.
 * */
export function isClass(obj: any) {
  const isCtorClass = obj.constructor
    && obj.constructor.toString().substring(0, 5) === 'class'
  if (obj.prototype === undefined) {
    return isCtorClass
  }
  const isPrototypeCtorClass = obj.prototype.constructor
    && obj.prototype.constructor.toString
    && obj.prototype.constructor.toString().substring(0, 5) === 'class'
  return isCtorClass || isPrototypeCtorClass
}

type OptionalDocumentPathParams = { clientID?: string, projectID?: string, creativeID?: string, animationID?: string, adID?: string, adIndex?: string, tearsheetID?: string };

/**
 * Given an object with the clientID, projectID, creativeID, tearsheetID, and adID, return a Firestore path.
 * The object can be missing any of the properties from the end of the path to the beginning.
 * For example, if you only have the clientID and creativeID, you can pass in an object with those
 * two properties and it will return the path for the creative: '/clients/{clientID}/creatives/{creativeID}'
 * @example
 * ```
 * createDocumentPath({ clientID: '123', projectID: '456', creativeID: '789', adID: '123' })
 * // returns '/clients/123/projects/456/creatives/789/ads/123'
 * ```
 * @param pathObject An object with the clientID, projectID, creativeID, tearsheetID, and adID.
 * @returns A Firestore path.
 * */
export function createDocumentPath(pathObject: OptionalDocumentPathParams) {
    let path = '/clients/' + pathObject.clientID;
    if (pathObject.projectID) path += '/projects/' + pathObject.projectID;
    if (pathObject.creativeID || pathObject.animationID) path += '/creatives/' + pathObject.creativeID;
    if (pathObject.tearsheetID) path += '/tearsheets/' + pathObject.tearsheetID;
    if (pathObject.adID) path += '/ads/' + pathObject.adID;
    return path;
}

export function getDocumentType(path: string): Promise<DocumentType> {
  return new Promise(async (resolve, reject) => {
      let doc = await db.doc(path).get();
      let data = doc.data();
      let type: DocumentType = data?.type;
      if(type) return resolve(type);
      else return resolve(DocumentType.NONE);
  });
}

/**
 * Given an object with the clientID, projectID, creativeID, tearsheetID, and adID, return a url.
 * The object can be missing any of the properties from the end of the path to the beginning. 
 * For example, if you only have the clientID and creativeID, you can pass in an object with those 
 * two properties and it will return the url for the creative: '/view/{clientID}/{creativeID}'
 * @example
 * ```
 * createDocumentUrl({ clientID: '123', projectID: '456', creativeID: '789', adID: '123' })
 * // returns '/view/123/456/789/123'
 * ```
 * @param pathObject An object with the clientID, projectID, creativeID, tearsheetID, and adID.
 * @returns A url.
 * */
export function createDocumentUrl(pathObject: OptionalDocumentPathParams) {
    let url = '/view/' + pathObject.clientID;
    if (pathObject.projectID) url += `/${pathObject.projectID}`;
    if (pathObject.creativeID) url += `/${pathObject.creativeID}`;
    if (pathObject.tearsheetID) url += `/${pathObject.tearsheetID}`;
    if (pathObject.adID) url += `/${pathObject.adID}`;

    return url;
}

/**
 * Given a document's Firestore path, return an object with the clientID, projectID, creativeID, tearsheetID, and adID.
 * This is based on the expected url structure: /clients/{clientID}/projects/{projectID}/creatives/{creativeID}/ads/{adID}
 * Or: /clients/{clientID}/projects/{projectID}/creatives/{creativeID}/tearsheets/{tearsheetID}
 * @param documentPath The document's Firestore path
 * @returns An object with the clientID, projectID, creativeID, tearsheetID, and adID.
 * @example
 * ```
 * documentPathToParams('/clients/123/projects/456/creatives/789/ads/123')
 * // returns { clientID: '123', projectID: '456', creativeID: '789', adID: '123' }
 * ```
 * */
export function documentPathToParams<Params>(documentPath: string): Params {
    let path = documentPath.split('/');
    let params = {} as any;
    if (path[0] === 'clients') params.clientID = path[1];
    if (path[2] === 'projects') params.projectID = path[3];
    if (path[4] === 'creatives') params.creativeID = path[5];
    if (path[6] === 'tearsheets') params.tearsheetID = path[7];
    if (path[6] === 'ads') params.adID = path[7];
    console.log('params', params);
    return params;
}

/**
 * Given an array of breadcrumbs, return a string with the names of the breadcrumbs separated by slashes.
  * @param {Breadcrumb[]} breadcrumbs An array of breadcrumbs.
  * @returns {string} A string with the names of the breadcrumbs separated by slashes.
  * @example
  * ```
  * breadcrumbsToTitle([{ name: 'Home' }, { name: 'Clients' }, { name: 'Client Name' }])
  * // returns 'Home / Clients / Client Name'
  * ```
  * */
export function breadcrumbsToTitle(breadcrumbs: Breadcrumb[]): string {
  let title = '';
  // add slashes to the end of each breadcrumb except the last one
  breadcrumbs.forEach((breadcrumb, index) => {
    title += breadcrumb.name;
    if (index !== breadcrumbs.length - 1) title += ' / ';
  });
  console.log('store.current.breadcrumbs', title);
  return title;
}

// below will be a jsdoc comment, with types, for the below function arrayToChunks
/**
 * Splits apart an array into parts of a given size.
 * @example
 * ```
 * arrayToChunks([1, 2, 3, 4, 5, 6, 7, 8, 9], 3)
 * // returns [[1, 2, 3], [4, 5, 6], [7, 8, 9]]
 * ```
 * @param array The array to split apart.
 * @param chunkSize The size of each chunk.
 * @returns An array of arrays, each of which is a chunk of the original array.
 * */
export function arrayToChunks<T>(array: T[], chunkSize: number): T[][] {
  let chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

// below will be a jsdoc comment, with types, for the below function removeSpecialCharacters
/**
 * Removes these special characters from a string: % - _ ! @ # $ ( ) + =
 * 
 * It also removes extra spaces from the beginning and end of the string, and extra spaces between words.
 * @example
 * ```
 * removeSpecialCharacters('This  is a string with  extra spaces and special characters % - _ ! @ # $ ( ) + =');
 * // returns 'This is a string with extra spaces and special characters'
 * ```
 * @param str The string to remove special characters from.
 * @returns The string with special characters removed.
 * */
export function removeSpecialCharacters(str: string) {
  return str.replace(/[^a-zA-Z0-9\ \%\-\_\!\@\#\$\(\)\+\=]+/g, '');
}

// below will be a jsdoc comment, with types, for the below function removeExtraSpaces
/**
 * Removes extra spaces from the beginning and end of the string, and extra spaces between words.
 * @example
 * ```
 * removeExtraSpaces(' This  is a string with  extra spaces ');
 * // returns 'This is a string with extra spaces'
 * ```
 * @param str The string to remove extra spaces from.
 * @returns The string with extra spaces removed.
 * */
export function removeExtraSpaces(str: string) {
  return str.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
}

// below will be a jsdoc comment, with types, for the below function deepObjectCompare
/**
 * Compares two objects to see if they are equal.
 * */
export function deepObjectCompare(a: any, b: any) {
  if ((typeof a == 'object' && a != null) &&
    (typeof b == 'object' && b != null)) {
    var count = [0, 0];
    for (var key in a) count[0]++;
    for (var key in b) count[1]++;
    if (count[0] - count[1] != 0) { return false; }
    for (var key in a) {
      if (!(key in b) || !deepObjectCompare(a[key], b[key])) { return false; }
    }
    for (var key in b) {
      if (!(key in a) || !deepObjectCompare(b[key], a[key])) { return false; }
    }
    return true;
  }
  else {
    return a === b;
  }
}

// below will be a detailed jsdoc comment, with types, that is detailed, for the below function objectsEqual
/**
 * Compares two objects to see if they are equal (ignoring certain keys).
 * @example
 * ```
 * objectsEqual({ a: 9999, b: 2, c: 3 }, { a: 1, b: 2, c: 3 }, ['a']);
 * // returns true
 * ```
 * @param object1 The first object to compare.
 * @param object2 The second object to compare.
 * @param ignoreKeys An array of keys (strings) to ignore when comparing the objects.
 * @returns True if the objects are equal, false if they are not.
 * */
export function objectsEqual(object1: any, object2: any, ignoreKeys: any[]) {
    const keys1 = Object.keys(object1).filter(val => !ignoreKeys.includes(val));
    const keys2 = Object.keys(object2).filter(val => !ignoreKeys.includes(val));

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !objectsEqual(val1, val2, ignoreKeys) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object: any) {
    return object != null && typeof object === 'object';
}

/**
 * Returns a debounced version of a function.
 * @example
 * ```
 * const debouncedFunction = useDebounce(() => console.log('hello'), 1000);
 * debouncedFunction();
 * // will log 'hello' after 1000ms
 * ```
 * @param func The function to debounce.
 * @param timeout The timeout in milliseconds.
 * @returns The debounced function.
 * */
export function useDebounce(func: Function, timeout = 300) {
  let timer: Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func(...args); }, timeout);
  };
}

export const hasOwnProperty = (obj: any, prop: string) => Object.prototype.hasOwnProperty.call(obj, prop);