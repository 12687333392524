import { Alert } from 'antd';
import { GoogleSignInButton } from 'components/UI';
import { observer } from 'mobx-react';
import React from 'react';
import store from '../../stores';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  .alert {
    margin: 11px 0;
    width: 300px;
  }

  .center-box {
    border: 1px solid #e4e4e4;
    background: #fff;
    border-radius: 3px;
    padding: 40px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
  }

  .logo {
    font-weight: 900;
    font-size: 87px;
    letter-spacing: -3px;
    text-align: center;
    line-height: 61px;
    padding: 21px 42px 32px 42px;
  }

  .signin-button {
    margin-top: 30px;
  }
`;

class Login extends React.Component {
  render() {
    document.title = 'Login';
    return (
      <Container>
        <div className="center-box">
          <div className="logo">ADHUB</div>
          <Alert className="alert" message="Logged out." type="warning" showIcon />
          <GoogleSignInButton className="signin-button" onClick={store.user.login} />
        </div>
      </Container>
    )
  }
}

export default observer(Login);