import { Button, Dropdown, Icon, Menu } from 'antd';
import React from 'react';

export type Option = {
  label: string;
  value: string;
}

export interface HeaderButtonProps {
  buttonClassname?: string;
  className?: string;
  disabled?: boolean;
  label: string;
  onSelect: (option: Option) => void;
  optionClassname?: string;
  options: Option[];
}

class HeaderButton extends React.Component<HeaderButtonProps>{
  constructor(props: HeaderButtonProps) {
    super(props);
  }

  render() {
    const {
      buttonClassname,
      className,
      disabled,
      label,
      onSelect,
      optionClassname,
      options,
    } = this.props;

    return (
      <Dropdown
        className={className}
        disabled={disabled}
        overlay={() => (
          <Menu className={className}>
            {options.map((option) => (
              <Menu.Item
                className={optionClassname}
                key={`option.${option.label}`}
                onClick={() => onSelect(option)}
              >
                {option.label}
              </Menu.Item>
            ))}
          </Menu>
        )}
      >
        <Button className={buttonClassname}>
          <span>{label}</span>
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  }
};

export default HeaderButton;
