import { CollectionReference, DocumentReference } from '@firebase/firestore-types';
import { DocumentStatus, Newable } from './Types';
import DocumentStore, { DocumentEvents } from './DocumentStore';
import DocumentCollection from './DocumentCollection';
import store from '.';
import Animation from './Animation';
import Creative from './Creative';
import type DocumentFolder from './DocumentFolder';
import { FolderSchema } from './DocumentFolderTypes';

export default class DocumentFolderCollection<ChildType extends DocumentStore<any> = any, ChildSchema = any> extends DocumentCollection<ChildType, ChildSchema> {
    query = () => this.collectionRef.where('archived', '==', false).orderBy('dateModified', 'desc');
    
    get tags() {
        let allTags = this.all.map(p => p.data.tags).flat() as string[];
        let uniqueTags = [... new Set(allTags)];
        return uniqueTags;
    }

    addFolder = async <T extends DocumentFolder<any, any, any>>(
        itemClass: Newable<T>,
        data: Partial<T['schema']>
    ): Promise<DocumentFolder<any, FolderSchema, any>> => {
        let docRef: DocumentReference = this.collectionRef.doc();
        let newChild = await this._onFolderCreate(itemClass, docRef, data);
        newChild.addEventListener(DocumentEvents.CHANGED, this.onChildChanged);
        newChild.status = DocumentStatus.LOADING;
        this.parent?.updateDateModified();

        await newChild.save();
        this.children.push(newChild as any);

        return newChild;
    }

    _onFolderCreate = async <T extends DocumentFolder<any, any, any>>(
        itemClass: Newable<T>,
        docRef: DocumentReference,
        data: Partial<T['schema']>
    ): Promise<DocumentFolder<any, FolderSchema, any>> => {
        if (!itemClass) {
            console.warn('AdHub: No "childClass" provided for DocumentCollection');
            return {} as DocumentFolder<any, any, any>;
        }
        let newChild = new itemClass(this, docRef);
        data = Object.assign(newChild.defaults, data);
        newChild._update(data);
        newChild.onReady();
        newChild.addEventListener(DocumentEvents.CHANGED, this.onChildChanged);

        return newChild;
    }

    constructor(parent: DocumentStore<any, any> | CollectionReference, childClass: Newable<ChildType>) {
        super(parent, childClass);
        this.init();
    }
}