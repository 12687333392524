import React from 'react';
import styled from 'styled-components';
import { reaction } from 'mobx';
import store from 'stores';
import AnimationStageFrame from './AnimationStageFrame';
import EmptyPlaceholder from './EmptyPlaceHolder';
import { observer } from 'mobx-react';
import { Icon } from 'antd';
import { AntDesignIconName } from 'stores/Types';
import { DocumentCollectionEvents } from 'stores/DocumentCollection';
import PlayerControls from './PlayerControls';

const antIconName: AntDesignIconName = 'loading';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0; /* Allows element1 to shrink as needed */
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const LoadingIcon = styled(Icon).attrs({type: antIconName})`
  font-size: 40px;
`;

export interface AnimationStageProps extends React.HTMLAttributes<HTMLDivElement> {}

class AnimationStage extends React.Component<AnimationStageProps> {
  constructor(props: AnimationStageProps) {
    super(props);
    store.animation.assets.addEventListener(DocumentCollectionEvents.LOADED, function(){
      console.log('AnimationStage assets loaded');
    })
  }

  handleFileSelected = async (file: File) => {
    await store.animation.uploadAnimationFile(file);
  };

  render() {
    const animationURL = store.animation.currentAnimationAsset?.downloadURL;
    const loading = store.animation.assets.loaded === false;

    return (
      <Container className="test">
        {loading ? (
          <LoadingIcon />
        ) : animationURL ? (
          <>
            <AnimationStageFrame />
            <PlayerControls />
          </>
        ) : (
          <EmptyPlaceholder onFileSelected={this.handleFileSelected} />
        )}
      </Container>
    );
  }
}

export default observer(AnimationStage);