import { DocumentStatus } from 'stores/Types';
import { Icon as BaseIcon } from 'antd';
import Bundle from 'stores/Bundle';
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const Icon = styled(BaseIcon)`
  align-self: center;
  color: red;
  padding-right: 12px;
`;

export interface BundleWarningIconProps {
  bundle: Bundle;
}

class BundleWarningIcon extends React.Component<BundleWarningIconProps>{
  constructor(props: BundleWarningIconProps) {
    super(props);
  }

  render() {
    const { bundle } = this.props;
    const { status, warning } = bundle;

    if (!warning && !(status === DocumentStatus.ERROR)) {
      return null;
    }

    return (
      <Icon type="exclamation-circle" />
    );
  }
};

export default observer(BundleWarningIcon);
