import { Icon, message } from 'antd';
import Bundle from 'stores/Bundle';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import { observer } from 'mobx-react';

export interface GetBundleDownloadLinkButtonProps {
  bundle: Bundle;
}

class GetBundleDownloadLinkButton extends React.Component<GetBundleDownloadLinkButtonProps>{
  constructor(props: GetBundleDownloadLinkButtonProps) {
    super(props);
  }

  copyDownloadUrlToClipboard = async () => {
    const { bundle } = this.props;
    const { downloadURL } = bundle;

    await navigator.clipboard.writeText(downloadURL);
    message.success('Link to Bundle ZIP copied.');
  };


  render() {
    const { copyDownloadUrlToClipboard } = this;
    const { bundle } = this.props;

    return (
      <DrawerButton disabled={bundle.loading} onClick={copyDownloadUrlToClipboard}>
        <Icon type="link" />
      </DrawerButton>
    );
  }
};

export default observer(GetBundleDownloadLinkButton);
