import { reaction } from 'mobx';
import { KeyCode } from './../utils/Types/UITypes';
import Store from './Store';

class Input extends Store {
    meta = false;
    ctrl = false;
    alt = false;
    shift = false;
    up = false;
    down = false;
    left = false;
    right = false;
    mouseDown = false;
    spacebar = false;
    spacebarDown = false;
    spacebarUp = false;

    constructor() {
        super();
        this.init();
    }

    handleMouseDown = (e: MouseEvent) => {
        this.mouseDown = true;
    }

    handleMouseUp = (e: MouseEvent) => {
        this.mouseDown = false;
    }

    handleKeyDown = (e: KeyboardEvent) => {
        if (e.altKey) this.alt = true;
        if (e.shiftKey) this.shift = true;
        if (e.ctrlKey) this.ctrl = true;
        if (e.metaKey) this.meta = true;
        
        if (e.keyCode === 38) this.up = true;
        if (e.keyCode === 40) this.down = true;
        if (e.keyCode === 37) this.left = true;
        if (e.keyCode === 39) this.right = true;
        if (e.key === ' ') { 
            this.spacebar = true;
            this.spacebarDown = true;
            this.spacebarUp = false;
        }
        // console.log('keys', this.meta, this.ctrl, this.alt, this.shift, this.up, this.down, this.left, this.right, this.spacebar, this.spacebarDown, this.spacebarUp);
    }
    
    handleKeyUp = (e: KeyboardEvent) => {
        if (!e.altKey) this.alt = false;
        if (!e.shiftKey) this.shift = false;
        if (!e.ctrlKey) this.ctrl = false;
        if (!e.metaKey) this.meta = false;
        
        if (e.keyCode === 38) this.up = false;
        if (e.keyCode === 40) this.down = false;
        if (e.keyCode === 37) this.left = false;
        if (e.keyCode === 39) this.right = false;
        if (e.key === ' ') {
            this.spacebar = false;
            this.spacebarDown = false;
            this.spacebarUp = true;
        }
        // console.log('keys', this.meta, this.ctrl, this.alt, this.shift, this.up, this.down, this.left, this.right, this.spacebar, this.spacebarDown, this.spacebarUp);
    }
}

export default Input;