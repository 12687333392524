import { Container } from "./DocumentFolderView.styles";
import { observer } from "mobx-react";
import { ViewsLayout } from "components/UI";
import ChildrenTable from "./ChildrenTable";
import type DocumentFolder from "stores/DocumentFolder";
import React from 'react';

export interface DocumentFolderViewProps {
  childrenTableHeaderButtons?: JSX.Element | JSX.Element[];
  childrenTableTitle?: string;
  membersTableTitle?: string;
  parentDocument: DocumentFolder;
  tagsManagerTitle?: string;
}

class DocumentFolderView extends React.Component<DocumentFolderViewProps>{
  constructor(props: DocumentFolderViewProps) {
    super(props);
  }

  render() {
    const {
      childrenTableHeaderButtons = [],
      childrenTableTitle,
      parentDocument,
    } = this.props;

    const isTheDocumentReady = !!parentDocument;

    return (
      <ViewsLayout>
        <Container>
          {isTheDocumentReady && (
            <ChildrenTable
              actionButtons={childrenTableHeaderButtons}
              parentDocument={parentDocument}
              title={childrenTableTitle}
            />
          )}
        </Container>
      </ViewsLayout>
    );
  }
}

export default observer(DocumentFolderView);
