export enum KeyCode {
    ArrowUp = "ArrowUp",
    ArrowLeft = "ArrowLeft",
    ArrowRight = "ArrowRight",
    ArrowDown = "ArrowDown",
    Escape = "Escape",
    Digit0 = "Digit0",
    Digit1 = "Digit1",
    Digit2 = "Digit2",
    Digit3 = "Digit3",
    Digit4 = "Digit4",
    Digit5 = "Digit5",
    Digit6 = "Digit6",
    Digit7 = "Digit7",
    Digit8 = "Digit8",
    Digit9 = "Digit9",
    KeyQ = "KeyQ",
    KeyW = "KeyW",
    KeyE = "KeyE",
    KeyR = "KeyR",
    KeyT = "KeyT",
    KeyY = "KeyY",
    KeyU = "KeyU",
    KeyI = "KeyI",
    KeyO = "KeyO",
    KeyP = "KeyP",
    KeyA = "KeyA",
    KeyS = "KeyS",
    KeyD = "KeyD",
    KeyF = "KeyF",
    KeyG = "KeyG",
    KeyH = "KeyH",
    KeyJ = "KeyJ",
    KeyK = "KeyK",
    KeyL = "KeyL",
    KeyZ = "KeyZ",
    KeyX = "KeyX",
    KeyC = "KeyC",
    KeyV = "KeyV",
    KeyB = "KeyB",
    KeyN = "KeyN",
    KeyM = "KeyM",
    AltLeft = "AltLeft",
    AltRight = "AltRight",
    ControlLeft = "ControlLeft",
    ControlRight = "ControlRight",
    ShiftLeft = "ShiftLeft",
    ShiftRight = "ShiftRight",
    BracketLeft = "BracketLeft",
    BracketRight = "BracketRight",
    Minus = "Minus",
    Equal = "Equal",
    Backspace = "Backspace",
    Tab = "Tab",
    Enter = "Enter",
    Quote = "Quote",
    Semicolon = "Semicolon",
    Backquote = "Backquote",
    Backslash = "Backslash",
    Comma = "Comma",
    Period = "Period",
    Slash = "Slash",
    Space = "Space",
    CapsLock = "CapsLock",
    F1 = "F1",
    F2 = "F2",
    F3 = "F3",
    F4 = "F4",
    F5 = "F5",
    F6 = "F6",
    F7 = "F7",
    F8 = "F8",
    F9 = "F9",
    F10 = "F10",
    F11 = "F11",
    F12 = "F12",
    Pause = "Pause",
    Numpad1 = "Numpad1",
    Numpad2 = "Numpad2",
    Numpad3 = "Numpad3",
    Numpad4 = "Numpad4",
    Numpad5 = "Numpad5",
    Numpad6 = "Numpad6",
    Numpad7 = "Numpad7",
    Numpad8 = "Numpad8",
    Numpad9 = "Numpad9",
    Numpad0 = "Numpad0",
    NumpadMultiply = "NumpadMultiply",
    NumpadDivide = "NumpadDivide",
    NumpadSubtract = "NumpadSubtract",
    NumpadAdd = "NumpadAdd",
    NumpadDecimal = "NumpadDecimal",
    NumpadEqual = "NumpadEqual",
    NumpadComma = "NumpadComma",
    NumpadEnter = "NumpadEnter",
    ScrollLock = "ScrollLock",
    PrintScreen = "PrintScreen",
    IntlBackslash = "IntlBackslash",
    IntlYen = "IntlYen",
    MediaTrackPrevious = "MediaTrackPrevious",
    MediaTrackNext = "MediaTrackNext",
    AudioVolumeMute = "AudioVolumeMute",
    MediaPlayPause = "MediaPlayPause",
    MediaStop = "MediaStop",
    AudioVolumeDown = "AudioVolumeDown",
    AudioVolumeUp = "AudioVolumeUp",
    BrowserHome = "BrowserHome",
    NumLock = "NumLock",
    Home = "Home",
    PageUp = "PageUp",
    End = "End",
    PageDown = "PageDown",
    Insert = "Insert",
    Delete = "Delete",
    ContextMenu = "ContextMenu",
    BrowserSearch = "BrowserSearch",
    BrowserFavorites = "BrowserFavorites",
    BrowserRefresh = "BrowserRefresh",
    BrowserStop = "BrowserStop",
    BrowserForward = "BrowserForward",
    BrowserBack = "BrowserBack"
}