import { AdHubLogo as BaseAdHubLogo, NavBreadcrumb } from 'components/UI';
import { AdHubLogoVariant } from 'components/UI/AdHubLogo/AdHubLogo';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 21px;
  justify-content: space-between;
  padding: 11px 15px;
`;

const AdHubLogo = styled(BaseAdHubLogo)`
  font-size: 20px;
  width: 42px;
`;

class Header extends React.Component {
  constructor(props: {}) {
    super(props);
  }

  render() {
    const { breadcrumbs } = store.current;

    return (
      <Container>
        <AdHubLogo
          collapsed
          variant={AdHubLogoVariant.BLACK}
        />

        <NavBreadcrumb
          breadcrumbs={breadcrumbs}
        />
      </Container>
    );
  }
};

export default observer(Header);
