import { Button } from 'antd';
import React from 'react';
import SelectionCheckbox from './SelectionCheckbox';
import SelectionMenu from './SelectionMenu';
import styled from 'styled-components';

const Container = styled(Button.Group)`
  display: flex;

  .ant-select-selection {
    border-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;


export interface SelectionGroupProps { }

class SelectionGroup extends React.Component<SelectionGroupProps>{
  constructor(props: SelectionGroupProps) {
    super(props);
  }

  render() {
    return (
      <Container>
        <SelectionCheckbox />
        <SelectionMenu />
      </Container>
    );
  }
}

export default SelectionGroup;
