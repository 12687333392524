import { Icon as BaseIcon } from 'antd';
import { observer } from 'mobx-react';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Icon = styled(BaseIcon)`
  padding-top: 3px;
  font-size: 16px;
`;

export interface NavigateBackButtonProps { }

class NavigateBackButton extends React.Component<NavigateBackButtonProps>{
  constructor(props: NavigateBackButtonProps) {
    super(props);

    this.state = {
      isCreateNewFolderModalOpen: false,
    }
  }

  navigateBack = () => {
    const { creative } = store;
    creative.assets.navigateFolderUp();
  }

  render() {
    const { navigateBack } = this;
    const { creative } = store;
    const isEnabled = !!creative.assets.currentFolder;

    return (
      <DrawerButton
        disabled={!isEnabled}
        onClick={navigateBack}
      >
        <Icon type="arrow-up" />
      </DrawerButton>
    );
  }
};

export default observer(NavigateBackButton);
