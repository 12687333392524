import { AssetFileType } from './Asset/Types';
import { DocumentType } from './Types';

/**
 * @class DocumentSchema
 * @description
 * The DocumentSchema class is used to define the base default schema for a document.
 */
export class DocumentSchema {
    name: string;
    type: DocumentType | AssetFileType = DocumentType.DEFAULT;
}
