import { DocumentReference } from '@firebase/firestore-types';
import { DocumentCollectionType, DocumentStatus, DocumentType, LooseObject } from './Types';
import root, { RootStore } from './index';
import Project from './Project';
import { FolderPublicSchema, FolderSchema } from './DocumentFolderTypes';
import DocumentFolder from './DocumentFolder';
import DocumentFolderCollection from './DocumentFolderCollection';

export type ClientParams = {
    clientID: string
}

export default class Client extends DocumentFolder<ClientParams> {
    schema = new FolderSchema();
    publicSchema = new FolderPublicSchema();
    type = DocumentType.CLIENT;
    collectionType = DocumentCollectionType.CLIENT;

    documents = new DocumentFolderCollection(this, Project);

    constructor(parent: any, docRef?: DocumentReference, data?: FolderSchema) {
        super(parent, docRef, data);
        this.init();
    }

    navigateTo = () => {
        root.router.goTo(root.routes.client, this.params);
    }

    onReady = async () => {
        console.log('Client ready', this.documents.all.length);
    }
}