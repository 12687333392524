import { IDynamicProperty } from ".";
import { DynamicPropertyType } from './DynamicPropertyType';
import { DynamicProperty } from "./Property";

export default class Color extends DynamicProperty<string> {
  type = DynamicPropertyType.COLOR;
  value = '#FFFFFF';
  default = () => '#FFFFFF';

  constructor(parent:IDynamicProperty<any>, name:string, value:string) {
    super(parent, name, value);
    // this.init();
  }

  setDefault = () => {
    this.setInitialValue('#FFFFFF');
  }

  setUsingCSV = (value: string) => {
    if(this.isColor(value)) {
      this.set(value);
    } else {
      this.throwCSVImportError('Invalid color: ' + value);
    }
  };

  isColor = (strColor: string) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  }
}