import { Icon as BaseIcon } from 'antd';
import Asset from 'stores/Asset';
import DownloadFileButton from './DownloadFileButton';
import React from 'react';
import RemoveFileButton from './RemoveFileButton';
import styled from 'styled-components';
import UpdateFileButton from './UpdateFileButton';
import { observer } from 'mobx-react';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
`;

const LoadingIcon = styled(BaseIcon).attrs({
  type: 'loading',
})`
  padding-right: 8px;
`;

const FileSizeLabel = styled.span``;

export interface FileRowButtonsProps {
  asset: Asset;
}

class FileRowButtons extends React.Component<FileRowButtonsProps>{
  constructor(props: FileRowButtonsProps) {
    super(props);
  }

  render() {
    const { asset } = this.props;
    const { loading: isLoading, fileSizeString } = asset;

    return (
      <Container>
        {isLoading && <LoadingIcon />}
        <FileSizeLabel>{fileSizeString}</FileSizeLabel>
        <DownloadFileButton asset={asset} />
        <UpdateFileButton asset={asset} />
        <RemoveFileButton asset={asset} />
      </Container >
    );
  }
};

export default observer(FileRowButtons);
