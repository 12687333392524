import { FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { DocumentType, Member } from './Types';
import { DocumentSchema } from './DocumentSchema';
import { nowTimestamp } from '../utils';

export class FolderSchema extends DocumentSchema {
    author: string = '';
    dateModified: FirestoreTimestamp | FieldValue = nowTimestamp();
    name: string = '';
    tags: string[] = [];
    type: DocumentType = DocumentType.DEFAULT;
    archived: boolean = false;
    members: { [key: string]: Member } = {};
    memberKeys: string[] = [];
}

export class FolderPublicSchema {
    type = DocumentType.DEFAULT;
    name: string = '';
}

export type FolderParams = {
    clientID?: string,
    projectID?: string,
    creativeID?: string
}