import { Button as BaseButton } from 'antd';
import ClearAllFiltersButton from './ClearAllFiltersButton';
import FiltersSetsDropdown from './FiltersSetsDropdown';
import NewFilterSetButton from './NewFilterSetButton';
import React from 'react';
import RemoveActiveFilterSetButton from './RemoveActiveFilterSetButton';
import SaveActiveFilterSetButton from './SaveActiveFilterSetButton';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
`;

const FormGroup = styled(BaseButton.Group)`
  display: flex;
`;

const Button = styled(BaseButton)`
  font-size: 16px;
  padding: 0px 9px;
`;

const Title = styled.span`
  margin-right: 13px;
`;

export interface FiltersDrawerHeaderProps { }

class FiltersDrawerHeader extends React.Component<FiltersDrawerHeaderProps>{
  constructor(props: FiltersDrawerHeaderProps) {
    super(props);
  }

  render() {
    return (
      <Container>
        <Title>Edit Filter</Title>

        <FormGroup>
          <FiltersSetsDropdown />
          <NewFilterSetButton />
          <SaveActiveFilterSetButton />
          <RemoveActiveFilterSetButton />
        </FormGroup>

        <ClearAllFiltersButton />
      </Container>
    );
  }
};

export default FiltersDrawerHeader;
