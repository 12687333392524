import { Button as BaseButton, Icon } from 'antd';
import { CustomIconComponentProps } from 'antd/lib/icon';
import { observer } from 'mobx-react';
import { PanelViews } from 'stores/CreativeTypes';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  align-items: center;
  display: flex;
  font-size: 20px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 3px;

  &.selected {
    background: #a8a8a8;
    border-color: #989696;
    color: white;
  }
`;

const Label = styled.span`
  font-size: 14px;
  margin-bottom: 2px;
`;

type BaseDrawerToolbarButtonProps = {
  disabled?: boolean;
  label: string;
  panelType: PanelViews;
}

export type DrawerToolbarButtonProps = BaseDrawerToolbarButtonProps
  & (
    {
      iconType: string;
    }
    | {
      iconComponent: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
    }
  );

class DrawerToolbarButton extends React.Component<DrawerToolbarButtonProps>{
  constructor(props: DrawerToolbarButtonProps) {
    super(props);
  }

  openDrawer = () => {
    console.log('openDrawer', this.props.panelType);
    const { panelType } = this.props;
    store.creative.setPanel(panelType, true);
  }

  render() {
    const { canModify, displayPanel } = store.creative;
    const { disabled, label, panelType } = this.props;
    const { openDrawer } = this;

    const isDrawerOpen = displayPanel === panelType;

    if (!canModify) {
      return null;
    }

    return (
      <Button
        className={isDrawerOpen ? 'selected' : ''}
        disabled={disabled}
        onClick={openDrawer}
      >
        {'iconType' in this.props && <Icon type={this.props.iconType} />}
        {'iconComponent' in this.props && <Icon component={this.props.iconComponent} />}
        <Label>{label}</Label>
      </Button>
    );
  }
};

export default observer(DrawerToolbarButton);
