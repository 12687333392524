import { Popconfirm } from 'antd';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores';
import Template from 'stores/Template';

export interface RemoveTemplateButtonProps {
  template: Template;
}

class RemoveTemplateButton extends React.Component<RemoveTemplateButtonProps>{
  constructor(props: RemoveTemplateButtonProps) {
    super(props);
  }

  confirmRemove = () => {
    const { template } = this.props;
    template.setStatusPending();
    store.creative.templates.remove(template.id);
  }

  render() {
    const { confirmRemove } = this;

    const { template } = this.props;
    const { loading } = template;

    return (
      <Popconfirm
        cancelText="No"
        okText="Yes"
        onConfirm={confirmRemove}
        placement="bottomRight"
        title="Are you sure you want to remove this template?"
      >
        <DrawerButton
          disabled={loading}
        >
          Remove
        </DrawerButton>
      </Popconfirm>
    );
  }
};

export default RemoveTemplateButton;
