import { observer } from 'mobx-react';
import { Popconfirm } from 'antd';
import FiltersToolbarButton from './FiltersToolbarButton';
import React from 'react';
import store from 'stores';

export interface ClearAllFiltersButtonProps { };

class ClearAllFiltersButton extends React.Component<ClearAllFiltersButtonProps>{
  constructor(props: ClearAllFiltersButtonProps) {
    super(props);
  }

  clearAllFilters = () => {
    store.creative.ads.filter.resetToCustomSet();
  }

  render() {
    const { clearAllFilters } = this;

    return (
      <Popconfirm
        cancelText="No"
        okText="Yes"
        onConfirm={clearAllFilters}
        title="Clear all filters?"
      >
        <FiltersToolbarButton
          label='Clear All'
        />
      </Popconfirm>
    );
  }
};

export default observer(ClearAllFiltersButton);
