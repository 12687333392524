import { DynamicPropertyData, IDynamicProperty } from './DynamicProperties/Types';
import { DynamicPropertyType } from './DynamicProperties/Types/DynamicPropertyType';
import DynamicGroup, { DynamicGroupEvent, DynamicGroupStatus } from './DynamicProperties/Types/Group';
import Ad from './Ad';
import Store from './Store';
import store from './index';
import Creative from './Creative';
import { DynamicProperty } from './DynamicProperties/Types/Property';
import { deepCopy } from 'utils';

export const FILTER_MANAGER_DEFAULT_SET_NAME = 'customSet';

export default class FilterManager extends Store {
  current = new DynamicGroup(null, 'clipboard', []);
  parent: Creative;

  get valuesCopied() {
    return this.current.properties.length > 0;
  }

  constructor(parent: Creative, data?: DynamicPropertyData[]) {
    super();
    this.parent = parent;
    this.init();
  }

  copy = (property: IDynamicProperty<any>) => {
    this.current.reset();
    if(property instanceof DynamicGroup) {
      this.current.addGroup(property.name, property.properties);
    } else {
      this.current.addProperty(property);
    }
  }

  paste = (targetProperty: IDynamicProperty<any>) => {
    let properties = this.current.properties;
    if (!properties || properties.length === 0) return;
    if (properties.length === 1) {
      let copiedProperty = this.current.properties[0];

      // If you've copied a group and are pasting to a group...
      if (targetProperty instanceof DynamicGroup && copiedProperty instanceof DynamicGroup) {
        targetProperty.merge(copiedProperty.properties, true, false);
      }
      
      // If you've copied a single property and are pasting to a group...
      else if(targetProperty instanceof DynamicGroup && copiedProperty instanceof DynamicProperty) {
        console.log('Pasting a single property to a group');
        if (targetProperty.get(copiedProperty.name)) {
          console.log('attempting...');
          targetProperty.get(copiedProperty.name)?.set(copiedProperty.value);
        }
      }

      // If you've copied a single property and are pasting to a single property...
      else if (targetProperty instanceof DynamicProperty && copiedProperty instanceof DynamicProperty) {
        if (
          targetProperty.type === copiedProperty.type /* && */
          /* targetProperty.name === copiedProperty.name */
        ) {
          targetProperty.set(copiedProperty.value);
        } else {
          throw new Error(`Cannot paste a property of type "${copiedProperty.type}" to a property of type "${targetProperty.type}".`);
        }
      }
    }
    
    // If you've copied multiple different properties and are pasting to a group...
    else {
      if(targetProperty instanceof DynamicGroup) {
        targetProperty.merge(this.current.properties, true, false);
      } else {
        throw new Error(`Cannot paste multiple properties to a single property.`);
      }
    }
  }
}