import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { AutoComplete, Input, Button, Icon, Select, Divider, Switch, Checkbox } from "antd";
import { IDynamicProperty } from "../../../../../stores/DynamicProperties/Types";
import { DynamicPropertyType } from '../../../../../stores/DynamicProperties/Types/DynamicPropertyType';

const Container = styled.div<{
  changed: boolean,
}>`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  width: 100%;
  ${({ changed }) => changed && `
  > div {
    background: #e1f3ff;
  }`}
`;

const SwitchContainer = styled.div`
  border: 1px solid #d6d6d6;
  border-radius: 0px 4px 4px 0px;
  border-width: 1px 1px 1px 0;
  padding: 0 6px;
  background: #ffffff;
  height: 32px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BooleanSwitch = styled(Switch)`
  width: auto;
`;

type SwitchInputProps = {
  property: IDynamicProperty<any>;
  style?: React.CSSProperties | undefined;
  disabled: boolean;
  editMode?: boolean;
}

class SwitchInput extends React.Component<SwitchInputProps> {
  property: IDynamicProperty<any>;
  newPropertyName = '';
  newPropertyType: DynamicPropertyType = DynamicPropertyType.NONE;

  constructor(props: SwitchInputProps) {
    super(props);
    this.property = props.property;
        this.property.subscribe(this);
  }

  handleChange = async (value: boolean, skipUpdate?:boolean) => {
    this.property.set(value);
  };

  render() {
    let property = this.property;
    let name = property.name;

    return (
      <Container key={name} changed={property.getChanged()} style={this.props.style}>
        <SwitchContainer style={{ borderWidth: '1px', borderRadius: '4px' }}>
          <BooleanSwitch
            style={{ width: "100%" }}
            checked={this.property.value}
            onChange={(e) => this.handleChange(e)}
            // placeholder="Switch an asset"
            disabled={this.props.disabled}
          >
          </BooleanSwitch>
        </SwitchContainer>
      </Container>
    );
  }
}

export default observer(SwitchInput);
