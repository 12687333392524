import { DynamicProperty, DynamicPropertyEvent } from './Property';
import { IDynamicProperty } from './index';
import { DynamicPropertyType } from './DynamicPropertyType';



export default class Number extends DynamicProperty<number> {
  type = DynamicPropertyType.NUMBER;
  value: number = 0;
  default = () => {
    this.value = 0;
    this.initialValue = 0;
    return 0;
  };

  constructor(parent: IDynamicProperty<any>, name: string, value: number) {
    // console.log('NUMBER CONSTRUCTOR', name, value);
    if(value && typeof value === 'string' && isNaN(value) === false) {
      value = parseFloat(value);
      // console.log('NUMBEr VALUE', value);
    }
    super(parent, name, value);
  }

  setDefault = () => {
    this.setInitialValue(10);
    console.log('SETTING DEFAULT', this.name, this.type, this.value);
  }

  transform = (value: any) => {
    if (typeof value === 'number') {
      return value;
    } else if (typeof value === 'string') {
      return parseFloat(value);
    }
  };

  toNumber() {
    return this.value;
  }

  getString = () => {
    return this.value + '';
  };

  getPreviewData = () => {
    return this.value;
  };

  getObject = () => {
    return this.getString();
  };

  getCSVData = (): number => {
    return this.value;
  };

  setUsingCSV = (value: string) => {
    let newValue = parseFloat(value);
    if (isNaN(newValue)) {
      this.throwCSVImportError(`Invalid number. Value: ${value}`);
    } else {
      this.set(newValue);
    }
  };
}
