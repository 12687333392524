import styled from 'styled-components';

export const Button = styled.button`
    padding:0;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25), 0 3px 5px 0 rgba(0,0,0,.0);
    box-sizing: border-box;
    transition: background-color .218s,border-color .218s,box-shadow .218s;
    background-color: #fff;
    background-image: none;
    color: #262626;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    background-color: #4285f4;
    border: none;
    color: #fff;
    height: 50px;
    width: 240px;
    display: flex;
    border: 1px solid #4285f4;

    &:hover {
      background-color: #4c8fff;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.0), 0 3px 5px 0 rgba(0,0,0,.25);
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border: 1px transparent;
      height: 50px;
      width: 50px;
    }

    .text {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      font-family: Roboto,arial,sans-serif;
      font-weight: 500;
      letter-spacing: .21px;
      font-size: 16px;
      line-height: 48px;
    }
`;
