import { AdHubLogo } from 'components/UI';
import { LogoContainer, Nav } from './NavSidebar.styles';
import { Menu, Icon } from 'antd';
import { observer } from 'mobx-react';
import { UserRoles } from 'stores/UserTypes';
import React from 'react';
import store from 'stores';

export interface NavSidebarProps {
  className?: string;
}

class NavSidebar extends React.Component<NavSidebarProps> {

  constructor(props: NavSidebarProps) {
    super(props);
  }

  render() {
    const AdminMenuItem = (
      <Menu.Item key='/admin' onClick={() => { store.router.goTo(store.routes.admin) }}>
        <Icon type='lock' />
        <span>Admin</span>
      </Menu.Item>
    );

    return (
      <Nav
        className={this.props.className}
        collapsed={store.ui.collapsed}
        collapsible
        trigger={null}
      >
        <LogoContainer className={store.ui.collapsed ? 'collapsed logo' : 'logo'}>
          <AdHubLogo collapsed={store.ui.collapsed} />
        </LogoContainer>
        <Menu selectable={false} theme='dark' mode='inline' selectedKeys={[store.router.currentPath]}>
          <Menu.Item key='/dashboard' disabled={!store.user.data.activated} onClick={() => { store.router.goTo(store.routes.dashboard) }}>
            <Icon type='home' />
            <span>Dashboard</span>
          </Menu.Item>
          <Menu.Item key='/clients' disabled={!store.user.data.activated} onClick={() => { store.router.goTo(store.routes.clients) }}>
            <Icon type='solution' />
            <span>Clients</span>
          </Menu.Item>
          {store.user.role === UserRoles.ADMIN && AdminMenuItem}
        </Menu>

      </Nav>
    );
  }
}

export default observer(NavSidebar);