import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import Bundle from 'stores/Bundle';
import BundleReportModal from './BundleReportModal';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';

export interface BundleReportButtonProps {
  bundle: Bundle;
}

class BundleReportButton extends React.Component<BundleReportButtonProps>{
  isModalVisible: boolean = false;

  constructor(props: BundleReportButtonProps) {
    super(props);

    makeObservable(this, {
      isModalVisible: observable,
    });
  }

  showModal = () => {
    if(this.props.bundle.elements.mounted === false) this.props.bundle.elements.load();
    this.isModalVisible = true;
  }

  hideModal = () => {
    this.isModalVisible = false;
  }

  render() {
    const { bundle } = this.props;
    const { hideModal, showModal } = this;
    const { isModalVisible } = this;

    return (
      <>
        <DrawerButton 
          disabled={bundle.loading}
          onClick={showModal}
        >
          Report
        </DrawerButton>

        <BundleReportModal
          bundle={bundle}
          isVisible={isModalVisible}
          onClose={hideModal}
        />
      </>
    );
  }
};

export default observer(BundleReportButton);
