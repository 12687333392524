import { observer } from 'mobx-react';
import { PanelViews } from 'stores/CreativeTypes';
import DrawerToolbarButton from './DrawerToolbarButton';
import React from 'react';
import store from 'stores';

export interface EditButtonProps { }

class EditButton extends React.Component<EditButtonProps>{
  constructor(props: EditButtonProps) {
    super(props);
  }

  render() {
    const disabled = store.creative.mounted === false;

    return (
      <DrawerToolbarButton
        disabled={disabled}
        panelType={PanelViews.AD}
        label="Properties"
        iconType="form"
      />
    );
  }
};

export default observer(EditButton);
