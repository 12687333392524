import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;
`;

export const PrincipalChunk = styled.span``;

export const SecondaryChunk = styled.span`
  color: #ccc;
`;

export const Separator = styled(SecondaryChunk).attrs({
  children: '/'
})``;
