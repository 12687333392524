import { observer } from 'mobx-react';
import { QueryParams, Route } from 'mobx-router';
import React from 'react';
import store, { RootStore } from 'stores';

export interface RouteLinkProps<P extends QueryParams, QP extends QueryParams = QueryParams> {
  children: React.ReactNode;
  params?: P;
  queryParams?: QP;
  route: Route<RootStore, P, QP>;
}

class RouteLink<P extends QueryParams, QP extends QueryParams = QueryParams> extends React.Component<RouteLinkProps<P, QP>>{
  constructor(props: RouteLinkProps<P, QP>) {
    super(props);
  }

  handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    const {
      params,
      queryParams,
      route,
    } = this.props;

    const isMiddleButtonClick = e.button === 1;
    const isCmdOrCtrlKey = e.metaKey || e.ctrlKey;

    const shouldOpenInNewTab = isMiddleButtonClick || isCmdOrCtrlKey;
    const shouldNavigateManually = shouldOpenInNewTab || isCmdOrCtrlKey;

    if (!shouldNavigateManually) {
      e.preventDefault();
      store.router.goTo(route, params, queryParams);
    }
  }

  render() {
    const {
      children,
      params,
      queryParams,
      route,
    } = this.props;

    const { handleOnClick } = this;

    return (
      <a
        href={route.replaceUrlParams(params, queryParams)}
        onClick={handleOnClick}
      >
        {children}
      </a>
    );
  }
};

export default observer(RouteLink);
