import { Button } from 'antd';
import FiltersButton from './FiltersButton';
import FiltersSetsDropdown from 'components/Views/Creative/Drawers/FiltersDrawer/FiltersSetsDropdown';
import React from 'react';
import styled from 'styled-components';

const Container = styled(Button.Group)`
  display: flex;
`;

export interface FiltersGroupProps { }

class FiltersGroup extends React.Component<FiltersGroupProps>{
  constructor(props: FiltersGroupProps) {
    super(props);
  }

  render() {
    return (
      <Container>
        <FiltersSetsDropdown />
        <FiltersButton />
      </Container>
    );
  }
};

export default FiltersGroup;
