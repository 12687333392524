import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Menu, Icon, Button } from 'antd';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import { AntDesignIconName } from 'stores/Types';
import { AnimationPanelViews } from 'stores/Animation';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    margin-left: 5px;
    gap: 5px;
`;

function createIconDict<T extends Record<string, AntDesignIconName>>(obj: T): T {
    return obj;
}


export interface NavButtonsProps { }

class NavButtons extends React.Component<NavButtonsProps> {

    constructor(props: NavButtonsProps) {
        super(props);
        makeObservable(this, {
        });
    }

    componentWillUnmount() {
    }

    render() {
        var icons = createIconDict({
            versions: 'file-done',
            comments: 'message',
            export: 'export',
            canvas: 'picture',
        });

        return (
            <Container>
                <Button icon={icons.versions} onClick={() => store.animation.togglePanel(AnimationPanelViews.VERSIONS)}>Versions</Button>
                <Button icon={icons.comments} onClick={() => store.animation.togglePanel(AnimationPanelViews.COMMENTS)}>Comments</Button>
                <Button icon={icons.export} onClick={() => store.animation.togglePanel(AnimationPanelViews.EXPORT)}>Export</Button>
                <Button icon={icons.canvas} onClick={() => store.animation.togglePanel(AnimationPanelViews.CANVAS)}>Canvas</Button>
            </Container>
        );
    }
}

export default observer(NavButtons);
