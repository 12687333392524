import { Route } from 'mobx-router';
import { UserStatus } from 'stores/UserTypes';
import routes from 'stores/Routes';
import store, { RootStore } from 'stores';

export const validateAuth = async (doRedirect: boolean = true) => {
  if (store.user.authStatus === UserStatus.LOGGED_IN) {
    return;
  }

  if (store.user.authenticated === false && doRedirect) {
    redirect(routes.notAuthenticated, {});
    return;
  }

  throw new Error('Unauthorized');
}

export const redirect = (route: Route<any, any>, params: any = {}, routeStore?: RootStore, queryParams: any = {}) => {
  const pushState = window.history.pushState;
  window.history.pushState = window.history.replaceState;

  if (routeStore) {
    routeStore.router.goTo(route, params, queryParams);
  }
  else {
    store.router.goTo(route, params, queryParams)
  }

  window.history.pushState = pushState;
}

export const hideBreadcrumbs = () => {
  store.ui.hideBreadcrumbs();
}

export const showBreadcrumbs = () => {
  store.ui.showBreadcrumbs();
}

type RouteOnEnterInput = {
  disableBreadcrumbs?: boolean;
  redirectIfNoAuth?: boolean;
  requiresAuth?: boolean;
}

export const handleRouteOnEnter = async ({
  disableBreadcrumbs = false,
  redirectIfNoAuth = true,
  requiresAuth = true,
}: RouteOnEnterInput) => {
  if (requiresAuth) {
    await validateAuth(redirectIfNoAuth);
  }

  if (disableBreadcrumbs) {
    hideBreadcrumbs();
  }

  if (!disableBreadcrumbs) {
    showBreadcrumbs();
  }
}

// @ts-expect-error
window.redirect = redirect;