import { observer } from 'mobx-react';
import Asset from 'stores/Asset';
import FileRowButtons from './FileRowButtons';
import AssetTypeIcon from './VersionTypeIcon';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { AssetFileType } from 'stores/Asset/Types';
import { AntDesignIconName } from 'stores/Types';
import store from 'stores/index';
import { Icon } from 'antd';

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 9px 10px;
  font-size: 14px;

  &:last-child {
    border-bottom: none;
  }
`;

const FileSizeLabel = styled.span``;

const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  gap: 9px;
`;

const VersionIcon = styled(Icon)`
  width: 24px;
`;

const VersionIconContainer = styled.div`
  width: 24px;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

type LabelProps = { isLoading: boolean; }
const Label = styled.span<LabelProps>`
  font-weight: bold;
  opacity: ${({ isLoading }) => isLoading ? 0.5 : 1};
`;

export interface VersionFileRowProps {
  asset: Asset;
}

class VersionFileRow extends React.Component<VersionFileRowProps>{
  constructor(props: VersionFileRowProps) {
    super(props);
  }

  render() {
    const { asset } = this.props;
    const { type } = asset.data;
    const { loading: isLoading, fileSizeString } = asset;
    let iconType: AntDesignIconName | string = 'loading';
    const selected = asset.id === store.animation.currentAnimationAsset?.id;

    if(isLoading) {
      iconType = 'loading';
    } else if( selected ) {
      iconType = 'check';
    } else {
      iconType = ''
    }

    return (
      <Container>
        <InfoContainer>
          <VersionIconContainer>
            { iconType !== '' && <VersionIcon type={iconType} /> }
          </VersionIconContainer>
          <Label isLoading={isLoading}>
            {moment(Number(asset.data.dateModified.seconds) * 1000).format('MMMM Do YYYY, h:mm a')}
          </Label>
          <FileSizeLabel>{fileSizeString}</FileSizeLabel>
        </InfoContainer>

        <ActionsContainer>
          {/* {type === AssetFileType.FOLDER && (
            <FolderRowButtons asset={asset} />
          )} */}
          {type === AssetFileType.FILE && (
            <FileRowButtons selected={selected} asset={asset} />
          )}
        </ActionsContainer>
      </Container>

    );
  }
};

export default observer(VersionFileRow);
