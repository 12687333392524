import { DynamicProperty } from './Property';
import { getUnit } from './utils';
import { IDynamicProperty } from './index';
import { DynamicPropertyType } from './DynamicPropertyType';


export default class Points extends DynamicProperty<number> {
  type = DynamicPropertyType.POINTS;
  value = 0;
  default = () => 0;
  unit = 'pt';

  constructor(parent: IDynamicProperty<any>, name: string, value: number) {
    if(value && typeof value === 'string' && isNaN(value) === false) {
      value = parseFloat(value);
    }
    super(parent, name, value);
    // this.init();
  }

  setDefault = () => {
    this.setInitialValue(0);
  }

  getString = () => {
    if(!this.value && this.value !== 0) return '0' + this.unit;
    return this.value + this.unit;
  };

  getPreviewData = () => {
    return this.getString();
  };

  getObject = () => {
    return this.getString();
  };

  toNumber() {
    return this.value;
  }

  setUsingCSV = (value: string) => {
    let newValue = getUnit(value);
    if (newValue && newValue.type === this.unit) {
      this.set(newValue.value);
    } else {
      this.throwCSVImportError(`Invalid number. Value: ${value}`);
    }
  };
}
