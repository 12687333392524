import styled from 'styled-components';
import React from 'react';
import { Button } from 'antd';
import { IDynamicProperty } from '../../../../stores/DynamicProperties/Types';
import store from '../../../../stores';
import DynamicGroup from '../../../../stores/DynamicProperties/Types/Group';
import message from 'antd/lib/message';

// react commponent PropertyPopover class
const PopoverButton = styled(Button)`
    width: 100%;
    margin-top: 5px;

    &:first-child {
        margin-top: 0;
    };
`;

const RemoveButton = styled(PopoverButton)`
    width: 100%;
`;

type PropertyPopoverProps = {
    property: IDynamicProperty<any>;
    editMode: boolean;
    onClick?: Function;
    // onCopy: Function;
    // onPaste: Function;
    // onRemove: Function;
    // onRevert: Function;
}

export default class PropertyPopoverContent extends React.Component<PropertyPopoverProps> {

    constructor(props: PropertyPopoverProps) {
        super(props);
    }

    handleCopy = () => {
        this.props.onClick && this.props.onClick();
        try {
            store.creative.clipboard.copy(this.props.property);
        } catch (e: any) {
            message.error(e.message);
        }
        message.success(<>{'Property '}<b>{this.props.property.name}</b>{' successfully copied to clipboard'}</>);
    };

    handleRemove = () => {
        this.props.onClick && this.props.onClick();
        try {
            let property = this.props.property;
            if(property && property.parent instanceof DynamicGroup) {
                property.parent.remove(this.props.property.name);
            }
        } catch(e: any) {
            message.error(e.message);
        }
    }

    handlePaste = () => {
        this.props.onClick && this.props.onClick();
        try {
            store.creative.clipboard.paste(this.props.property);
        } catch (e: any) {
            message.error(e.message);
        }
    }

    handleRevert = () => {
        this.props.onClick && this.props.onClick();
        this.props.property.revert();
    }

    render() {
        let {
            // onRemove,
            // onCopy,
            // onPaste,
            // onRevert,
            property,
            editMode,
        } = this.props;

        // if(!onRemove) onRemove = this.handleRemove;
        // if(!onCopy) onCopy = this.handleCopy;
        // if(!onPaste) onPaste = this.handlePaste;

        return (
            <>
                <PopoverButton size='small' onClick={(e) => this.handleCopy()}>Copy</PopoverButton>
                <PopoverButton size='small' disabled={!store.creative.clipboard.valuesCopied} onClick={(e) => this.handlePaste()}>Paste</PopoverButton>
                <PopoverButton size='small' disabled={property.getChanged() === false} onClick={(e) => this.handleRevert()}>Revert</PopoverButton>
                {editMode ? <RemoveButton size='small' type='danger' onClick={() => this.handleRemove() }>{'Remove'}</RemoveButton> : <span></span>}
            </>
        );
    }
}