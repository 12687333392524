import { DynamicProperty } from './Property';
import { IDynamicProperty } from './index';
import { DynamicPropertyType } from './DynamicPropertyType';


export default class Text extends DynamicProperty<string> {
  type = DynamicPropertyType.TEXT;
  default = () => '';

  constructor(parent: IDynamicProperty<any>, name: string, value: string) {
    super(parent, name, value);
    // this.init();
  }

  setDefault = () => {
    this.setInitialValue('Lorem Ipsum');
  }

  setUsingCSV = (value: string) => {
    this.set(value);
  };
}
