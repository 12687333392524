import React from 'react';
import store from 'stores';
import { observer } from 'mobx-react';
import { IReactionDisposer, makeObservable, observable, reaction, when } from 'mobx';
import styled from 'styled-components';
import { urlJoin } from 'url-join-ts';
import Creative from 'stores/Creative';
import Ad, { AdEvents } from 'stores/Ad';
import { UploadStates } from 'stores/Assets';
import { Button, Icon, Spin } from 'antd';
import { deepCopy, deepObjectCompare, delay, objectsEqual, useDebounce } from 'utils';
import Template from 'stores/Template';
import { DynamicPropertyEvent } from 'stores/DynamicProperties/Types/Property';
import { LooseObject } from 'stores/Types';
import { AdsManagerEvents } from 'stores/AdsManager/AdsManager';
import UploadTemplateModal from '../Drawers/TemplatesDrawer/UploadTemplateModal';
import TemplateProperty from 'stores/DynamicProperties/Types/Template';
import { PanelViews } from 'stores/CreativeTypes';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: realtive;
`;

const IconLarge = styled(Icon)`
  font-size: 135px;
  margin-bottom: -20px;
  color: #dddee0;
`;

const AdContainer = styled.div`
  overflow: auto;
  height: calc(100% - 50px);
  width: 100%;

  #adview {
    transform-origin: 0 0;
  }
`;

const EmptyPlaceholder = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  flex-flow: column;
  line-height: 40px;
`;

interface Props {
}

class AdViewFrame extends React.Component<Props> {
  dom = null;
  ad: Ad | undefined;
  container: HTMLDivElement | null;
  creative: Creative;
  currentAdFrame:HTMLIFrameElement;
  reactionDisposer = null;
  adLoading = true;
  timeoutInstance: NodeJS.Timeout;
  modifiedInterval: NodeJS.Timeout;
  reloadWaitTime = 250;
  lastModified: number | null = null;
  sameCount = 0;
  reloadCurrent = false;
  templateUploading = false;
  previousData: LooseObject;
  adSwitchedDisposer: IReactionDisposer;
  adModifiedDisposer: IReactionDisposer;
  uploadStateDisposer: IReactionDisposer;
  isUploadTemplateModalVisible = false;
  
  ignoredTemplateProperties = ['label', 'backupImageFilesize', 'backupImageScale', 'bundleFilesize'];

  constructor(props: Props) {
    super(props);
    this.creative = store.creative;
    this.ad = store.creative.ads.current;

    makeObservable(this, {
      adLoading: observable,
      isUploadTemplateModalVisible: observable,
    });

    window.addEventListener('message', this.sendDynamicData);

    this.uploadStateDisposer = reaction(() => store.creative.assets.uploadState, (current, reaction) => {
      let uploadState = current;
      let assets = store.creative.assets;
      let currentAd = store.creative.ads.current;
      if (uploadState === UploadStates.UPLOAD_COMPLETE) {
        if (currentAd?.properties.getByValue('Asset', assets.newest.id)) this.renderAdFrame();
      }
    });

    when (() => store.creative.mounted === true, () => {
      // console.log('AdViewFrame: creative mounted');
      store.creative.ads.addEventListener(AdsManagerEvents.ACTIVATED, this.onActivated);
    });
  }

  componentDidMount() {
    this.renderAdFrame();
    // console.log('AdViewFrame: mounted');
  }

  componentWillUnmount() {
    // console.log('AdViewFrame: unmounting');
    window.removeEventListener('message', this.sendDynamicData);
    store.creative.ads.removeEventListener(AdsManagerEvents.ACTIVATED, this.onActivated);
    this.uploadStateDisposer();
  }

  componentWillUpdate() {
    
  }

  onActivated = async (ad: Ad) => {
    // console.log('AdViewFrame: onActivated', this.ad?.properties.getPreviewData());
    this.previousData = deepCopy(this.ad?.properties.getPreviewData());
    this.ad?.removeEventListener(AdEvents.RELOAD, this.handleReload);
    this.ad?.removeEventListener(AdEvents.CHANGED, this.handleChange);
    this.ad = ad;
    this.renderAdFrame();

    this.ad?.addEventListener(AdEvents.RELOAD, this.handleReload);
    this.ad?.addEventListener(AdEvents.CHANGED, this.handleChange);
  };

  renderAdFrame = async (forceRender = false) => {
    // console.log('AdViewFrame: RENDER');
    if(this.checkHasChanged() === false && forceRender === false) return;
    if (!store.creative.ads.current) return;
    // console.log('AdViewFrame: RENDERING');
    if (!this.container) return; // renderAdFrame has been called before component has been rendered properly.
    // TODO: improve adframe so it exists as an independent component.
    this.adLoading = true;
    if (this.currentAdFrame) this.currentAdFrame.style.display = 'none';
    // if(this.ad.changed === false) await this.ad.reload();
    let currentAd = store.creative.ads.current;
    let dimensions = currentAd.properties.get('dimensions');
    let templateProp = currentAd.properties.get('template') as TemplateProperty;
    let template = templateProp?.store;
    let iframe = document.createElement('iframe');
    this.currentAdFrame = iframe;
    let numTemplates = store.creative.templates.all.length;
    let templateAvailable = template?.id ? true : false;
    let cacheBusterString = '?session=' + new Date().getTime();
    let previewModeString = '&isAdHubPreview=true';
    let width = dimensions?.get('width')?.value;
    let height = dimensions?.get('height')?.value;
    let frameName = 'adframe' + currentAd.id;

    iframe.style.display = 'none';
    iframe.name = 'adframe';
    iframe.src = numTemplates && templateAvailable ? template?.getHostedURL() + cacheBusterString + previewModeString : '';
    iframe.style.display = 'block';
    iframe.style.width = width + 'px';
    iframe.style.height = height + 'px';
    iframe.style.border = '0';
    iframe.id = frameName;
    iframe.onload = () => {
      this.adLoading = false;
    }

    if(this.container) {
      this.container.innerHTML = '';
      this.container.appendChild(iframe);
    }
  }

  handleReload = () => {
    this.adLoading = true;
    if(this.currentAdFrame) this.currentAdFrame.style.display = 'none';
    this.renderAdFrame(true);
  }

  checkHasChanged = () => {
    // console.log('AdViewFrame: checkHasChanged');
    let newData = deepCopy(this.ad?.properties.getPreviewData());
    // recursively find and remove key "__type" with value "svgData"
    let removeSvgData = (obj: any) => {
      for (var propName in obj) {
        if (obj[propName] instanceof Object) {
          if (obj[propName].__type === 'svgData') {
            delete obj[propName].__type;
            delete obj[propName].__value;
          } else {
            removeSvgData(obj[propName]);
          }
        }
      }
    }

    removeSvgData(newData);

    let hasChanged = deepObjectCompare(this.previousData, newData) === false;
    this.previousData = deepCopy(newData);

    return hasChanged;
  }

  handleChange = useDebounce( () => {
    // console.log('AdViewFrame: handleChange');
    this.renderAdFrame();
  }, 500);

  sendDynamicData = (e: MessageEvent) => {
    if (e.data === 'requestDynamicData') {
      if (!this.ad) return;
      this.currentAdFrame?.contentWindow?.postMessage(this.ad.properties.getPreviewData(), '*');
    }
  }

  checkUrlExists = (url: string, callback: Function) => {
    return new Promise(function (resolve, reject) {
      var xobj = new XMLHttpRequest();
      xobj.open('GET', url, true); // Replace 'my_data' with the path to your file
      xobj.onreadystatechange = function () {
        if (xobj.status == 0) return;
        if (xobj.status == 404) {
          // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
          resolve(false);
        } else if (xobj.status == 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      xobj.send(null);
    });
  }

  renderEmptyPlaceholder = (templates: Template[], templatesProcessing: Template[], templateMissing: boolean, ads: Ad[], fileDragging: boolean) => {
    let numTemplates = templates.length;
    let noTemplates = numTemplates === 0;
    let areTemplatesProcessing = numTemplates === 1 && templatesProcessing.length > 0;
    let noAvailableAds = ads.length === 0;

    if(store.creative.mounted === false) {
      return <EmptyPlaceholder><Spin /></EmptyPlaceholder>
    }

    if (fileDragging && noTemplates) {
      return <EmptyPlaceholder>
        <IconLarge type="arrow-right" />
        Drop your template onto the templates panel.
      </EmptyPlaceholder>
    }

    if (noTemplates) {
      return <EmptyPlaceholder>
        <IconLarge type="login" style={{ transform: 'rotate(90deg)' }} />
        <br />
        Drag your template ZIP file here.
        <br />
        <Button disabled={this.templateUploading} loading={this.templateUploading} onClick={() => { this.showUploadTemplateModal() }}>Upload Template</Button>

      </EmptyPlaceholder>
    }
    if (areTemplatesProcessing) {
      return <EmptyPlaceholder><IconLarge type="exclamation-circle" /><br /><Button disabled={true} loading={true} onClick={() => this.showUploadTemplateModal() }>Upload Template</Button> to get started.</EmptyPlaceholder>
    }
    if (noAvailableAds) {
      return <EmptyPlaceholder><IconLarge type="plus-square" /><br />Click 'New' to create a new ad.</EmptyPlaceholder>
    }
    if (!store.creative.ads.current) {
      return <EmptyPlaceholder><IconLarge type="arrow-left" /><br />Select an ad on the left panel to get started.</EmptyPlaceholder>
    }
    if (templateMissing) {
      return <EmptyPlaceholder><IconLarge type="exclamation-circle" /><br />The current selected ad is missing a template. <br />Select one in the ad properties.</EmptyPlaceholder>
    }
  }

  showUploadTemplateModal = () => {
    store.creative.setPanel(PanelViews.TEMPLATES);
    store.creative.templates.uploadManager.show();
  }

  render() {
    var viewScale = store.creative.viewScale;
    var viewWidth = 1;
    var viewHeight = 1;
    let fileDragging = store.ui.fileDragging;
    let templates = [... store.creative.templates.all];
    let ads = [... store.creative.ads.all];
    let templatesProcessing = [... store.creative.templates.processing];
    let templateMissing = store.creative.ads.current ? store.creative.ads.current.properties.flattened.template === '' : false;

    if (store.creative.ads.current?.dimensions) {
      viewWidth = Number(store.creative.ads.current.dimensions.width) * viewScale;
      viewHeight = Number(store.creative.ads.current.dimensions.height) * viewScale;
    }

    let adContainerStyles: any = {};

    if (!store.creative.ads.current || templates.length === 0 || templateMissing) adContainerStyles.display = 'none';

    return (
      <Container {...this.props}>
        <AdContainer style={adContainerStyles}>
          <div id="adview"
            style={{
              transform: 'scale(' + viewScale + ')',
              transition: 'all 0.25s',
              width: viewWidth + 'px',
              height: viewHeight + 'px'
            }}
            ref={elem => this.container = elem}
            className="container"></div>
        </AdContainer>
        {this.renderEmptyPlaceholder(templates, templatesProcessing, templateMissing, ads, fileDragging)}
      </Container>
    );
  }
}

export default observer(AdViewFrame);
