import {
  buildColumn,
  TableColumnSortType,
  TableColumnFilterType,
} from 'components/UI/Table/Table/ColumnBuilder';
import { basicTextFilterer, basicTimestampDateRenderer } from 'components/UI/Table/Table/ColumnBuilder/ColumnBuilder.service';
import { observer } from 'mobx-react';
import { StarSwitch, Table, } from 'components/UI';
import { Tag } from 'antd';
import type DocumentFolder from 'stores/DocumentFolder';
import OptionsMenu from './OptionsMenu';
import React from 'react';
import { subscribe } from 'stores/SubscribeUtil';
import { camelCaseToTitle } from 'utils/String';
import { DocumentType } from 'stores/Types';
// import { DocumentEvents } from 'stores/DocumentStore';

export interface ChildrenTableProps<T> {
  actionButtons: JSX.Element | JSX.Element[];
  parentDocument: DocumentFolder;
  title?: string;
};

class ChildrenTable<T> extends React.Component<ChildrenTableProps<T>>{
  constructor(props: ChildrenTableProps<T>) {
    super(props);
  }

  getTableColumns() {
    const { parentDocument } = this.props;

    return [
      buildColumn<DocumentFolder>({
        render: (rowData) => <StarSwitch store={rowData} />,
        sorter: (a, b) => a.isStarred > b.isStarred ? 1 : -1,
        sortType: TableColumnSortType.Custom,
        title: 'Starred',
        width: 32,
      }),
      buildColumn<DocumentFolder>({
        filterer: (search, row) => basicTextFilterer(search, row.name),
        filterType: TableColumnFilterType.Search,
        sourceData: (rowData) => rowData.data.name,
        sortType: TableColumnSortType.Alphabetical,
        title: 'Name',
      }),
      buildColumn<DocumentFolder>({
        filterer: (activeSearchValue, rowData) => rowData.data.tags.includes(activeSearchValue),
        filterType: TableColumnFilterType.Options,
        render: (rowData) => (rowData.data.tags || []).map((tag: string) => <Tag key={`tag.${tag}`}>{tag}</Tag>),
        sortType: TableColumnSortType.NoSort,
        title: 'Tags',
        options: [...parentDocument.documentsTags].map((tag, i) => ({
          text: tag,
          value: tag,
        })),
      }),
      buildColumn<DocumentFolder>({
        filterer: (search, row) => basicTextFilterer(search, row.name),
        filterType: TableColumnFilterType.Search,
        // render the name with first letter capitalized
        render: (rowData) => {
          if(rowData.data.type === 'dynamic') return 'Creative'
          else return camelCaseToTitle( rowData.data.type) 
        },
        sourceData: (rowData) => {
          if(rowData.data.type === 'dynamic') return 'Creative'
          else return camelCaseToTitle( rowData.data.type) 
        },
        sortType: TableColumnSortType.Alphabetical,
        title: 'Type',
      }),
      buildColumn<DocumentFolder>({
        defaultSortOrder: 'descend',
        sourceData: (rowData) => rowData.data.dateModified,
        render: basicTimestampDateRenderer,
        sortType: TableColumnSortType.FirestampDate,
        title: 'Modified',
      }),
      buildColumn<DocumentFolder>({
        render: (rowData) => <OptionsMenu documentId={rowData.id} parentDocument={parentDocument} />,
        sortType: TableColumnSortType.NoSort,
        title: '',
        width: 50,
      }),
    ]
  };

  render() {
    const {
      actionButtons,
      parentDocument,
      title = "Children"
    } = this.props;

    const data = [...parentDocument.documents.allAvailable];
    const isLoading = parentDocument.documents.loading;

    const columns = this.getTableColumns();

    return (
      <Table
        columnsDefinition={columns}
        data={data}
        headerButtons={actionButtons}
        isLoading={isLoading}
        loadingLabel=''
        noDataLabel=''
        onRowClick={(record) => {
          try {
            parentDocument.documents.getById(record.id)?.navigateTo();
          } catch (e) {
            console.warn(e);
          }
        }}
        onRowMiddleClick={(record) => window.open(record.url, '_blank')}
        title={title}
      />
    );
  };
};

export default observer(ChildrenTable);
