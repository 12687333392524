



import { Icon, Select } from "antd";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import User from "../../../stores/User";
import { UserRoles } from "../../../stores/UserTypes";

const Dropdown = styled(Select)`
  width: 110px;
`;

const Container = styled.div`
  
`;

export interface RoleSelectProps {
  store: User;
}

class RoleSelect extends React.Component<RoleSelectProps>{
  constructor(props: RoleSelectProps) {
    super(props);
  }

  render() {
    const { store } = this.props;

    return (
      <Container>
        <Dropdown
          id={store.id}
          onSelect={(value: unknown) => { store.setRole(value as UserRoles) }}
          value={store.role}
        >
          <Select.Option value={UserRoles.ADMIN}>Admin</Select.Option>
          <Select.Option value={UserRoles.MANAGER}>Manager</Select.Option>
          <Select.Option value={UserRoles.DEFAULT}>Default</Select.Option>
        </Dropdown>
      </Container>
    );
  }
}

export default observer(RoleSelect);
