import { DocumentReference } from '@firebase/firestore-types';
import DocumentStore from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import firebase from 'firebase/app';
import { DocumentCollectionType, DocumentType } from './Types';
import TearsheetAdsManager from './TearsheetAdsManager';
import { documentPathToParams } from 'utils';
import root from './index';
import BreadcrumbManager from './BreadcrumbManager';
import { PanelViews } from './CreativeTypes';
import moment from 'moment';

export enum TearsheetViewMobileContentMode {
    LIST = 'list',
    VIEW = 'view',
};

export class TearsheetSchema extends DocumentSchema {
    author = "";
    generating = false;
    dateModified: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
    type = DocumentType.TEARSHEET;
}

export default class Tearsheet extends DocumentStore<TearsheetSchema> { // TODO: tearsheet class might be incomplete still
    schema = new TearsheetSchema();
    type = DocumentType.TEARSHEET;
    collectionType = DocumentCollectionType.TEARSHEET;
    displayPanel = PanelViews.NONE;

    ads = new TearsheetAdsManager(this);
    breadcrumbs: BreadcrumbManager = new BreadcrumbManager(this);
    generating = false;
    author = '';

    viewScale = 1;

    mobileContentMode: TearsheetViewMobileContentMode = TearsheetViewMobileContentMode.LIST;

    constructor(parent: any, docRef: DocumentReference, data?: TearsheetSchema) {
        super(parent, docRef, data);
        this.init();
    }

    get params() {
        return documentPathToParams<{ adID: string, creativeID: string, projectID: string, clientID: string, tearsheetID: string }>(this.docRef.path);;
    }

    get formattedDateModified() {
        return moment(this.data.dateModified.toDate()).format('MMMM D, YYYY, h:mm A');
    }

    navigateTo = () => {
        const { params } = this;
        root.router.goTo(root.routes.tearsheet, { ...params, adIndex: '1' });
    }

    onLoad = async (data: TearsheetSchema) => {
        await this.breadcrumbs.load();
        await this.ads.load();
    }

    setViewScale(scale: number) {
        this.viewScale = scale;
    }


    reset = () => {
        this.disposer.clear();

    }

    setMobileContentMode = (newContentMode: TearsheetViewMobileContentMode) => {
        this.mobileContentMode = newContentMode;
    }
}