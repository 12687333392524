import { Icon as BaseIcon } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import RemoveTemplateButton from './RemoveTemplateButton';
import styled from 'styled-components';
import Template from 'stores/Template';
import UpdateTemplateButton from './UpdateTemplateButton';
import DownloadButton from './DownloadButton';

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 7px;
`;

type MainLabelProps = {
  loading: boolean;
}

const MainLabel = styled.div<MainLabelProps>`
  font-size: 14px;
  font-weight: bold;
  opacity: ${({ loading }) => loading ? 0.5 : 1};
`;

const DateLabel = styled.div`
  font-size: 12px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  color: #666;
`;

const Icon = styled(BaseIcon)`
  align-self: center;
  padding-right: 8px;
`;

export interface TemplateSummaryRowProps {
  template: Template;
}

class TemplateSummaryRow extends React.Component<TemplateSummaryRowProps>{
  constructor(props: TemplateSummaryRowProps) {
    super(props);
  }

  render() {
    const { template } = this.props;
    const { name } = template.data;
    const { loading, downloadURL, formattedDateModified } = template;
    const showDownloadButton = downloadURL !== '';

    return (
      <Container>
        <MainLabel loading={loading}>{name}</MainLabel>

        <ButtonsContainer>
          {loading && <Icon type="loading" />}
          <DateLabel>{formattedDateModified}</DateLabel>
          {showDownloadButton && <DownloadButton template={template} />}
          <UpdateTemplateButton template={template} />
          <RemoveTemplateButton template={template} />
        </ButtonsContainer>
      </Container>
    );
  }
};

export default observer(TemplateSummaryRow);
