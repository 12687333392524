import root, { RootStore } from './index';
import { firestore, db, auth, googleProvider } from './Firebase';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
// import Starred from './Starred';
import { DocumentCollectionType, DocumentStatus, DocumentType, LooseObject, MemberRoles, } from './Types';
import { makeAutoObservable, reaction } from 'mobx';
import Store from './Store';
import DocumentStore from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import { delay, nullTimestamp } from '../utils';
import DocumentCollection from './DocumentCollection';
import StarredDocuments from './StarredDocuments';
import { FirebaseUser, StarredDocument, UserEvents, UserStatus, UserRoles } from './UserTypes';
import store from './index';

export class UserSchema extends DocumentSchema {
    activated = true;
    dateCreated: FirestoreTimestamp | FieldValue = nullTimestamp();
    dateModified: FirestoreTimestamp | FieldValue = nullTimestamp();
    department = '';
    displayName = '';
    email = '';
    firstName = '';
    lastName = '';
    phoneNumber = '';
    photoURL = '';
    role = UserRoles.DEFAULT;
    documentRole = MemberRoles.VIEW;
    uid = '';

    starredDocuments: StarredDocument[] = [];
    starredClients: LooseObject = {}
    starredCreatives: LooseObject = {}
    starredProjects: LooseObject = {}

    constructor(authData?: FirebaseUser | UserSchema) {
        super();
        if (!authData) return;
        const names = authData.displayName?.split(' ') || ['', ''];
        this.firstName = names[0];
        this.lastName = names[1];
        this.displayName = authData.displayName || '';
        this.email = authData.email || '';
        this.phoneNumber = authData.phoneNumber || '';
        this.photoURL = authData.photoURL || '';
        this.department = '';
        this.role = UserRoles.DEFAULT;
        this.uid = authData.uid;
        this.dateCreated = firestore.FieldValue.serverTimestamp();
        this.dateModified = firestore.FieldValue.serverTimestamp();
    }
}

export class UserSchemaPublic {
    department = "";
    displayName = "";
    email = "";
    firstName = "";
    lastName = "";
    photoURL = "";
    uid = "";

    constructor(authData?: FirebaseUser | UserSchemaPublic) {
        if (!authData) return;
        const names = authData.displayName?.split(' ') || ['', ''];
        this.firstName = names[0];
        this.lastName = names[1];
        this.displayName = authData.displayName || '';
        this.email = authData.email || '';
        this.photoURL = authData.photoURL || '';
        this.department = '';
        this.uid = authData.uid;
    }
}

export default class User extends DocumentStore<UserSchema, UserSchemaPublic> {
    schema = new UserSchema();
    publicSchema = new UserSchemaPublic();
    collection = db.collection('users');
    type = DocumentType.USER;
    collectionType = DocumentCollectionType.USER;

    placeholderName = '';
    connected = false;
    starredPaths: string[] = [];
    // starred = new Starred(this);
    get role(){ return this.data.role };
    
    authStatus: UserStatus = UserStatus.DISCONNECTED;
    error = '';
    starred = new StarredDocuments(this);
    
    starredDocuments:DocumentStore[] = [];

    get id() {
        return this.data.uid;
    }

    get authenticated() {
        return this.authStatus === UserStatus.LOGGED_IN;
    }

    constructor(parent: any, docRef?: DocumentReference, data?: UserSchema) {
        super(parent, docRef, data);
        this.init();
    }

    setError(error: string) {
        this.error = error;
    }

    get metadata() {
        if (this.authenticated) {
            return auth.currentUser?.metadata;
        } else {
            return null;
        }
    }

    onReset = () => {
        this.type = DocumentType.USER;
        this.collectionType = DocumentCollectionType.USER;
        this.placeholderName = '';
        this.connected = false;
        this.starredPaths = [];
        this.authStatus = UserStatus.DISCONNECTED;
        this.error = '';
        this.starred = new StarredDocuments(this);
        this.starredDocuments = [];
    }

    // get isEditor() {
    //     let members = root.current.members;
    //     let userMemberData = members.getById(this.id);
    //     if (!userMemberData) return false;
    //     if (userMemberData.role == MemberRoles.EDIT) return true;
    //     else return false;
    // }

    connect() { // Checks to see if user is logged in or not.
        return new Promise(async (resolve, reject) => {
            const onAuthStateChanged = async (authUser: FirebaseUser) => {
                this.status = DocumentStatus.LOADING;
                if (authUser) {
                    await this.loadUserData(authUser.uid);
                    if (this.exists === false) return await this.createUser(authUser);
                    this.status = DocumentStatus.LOADED;
                    this.authStatus = UserStatus.LOGGED_IN;
                } else {
                    this.reset();
                    this.status = DocumentStatus.LOADED;
                    this.authStatus = UserStatus.LOGGED_OUT;
                }
                console.log('User: Auth status:', this.authStatus);
                resolve(this.authStatus);
            };

            this.disposer.add(auth.onAuthStateChanged(onAuthStateChanged));
        });
    }

    onUpdate = (data: UserSchema) => {

    }

    getUserData = async (uid?: string) => {
        if (!uid && this.id) uid = this.id;
        else console.log('User: Error: Cannot check if a user exists. No ID provided.');
        return (await this.collection.doc(this.id).get()).exists;
    }

    login = async () => {
        if (this.authenticated === true) return;
        if (this.authenticated === false && this.authStatus !== UserStatus.LOGGED_IN) {

            try {
                let userCredential = await auth.signInWithPopup(googleProvider);
                let credential = userCredential.credential;
                let authUser = userCredential.user;

                if (!authUser) {
                    console.log('User: Error logging in.');
                    return false;
                }

                await this.loadUserData(authUser.uid);

                if (this.exists === false) { // TODO: Create user if it doesn't exist.

                }

                this.authStatus = UserStatus.LOGGED_IN;
                this.dispatch(UserEvents.LOGIN_SUCCESS, this.data);
                if(root.router.currentRoute === root.routes.notAuthenticated) {
                    root.router.goTo(root.routes.clients);
                }
            } catch (e) {
                console.log(e);
                this.dispatch(UserEvents.ERROR, this.data);
                this.authStatus = UserStatus.ERROR;
            }
        }
    }

    loadUserData = async (uid: string) => {
        let docRef = this.collection.doc(uid);
        let data = await this.load(root, docRef);
        return data;
    }

    createUser = async (authUser: FirebaseUser) => {
        this.data = new UserSchema(authUser);
        this.publicData = new UserSchemaPublic(authUser);
        await this.save();
    }

    onReady = async () => {
        // this.starred.setState(this.data.starredDocuments);
        // this.starred.load();
    }

    onUpdated = (data: UserSchema) => {
        this.starred.update(this.data.starredDocuments);
    };

    getPublicUserData = async (uid: string) => {
        let doc = await this.collection.doc(uid).collection('public').doc('info').get();
        return doc.data() as UserSchemaPublic;
    }

    setPlaceholderName = (name: string) => {
        this.placeholderName = name;
    }

    setRole = (role: UserRoles) => {
        this.data.role = role;
        this.save();
    }

    setActivated = (activated: boolean) => {
        this.data.activated = activated;
        this.save();
    }

    toggleActivated = () => {
        this.data.activated = !this.data.activated;
        this.save();
    }

    setFirstName = async (firstName: string) => {
        this.data.firstName = firstName;
        this.data.displayName = `${firstName} ${this.data.lastName}`;
        await this.save();
    }

    setLastName = async (lastName: string) => {
        this.data.lastName = lastName;
        this.data.displayName = `${this.data.firstName} ${lastName}`;
        await this.save();
    }

    setName = async (firstName: string, lastName: string) => {
        this.data.firstName = firstName;
        this.data.lastName = lastName;
        this.data.displayName = `${firstName} ${lastName}`;
        await this.save();
    }

    syncUserToGoogleAccount = () => {
        // TODO: re-sync to google account details. maybe chron job?
    }

    logout = () => {
        auth.signOut();
        this.reset();
        this.authenticated
        store.current.reset();
        store.setCurrent();
        this.dispatch(UserEvents.LOGGED_OUT);
    }

    checkUser = () => {
        if (auth.currentUser) {
            this.data = new UserSchema(auth.currentUser);
        }
    }

    // TODO: Add check to convert all starred documents to new data structure.

    getDocType = (path: string) => {
        var chunks = path.split('/');
        var client = chunks[0];
        var project = chunks[2];
        var creative = chunks[4];

        if (creative) return 'creative';
        if (project) return 'project';
        if (client) return 'client';
    }
};