import { Icon as BaseIcon } from 'antd';
import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

type ContainerProps = {
  bodyHeight: number;
  headerHeight: number;
};
const Container = styled.div<ContainerProps>`
    background: white;
    border-radius: 5px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    height: ${({ bodyHeight, headerHeight }) => bodyHeight + headerHeight}px;
    overflow: hidden;
    transition: height 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    width: 100%;

    &.collapsed{
      height: ${({ headerHeight }) => headerHeight}px;
    };
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

type HeaderContainerProps = {
  headerHeight: number;
};
const HeaderContainer = styled.div<HeaderContainerProps>`
  align-items: center;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  height: ${({ headerHeight }) => headerHeight}px;
  justify-content: space-between;
  padding: 14px;
  transition: all 0.1s;
  user-select: none;
`;

const TitleLabel = styled.span`
  color: #123;
  font-size: 18px;
  font-weight: bold;
`;

const Icon = styled(BaseIcon)`
  color: #123;
  font-size: 18px;
`;

export interface SimpleCollapseProps {
  bodyHeight?: number;
  children: React.ReactNode;
  className?: string;
  headerHeight?: number;
  onToggle: () => void;
  open: boolean;
  title: string | React.ReactNode;
  titleClassName?: string;
}

class SimpleCollapse extends React.Component<SimpleCollapseProps>{
  constructor(props: SimpleCollapseProps) {
    super(props);
  }

  render() {
    const {
      bodyHeight = 355,
      children,
      className,
      headerHeight = 45,
      onToggle,
      open,
      title,
    } = this.props;

    return (
      <Container
        className={cn(
          className, {
          'collapsed': !open,
        })}
        bodyHeight={bodyHeight}
        headerHeight={headerHeight}
      >
        <HeaderContainer
          headerHeight={headerHeight}
          onClick={onToggle}
        >
          <TitleLabel>{title}</TitleLabel>
          <Icon type={open ? 'down' : 'up'} />
        </HeaderContainer>

        <BodyContainer>
          {children}
        </BodyContainer>
      </Container>
    );
  }
};

export default SimpleCollapse;
