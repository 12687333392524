import TemplatesManager from './Templates';
import Store from './Store';
import { delay, getFilenameExtention } from '../utils';
import Template from './Template';
import { IReactionDisposer, reaction, when } from 'mobx';
import { DocumentEvents } from './DocumentStore';

export default class TemplateUploadManager extends Store {
    parent: TemplatesManager;
    modalVisible = false;
    template: Template | undefined = undefined;
    templateName = '';
    file: File | undefined = undefined;
    fileName = '';
    loading = false;
    isUpdate = false;
    replaceAssets = true;
    templateLoadingReactionDisposer : IReactionDisposer | undefined = undefined;

    constructor(parent: TemplatesManager) {
        super();
        this.parent = parent;
        this.init();
    }

    checkTemplateExists = () => {
        this.template = this.parent.getByName(this.templateName);
        this.isUpdate = this.template ? true : false;
        this.trackTemplateLoading();
    }

    setCurrentFile = (file: File) => {
        this.file = file;
    }

    setReplaceAssets = (replaceAssets: boolean) => {
        this.replaceAssets = replaceAssets;
    }

    setFile = async (file: File) => {
        if(!file) return;
        this.file = file;
        this.fileName = file.name;
        let templateName = removeFileExtention(file.name);
        this.template = this.parent.getByName(templateName);
        this.isUpdate = this.template ? true : false;
        this.templateName = templateName;
        this.trackTemplateLoading();
    }

    trackTemplateLoading = () => {
        if (this.template) {
            this.loading = this.template.loading;
            if(this.templateLoadingReactionDisposer) this.templateLoadingReactionDisposer();
            this.templateLoadingReactionDisposer = reaction(() => this.template?.loading, (loading) => this.loading = loading || false);
        } else {
            this.templateLoadingReactionDisposer && this.templateLoadingReactionDisposer();
        }
    }

    setTemplate = (template: Template) => {
        this.reset();
        this.template = template;
        this.templateName = template.data.name;
        this.fileName = '';
        this.isUpdate = true;
    }

    setTemplateName(name: string) {
        this.templateName = name;
        this.template = this.parent.getByName(name);
        this.isUpdate = this.template ? true : false;
    }

    showUploadTemplateModal = (file?: File, template?: Template) => {
        this.file = file;
        this.template = template;
        this.show(true);
    }

    show = (show: boolean = true) => {
        this.modalVisible = show;
        this.parent.addEventListener(DocumentEvents.CHANGED, this.checkTemplateExists);
    }

    hide = async () => {
        this.show(false);
        await delay(250);
        this.reset();
    }

    handleConfirm = async () => {
        if (!this.file) return;
        this.loading = true;
        this.templateName = removeExtraSpaces(this.templateName);
        await this.parent.upload(
            this.templateName,
            this.file,
            this.isUpdate,
            this.replaceAssets
        );
        this.loading = false;
        this.hide();
    };

    reset = () => {
        this.template = undefined;
        this.templateName = '';
        this.file = undefined;
        this.fileName = '';
        this.isUpdate = false;
        this.replaceAssets = true;
        this.loading = false;
        this.modalVisible = false;
        this.parent.removeEventListener(DocumentEvents.CHANGED, this.checkTemplateExists);
        if(this.templateLoadingReactionDisposer) this.templateLoadingReactionDisposer();
    }
}

function removeSpecialCharacters(str: string) {
    return str.replace(/[^a-zA-Z0-9\ \%\-\_\!\@\#\$\(\)\+\=]+/g, '');
}

function removeExtraSpaces(str: string) {
    return str.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
}

function removeFileExtention(str: string) {
    return str.replace(/\.[^/.]+$/, "");
}