import { Button } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import AddMemberModal from './AddMemberModal';
import DocumentStore from 'stores/DocumentStore';
import React from 'react';

export const ADD_MEMBER_BUTTON_LABEL = 'Add User';

export interface AddMemberButtonProps {
  parentDocument: DocumentStore;
}


class AddMemberButton extends React.Component<AddMemberButtonProps>{
  isModalVisible: boolean = false;

  constructor(props: AddMemberButtonProps) {
    super(props);

    makeObservable(this, {
      isModalVisible: observable,
    });
  }

  showModal = () => {
    this.isModalVisible = true;
  }

  hideModal = () => {
    this.isModalVisible = false;
  }

  render() {
    const { parentDocument } = this.props;
    const { canModify: creationEnabled } = parentDocument;
    const { isModalVisible, hideModal, showModal } = this;

    return (
      <>
        <Button
          disabled={!creationEnabled}
          onClick={showModal}
        >
          {ADD_MEMBER_BUTTON_LABEL}
        </Button>

        {creationEnabled && (
          <AddMemberModal
            isVisible={isModalVisible}
            onClose={hideModal}
            parentDocument={parentDocument}
          />
        )}

      </>
    );
  }
};

export default observer(AddMemberButton);
