import { Drawer as BaseDrawer } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Drawer = styled(BaseDrawer)`
  .ant-drawer-wrapper-body{
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .ant-drawer-body{
    flex: 1;
    overflow: auto;
    padding: 0;
  }

  .ant-drawer-header{
    align-items: center;
    display: flex;
    height: 65px;
  }

  .ant-drawer-close{
    height: 100%;
    padding-top: 4px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CREATIVE_DRAWER_LAYOUT_WIDTH = 720;

export interface DrawerLayoutProps {
  children?: React.ReactNode;
  isVisible: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
}

class DrawerLayout extends React.Component<DrawerLayoutProps>{
  constructor(props: DrawerLayoutProps) {
    super(props);
  }

  render() {
    const {
      children,
      isVisible,
      onClose,
      title,
    } = this.props;
    return (
      <Drawer
        onClose={onClose}
        mask={false}
        title={title}
        visible={isVisible}
        width={CREATIVE_DRAWER_LAYOUT_WIDTH}
      >
        <Body>
          {children}
        </Body>
      </Drawer>
    );
  }
};

export default DrawerLayout;
