import AssetsManager from './Assets';
import DocumentStore from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import { DocumentCollectionType, DocumentStatus, DocumentType, ProcessingStatus } from './Types';
import * as Firestore from '@firebase/firestore-types';
import { storageRef, hostingRef } from './Firebase';
import { getFilenameExtention } from '../utils';
import { appConfig as config } from '../config';
import { urlJoin } from 'url-join-ts';
import { AssetFileType, AssetMetadata, FileTypeExtensions } from './Asset/Types';
import { reaction, when } from 'mobx';

export class AssetSchema extends DocumentSchema {
    path: string = '';
    name: string = '';
    type: AssetFileType = AssetFileType.FILE;
    fileType: AssetFileType = AssetFileType.FILE;
    status: ProcessingStatus = ProcessingStatus.NONE;
    storage: string = '';
    dateModified: Firestore.Timestamp;
    author: string = '';
}

export default class Asset extends DocumentStore<AssetSchema> {
    schema = new AssetSchema();
    type = DocumentType.ASSET;
    collectionType = DocumentCollectionType.ASSET;

    publicURL = '';
    default = false;
    bundleFilesize = NaN;
    backupImageFilesize = NaN;
    error = false;
    metadata: AssetMetadata;
    fileSizeString = '';
    errorMessage = '';
    processing = false;
    
    get filename() {
        if(!this.data) return '';
        return this.getRootFromPath(this.data.path);
    }

    get fileSize() {
        if(!this.metadata) return 0;
        return this.metadata.size;
    }

    get hostingRef() {
        return hostingRef.child(this.data.storage);
    }

    get loading() {
        // if(this.data.status === ProcessingStatus.NONE) return false;
        return this.data.status !== ProcessingStatus.COMPLETE;
    }

    constructor(parent: AssetsManager, docRef: Firestore.DocumentReference, data: AssetSchema) {
        super(parent, docRef, data);
        // this.filename = this.getRootFromPath(this.data.path);
        this.init();
        let statusDisposer = reaction( () => this.data.status, () => {
            if(this.data.status === ProcessingStatus.COMPLETE) {
                this.updateFileSize();
            }
        })
    }

    get context() {
        return this.parent.context;
    }

    get absoluteURL(){
        return this.getAbsoluteURL();
    }

    get storageFolder() {
        return this.data.storage.substring(0, this.data.storage.lastIndexOf('/') + 1);
    }

    get downloadURL() {
        return 'https://' + config.hostingBucket + '/' + this.data.storage;
    }

    get fileType() { // TODO: bug: currently returns undefined
        return this.getFileType();
    }

    onLoad = async (data: AssetSchema) => {
        this.status = DocumentStatus.LOADING;
        this.data.name = this.getRootFromPath(this.data.path);
        this.status = DocumentStatus.LOADED;
    }

    onReady = async () => {
        this.data.fileType = String(this.data.type) as AssetFileType; // TODO: This data transform is currently needed due to a very early data structure conflict with Adhub 3.0 data schema standards. New schema standards use property 'type' on every document to define which schema type is being used for that document.
        when( () => this.data.status === ProcessingStatus.COMPLETE, () => this.updateFileSize() );
    }

    onBeforeSave = async (data: AssetSchema) => {
        this.data.type = this.data.fileType;
    }

    delete = async () => {
        this.status = DocumentStatus.LOADING;
        // await this.hostingRef.delete(); // TODO: Cannot delete. Do not have access. Ask Jim/Backend for function to delete, or access to delete.
        await this.docRef.delete();
        this.status = DocumentStatus.LOADED;
    }

    updateFileSize = async () => {
        if (this.data.fileType !== AssetFileType.FOLDER) {
            try {
                this.metadata = await this.hostingRef.getMetadata();
                this.fileSizeString = this.fileSizeToKB(this.metadata.size) + ' KB';
            } catch(e){
                console.log('Asset: Error getting file size', e);
            }
        }
    }

    setProcessing(isProcessing: boolean = true) {
        this.processing = isProcessing;
    }

    setLoading = (isLoading: boolean = true) => {
        if(isLoading) {
            this.data.status = ProcessingStatus.PENDING;
        } else {
            this.data.status = ProcessingStatus.COMPLETE;
        }
    }

    getAbsoluteURL() {
        return urlJoin(config.hostingBucket, this.data.storage);
    }

    getFileExtension() {
        return getFilenameExtention(this.filename);
    }

    getFileType() {
        let ext = getFilenameExtention(this.filename) || '';
        if (FileTypeExtensions.IMAGE.includes(ext)) {
            return AssetFileType.IMAGE;
        } else if (FileTypeExtensions.VIDEO.includes(ext)) {
            return AssetFileType.VIDEO;
        } else if (FileTypeExtensions.AUDIO.includes(ext)) {
            return AssetFileType.AUDIO;
        } else if (FileTypeExtensions.FONT.includes(ext)) {
            return AssetFileType.FONT;
        } else if (FileTypeExtensions.TEXT.includes(ext)) {
            return AssetFileType.TEXT;
        } else {
            return AssetFileType.NONE;
        }
    }

    getRootFromPath(path: string) {
        path = '/' + path;
        let pathDepth = path.split('/').length - 1;
        return path.split('/')[pathDepth];
    }

    fileSizeToKB(size: number) {
        return Math.round((size / 1024) * 100) / 100
    }
}