import DynamicGroup from './Group';
import Number from './Number';
import { IDynamicProperty, Dimensions2D } from './index';
import { DynamicPropertyType } from './DynamicPropertyType';

export default class Dimensions extends DynamicGroup {
  type = DynamicPropertyType.DIMENSIONS;

  constructor(parent: IDynamicProperty<any>, name: string, value: Dimensions2D) {
    super(parent, name, value);
    if(!value){
      this.properties = this.defaults();
    }
    // this.init();
  }

  setDefault = () => {
    this.add('width', DynamicPropertyType.NUMBER, 300);
    this.add('height', DynamicPropertyType.NUMBER, 250);
  };

  get width() {
    return this.get('width')?.value as number;
  }

  get height() {
    return this.get('height')?.value as number;
  }

  set width(value: number) {
    this.get('width')?.set(value);
  }

  set height(value: number) {
    this.get('height')?.set(value);
  }

  getString = () => {
    return `${this.width}x${this.height}`;
  };

  getPreviewData = () => {
    return this.getString();
  };

  setUsingCSV = (value: string) => {
    let newValue = value.split('x');
    if (newValue.length !== 2) {
      this.throwCSVImportError(`Invalid dimensions value`);
    } else {
      this.set('width', parseFloat(newValue[0]));
      this.set('height', parseFloat(newValue[1]));
    }
  };

  getCSVData = () => {
    return `${this.width}x${this.height}`;
  };
}
