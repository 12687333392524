import React from 'react';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import styled from 'styled-components';
import lottie, { AnimationEventName } from 'lottie-web';
import { DocumentCollectionEvents } from 'stores/DocumentCollection';
import store from 'stores';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnimationContainer = styled.div`
  overflow: visible;
`;

interface Props {}

class AnimationStageFrame extends React.Component<Props> {
  private animationContainer: React.RefObject<HTMLDivElement>;
  private animationAssetDisposer: () => void;

  constructor(props: Props) {
    super(props);
    this.animationContainer = React.createRef();
  }

  componentDidMount() {
    if(store.animation.assets.all.length > 0) {
      this.loadLottieAnimation(store.animation.assets.newest.downloadURL);
      this.animationAssetDisposer = reaction(() => store.animation.currentAnimationAsset, this.loadCurrentAnimation);
    } else {
      console.log('No animation assets found');
      store.animation.assets.addEventListener(DocumentCollectionEvents.LOADED, this.handleAssetsLoaded);
      store.animation.assets.addEventListener(DocumentCollectionEvents.CHANGED, this.loadCurrentAnimation);
    }
  }

  componentWillUnmount() {
    this.destroyAnimation();
    this.animationAssetDisposer();
    store.animation.assets.removeEventListener(DocumentCollectionEvents.LOADED, this.handleAssetsLoaded);
    store.animation.assets.removeEventListener(DocumentCollectionEvents.CHANGED, this.loadCurrentAnimation);
  }

  handleAssetsLoaded = () => {
    console.log('handleAssetsLoaded');
    this.animationAssetDisposer = reaction(() => store.animation.currentAnimationAsset, this.loadCurrentAnimation);
    this.loadCurrentAnimation();
  }

  loadCurrentAnimation = () => {
    let animationAsset = store.animation.currentAnimationAsset;
    if(!animationAsset) {
      this.destroyAnimation();
      return;
    };
    this.loadLottieAnimation(animationAsset.downloadURL);
  }

  loadLottieAnimation = (url: string) => {
    console.log('loadLottieAnimation', url);
    let { renderer, loop, autoplay } = store.animation.player;
    if (this.animationContainer.current) {
      this.destroyAnimation();
  
      const animation = lottie.loadAnimation({
        container: this.animationContainer.current,
        renderer: renderer,
        loop: loop,
        autoplay: autoplay,
        path: url
      });
  
      store.animation.player.updateAnimationInstance(animation);
      animation.setSpeed(store.animation.player.speed);
  
      animation.addEventListener('enterFrame', this.handleEnterFrame);
      animation.addEventListener('DOMLoaded', this.handleDOMLoaded);
      animation.addEventListener('data_ready', this.handleDataReady);
    }
  }

  destroyAnimation = () => {
    const animation = store.animation.player.instance;
    if (animation) {
      animation.removeEventListener('enterFrame', this.handleEnterFrame);
      animation.removeEventListener('DOMLoaded', this.handleDOMLoaded);
      animation.removeEventListener('data_ready', this.handleDataReady);
      animation.destroy();
      store.animation.player.updateAnimationInstance(null);
    }
  }

  handleEnterFrame = () => {
    const playerStore = store.animation.player;
    const animation = playerStore.instance;
    if (animation) {
      const progress = (animation.currentFrame / animation.totalFrames) * 100;
      const currentTime = (animation.currentFrame / animation.frameRate);
      playerStore.updateProgress(progress);
      playerStore.updateCurrentTime(currentTime);
      playerStore.updateCurrentFrame(animation.currentFrame);
    }
  }

  handleDataReady = () => {
    const animation = store.animation.player.instance;
    if (animation) {
      const durationInSeconds = animation.totalFrames / animation.frameRate;
      store.animation.player.updateAnimationDuration(durationInSeconds);
    }
  }

  handleDOMLoaded = () => {
    const animation = store.animation.player.instance;
    if (animation && this.animationContainer.current) {
      const animElement = this.animationContainer.current.firstElementChild as SVGSVGElement;
      if (animElement && animElement instanceof SVGSVGElement) {
        const viewBox = animElement.getAttribute('viewBox');
        if (viewBox) {
          const [, , width, height] = viewBox.split(' ').map(Number);
          if (!isNaN(width) && !isNaN(height)) {
            animElement.style.width = `${width}px`;
            animElement.style.height = `${height}px`;
          }
        } else {
          // Fallback if viewBox is not available
          const bbox = animElement.getBBox();
          animElement.style.width = `${bbox.width}px`;
          animElement.style.height = `${bbox.height}px`;
        }
      }
    }
  }

  render() {
    return (
      <Container {...this.props}>
        <AnimationContainer ref={this.animationContainer} />
      </Container>
    );
  }
}

export default observer(AnimationStageFrame);