import { PublicInfo, DocumentType, DocumentStatus, DocumentCollectionType, LooseObject, ProcessingStatus, Newable } from './Types';
import { makeAutoObservable, reaction } from 'mobx';
import firebase from 'firebase';
import { firestore, db, functionAuthRequest, FirestoreEvents } from './Firebase';
// import { Mixin } from 'ts-mixer';
import * as Firestore from '@firebase/firestore-types';
import root from './index';
// import { urlJoin, urlJoinP } from 'url-join-ts';
// import MembersManager, { MemberMetadataSchema } from './MembersManager';
import CommentsManager, { CommentMetadataSchema } from './CommentsManager';
import DocumentCollection from './DocumentCollection';
import Store from './Store';
import MembersManager from './MembersManager';
import { UserRoles } from './UserTypes';
import { DocumentSchema } from './DocumentSchema';
import { deepCopy, documentPathToParams, nowTimestamp } from 'utils';
import BreadcrumbManager from './BreadcrumbManager';
import type { Routes } from './Routes';
import type { QueryParams, Route } from 'mobx-router';
// import { isClass } from '../utils';

type AnyRoute = Route<any, any, any>;

/**
 * DocumentEvents are events that are emitted by the DocumentStore class.
 */
export enum DocumentEvents {
    READY = 'ready',
    CHANGED = 'changed',
    REMOVED = 'removed',
    ERROR = 'error'
}

/**
 * @class DocumentStore
 * @description DocumentStore is a wrapper class that manages the lifecycle of a Firestore document,
 * and provides abstractions for other additional features for member management, and publicly
 * accessible meta data.
 */
export default class DocumentStore<Schema = any, PublicSchema = any> extends Store {
    // [index: string]: any; // allow values on the class to be set programatically
    schema: Schema; // This is the expected data structure/schema of the Firestore document. It is checked against the data that comes from the Firestore database itself to check for any errors in the shape of the data.
    publicSchema: PublicSchema; // Schema used but the public child document that contains public metadata about this document. This is used to automatically populate the public child document with meta data.

    type: DocumentType = DocumentType.DEFAULT;
    collectionType: DocumentCollectionType = DocumentCollectionType.DEFAULT;

    data: Schema = {} as Schema; // Current data for the document.
    oldData: Schema = {} as Schema; // Used internally as a reference to check against updates coming in from the Firestore server.
    newData: Schema = {} as Schema; // Used internally as a reference to check against updates coming in from the Firestore server.
    publicData: PublicSchema; // Schema used but the public child document that contains public metadata about this document. This is used to automatically populate the public child document with meta data.

    status: DocumentStatus | ProcessingStatus = DocumentStatus.NONE; // Current document lifecucle status: loaded, loading, error, local
    breadcrumbs: BreadcrumbManager;

    // path = '';
    // id = '';
    parent: any; // Parent reference, most often an instance of DocumentCollection.
    exists = false;
    docRef: Firestore.DocumentReference; // Direct reference to the Firestore document via the Firestore API.
    publicDocRef: Firestore.DocumentReference; // Reference to the public child document that contains public metadata about this document.

    lastModified: number = NaN; // Unix Timestamp of the last modified date.
    lastSynced: number = NaN; // Unix Timestamp of the last time this document synced with the server.

    members: MembersManager = new MembersManager(this); // Reference to the MembersManager for managing the members that belong to this document.
    // comments: CommentsManager; // Reference to the comments manager for this document.
    children: DocumentCollection<any, any>; // Reference to an instance of DocumentCollection that contains child documents of this document.

    isViewingComments = false; // Are the comments of this document currently being viewed.
    isRead = true; // Has this document been viewed since it's last update.
    isLocalOnly = false; // Does this document only exist on the local machine.
    isDataLoaded = false; // Is the data for this document fully loaded.
    dataLoadedResolve: Function;

    mounted = false;

    updatesEnabled = true;

    collectionName: DocumentCollectionType = DocumentCollectionType.DEFAULT;

    /**
     * @returns {string} The id of the document if the document exists, otherwise it returns an empty string.
    */
    get id() {
        return this.docRef ? this.docRef.id : '';
    }

    /**
     * @returns {string} The path of the document if the document exists, otherwise it returns an empty string.
     */
    get path() {
        return this.docRef ? this.docRef.path : '';
    }

    /**
     * @returns {string} The key of the document. The key is a combination of the document type and the document id.
     */
    get key() {
        return `${this.type}.${this.id}`;
    }
    /**
     * @returns {Schema} The default values for the document. This is the same as the schema.
     */
    get defaults() {
        return this.schema;
    }

    /**
     * @returns {boolean} Returns if the document can be modified by the current user.
     */
    get canModify() {
        let canModify =
            root.user.role === UserRoles.ADMIN ||
            root.user.role === UserRoles.MANAGER ||
            this.members.canModify(root.user.id);
        if (this.members.enabled) return canModify;
    }

    /**
     * If the parent, docRef, data, or docType parameters are passed in, then assign them to the
     * corresponding properties of the class
     * @param {any} [parent] - The parent of this document. Most often an instance of DocumentCollection.
     * @param [docRef] - The Firestore DocumentReference that this document is connected to.
     * @param {Schema} [data] - The data to be used to populate the document.
     * @param {DocumentType} [docType] - The type of document.
     */
    constructor(
        parent?: any,
        docRef?: Firestore.DocumentReference,
        data?: Schema,
        docType?: DocumentType
    ) {
        super();
        if (parent) this.parent = parent;
        // if (docType) this.type = docType;
        if (docRef) this.connectDocRef(docRef);
        if (data) {
            this._update(data as Schema);
        } else {
            this.data = Object.assign({}, this.schema);
            // this.defaults = Object.assign({}, this.schema);
        }
    }

    /** @returns {boolean} Returns true if the document is currently loading. */
    get loading() {
        return this.status === DocumentStatus.LOADING;
    }

    /** @returns {boolean} Returns true if the document has completely loaded. */
    get loaded() {
        return this.status === DocumentStatus.LOADED;
    }

    /** @returns {boolean} Returns true if the document has changed. */
    get changed() {
        return this.lastModified > this.lastSynced;
    }

    /** @returns {string} Returns the Firestore path of the document. */
    get context() {
        return this.path;
    };

    // get name() { // TODO: find where this is necessary, if ever
    //     let data = this.data as LooseObject;
    //     return data['name'];
    // }

    // init = () => {
    //     if (!this.defaults) {
    //         this.defaults = Object.assign({}, this.schema);
    //     }
    //     this.initStateManagement();
    // }

    onInitData = () => { };

    /** 
     * Connects the `docRef` and `publicDocRef` properties of this class to Firestore document references.
     * @param {DocumentReference} docRef - The Firestore DocumentReference that this document is connected to.
     * @returns {void}
     * */
    connectDocRef = (docRef: Firestore.DocumentReference) => {
        // console.log('connectDocRef', docRef);
        this.docRef = docRef;
        // this.id = docRef.id;
        // this.path = docRef.path;
        this.publicDocRef = this.docRef.collection('public').doc('info');
    }
    /** 
     * Synchornizes the data of this document with the data in Firestore, but does not subscribe to updates.
     * */
    loadDataWithUpdates = () => {
        console.log('Document: loadDataWithUpdates');
        // if(!this.updatesEnabled) return;
        return new Promise((resolve, reject) => {
            this.disposer.add(
                this.docRef.onSnapshot(this.onSnapshot)
            );
            this.onInitData = () => {
                resolve(this.data);
            }
        });
    }

    /**
     * Gets the default values from this documents Schema and returns them as a new, cloned object.
     * @returns {Schema} The default values for this document.
     * */
    getDefaults = () => {
        if (this.defaults) {
            return Object.assign({}, this.defaults);
        } else {
            return Object.assign({}, this.schema);
        }
    }

    
    // enableUpdates = () => {
    //     this.updatesEnabled = true;
    // }

    // disableUpdates = () => {
    //     this.updatesEnabled = false;
    // }

    /**
     * Sets the data of this document.
     * @param {Schema} data - The data to be used to update the document.
     * */
    set = (data: Schema) => {
        this._update(data);
    }

    // onCreate = (parent: any, docRef: Firestore.DocumentReference, data: Schema) => {
    //     this.parent = parent;
    //     this.connectDocRef(docRef);
    //     this.set(data);
    // }

    /**
     * load() is the main method for loading data into a document. It is called by the DocumentCollection
     * class when a document is added to the collection. It can also be called manually to load data into
     * a document that is not part of a collection.
     * Example:
     * ```
     * let doc = new DocumentStore();
     * await doc.load(null, docRef);
     * ```
     * @param {any} [parent] - The parent of this document. Most often an instance of DocumentCollection.
     * @param [docRef] - The Firestore DocumentReference that this document is connected to.
     * @param {Schema} [data] - The data to be used to populate the document.
     * @returns {Promise<Schema>} Returns a promise that resolves with the data of the document.
     * */
    load = async (parent?: any, docRef?: Firestore.DocumentReference, data?: Schema) => {
        await this.onBeforeLoad();
        console.log('Document: load', this.constructor.name);
        this.status = DocumentStatus.LOADING;
        if (docRef) this.connectDocRef(docRef);
        if (parent) this.parent = parent;
        // if updates are specifically enabled,
        // or the parent is not an instance of DocumentCollection (who manages child updates), 
        // OR there is no parent, then this document should self update.
        let selfUpdating = this.parent instanceof DocumentCollection === false;// || this.parent;


        if (this.updatesEnabled === false) selfUpdating = false;

        let loadedData = this.data;
        if (this.docRef) {
            // if(this.parent instanceof DocumentCollection) selfUpdating = false;
            if (selfUpdating) {
                await this.loadDataWithUpdates();
                loadedData = this.data;
            } else {
                loadedData = await this.loadData();
            }
        }

        Object.entries(this.data as any).forEach(([key, value]) => {
            if (key === 'type') this.type = value as DocumentType;
        });

        // await this.loadBreadcrumbs();

        // if (this.children) await this.loadChildren();
        // await this.loadAllCollections();

        // await Promise.all([
        //     this.loadMembers,
        //     this.loadComments,
        // ]);

        await this.onLoad(this.data);
        this.dispatch(DocumentEvents.READY, this);
        this.disposer.add(
            reaction(() => this.data, () => {
                console.log('Document: data changed');
                this.dispatch(DocumentEvents.CHANGED, this);
            })
        );
        this.onReady();
        this.mounted = true;
        this.status = DocumentStatus.LOADED;

        return loadedData;
    }
    
    /** A strub method for child classes to override. This will be called before the document loads and can be used to perform any necessary setup. */
    onBeforeLoad = async () => { };

    /** A stub method for child classes to override. This will be called after the document loads. The data argument will provide the data that was loaded.
     * @returns {Promise<void>} Promise that resolves when the document has finished loading.
     * */
    onLoad = async (data: Schema) => {
        // This is a place holder for child classes to override.
    };

    /** A stub method for child classes to override. This will be called after the document is created. 
     * A document is created when it is created locally.
     * The data argument will provide the data that was created locally. */
    onCreate = (data: Schema) => { }

    /** A stub method for child classes to override. This will be called last after a document is fully loaded. */
    onReady = () => { };

    loadMembers = async () => {
        // if(!this.members.enabled) return;
        // await this.members.load();
        // this.canModify = this.members.canModify(root.user.id);
    }

    /** Loads the children `Document Collection` of this document. */
    loadChildren = async () => {
        await this.children.load();
    }

    loadComments = async () => {
        // if(this.comments.enabled) await this.comments.load();
    }

    /**
     * Loads the data of this document from Firestore. This method is called by the `load` method.
     * @returns {Promise<Schema>} Returns a promise that resolves with the data of the document.
     * */
    loadData = async () => {
        console.log('Document: loadData');
        let responsePublic;
        if (this.publicSchema) responsePublic = await this.publicDocRef.get();
        let response = await this.docRef.get();
        let exists = response.exists;
        try {
            if (!exists) {
                console.warn(`Document: loadData: Error loading document type '${this.type}`);
                // return { ... this.schema, type: DocumentType.NONE }; // TODO: check why this was put here
            }
        } catch (e) {
            console.warn(`Document: loadData: Error loading document type '${this.type}: Error: ${e}`);
        }

        let data = response.data() as Schema;
        this.exists = exists;
        // this.data = data; // TODO: Check this is necessary. Seems like a duplicate data update that _update is already doing.
        // if(this.publicSchema) this.publicData = responsePublic?.data() as PublicSchema;

        this._update(data);

        // this.breadcrumbs = await this.loadBreadcrumbs();

        return data as Schema;
    }
    /**
     * This method is called when the document is updated in Firestore. It is called by the `loadDataWithUpdates` method.
     * @param {DocumentSnapshot} snapshot The snapshot of the document.
     * */
    onSnapshot = (snapshot: Firestore.DocumentSnapshot) => {
        if (!snapshot.exists) {
            this.status = DocumentStatus.ERROR;
            console.log('Document: onSnapshot: Document does not exist.');
            if (!this.isDataLoaded) {
                this.dispatch(DocumentEvents.ERROR, this);
                this.onInitData();
            }
        } else {
            if (!this.isDataLoaded) {
                this._update(snapshot.data() as Schema);
                this.exists = true;
                this.onInitData();
                this.isDataLoaded = true;
            } else {
                this._update(snapshot.data() as Schema);
                this.onInitData();
            }
        }
    }

    /**
     * Updates the local data of the document. This method is called by the `update` method. 
     * This method is used to update the local data of the document.
     * @param {Partial<Schema>} data The data to update the document with.
     * @returns {Schema} Returns the updated data.
     * */
    updateLocal = (data: Partial<Schema>) => {
        let oldData = Object(this.data);
        for (let key in oldData) {
            if(Object(data).hasOwnProperty(key)) {
                Object(this.data)[key] = Object(data)[key];
            } else {
                Object(this.data)[key] = Object(oldData)[key];
            }
        }
        return this.data;
    }
    
    /**
     * Updates the local data of the document. This method is called by the `loadData` method.
     * If any data is missing from the document, the default values will be used.
     * @param {Schema} data The data to update the document with.
     * */
    _update = (data: Schema) => {
        this.oldData = this.newData;
        this.newData = data;
        if (!data) data = this.getDefaults();

        // this.updateCommentsState();
        // this.canModify = this.members.canModify(root.user.id);

        this.validateSchema(this.data);
        // if(this.comments.enabled) this.comments.setState(this.data);
        // if(this.members.enabled) this.members.setState(this.data);
        let newData = {} as Object;
        if (!this.schema) console.warn(`Document: _update: Schema is not defined for ${this.constructor.name}`, this);
        let schema = Object(this.schema); 
        for (let key in schema) {
            if (Object(data).hasOwnProperty(key)) { // TODO: replace with hasOwnProperty util function
                Object(this.data)[key] = Object(data)[key];
            } else {
                Object(this.data)[key] = Object(this.defaults)[key];
            }
        }

        // this.data = deepCopy(newData) as Schema; // Apply data to defaults in case there are any missing properties from the Firestore document.
        this.lastSynced = new Date().getTime();
        if (this.isDataLoaded || this.parent instanceof DocumentCollection) {
            // console.log('Document: _update: Dispatching UPDATE event.');
            this.onUpdated(this.newData, this.oldData)
            this.dispatch(DocumentEvents.CHANGED, this);
        } else {
            this.isDataLoaded = true;
        }
    }

    /**
     * This method is called when the document is removed from Firestore. It is called by the `loadDataWithUpdates` method.
     * */
    _removed = () => {
        this.dispatch(DocumentEvents.REMOVED, this);
        this.onRemoved();
    }

    /**
     * Updates the dateModified property of the document.
     * */
    updateDateModified = async () => { // TODO: This is sort of messy. But works for now until we redesign the database. This will be saved in metadata.
        if (!this.data) return;
        let data = this.data as LooseObject;
        // if data has dateModified, update it with firestore timestamp
        if (data['dateModified']) {
            data['dateModified'] = nowTimestamp();
        }
        this.data = data as Schema;
        await this.save();
    }

    /** Updates the name property of the document. */
    updateName = async (newName: string) => {
        if (!this.data) return;
        const data = this.data as LooseObject;
        data.name = newName;
        this.data = data as Schema;
        await this.save();
    }

    updateCommentsState = () => {
        // TODO: Move this to comments manager. there might need to be a "viewed" state on changed ads outside of comment viewing.
        // if (this.isViewingComments === true && this.hasViewed() === false) {
        //     this.isRead = true;
        // } else {
        //     this.isRead = this.hasViewed();
        // }
    }
    /**
     * This method is a stub to be overriden by the child class. It is called when the document is updated.
     * @param {Schema} data The new data of the document.
     * @param {Schema} oldData The old data of the document.
     * */
    onUpdated = (data: Schema, oldData: Schema) => { }

    /**
     * This method is a stub to be overriden by the child class. It is called when the document is removed.
     * */
    onRemoved = () => { }

    /**
     * This method is a stub to be overriden by the child class. It is called before the document is saved to Firestore.
     * @param {Schema} data The data of the document to be saved.
     * */
    onBeforeSave = async (data: Schema) => { }

    /**
     * Saves the document to Firestore.
     * */
    save = async () => {
        await this.saveData();
    }

    /**
     * Updates the document with new data.
     * @param {Schema} newData The new data to update the document with.
     * */
    update = async (newData: Schema) => {
        this._update(newData);
    }

    /**
     * Saves the document to Firestore along with the public metadata. 
     * It also calls the `onBeforeSave` method so child classes can do any pre-processing before saving.
     * If this document was created locally, it will create a new document in Firestore.
     * */
    saveData = async () => {
        this.status = DocumentStatus.LOADING;
        await this.onBeforeSave(this.data);
        // TODO: Validate excluding metadata.
        this.validateSchema(this.data);

        // @ts-ignore
        // const { parent, ...basePayload } = this.data;

        let payload = JSON.parse(JSON.stringify(deepCopy(this.data)));
        // payload.type = this.type;

        let isDocNewlyCreated = false;

        try {
            await this.docRef.get({ source: 'cache' });
        } catch (e) {
            // console.log(e);
            isDocNewlyCreated = true;
        }

        if (this.isLocalOnly || isDocNewlyCreated) {
            console.log('Document('+this.constructor.name+'): saveData: Creating new document.');
            let doc = await this.docRef.set(payload);
        } else {
            await this.docRef.update(payload);
        }

        if (this.publicSchema) await this.savePublicProps();
        this.status = DocumentStatus.LOADED;
    }

    removeUnsupportedProperties = (payload: LooseObject) => {
        // let memberSchema = Object.assign({}, new MemberMetadataSchema());
        // let commentSchema = Object.assign({}, new CommentMetadataSchema());

        // if(this.comments.enabled) payload = this.removeMatchingProperties(payload, memberSchema);
        // if(this.members.enabled) payload = this.removeMatchingProperties(payload, commentSchema);

        return payload;
    }

    /**
     * Removes properties from an object that match the properties of another object.
     * @param {LooseObject} originalProps The original object to remove properties from.
     * @param {LooseObject} matchProps The object to match properties against.
     * @returns {LooseObject} The original object with the matching properties removed.
     * */
    removeMatchingProperties = (originalProps: LooseObject, matchProps: LooseObject) => {
        let props: LooseObject = {};
        for (let key in originalProps) {
            let value = originalProps[key];
            let match = matchProps[key];
            if (!match) props[key] = value;
        }
        return props;
    }

    /**
     * Validates the provided data against the schema.
     * @param {Schema} data The data to validate.
     * @returns {boolean} Whether the data is valid or not.
     * */
    validateSchema = (data: any) => {
        let invalid = false;
        let schema = Object.assign({}, this.schema) as LooseObject;
        for (let key in data) {
            let newValue = data[key];
            let schemaValue = schema[key];
            if (typeof schemaValue !== typeof newValue) {
                invalid = true;
                console.warn(`AdHub Document: ${key} is not a valid field for ${data.type}`);
                continue;
            }
        }
        return invalid;
    }

    // updateViewed() {
    //     if(!this.comments) return;
    //     let uid = root.user.id;
    //     let localViewHistory = { ...this.data.viewHistory };
    //     localViewHistory[uid] = { seconds: Math.ceil(new Date().getTime() / 1000) };
    //     this.data.viewHistory = localViewHistory;

    //     let remoteViewHistory: LooseObject = {};
    //     remoteViewHistory[`viewHistory.${uid}`] = firestore.FieldValue.serverTimestamp();

    //     this.docRef.update(remoteViewHistory);
    // }

    // hasViewed(){
    //     if(this.data.numComments === 0) {
    //         return true;
    //     } else if (this.data.viewHistory[root.user.id]) {
    //         return this.data.viewHistory[root.user.id].seconds >= this.data.commentsDateAdded.seconds;
    //     } else {
    //         return false;
    //     }
    // }

    /**
     * Sets whether the user is viewing comments or not.
     * @param {boolean} isViewing Whether the user is viewing comments or not.
     * */
    setViewingComments(isViewing: boolean) {
        this.isViewingComments = isViewing;
    }

    /**
     * Resets the parent most document to its default state.
     * */
    reset() {
        console.log('Document: reset');
        this.onReset();
        this.disposer.clear();
        this.status = DocumentStatus.NONE;
        this.isDataLoaded = false;
        this.parent = undefined;
        this.exists = false;
        this.mounted = false;
        this.lastModified = NaN;
        this.lastSynced = NaN;
        this.isViewingComments = false;
        this.isRead = true;
        this.isLocalOnly = false;
        this.isDataLoaded = false;
        this.updatesEnabled = true;

        this.data = Object.assign({}, this.schema);
        // this.canModify = false;
    }

    /**
     * Called when the document is reset. To be overridden by child classes.
     * */
    onReset = () => { }

    /**
     * Saves the public metadata of the document to Firestore.
     * */
    savePublicProps = async () => {
        this.status = DocumentStatus.LOADING;
        let publicSchema = Object.assign({}, this.publicSchema) as LooseObject;
        let currentData = Object.assign({}, this.data) as LooseObject;
        let publicDataPayload = {} as LooseObject;

        for (let key in publicSchema) {
            let schemaValue = publicSchema[key];
            let currentDataValue = currentData[key];
            if (typeof schemaValue !== typeof currentDataValue) {
                console.warn(`AdHub: ${key} is not a valid field for public properties`);
                continue;
            }
            publicDataPayload[key] = currentDataValue;
        }

        publicDataPayload.type = this.type;

        await this.publicDocRef.set(publicDataPayload);
        this.status = DocumentStatus.LOADED;
    }

    /**
     * Deletes the document from Firestore.
     * @returns {Promise<void>} A promise that resolves when the document is deleted.
     * */
    delete = async () => {
        return await this.docRef.delete();
    }

    /**
     * Sets the document's loading `status` to DocumentStatus.LOADING if isLoading is true, otherwise sets it to DocumentStatus.LOADED.
     * @param {boolean} isLoading Whether the document is loading.
     * */
    setLoading = (isLoading: boolean) => {
        if (isLoading) this.status = DocumentStatus.LOADING;
        else this.status = DocumentStatus.LOADED;
    }

    navigateTo = (e?: React.MouseEvent<HTMLAnchorElement>) => {
        e?.preventDefault();
        root.setDocumentType(this.type as DocumentType);
        let docTypeSerialized: string = this.type as string;
        
        // Handle the special case for CREATIVE
        if (docTypeSerialized === DocumentType.CREATIVE || docTypeSerialized === DocumentType.ANIMATION) {
            docTypeSerialized = 'creative';
        }

        let routes = root.routes as Routes;
        const route = routes[docTypeSerialized as keyof typeof routes];
        
        if (route) {
            console.log('Navigating to route:', route);
            let params = documentPathToParams(this.path);
            // Use a type assertion here
            root.router.goTo(route as AnyRoute, params as QueryParams);
        } else {
            console.error(`No route found for document type: ${docTypeSerialized}`);
            // Handle the error case, perhaps navigate to a default route
        }
    }

    /**
     * Simplifies the path to the document by removing the document type from the path.
     * For example: 'organization/1234/users/5678' becomes '1234/5678'.
     * @param {string} [path] The path to the document. If not provided, the document's path will be used.
     * @returns {string} The simplified path.
     * */
    simplifiedPath = (path?: string) => {
        if (!path) path = this.path;
        let chunks = path.split('/');
        var i = chunks.length;
        while (i--) (i + 1) % 2 !== 0 && (chunks.splice(i, 1));
        return chunks.join('/');
    }

    /**
     * Converts the path to the document to a URL.
     * For example: 'organization/1234/users/5678' becomes '/1234/5678'.
     * @param {string} path The path to the document.
     * @returns {string} The URL.
     * */
    pathToURL(path: string) {
        let pathChunks = path.split('/');
        let urlChunks: string[] = [];
        pathChunks.map((item, i) => {
            if (i % 2) urlChunks.push(pathChunks[i + 1])
        });
        return '/' + urlChunks.join('/');
    }
}

type DataObject = {
  [key: string]: any;
  // Consider extending this with more specific types as needed
};

function prepareDataForSave(obj: DataObject, originalObj: DataObject): DataObject {
  return Object.keys(obj).reduce<DataObject>((acc, key) => {
    const value = obj[key];

    if (value instanceof firebase.firestore.Timestamp) {
      // Preserve Firebase Timestamps as-is
      acc[key] = value;
    } else if (value === firebase.firestore.FieldValue.serverTimestamp()) {
      // Preserve instructions for server-side timestamp generation
      acc[key] = originalObj[key];
    } else if (typeof value === 'object' && value !== null) {
      // Recursively prepare nested objects
      acc[key] = prepareDataForSave(value, originalObj[key] as DataObject);
    } else {
      // Handle all other types of values
      acc[key] = value;
    }

    return acc;
  }, Array.isArray(obj) ? [] : {});
}
