import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Select, Button, Icon, Popconfirm, Radio } from 'antd';
import store from '../../../../../stores';
import { makeObservable, observable } from 'mobx';
import DynamicGroup from 'stores/DynamicProperties/Types/Group';
import DynamicPropertiesManager from 'stores/DynamicProperties/Manager';
import { PropertyManagerTypes } from 'stores/Creative';

const { Option } = Select;

const ToolbarButton = styled(Button)`
  /* display: flex; */
  align-items: center;
  font-size: 16px;
  margin-right: 7px;
  padding: 0px 9px;
`;

const Label = styled.span`
    margin-right:20px;
`;

const SelectGroup = styled(Button.Group)`
    margin-right: 7px;
    /* margin-left: 7px; */
    display: flex;
    .ant-select-selection {
        border-radius: 0px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-right: -1px;
        z-index: 99;
    }
`;

const SelectButton = styled(ToolbarButton)`
  font-size: 16px;
  margin-right: 0px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
`;

type PropertyDrawerToolbarProps = {
    property: DynamicGroup | DynamicPropertiesManager;
    tabMode: PropertyManagerTypes;
    currentPropertiesManager: DynamicPropertiesManager | DynamicGroup | undefined;
    handleTabChange: (tabMode: PropertyManagerTypes) => void;
};

class PropertyDrawerToolbar extends React.Component<PropertyDrawerToolbarProps> {
    values = [];
    confirmVisible = false;
    saving = false;

    constructor(props: PropertyDrawerToolbarProps) {
        super(props);
        
        makeObservable(this, {
            confirmVisible: observable,
            saving: observable
        });
    }

    componentWillUnmount() {
        this.props.currentPropertiesManager?.unsubscribe(this);
    }

    handleSave = async () => {
        let manager = this.props.currentPropertiesManager as DynamicPropertiesManager;
        if (manager && manager.getChanged()) {
            manager.parentAd.saveDynamicProperties();
        }
    }
    
    handleRevert = async () => {
        let manager = this.props.currentPropertiesManager as DynamicPropertiesManager;
        this.confirmVisible = false;
        if (manager && manager.getChanged()) {
            manager.revert();
        }
    }

    handleApplyTemplateDefaults = () => {
        // TODO: Needs to be re-implemented
    }

    render() {
        this.props.currentPropertiesManager?.subscribe(this);
        // console.log('naa', this.props.currentPropertiesManager?.getChanged());
        let propertyManager = this.props.currentPropertiesManager;
        let loading = true;
        if (propertyManager) loading = propertyManager.loading || false;
        let tabMode = this.props.tabMode;
        let changed = propertyManager?.getChanged() || false;

        return (
            <Container>
                <Label>Properties</Label>
                <Radio.Group value={tabMode} onChange={ e => { this.props.handleTabChange(e.target.value)}}>
                    <Radio.Button disabled={store.creative.ads.current ? false : true} value="Ad">Ad</Radio.Button>
                    <Radio.Button disabled={store.creative.ads.current ? false : true} value="Template">Template</Radio.Button>
                    <Radio.Button value="Creative">Creative</Radio.Button>
                </Radio.Group>
                <span style={{marginLeft: '140px'}} />
                <Popconfirm
                    title="Discard all changes?"
                    visible={this.confirmVisible}
                    onConfirm={this.handleRevert}
                    onCancel={() => { this.confirmVisible = false }}
                    okText="Yes"
                    cancelText="No"
                >
                    <ToolbarButton onClick={() => this.confirmVisible = true} disabled={changed === false}><Icon type="sync" /> Revert</ToolbarButton>
                </Popconfirm>
                <ToolbarButton onClick={this.handleSave} disabled={changed === false}><Icon type="vertical-align-bottom" /> Save</ToolbarButton>
                {/* <ToolbarButton onClick={this.handleApplyTemplateDefaults} disabled={ad.loading}><Icon type="block" /> Load Template Defaults</ToolbarButton> */}
            </Container>
        );
    }
}

export default observer(PropertyDrawerToolbar);
