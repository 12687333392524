import {
  Button as BaseButton,
  Dropdown,
  Icon,
  Menu,
} from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import store from '../../../../../stores';
import styled from 'styled-components';
import Template from 'stores/Template';

const Button = styled(BaseButton)`
  padding: 0 10px;
`;

export interface NewDropdownProps { }

class NewDropdown extends React.Component<NewDropdownProps>{
  constructor(props: NewDropdownProps) {
    super(props);
  }

  // createDefaultAd = async () => {
  //   store.creative.ads.new();
  // }

  createAdFromTemplate = async (template: Template) => {
    store.creative.ads.newFromTemplate(template);
  }

  render() {
    const templates = store.creative.templates.all;
    const currentlyProcessedTemplates = store.creative.templates.processing;
    const disableDropdown = templates.length === 0 || currentlyProcessedTemplates.length > 0;

    const { createAdFromTemplate, /* createDefaultAd */ } = this;

    return (
      <Dropdown
        disabled={disableDropdown}
        trigger={['click']}
        overlay={(
          <Menu>
            {/* <Menu.Item
              onClick={createDefaultAd}
            >
              Default
            </Menu.Item> */}

            {templates.map((template) => (
              <Menu.Item
                key={`creative.newDropdown.template.${template.id}`}
                onClick={() => createAdFromTemplate(template)}
              >
                {template.data.name}
              </Menu.Item>
            ))}
          </Menu>
        )}
      >
        <Button>
          <Icon type="down" />
          New
        </Button>
      </Dropdown>
    );
  }
}

export default observer(NewDropdown);
