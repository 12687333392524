import { DynamicProperty } from './Property';
import { IDynamicProperty } from './index';
import { DynamicPropertyType } from './DynamicPropertyType';


export default class SVGData extends DynamicProperty<string> {
  type = DynamicPropertyType.SVGData;
  name = 'SVGData';
  value = '';
  default = () => '';

  constructor(parent: IDynamicProperty<any>, name: string, value: string) {
    super(parent, name, value);
    // this.init();
  }

  getChanged = () => { // TODO: This is a temporary fix. Real fix needs to be found. Generally SVGData is more like meta data. So it being changed isn't generally important to know.
    return false;
  }

}
