import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { DocumentCollectionType, DocumentStatus, DocumentType, LooseObject, MemberRoles, PublicInfo, } from './Types';
import { makeAutoObservable, reaction } from 'mobx';
import DocumentStore from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";

export class BreadcrumbSchema extends DocumentSchema {}

export default class Breadcrumb extends DocumentStore<PublicInfo, PublicInfo> {
    schema = new BreadcrumbSchema();
    type = DocumentType.NONE;
    url = '';
    
    constructor(parent: any, docRef: DocumentReference, data: PublicInfo) {
        super(parent, docRef);
        this.set(data);
        this.init();
    }

    get name () {
        return this.data?.name;
    }

    setType = (documentType: DocumentType) => {
        this.type = documentType;
    }

    setUrl = (url: string) => {
        this.url = url;
    }
};