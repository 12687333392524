import {
  AvatarContainer,
  FloatingIconContainer,
  Icon,
  Picture,
} from './SsoAvatar.styles';
import React from 'react';

export interface SsoAvatarProps {
  className?: string;
  icon: 'google' | 'google-plus';
  src: string;
}

class SsoAvatar extends React.Component<SsoAvatarProps>{
  constructor(props: SsoAvatarProps) {
    super(props);
  }

  render() {
    const { className, icon, src } = this.props;

    return (
      <AvatarContainer className={className}>
        <Picture src={src} />
        <FloatingIconContainer>
          <Icon type={icon} />
        </FloatingIconContainer>
      </AvatarContainer>
    );
  }
}

export default SsoAvatar;
