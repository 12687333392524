import { observer } from 'mobx-react';
import Asset from 'stores/Asset';
import FileRowButtons from './FileRowButtons';
import FolderRowButtons from './FolderRowButtons';
import AssetTypeIcon from './AssetTypeIcon';
import React from 'react';
import styled from 'styled-components';
import { AssetFileType } from 'stores/Asset/Types';

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
`;

const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 9px;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

type NameLabelProps = { isLoading: boolean; }
const NameLabel = styled.span<NameLabelProps>`
  font-weight: bold;
  opacity: ${({ isLoading }) => isLoading ? 0.5 : 1};
`;

export interface AssetSummaryRowProps {
  asset: Asset;
}

class AssetSummaryRow extends React.Component<AssetSummaryRowProps>{
  constructor(props: AssetSummaryRowProps) {
    super(props);
  }

  render() {
    const { asset } = this.props;
    const { type } = asset.data;
    const { loading: isLoading } = asset;

    return (
      <Container>
        <InfoContainer>
          <AssetTypeIcon asset={asset} />
          <NameLabel isLoading={isLoading}>
            {asset.filename}
          </NameLabel>
        </InfoContainer>

        <ActionsContainer>
          {type === AssetFileType.FOLDER && (
            <FolderRowButtons asset={asset} />
          )}
          {type === AssetFileType.FILE && (
            <FileRowButtons asset={asset} />
          )}
        </ActionsContainer>
      </Container>

    );
  }
};

export default observer(AssetSummaryRow);
