import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Input, Button, Icon, Popconfirm } from 'antd';
import { Comment as AntComment } from 'antd';
import store from '../../../stores';
import type CommentStore from '../../../stores/Comment';

const { TextArea } = Input;

const CommentInput = styled.textarea`
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    padding: 8px 11px;
    resize: none;
    display: flex;
`;

const ThumbnailPlaceholder = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #ededed;
    background: #f6f2f2;
    font-size: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.14);
    overflow: hidden;
`;

const Container = styled.div`
    align-items: center;
    margin:0 10px 10px 10px;
    padding: 6px;
    border: 1px solid #eee;
    border-radius: 5px;
    background: white;

    &:first-child {
        margin:10px;
    }

    .ant-comment {
        width: 100%;
    }

    .ant-comment-inner {
        padding: 0;
    }

    .ant-comment-actions {
        margin: 3px 0;
    }
`;

const CommentDisplay = styled(AntComment)`
    display: block;
    .ant-comment-content-detail {
        white-space: pre-wrap;
    }
`;

type CommentProps = {
    commentStore: CommentStore;
    onCreated: (comment: Comment) => void;
};

class Comment extends React.Component<CommentProps> {
    commentInput: React.RefObject<HTMLTextAreaElement> = React.createRef();
    confirmVisible = false;
    comment: CommentStore;

    constructor(props: CommentProps) {
        super(props);
        this.comment = props.commentStore;
    }

    handleEdit = () => {
        this.comment.toggleEditing();
    };

    handleConfirmEdit = async (content: string) => {
        console.log('handleConfirmEdit', content);
        this.comment.toggleEditing();
        this.comment.setContent(content);
        await this.comment.edit(content);
    }

    handleDelete = async () => {
        await this.comment.delete();
    }

    handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let content = e.target.value;
        let numLines = 1 + content.split(/\r\n|\r|\n/).length;
        if (numLines > 1) e.target.style.height = (25 * numLines) + 'px';
        else e.target.style.height = '40px';
    }

    handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (e.currentTarget.value === '') return;
            this.handleConfirmEdit(e.currentTarget.value);
        }
    }

    renderCommentInput = () => {
        let numLines = 1 + this.comment.data.content.split(/\r\n|\r|\n/).length;
        let inputHeight = numLines * 25;
        let style = { height: `${inputHeight}px` };
        if (numLines === 1) style.height = '40px';

        return (
            <CommentInput
                ref={this.commentInput}
                style={style}
                defaultValue={this.comment.data.content}
                onKeyPress={this.handlePressEnter}
                onChange={this.handleChange}
            />
        );
    }

    focusInput = () => {
        const input = this.commentInput.current;
        if (!input) return;
        input.focus();
        input.value = input.value; // Trigger cursor move to end
    }

    renderThumbnail = () => {
        return this.comment.ownerPhotoURL ?
            <img src={this.comment.ownerPhotoURL} style={{ width: '32px', height: '32px', borderRadius: '50%' }} alt="user" /> :
            <ThumbnailPlaceholder style={{ background: this.comment.thumbnailColor }}><Icon type="user" /></ThumbnailPlaceholder>;
    }

    handleShowConfirm = async () => {
        if (this.confirmVisible) return;
        this.confirmVisible = true;
        await delay(100);
        window.addEventListener('click', this.handleHideConfirm);
    }

    handleHideConfirm = () => {
        if (!this.confirmVisible) return;
        this.confirmVisible = false;
        window.removeEventListener('click', this.handleHideConfirm);
    }

    render() {
        this.props.onCreated(this);
        const actions: React.ReactNode[]  = [];
        if (store.user.authenticated && this.comment.data.owner === store.user.id && !this.comment.editing) {
            actions.push(<span key='comment-edit' onClick={this.handleEdit}>Edit</span>);
            actions.push(
                <Popconfirm
                    key='comment-delete-confirm'
                    title="Are you sure you want to delete this comment?"
                    onConfirm={this.handleDelete}
                    onCancel={this.handleHideConfirm}
                    okText="Yes"
                    cancelText="No"
                >
                    <span key='comment-delete' onClick={this.handleShowConfirm}>Delete</span>
                </Popconfirm>
            );
        }

        if (this.comment.data.owner === store.user.id && this.comment.editing) {
            actions.push(<span key='comment-edit-cancel' onClick={this.handleEdit}>Cancel</span>);
            actions.push(<span key='comment-edit-save' onClick={() => { this.handleConfirmEdit(this.commentInput.current ? this.commentInput.current.value : '') }}>Save</span>);
        }

        return (
            <Container key={this.comment.uid}>
                <CommentDisplay
                    actions={actions}
                    author={this.comment.data.placeholderName || this.comment.ownerDisplayName}
                    avatar={this.renderThumbnail()}
                    content={this.comment.editing ? this.renderCommentInput() : this.comment.data.content}
                    datetime={this.comment.datetime + (this.comment.edited ? ' (edited)' : '')}
                />
            </Container>
        );
    }
}

function delay(time: number, resolveValue = null) {
    return new Promise((resolve) => setTimeout(resolve, time, resolveValue));
}

export default observer(Comment);
