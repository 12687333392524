import { Container } from './NavHeader.styles';
import { Icon } from 'antd';
import { observer } from 'mobx-react';
import { EditDocumentButton, NavBreadcrumb, ProfileButton, Spacer } from 'components/UI';
import React from 'react';
import store from 'stores';
import { reaction } from 'mobx';
import { NavButtons } from 'components/Views/Animation';
import { DocumentType } from 'stores/Types';

export interface NavHeaderProps { }

class NavHeader extends React.Component<NavHeaderProps> {
  constructor(props: NavHeaderProps) {
    super(props);
  }

  render() {
    const { areBreadcrumbsVisible, isMobile,  } = store.ui;
    const breadcrumbs = store.current.breadcrumbs;
    return (
      <Container className={store.ui.collapsed ? 'collapsed' : ''}>
        <Icon
          className='trigger'
          type={store.ui.collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={store.ui.toggleCollapse}
        />
        { areBreadcrumbsVisible && (
          <NavBreadcrumb
            breadcrumbs={breadcrumbs}
          />
        )}
        {!isMobile && breadcrumbs?.all.length > 0 && store.current.canModify && store.router.currentRoute  && (
          <EditDocumentButton />
        )}
        {!isMobile && store.documentType === DocumentType.ANIMATION && store.current.canModify && store.router.currentRoute && (
          <NavButtons />
        )}
        <Spacer />
        <ProfileButton className="profile-button" />
        
      </Container>
    );
  }
}

export default observer(NavHeader);
