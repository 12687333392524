import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  min-height: 53px;
  padding: 10px;
`;

export const ActionButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const TitleLabel = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0px 0px 5px;
`;
