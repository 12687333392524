import { ColumnProps, TableSize } from 'antd/lib/table';
import { getClickHandlersWithAutoControlKey } from 'utils/Mouse/MouseHandlers';
import { PaginationConfig } from 'antd/lib/pagination';
import { PickRename, WithClassName } from 'utils/Types/common';
import { Table as BaseTable } from 'antd';
import { TableWrapper } from 'components/UI';
import { TableWrapperProps } from 'components/UI/Table/TableWrapper/TableWrapper';
import React from 'react';
import TableHeader, { TableHeaderProps } from 'components/UI/Table/TableHeader/TableHeader';

const DEFAULT_PAGINATION_OPTIONS: PaginationConfig = {
  defaultPageSize: 20,
  pageSizeOptions: ['10', '20', '30', '40', '50', '75', '100'],
  showSizeChanger: true
};

interface BaseTableProps<T> extends
  WithClassName,
  Pick<TableHeaderProps, 'title'>,
  Pick<TableWrapperProps, 'variant'>,
  PickRename<TableHeaderProps, 'actionButtons', 'headerButtons'> {
  columnsDefinition: ColumnProps<T>[];
  data: T[];
  isLoading?: boolean;
  onRowClick?: (rowData: T) => void;
  onRowMiddleClick?: (rowData: T) => void;
  onRowDoubleClick?: (rowData: T) => void;
  onRowMouseEnter?: (rowData: T) => void;
  onRowMouseLeave?: (rowData: T) => void;
  paginationConfig?: PaginationConfig | null;
  size?: TableSize;
}

export type TableProps<T> = BaseTableProps<T> & TableWrapperProps;

class Table<T> extends React.Component<TableProps<T>> {
  render() {
    const {
      className,
      columnsDefinition,
      data,
      headerButtons,
      isLoading,
      onRowClick,
      onRowMiddleClick,
      onRowDoubleClick,
      onRowMouseEnter,
      onRowMouseLeave,
      paginationConfig,
      size,
      title,
      ...tableWrapperProps
    } = this.props;

    const pagination = (() => {
      if (paginationConfig) {
        return paginationConfig;
      }

      if (paginationConfig === null) {
        return false;
      }

      return DEFAULT_PAGINATION_OPTIONS;
    })();

    return (
      <TableWrapper
        className={className}
        isLoading={isLoading}
        {...tableWrapperProps}
      >
        <TableHeader
          actionButtons={headerButtons}
          title={title}
        />

        <BaseTable
          columns={columnsDefinition}
          dataSource={data}
          loading={isLoading}
          pagination={pagination}
          size={size}
          onRow={(record: T) => ({
            onDoubleClick: () => onRowDoubleClick?.(record),
            onMouseEnter: () => onRowMouseEnter?.(record),
            onMouseLeave: () => onRowMouseLeave?.(record),
            ...getClickHandlersWithAutoControlKey({
              onClick: () => onRowClick?.(record),
              onMiddleClick: () => onRowMiddleClick?.(record),
            }),
          })}
        />
      </TableWrapper>
    );
  }
};

export default Table;
