import { firestore, db, functionAuthRequest, storageRef } from './Firebase';
import { urlJoin } from 'url-join-ts';
import Document from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import { DynamicPropertyData } from './DynamicProperties/Types';
import { DocumentCollectionType, DocumentType, LogProperties, LooseObject, ProcessingStatus } from './Types';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import Templates from './Templates';
import DynamicPropertiesManager from './DynamicProperties/Manager';
import { appConfig as config } from '../config';
import { fieldValueTimestamp, deepCopy } from 'utils';
import moment from 'moment';

export class TemplateFileProperties {   
    path: string = '';
    status: ProcessingStatus = ProcessingStatus.NONE;
    storage: string = '';
}

export class TemplateSchema extends DocumentSchema {
    createTime = fieldValueTimestamp();
    modifiedTime = fieldValueTimestamp();
    dynamicProperties: DynamicPropertyData [] = [];
    entry: string = '';
    files: TemplateFileProperties [] = [];
    id: string = '';
    log: LogProperties [] = [];
    mutex = 0;
    name: string = '';
    prefix: string = '';
    processing: boolean = false;
    revision: number = NaN;
    storage: string = '';
    seedValue: LooseObject = {};
    status: ProcessingStatus = ProcessingStatus.NEW;
    userId: string = '';
}

export default class Template extends Document<TemplateSchema> {
    schema = new TemplateSchema();
    type = DocumentType.TEMPLATE;
    collectionType = DocumentCollectionType.TEMPLATE;
    downloadURL = '';
    properties = new DynamicPropertiesManager(this, 'Template');

    constructor(parent: Templates, docRef: DocumentReference, data: TemplateSchema){
        super(parent, docRef, data);
        this.init({
            properties: false
        });
    }

    get loading() {
        return this.data.status !== ProcessingStatus.COMPLETE;
    }

    get loaded() {
        return this.data.status === ProcessingStatus.COMPLETE;
    }

    get formattedDateModified() {
        let modifiedTime = this.data.modifiedTime as FirestoreTimestamp;
        return moment(modifiedTime.toDate?.()).format('MMMM D, YYYY, h:mm A');
    }

    setStatus = (status: ProcessingStatus) => {
        this.data.status = status;
    }

    setStatusPending = () => this.setStatus(ProcessingStatus.PENDING);

    saveDynamicProperties = async () => {
        this.data.dynamicProperties = this.properties.getData();
        this.properties.resetChanged();
        await this.save();
    }

    getHostedURL = () =>{
        return urlJoin('https://', config.hostingBucket, this.data.prefix, this.data.entry);
    }

    onReady = async () => {
        console.log('Template ready', this.data.name);
        this.properties.setState(deepCopy(this.data.dynamicProperties));
        this.properties.resetChanged();
        if(this.data.storage !== '') { 
            this.downloadURL = await storageRef.child(this.data.storage).getDownloadURL();
        }
    }

    onUpdate = (data: TemplateSchema) => {
        this.properties.setState(deepCopy(data.dynamicProperties));
        this.properties.resetChanged();
    }

    onReset = () => {
        this.properties = new DynamicPropertiesManager(this);
    }

    setProcessing(isProcessing: boolean = true) {
        this.data.processing = isProcessing;
    }
}