import root, { RootStore } from './index';
import Client from './Client';
import { UserRoles } from './UserTypes';
import { db } from './Firebase';
import { FolderSchema } from './DocumentFolderTypes';
import type DocumentFolder from './DocumentFolder';
import User from './User';
import DocumentCollection from './DocumentCollection';
import DocumentStore from './DocumentStore';

export default class AdminManager extends DocumentStore {
    users = new DocumentCollection(db.collection('users'), User);

    constructor(parent?: any, docRef = undefined) {
        super(parent, docRef);
        this.users.init();
    }

    onLoad = async (data: FolderSchema) => {
        console.log('Loading ')
        await this.users.load();
    }
}