import { firestore, firebase, db, functionAuthRequest } from './Firebase';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { DocumentCollectionType, DocumentStatus, DocumentType, FilterProperty, FilterSet, LooseObject } from './Types';
import root, { RootStore } from 'stores';
import Assets from './Assets';
import { DynamicPropertyData, IDynamicProperty } from './DynamicProperties/Types';
import { DynamicPropertyType } from './DynamicProperties/Types/DynamicPropertyType';
import AdsManager from './AdsManager/index';
import DynamicPropertiesManager from './DynamicProperties/Manager';
import Templates from './Templates';
import Tearsheets from './Tearsheets';
import { FolderSchema } from './DocumentFolderTypes';
import DocumentFolder from './DocumentFolder';
import DocumentFolderCollection from './DocumentFolderCollection';
import DocumentCollection from './DocumentCollection';
import BundlesManager from './BundlesManager';
import ClipboardManager from './ClipboardManager';

import { 
    PanelViews,
    CreativeSchema,
    CreativePublicSchema,
    CreativeParams
} from './CreativeTypes';
import DynamicGroup from './DynamicProperties/Types/Group';
import Template from './Template';
import Dimensions from './DynamicProperties/Types/Dimensions';
import Asset from './Asset';
import { delay } from 'utils';

export enum PropertyManagerTypes {
    AD = 'Ad',
    TEMPLATE = 'Template',
    CREATIVE = 'Creative',
  }

export default class Creative extends DocumentFolder<CreativeParams, CreativeSchema, CreativePublicSchema> {
    schema = new CreativeSchema();
    publicSchema = new CreativePublicSchema();
    type = DocumentType.CREATIVE;
    clipboard = new ClipboardManager(this);
    collectionType = DocumentCollectionType.CREATIVE;    
    
    get defaults() { // TODO: Setup DocumentStore to support this method of getting defaults so that it returns a new instance of the schema. Useful for dynamic values, like getting a newTimestamp on each instance.
        let data = new CreativeSchema();
        let parentCollection = this.parent as DocumentFolderCollection;
        let collectionOwner = parentCollection?.parent ? parentCollection.parent as DocumentFolder<any, CreativeSchema, CreativePublicSchema> : null;
        if (parentCollection && collectionOwner) {
            data.members = collectionOwner.data.members;
            data.memberKeys = collectionOwner.data.memberKeys;
        }
        data.author = root.user.id;
        return data;
    }
    
    generating = false;
    ads = new AdsManager(this);
    tearsheets = new Tearsheets(this); // TODO: this will be connected to bundles.
    templates = new Templates(this);
    assets = new Assets(this);
    bundles = new BundlesManager(this);
    dynamicProperties = new DynamicPropertiesManager(this, 'Creative');
    author = '';
    
    failed = false;
    error = '';
    displayPanel: PanelViews = PanelViews.NONE;
    lastDisplayPanel: PanelViews = PanelViews.NONE;
    viewScale = 1;
    currentPropertyManager: DynamicPropertiesManager | undefined = this.dynamicProperties;
    currentPropertyManagerType: PropertyManagerTypes;

    parent: DocumentCollection | null;

    constructor(parent: any, docRef: DocumentReference, data?: CreativeSchema) {
        super(parent, docRef, data);
        this.init();
    }

    // navigateTo = () => {
    //     console.log('ROUTE', root.routes.creative);
    //     console.log('PARAMS', this.params);
    //     root.router.goTo(root.routes.creative, this.params);
    // }

    onBeforeSave = async () => {
        this.data.filterSets = this.ads.filter.sets.getState().value;
    }

    setCurrentPropertyManager = (mode: PropertyManagerTypes) => {
        if(mode === PropertyManagerTypes.TEMPLATE) {
            this.currentPropertyManager = this.ads.current?.template?.properties;
        } else if(mode === PropertyManagerTypes.AD) {
            this.currentPropertyManager = this.ads.current?.properties;
        } else if(mode === PropertyManagerTypes.CREATIVE) {
            this.currentPropertyManager = this.dynamicProperties;
        }
    }

    setDefaultDynamicProperties = (template?: Template) => {
        let props = this.dynamicProperties;
        if(!props.get('template')) props.add('template', DynamicPropertyType.TEMPLATE, template ? template.id : '');
        if(!props.get('dimensions')) { 
            props.add('dimensions', DynamicPropertyType.DIMENSIONS, undefined);
            let dimensions = props.get('dimensions') as Dimensions;
            dimensions.width = 300;
            dimensions.height = 250;
        }
        if(!props.get('label'))                  props.add('label', DynamicPropertyType.LABEL, 'Untitled');
        if(!props.get('backupImageFilesize'))    props.add('backupImageFilesize', DynamicPropertyType.NUMBER, 40000);
        if(!props.get('backupImageScale'))       props.add('backupImageScale', DynamicPropertyType.NUMBER, 1);
        if(!props.get('bundleFilesize'))         props.add('bundleFilesize', DynamicPropertyType.NUMBER, 150000);

        if(template) props.merge(template.properties.properties);

        props.resetChanged();
    }

    saveDynamicProperties = async () => {
        this.data.dynamicProperties = this.dynamicProperties.getData();
        this.dynamicProperties.resetChanged();
        await this.save();
    }

    onReady = async () => {
        this.dynamicProperties.setState(this.data.dynamicProperties);
        this.dynamicProperties.resetChanged();
    }

    onLoad = async (data: FolderSchema) => {
        // this.projects.query = this.creatives.collectionRef.orderBy('dateModified', 'desc');
        await this.breadcrumbs.load();
        if (this.stub) return;
        await this.members.load();
        await this.assets.load();
        await this.templates.load();
        await this.tearsheets.load();
        await this.bundles.load();
        await this.ads.load();
        this.ads.filter.sets.setState(this.data.filterSets);
        console.log('CREATIVE LOAD END');
    }

    onUpdated = () => {
        this.dynamicProperties.setState(this.data.dynamicProperties);
        this.dynamicProperties.resetChanged();
    }

    setPanel = (panelView: PanelViews, toggle = false) => {
        if(panelView !== this.displayPanel) {
            this.lastDisplayPanel = this.displayPanel;
        }
        if(toggle && panelView === this.displayPanel) {
            return this.displayPanel = PanelViews.NONE;
        }
        this.displayPanel = panelView;
    }

    setViewScale = (scale: number) => {
        this.viewScale = scale;
    }

    addSelectedFilterProperty = (property: IDynamicProperty<any>) => {
        this.ads.addSelectedFilterProperty(property);
    }

    removeSelectedFilterProperty = (property: IDynamicProperty<any>) => {
        this.ads.removeSelectedFilterProperty(property);
    }

    reset = () => {
        super.reset();
        this.status = DocumentStatus.NONE;

        this.generating = false;
        this.ads.reset();
        this.ads = new AdsManager(this);
        this.tearsheets.reset();
        this.tearsheets = new Tearsheets(this);
        this.templates.reset();
        this.templates = new Templates(this);
        this.assets.reset();
        this.assets = new Assets(this);
        this.bundles.reset();
        this.bundles = new BundlesManager(this);
        this.dynamicProperties.reset();
        this.dynamicProperties = new DynamicPropertiesManager(this);
        this.author = '';

        this.failed = false;
        this.error = '';
        this.displayPanel = PanelViews.NONE;
        this.viewScale = 1;
    }
}