/**
 * A place to store (and later call) the unlisten functions returned by
 * Firestore's `onSnapshot` method.
 */
 export default class DisposerManager {
    _callbacks: Function[] = [];

    add(...callbacks: Function[]) {
        this._callbacks.push(...callbacks);
    }

    clear() {
        const callbacks = this._callbacks.splice(0);
        for (let i = 0; i < callbacks.length; i++) {
            const cb = callbacks[i];
            cb();
        }
    }
}