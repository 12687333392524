import { observer } from 'mobx-react';
import { AnimationPanelViews, AnimationPanelViews as PanelViews } from 'stores/Animation';
import VersionFileRow from './VersionFileRow';
import React from 'react';
import store from 'stores';
import Drawer from '../Drawer';
import UploadVersionButton from './UploadVersionButton';
import { getFilenameExtention } from 'utils/index';
import styled from 'styled-components';
import { UploadStates } from 'stores/Assets';

const DrawerBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #E6E6E6;
  margin: 10px;
  background: #ffffff;
  border-radius: 8px;
`;

export interface VersionsDrawerProps { }

class VersionsDrawer extends React.Component<VersionsDrawerProps>{
  constructor(props: VersionsDrawerProps) {
    super(props);
    // subscribe(this, store.animation.assets);
    store.animation.assets.addEventListener(UploadStates.UPLOAD_COMPLETE, () => console.log('ALL DONE.'))
  }

  render() {
    const { uploadAnimationFile } = store.animation;

    const assets = [...store.animation.assets.folderContext];

    return (
        <Drawer 
          label="Versions"
          panelName={AnimationPanelViews.VERSIONS}
          enableDrop={true}
          handleOnDrop={uploadAnimationFile}
          headerContent={<UploadVersionButton onSelected={uploadAnimationFile} />}
        >
          { assets.length > 0 && 
            <DrawerBodyContainer>
              {assets.map((asset) => (
                <VersionFileRow
                  asset={asset}
                  key={`animation.assetsDrawer.asset.${asset.id}`}
                />
              ))}
            </DrawerBodyContainer> 
          }
        </Drawer>
    );
  }
};

export default observer(VersionsDrawer);
