import { observer } from 'mobx-react';
import { TearsheetViewMobileContentMode } from 'stores/Tearsheet';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import TearsheetAd from 'stores/TearsheetAd';

const Container = styled.div`
  border-top: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const AdRow = styled.div`
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  color: #000;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 44px;
  padding: 0 5px;
`;

const IndexLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1px;
  margin-left: 3px;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 300;
`;

class AdList extends React.Component {
  constructor(props: {}) {
    super(props);
  }

  handleAdClick = (targetAd: TearsheetAd) => {
    const { tearsheet } = store;

    tearsheet.setMobileContentMode(TearsheetViewMobileContentMode.VIEW);
    tearsheet.ads.getById(targetAd.id)?.navigateTo();
  }

  render() {
    const { handleAdClick } = this;
    const { tearsheet } = store;
    const ads = [...tearsheet.ads.all];

    return (
      <Container>
        {ads.map((ad, i) => (
          <AdRow
            key={`tearsheet.mobileAdList.${ad.id}`}
            onClick={() => handleAdClick(ad)}
          >
            <IndexLabel>{i + 1}</IndexLabel>
            <Label>{ad.data.label}</Label>
          </AdRow>
        ))}
      </Container>
    );
  }
};

export default observer(AdList);
