import { Button } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { UserRoles } from 'stores/UserTypes';
import AddClientModal from './AddClientModal';
import React from 'react';
import store from 'stores'
import DocumentFolder from 'stores/DocumentFolder';

export const ADD_CLIENT_BUTTON_LABEL = 'Add Client';

export interface AddClientButtonProps {
  parentDocument: DocumentFolder;
};

class AddClientButton extends React.Component<AddClientButtonProps>{
  isModalVisible: boolean = false;

  constructor(props: AddClientButtonProps) {
    super(props);

    makeObservable(this, {
      isModalVisible: observable,
    });
  }

  showModal = () => {
    this.isModalVisible = true;
  }

  hideModal = () => {
    this.isModalVisible = false;
  }

  render() {
    const parentDocument = store.clients;
    const creationEnabled = store.user.role === UserRoles.ADMIN || store.user.role === UserRoles.MANAGER;
    const { isModalVisible, hideModal, showModal } = this;

    return (
      <>
        <Button
          disabled={!creationEnabled}
          onClick={showModal}
        >
          {ADD_CLIENT_BUTTON_LABEL}
        </Button>

        {creationEnabled && (
          <AddClientModal
            isVisible={isModalVisible}
            onClose={hideModal}
            parentDocument={parentDocument}
          />
        )}
      </>
    )
  }
}

export default observer(AddClientButton);
