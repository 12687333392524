import FiltersGroup from './FiltersGroup';
import NewDropdown from './NewDropdown';
import React, { HTMLAttributes } from 'react';
import SelectionGroup from './SelectionGroup';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid #f2ebeb;
  border-top: 1px solid #f2ebeb;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 7px;
  width: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export interface CreativeAdListToolbarProps extends HTMLAttributes<HTMLDivElement> { }

class CreativeAdListToolbar extends React.Component<CreativeAdListToolbarProps>{
  constructor(props: CreativeAdListToolbarProps) {
    super(props);
  }

  render() {
    return (
      <Container { ...this.props }>
        <LeftContainer>
          <SelectionGroup />
          <NewDropdown />
        </LeftContainer>

        <FiltersGroup />
      </Container>
    );
  }
}

export default observer(CreativeAdListToolbar);
