import { Breadcrumb } from '../../../stores/Types';
import { Container, PrincipalChunk, SecondaryChunk, Separator } from './BreadcrumbCell.styles';
import React from 'react';

export interface BreadcrumbCellProps {
  breadcrumbs: Breadcrumb[];
}

class BreadcrumbCell extends React.Component<BreadcrumbCellProps>{
  constructor(props: BreadcrumbCellProps) {
    super(props);
  }

  render() {
    const { breadcrumbs } = this.props;
    const principalItem = breadcrumbs[breadcrumbs.length - 1];
    const secondaryItems = breadcrumbs.slice(0, -1);

    return (
      <Container>
        {secondaryItems.map((item) => (
          <>
            <SecondaryChunk>
              {item.name}
            </SecondaryChunk>

            <Separator />
          </>
        ))}

        <PrincipalChunk>
          {principalItem.name}
        </PrincipalChunk>
      </Container>
    );
  }
}

export default BreadcrumbCell;
