import { Popconfirm } from 'antd';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores';
import Tearsheet from 'stores/Tearsheet';

export interface RemoveTearsheetButtonProps {
  tearsheet: Tearsheet;
}

class RemoveTearsheetButton extends React.Component<RemoveTearsheetButtonProps>{
  constructor(props: RemoveTearsheetButtonProps) {
    super(props);
  }

  removeTearsheet = async () => {
    const { tearsheet } = this.props;
    await store.creative.tearsheets.remove(tearsheet.id);
  }

  render() {
    const { removeTearsheet } = this;

    return (
      <Popconfirm
        cancelText='No'
        okText='Yes'
        onConfirm={removeTearsheet}
        placement='bottomRight'
        title='Are you sure you want to remove this tearsheet?'
      >
        <DrawerButton>
          Remove
        </DrawerButton>
      </Popconfirm>
    );
  }
};

export default RemoveTearsheetButton;
