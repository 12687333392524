import { firestore, db, functionAuthRequest, FirestoreEvents } from './Firebase';
import { DocumentReference, CollectionReference, QuerySnapshot, Query, DocumentData, QueryDocumentSnapshot, FieldValue } from '@firebase/firestore-types';
import { DocumentCollectionType, DocumentStatus, DocumentType, Newable } from './Types';
import Store from './Store';
import DocumentStore, { DocumentEvents } from './DocumentStore';
import { observable } from 'mobx';
import { arrayToChunks, delay, deepCopy } from 'utils';
import store from 'stores';

type Nullable<T> = T | undefined | null;

export enum DocumentCollectionEvents {
    REMOVED = 'removed',
    ADDED = 'added',
    CHANGED = 'changed',
    ADDED_LOCAL = 'added_local',
    LOADED = 'loaded',
}

export class Filter {
    name: string;
    values: any[];

    constructor(name: string, values: any[], match: boolean = true) {
        this.name = name;
        this.values = values;
    }
}

/**
 * @class DocumentCollection
 * @description
 * The DocumentCollection class is used to manage a collection of `DocumentStore` instances. 
 * It manages the lifecycle of the child documents as documents on the Firestore database are updated, modified, creatived, and removed.
 * It also provides methods for querying and filtering the collection. 
 * 
 * @example
 * ```
 * let myCollection = new DocumentCollection(myParent, MyDocumentStoreClassReference);
 * ```
 * 
 * @param {DocumentStore | CollectionReference} parent - The parent of the collection. This can be either a `DocumentStore` or a `CollectionReference`. 
 * @param {Newable<ChildType>} childClass - The class of the child documents. This is used to create new instances of the child documents.
 * */
export default class DocumentCollection<ChildType extends DocumentStore<any> = any, ChildSchema = any> extends Store {
    children: ChildType[] = observable.array([]);
    // collectionRef: CollectionReference;
    // collectionType: DocumentCollectionType;
    childType: DocumentType = DocumentType.DEFAULT;
    childClass: Newable<ChildType>;
    status = DocumentStatus.LOADING;
    parent: DocumentStore<any>;
    parentCollectionRef: CollectionReference;
    hasChildren = false;
    // filtering: boolean = false;
    filters: Filter[] = [];
    filtersLastModified: number = NaN;
    mounted = false;

    /**
     * The query method is used to get the query for the collection. This can be overridden to provide custom filtering and sorting.
     * 
     * @example
     * ```
     * query = () => {
     *  return this.collectionRef.where('archived', '==', true).orderBy('dateModified', 'desc');
     * }
     * // sets the query to return all archived documents, sorted by dateModified
     * ```
     * 
     * @returns {Query} - The query for the collection.
     * */
    query = (): Query => {
        return this.collectionRef;
        // return this.collectionRef.where('archived', '==', false).orderBy('dateModified', 'desc');
        // if(this.filters.length > 0) {
        //     this.filtering = true;
        //     this.filters.forEach(f => {
        //         query = query.where(f.name, f.match ? 'in' : 'not-in', f.values);
        //     }
        //     );
    }

    batchSize: number = 400;
    childObject: ChildType | null = null;
    childClassProxy: any;
    /**
     * If the child class has a `collectionType` property, it will be used as the collection type for the collection and return that value.
     * Otherwise, it will return the default collection type.
     * */
    get collectionType(): DocumentCollectionType {
        if (this.childObject) return this.childObject.collectionType;
        return DocumentCollectionType.DEFAULT;
    }
    
    /**
     * Returns the Firestore collection reference for the collection.
     * */
    get collectionRef() {
        // if(!this.collectionType) console.log('GETTING COLLECTION TYPE', this.collectionType, this.childObject?.collectionType);
        let parent = this.parent;
        if (parent instanceof DocumentStore && this.parent.docRef) {
            return this.parent.docRef.collection(this.collectionType);
        } else {
            return this.parentCollectionRef;
        }
    }

    constructor(parent: DocumentStore<any, any> | CollectionReference, childClass: Newable<ChildType>) {
        super();
        if (parent instanceof DocumentStore) this.parent = parent;
        else this.parentCollectionRef = parent;
        this.childObject = new childClass(this);
        this.childType = this.childObject.type;
        // this.collectionType = this.childObject.collectionType;
        // if(parent instanceof DocumentStore && this.parent.docRef) {
        //     this.collectionRef = this.parent.docRef.collection(this.collectionType);
        // } else {
        //     this.collectionRef = parent as CollectionReference;
        // }
        this.childClass = childClass;
        this.childClassProxy = new Proxy(this.childClass, {
            apply(target, thisArg, argumentsList) {
                return new target(...argumentsList);
            }
        });
        if (!this.collectionType) throw new Error('DocumentCollection: Collection type not defined for child class: ' + childClass.constructor.name);
        // this.init({ // TODO: Not required for now but may be in the future. Find a way to exclude state management on properties from the this base class.
        //     _onCreate: false
        // });
    }

    get all() {
        return this.children;
    }

    /**
     * Returns all documents in the collection that are not archived.
     * */
    get allAvailable() {
        return this.all.filter(item => !item.data.archived);
    }
    
    get length() {
        return this.children.length;
    }
    
    /**
     * Returns all documents in the collection that exist locally only. These documents have not been saved to the database yet.
     * Could be used to save all local documents to the database.
     * */
    get allLocal() {
        return this.children.filter(item => item.isLocalOnly === true);
    }
    
    get context() {
        return this.parent.context;
    }
    
    // get context() {
        //     let context: string;
        //     if(this.parent) context = this.parent.context;
        //     else context = this.collectionRef.path;
        //     return context;
        // }
        
    get loading() {
        return this.status === DocumentStatus.LOADING;
    }
        
    get loaded() {
        return this.status === DocumentStatus.LOADED;
    }
        
    /**
     * Returns a promise that resolves to a boolean indicating whether the collection exists in the database.
     * */
    exists = async () => {
        let collection = await this.collectionRef.get();
        return collection.docs.length > 0;
    }
    
    setFilters(value: Filter[]) {
        this.filters = value;
    }

    addFilter(name: string, value: any) {
        // find a filter with a matching name and insert the new value 
        let filters = [...this.filters];
        let filter = filters.find(f => f.name === name);
        if (filter) {
            filter.values.push(value);
        } else {
            filters.push(new Filter(name, [value], false));
        }
        this.filters = filters;
    }

    removeFilter(name: string, value: any = null) {
        // find a filter with a matching name and remove the value
        let filters = [...this.filters];
        let filter = filters.find(f => f.name === name);
        if (filter) {
            if (value) {
                filter.values = filter.values.filter(v => v !== value);
            } else {
                this.filters = filters.filter(f => f.name !== name);
            }
        }
    }

    updateFilterStatus = () => { // TODO: check if this is needed if things are updating properly. this might have been a hack from a while ago.
        // this.filtersLastModified = new Date().getTime();
        // if (this.filters.length === 0) this.filtering = false;
        // else this.filtering = true;
    }

    getFilterValues = (propertyName: string) => {
        for (let i in this.filters) {
            let property = this.filters[i];
            if (property.name === propertyName)
                return property.values;
        }
        return null;
    }

    getFilterProperties = (propertyName: string) => {
        for (let i in this.filters) {
            let property = this.filters[i];
            if (property.name === propertyName) {
                return property;
            }
        }
        return null;
    }

    /**
     * Loads the collection from the Firestore database.
     * */
    load = async () => {
        console.log('LOADING COLLECTION', this.collectionType);
        this.reset();
        // if(!this.collectionRef && this.parent) this.collectionRef = this.parent.docRef.collection(this.collectionType);
        
        this.status = DocumentStatus.LOADING;
        this.disposer.add(this.collectionRef.onSnapshot(this.onUpdate));
        
        this.children = await this.loadData();
        await this.onLoad();
        if(this.mounted === false) this.dispatch(DocumentCollectionEvents.LOADED, this);
        this.mounted = true;
        this.onReady();
        this.status = DocumentStatus.LOADED;
    }
    
    /**
     * This method is called after the collection has been loaded from the database. It is a place holder for child classes to override.
     * */
    onLoad = async () => {
        // This is a place holder for child classes to override.
    };
    
    /**
     * This method is called after the collection's onLoad method has resolved. It is a place holder for child classes to override.
     * */
    onReady = () => { };

    /**
     * This method is called after each child document is created. It is a place holder for child classes to override.
     * */
    onCreate = (store: ChildType) => { };

    reload = async () => {
        await this.load();
    }

    _onCreate = async (docRef: DocumentReference, data: any): Promise<ChildType> => {
        if (!this.childClass) {
            console.warn('AdHub: No "childClass" provided for DocumentCollection');
            return {} as ChildType;
        }
        let newChild = new this.childClass(this, docRef);
        data = Object.assign(newChild.defaults, data);
        newChild._update(data);
        newChild.onReady();
        newChild.addEventListener(DocumentEvents.CHANGED, this.onChildChanged);

        // TODO: refactor the owner metadata getter for
        // being agnostic to the DocumentCollection.
        if (newChild.data.owner) {
            const ownerMetadata = await store.usersCache.getById(newChild.data.owner);
            newChild.data.ownerMetadata = ownerMetadata;
        }

        return newChild;
    }

    loadData = async () => {
        let response;
        // if(this.query) {
        response = await this.query().get();
        // } else {
        //     response = await this.collectionRef.where('archived', '==', false).orderBy('dateModified', 'desc').get();
        // }
        this.hasChildren = response.docs.length > 0;
        if (this.hasChildren) {
            console.log('DocumentCollection: Loading ' + response.docs.length + ' ' + this.collectionType + ' documents');
            let childrenData = response.docs;
            let children = [];

            for (let i in childrenData) {
                let doc = childrenData[i];
                let data = doc.data() as ChildSchema;
                let docRef = doc.ref;
                let newDoc = await this._onCreate(docRef, data);
                newDoc.updatesEnabled = false;
                children.push(newDoc);
                this.onCreate(newDoc);
            };
            return children;
        } else {
            console.log('DocumentCollection: No ' + this.collectionType + ' documents found');
            return [];
        }
    }

    /**
     * Adds a new document to the collection with the provided data.
     * @param data The data to be added to the new document.
     * @returns The newly created document.
     * */
    add = async (data: Partial<ChildSchema>): Promise<ChildType> => {
        let docRef: DocumentReference = this.collectionRef.doc();
        let newChild = await this._onCreate(docRef, data);
        newChild.addEventListener(DocumentEvents.CHANGED, this.onChildChanged);
        newChild.status = DocumentStatus.LOADING;
        // this.dispatch(DocumentCollectionEvents.ADDED_LOCAL, newChild); // This is likely not needed since the Firebase API should fire an added event instantly.
        this.parent?.updateDateModified(); // We don't await this because it's not critical to know when it has completed.

        await newChild.save();
        this.children.push(newChild);

        return newChild;
    }

    /**
     * Adds a new document to the collection with the provided data. This document will not be synced with the database but will exist locally.
     * @param data The data to be added to the new document.
     * @returns The newly created document.
     * */
    addLocal = async (data: ChildSchema) => {
        console.log('DocumentCollection: Adding local ' + this.collectionType + ' document');
        // TODO: might be possible to add these to a firebase batch, until they are to be synced. then commit the batch.
        let stub = await this._onCreate(this.collectionRef.doc(), data);
        stub.isLocalOnly = true;
        console.log('IS LOCAL ONLY: ' + stub.isLocalOnly);
        this.children.push(stub);
        this.dispatch(DocumentCollectionEvents.ADDED_LOCAL, stub);
        this.dispatch(DocumentCollectionEvents.ADDED, stub);
        return stub;
    }


    /**
     * Creates a new document in the collection with the provided data. This document is not added as a child and simply returns a new document.
     * @param data The data to be added to the new document.
     * @returns The newly created document.
     * */
    create = async (data: ChildSchema) => {
        console.log('DocumentCollection: Adding local ' + this.collectionType + ' document');
        // TODO: might be possible to add these to a firebase batch, until they are to be synced. then commit the batch.
        let stub = await this._onCreate(this.collectionRef.doc(), deepCopy(data));
        stub.isLocalOnly = true;
        return stub;
    }

    /**
     * Adds multiple documents to the collection with the provided data in the form of an array of document data.
     * @param dataSet An array of document data to be used to create the new documents.
     * @param insertIndex The index to insert the new documents at. If not provided, the documents will be added to the end of the collection.
     * */
    addMultiple = async (dataSet: ChildSchema[], insertIndex?: number) => {
        this.addMultipleLocal(dataSet, insertIndex);
        await this.commitLocal();
    }

    /**
     * Adds multiple documents to the collection with the provided data in the form of an array of document data. 
     * These documents will not be synced with the database but will exist locally.
     * @param dataSet An array of document data to be used to create the new documents.
     * @param insertIndex The index to insert the new documents at. If not provided, the documents will be added to the end of the collection.
     * */
    addMultipleLocal = async (dataSet: ChildSchema[], insertIndex?: number) => {
        let children = [ ... this.children ];
        let newDocs:ChildType[] = [];

        for (let data of dataSet) {
            newDocs.push(await this.create(data));
        }

        if(insertIndex !== undefined) {
            children.splice(insertIndex, 0, ...newDocs);
        } else {
            children.push(...newDocs);
        }

        this.children = children;
    }

    /**
     * Removes multiple documents from the collection by an array of references to child documents.
     * @param set An array of document ids to be removed from the collection.
     * */
    removeMultiple = async (set: ChildType[]) => { // Remove multiple children by their id and commit them in batches to Firestore.
        let chunks = arrayToChunks(set, this.batchSize);
        
        for (let chunk of chunks) {
            let batch = this.collectionRef.firestore.batch();
            for (let child of chunk) {
                batch.delete(child.docRef);
            }
            await batch.commit();
        }
        this.parent?.updateDateModified();
    }
    /**
     * Removes multiple documents from the collection by their id in the form of an array of document ids.
     * @param set An array of document ids to be removed from the collection.
     * */
    removeMultipleById = async (set: string[]) => { // Remove multiple children by their id and commit them in batches to Firestore.
        let chunks = arrayToChunks(set, this.batchSize);
        
        for (let chunk of chunks) {
            let batch = this.collectionRef.firestore.batch();
            for (let childId of chunk) {
                let child = this.getById(childId);
                if (child) batch.delete(child.docRef);
            }
            await batch.commit();
        }

        this.parent?.updateDateModified();
    }

    /**
     * Commits all local documents to the database. This method is called automatically when using the addMultiple() method.
     * */
    commitLocal = async () => {
        let batch = this.collectionRef.firestore.batch();
        let children = [... this.allLocal];

        let batchSize = this.batchSize;
        let index = 0;
        let complete = false;

        let currentBatch = [];

        while (batchSize-- && index < children.length) {
            let doc = children[index];
            currentBatch.push(doc);
            batch.set(doc.docRef, doc.data);
            index++;
        }

        if (index === children.length) {
            complete = true;
        }

        await batch.commit();

        for (let doc of currentBatch) {
            doc.isLocalOnly = false;
        }

        if (complete === false) {
            await this.commitLocal();
        }
    }

    /**
     * Removes a document from the collection by its id. This change will not be synced with the database and will only exist locally.
     * @param id The id of the document to be removed.
     * */
    removeLocal = (id: string) => {
        var children = [...this.children];
        for (var i = children.length; i >= 0; i--) {
            var child = children[i];
            if (child.id === id) children.splice(i, 1);
        }
        this.children = children;
    };

    /**
     * Gets a document from the collection by its id.
     * @param id The id of the document to be retrieved.
     * @returns The document with the provided id.
     * */
    getById = (id: string) => {
        for( let i = 0; i < this.children.length; i++) {
            let child = this.children[i];
            if (child.id === id) return child;
        }
    }

    /**
     * Gets a document from the collection by its name.
     * @param name The name of the document to be retrieved.
     * @returns The document with the provided name.
     * */
    getByName = (name: string) => {
        for (let item of this.all) {
            if (item.data.name === name) {
                return item;
            }
        }
    }

    /**
     * Removes a document from the collection by its id. This change will be synced with the database.
     * @param id The id of the document to be removed.
     * */
    remove = async (id: string) => {
        let child = this.getById(id);
        if (!child) return;

        child.reset();
        child.removeEventListener(DocumentEvents.CHANGED, this.onChildChanged);
        await child.delete();
        this.parent.updateDateModified();
        return id;
    }

    /**
     * Saves all documents with changes to the database in batches to avoid exceeding the Firestore write limit.
     * */
    saveAll = async () => { // Save all documents with changes.
        let batch = this.collectionRef.firestore.batch();
        let children = [... this.children];

        let batchSize = this.batchSize;
        let index = 0;
        let complete = false;

        let currentBatch = [];

        while (batchSize-- && index < children.length) {
            let doc = children[index];
            currentBatch.push(doc);
            batch.set(doc.docRef, doc.data);
            index++;
        }

        if (index === children.length) {
            complete = true;
        }

        await batch.commit();

        // if (complete === false) { // Does not appear to be necessary.
        //     await this.commitLocal();
        // }
    }

    /**
     * Archives a document by its id. This sets the document property `archive` to true. 
     * @param id The id of the document to be archived.
     * */
    archive = async (id: string) => {
        const targetDoc = this.getById(id);
        if (targetDoc?.data) {
            targetDoc.data.archived = true;
        }

        await this.collectionRef.doc(id).update({ archived: true });
        return id;
    }

    /**
     * Unarchives a document by its id. This sets the document property `archive` to false.
     * @param id The id of the document to be unarchived.
     * */
    unarchive = async (id: string) => {
        const targetDoc = this.getById(id);
        if (targetDoc?.data) {
            targetDoc.data.archived = false;
        }

        await this.collectionRef.doc(id).update({ archived: false });
        return id;
    }

    /**
     * Archives all documents in the collection. This sets the document property `archive` to true for all children.
     * */
    unarchiveAll = () => {
        this.all.forEach((doc) => {
            this.unarchive(doc.id);
        });
    }

    /**
     * This is a private method meant is called when any document in the collection is updated, added, or removed.
     * @param snapshot The snapshot of the collection.
     * */
    onUpdate = async (snapshot: QuerySnapshot) => {
        console.log('DocumentCollection('+this.childClass.name+') EVENT: onUpdate');
        if (this.loading) return;
        let changed = snapshot.docChanges();
        for (let change of changed) {
            let doc: QueryDocumentSnapshot = change.doc;
            if (change.doc.metadata.hasPendingWrites) return; // !!! firestore will attempt to update data locally instantly. but some things don't work right with this.
            if (change.type === FirestoreEvents.COLLECTION_ADDED) {
                this.onAdded(doc);
            }
            if (change.type === FirestoreEvents.COLLECTION_MODIFIED) {
                this.onModified(doc);
            }
            if (change.type === FirestoreEvents.COLLECTION_REMOVED) {
                this.onRemoved(doc);
            }
        }
    }

    /**
     * This is a private method is called when a document is added to the collection.
     * It uses the provided child class reference to create a new instance of that class, hydrates it with the data from the document, and adds it to the collection as a child
     * @param doc The Firestore DocumentSnapshot that was added to the collection.
     * */
    private onAdded = async (doc: QueryDocumentSnapshot) => {
        console.log('DocumentCollection EVENT: onAdded', doc.id);
        let childExists = this.getById(doc.id) ? true : false;
        if (childExists) {
            this.onModified(doc);
            return;
        }

        let data = doc.data() as ChildSchema;
        let docRef = doc.ref;

        let newChild = await this._onCreate(docRef, data); // TODO: docmanager - figure this out
        newChild.onCreate(newChild.data);

        this.dispatch(DocumentCollectionEvents.ADDED, newChild);
        this.dispatch(DocumentCollectionEvents.CHANGED, newChild);
        this.children.push(newChild);
    }

    /**
     * This is a private method is called when a document is removed from the collection.
     * */
    onRemoved = (doc: QueryDocumentSnapshot) => {
        let docId = doc.id;

        for (let i = 0; i < this.children.length; i++) {
            const document = this.children[i];
            if (document.id === docId) {
                let child = this.children[i];
                this.dispatch(DocumentCollectionEvents.REMOVED, child);
                this.dispatch(DocumentCollectionEvents.CHANGED, child);
                child.reset();
                child._removed();
                this.children.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    /**
     * This is a private method is called when a document is modified in the collection.
     * @param doc The Firestore DocumentSnapshot that was modified in the collection.
     * */
    onModified = (doc: QueryDocumentSnapshot) => {
        // console.log('DocumentCollection EVENT: onModified');
        let data = doc.data() as ChildSchema;
        let docRef = doc.ref;
        let document = this.getById(docRef.id);
        // item.onUpdate(data);
        // document?.updateValidate(docPropeties); // TODO: docManager - updateValidate doesn't exist yet. maybe create it in the future.
        // check if data, which is an object, has changed
        if(document){
            let isChanged = JSON.stringify(document.data) !== JSON.stringify(data);
            if(isChanged) {
                document?._update(data);
                this.dispatch(DocumentCollectionEvents.CHANGED, document);
            }
        }

    }

    /**
     * This is a private method is called when a child document dispatches a change event. This is useful for listening to any changes that have happened to any document in the collection.
     * @param child The child document that emmitted the change event.
     * */
    onChildChanged = (child: Document) => {
        console.log('DocumentCollection EVENT: onChildChanged');
        this.dispatch(DocumentCollectionEvents.CHANGED, child);
    }
    
    /**
     * This method resets the collection to its initial state. It clears all children, disposes of all listeners, and resets all properties.
     * */
    reset() {
        this.disposer.clear();
        this.children = [];
        // this.collectionType = DocumentCollectionType.NONE;
        this.childType = DocumentType.NONE;
        this.status = DocumentStatus.NONE;
        this.filters = [];
        this.mounted = false;
    }
}