import { FILTER_MANAGER_DEFAULT_SET_NAME } from 'stores/FilterManager';
import { Icon as BaseIcon, Select as BaseSelect } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const CREATE_OPTION_VALUE = 'create';

const { Option } = BaseSelect;

const Select = styled(BaseSelect)`
  width: 200px;

  .ant-select-selection {
    border-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-right: -1px;
    z-index: 1;
  }
`;

const EditIcon = styled(BaseIcon).attrs({
  type: 'edit',
})`
  margin-right: 4px;
`;

export interface FilterSetsDropdownProps {
  className?: string;
}

class FilterSetsDropdown extends React.Component<FilterSetsDropdownProps>{
  search = '';

  constructor(props: FilterSetsDropdownProps) {
    super(props);

    makeObservable(this, {
      search: observable,
    });
  }

  handleSearchOnChange = (value: string) => {
    this.search = value;
  }

  handleSelectionOnChange = (value: unknown) => {
    const { search } = this;
    const setName = value as string;

    if (!setName) {
      store.creative.ads.filter.resetToCustomSet();
      this.search = '';
    }

    if (setName === CREATE_OPTION_VALUE) {
      store.creative.ads.filter.openNewSetModal(search);
      return;
    }

    store.creative.ads.filter.setCurrentSetByName(setName);
  }

  render() {
    const {
      handleSearchOnChange,
      handleSelectionOnChange,
      search,
    } = this;

    const { currentSet, sets } = store.creative.ads.filter;
    const currentSetName = currentSet.name;

    const value = currentSetName !== FILTER_MANAGER_DEFAULT_SET_NAME ? currentSetName : undefined;

    return (
      <Select
        allowClear
        filterOption={() => true}
        notFoundContent={null}
        onChange={handleSelectionOnChange}
        onSearch={handleSearchOnChange}
        optionFilterProp="children"
        placeholder="Select a filter"
        showSearch
        value={value}
      >
        {(search || !sets.properties.length) && (
          <Option key="create" value={CREATE_OPTION_VALUE}>
            <EditIcon />
            Create New
          </Option>
        )}

        {sets.properties
          .filter((property) => property.name.toLowerCase().includes(search.toLowerCase()))
          .map((set) => (
            <Option
              key={`filterSetDropdown.option.${set.name}`}
              value={set.name}
            >
              {set.name}
            </Option>
          ))}
      </Select>
    );
  }
};

export default observer(FilterSetsDropdown);
