import { Container, PopoverContent } from './ProfileButton.styles';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Popover, Button } from 'antd';
import React from 'react';
import store from 'stores/index';

export interface ProfileButtonProps {
  className?: string;
}

class ProfileButton extends React.Component<ProfileButtonProps> {
  isVisible: boolean = false;

  constructor(props: ProfileButtonProps) {
    super(props);

    makeObservable(this, {
      isVisible: observable,
    });
  }

  hideButton = () => {
    this.isVisible = false;
  }

  handleShowProfile = () => {
    const { hideButton } = this;

    hideButton();
    store.router.goTo(store.routes.profile);
  }

  handleLogout = () => {
    const { hideButton } = this;

    hideButton();
    store.user.logout();
  }

  render() {
    const Content = (
      <PopoverContent>
        <div className="avatar"><img alt="Profile Photo" src={store.user.data.photoURL} /></div>
        <div className="info">
          <div className="name">{store.user.data.displayName}</div>
          <div className="email">{store.user.data.email}</div>
          <div className="buttons">
            <Button className="view-profile" onClick={this.handleShowProfile} type="primary">
              View Profile
            </Button>

            <Button className="sign-out" onClick={this.handleLogout}>
              Sign Out
            </Button>
          </div>
        </div>
      </PopoverContent>
    );

    return (
      <Popover
        content={Content}
        onVisibleChange={(e) => this.isVisible = e}
        placement="bottomRight"
        trigger="hover"
        visible={this.isVisible}
      >
        <Container className={this.props.className}>
          <div className="avatar">
            <img alt="Profile Photo" src={store.user.data.photoURL} />
          </div>

          <div className="name">
            {store.user.data.firstName}
          </div>
        </Container>
      </Popover>
    )
  }
}

export default observer(ProfileButton);
