import { Tag } from './TruncatedTag.styles';
import { Tooltip } from 'antd';
import { WithClassName } from 'utils/Types/common';
import React from 'react';

export const TRUNCATED_TAG_DEFAULT_MAX_LENGTH = 20;

export interface TruncatedTagProps extends WithClassName {
  disabled?: boolean;
  label: string;
  maxLength?: number;
  onRemove: () => void;
}

class TruncatedTag extends React.Component<TruncatedTagProps>{
  constructor(props: TruncatedTagProps) {
    super(props);
  }

  get labelExceedsMaxLength() {
    const {
      label,
      maxLength = TRUNCATED_TAG_DEFAULT_MAX_LENGTH
    } = this.props;

    return label.length > maxLength;
  }

  getTagElement() {
    const {
      className,
      disabled,
      label,
      maxLength = TRUNCATED_TAG_DEFAULT_MAX_LENGTH,
      onRemove,
    } = this.props;

    return (
      <Tag className={className} closable={!disabled} onClose={onRemove}>
        {this.labelExceedsMaxLength ? `${label.slice(0, maxLength)}...` : label}
      </Tag>
    );
  }

  render() {
    const { label } = this.props;

    if (this.labelExceedsMaxLength) {
      return (
        <Tooltip title={label}>
          {this.getTagElement()}
        </Tooltip>
      );
    }

    return this.getTagElement();
  }
}

export default TruncatedTag;
