import { Icon as BaseIcon } from 'antd';
import Asset from 'stores/Asset';
import NavigateToFolderButton from './NavigateToFolderButton';
import RemoveFolderButton from './RemoveFolderButton';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
`;

const LoadingIcon = styled(BaseIcon).attrs({
  type: 'loading'
})`
  padding-right: 8px;
`;

export interface FolderRowButtonsProps {
  asset: Asset;
}

class FolderRowButtons extends React.Component<FolderRowButtonsProps>{
  constructor(props: FolderRowButtonsProps) {
    super(props);
  }

  render() {
    const { asset } = this.props;
    const { loading: isLoading } = asset;

    return (
      <Container>
        {isLoading && <LoadingIcon />}
        <NavigateToFolderButton asset={asset} />
        <RemoveFolderButton asset={asset} />
      </Container>
    );
  }
};

export default FolderRowButtons;
