import { Button } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import AddDocumentModal from './AddDocumentModal';
import type DocumentFolder from 'stores/DocumentFolder';
import React from 'react';
import { DocumentFolderClassRef } from 'stores/Types';

export interface AddDocumentButtonProps {
  parentDocument: DocumentFolder;
  documentType: DocumentFolderClassRef;
  documentTypeName: string;
}

class AddDocumentButton extends React.Component<AddDocumentButtonProps>{
  isModalVisible: boolean = false;

  constructor(props: AddDocumentButtonProps) {
    super(props);

    makeObservable(this, {
      isModalVisible: observable,
    });
  }

  showModal = () => {
    this.isModalVisible = true;
  }

  hideModal = () => {
    this.isModalVisible = false;
  }

  render() {
    const { parentDocument, documentType, documentTypeName } = this.props;
    const { canModify } = parentDocument;
    const { isModalVisible, hideModal, showModal } = this;

    return (
      <>
        <Button
          disabled={!canModify}
          onClick={showModal}
        >
          {`Add ${documentTypeName}`}
        </Button>

        {canModify && (
          <AddDocumentModal
            isVisible={isModalVisible}
            onClose={hideModal}
            parentDocument={parentDocument}
            documentType={documentType}
            documentTypeName={documentTypeName}
          />
        )}
      </>
    )
  }
}

export default observer(AddDocumentButton);
