import User from './User';
import { UserEvents, UserStatus } from './UserTypes';
import UI, { ViewModes } from './UI';
import Input from './Input';
import DocumentStore from './DocumentStore';
import routes from './Routes';
import Project from './Project';
import Client from './Client';
import Creative from './Creative';
// import Tearsheet from './Tearsheet';
import { db } from './Firebase';
import { Route, RouterStore, startRouter } from 'mobx-router';
import ClientsManager from './ClientsManager';
import AdminManager from './AdminManager';
import { deepCopy, documentPathToParams } from 'utils';
import UsersCache from './UsersCache';
import Tearsheet from './Tearsheet';
import Animation from './Animation';
import { reaction } from 'mobx';
import { configure } from 'mobx';
import { DocumentType } from './Types';

configure({
    enforceActions: "never",
})

// test project path: '/clients/CImTDuGgZixPziFqlWuZ/projects/07nQAaDwHhZDdwcPkV7T'

Object(window).documentPathToParams = documentPathToParams;
Object(window).deepCopy = deepCopy;

export class RootStore extends DocumentStore<any, any> {
  current: DocumentStore<any, any> = new DocumentStore(this);
  client: Client;
  project: Project;
  creative: Creative;
  tearsheet: Tearsheet;
  animation: Animation;
  documentType: DocumentType;

  clients = new ClientsManager(this, undefined);
  admin = new AdminManager(this, undefined);
  // dashboard = new DashboardManager(this); // TODO: create dashboard manager

  ui = new UI();
  input = new Input();
  user = new User(this);
  router: RouterStore<any> = new RouterStore(this);
  routes = routes;

  usersCache = new UsersCache();

  constructor() {
    super();
    console.log('ROOT: constructor');
    this.setCurrent(new DocumentStore(this)); // TODO: this could be more elegant, but it works for now
    this.init({ routes: false });

    this.user.on(UserEvents.LOGIN_SUCCESS, () => {
      console.log('ROOT: login success');
      this.router.goTo(routes.clients);
    });

    this.user.on(UserEvents.LOGGED_OUT, () => {
      console.log('ROOT: logged out');
      this.router.goTo(routes.notAuthenticated);
      this.reset();
    });

    this.connectAuth();

    reaction(() => this.router.queryParams, (queryParams, prevQueryParams) => {
      const typedQueryParams = queryParams as { mode?: ViewModes };

      if (!typedQueryParams?.mode) {
        this.ui.setViewMode(ViewModes.DESKTOP);
        return;
      }

      if (queryParams?.mode !== prevQueryParams?.mode) {
        this.ui.setViewMode(typedQueryParams.mode);
      }
    });
  }

  connectAuth = async () => {
    await this.user.connect();
    console.log('ROOT: connected');
    // @ts-expect-error
    startRouter(this.routes, this, { html5history: true });
  }

  loadAnimation = async (path: string, queryParams: { filterSetName: string }) => {
    console.log('loading animation:', path);
    this.setDocumentType(DocumentType.ANIMATION);
    if (this.animation) this.animation.reset();
    this.animation = new Animation(this, db.doc(path));
    await this.animation.load();
    this.setCurrent(this.animation);
  }

  loadCreative = async (path: string, queryParams: { filterSetName: string }) => {
    this.setDocumentType(DocumentType.CREATIVE);
    
    if(store.creative?.mounted === true) {
      store.creative.ads.setCurrent();
      return;
    }
    
    if (this.creative) this.creative.reset();
    this.creative = new Creative(this, db.doc(path));
    await this.creative.load();
    this.setCurrent(this.creative);
    
    if (queryParams && queryParams.filterSetName) store.creative.ads.filter.setCurrentSetByName(queryParams.filterSetName);
  }

  loadProject = async (path: string) => {
    this.setDocumentType(DocumentType.PROJECT);
    if (this.project) this.project.reset();
    this.project = new Project(this, db.doc(path));
    await this.project.load();
    this.setCurrent(this.project);
  }

  loadClient = async (path: string) => {
    this.setDocumentType(DocumentType.CLIENT);
    if (this.client) this.client.reset();
    this.client = new Client(this, db.doc(path));
    await this.client.load();
    this.setCurrent(this.client);
  }

  loadTearsheet = async (path: string) => {
    this.setDocumentType(DocumentType.TEARSHEET);
    if (this.tearsheet) this.tearsheet.reset();
    this.tearsheet = new Tearsheet(this, db.doc(path));
    await this.tearsheet.load();
    this.setCurrent(this.tearsheet);
  }

  // loadAdmin = async () => {
  //     this.setCurrent(this.rootAdmin);
  // }

  // loadDashboard = async () => {
  //     this.setCurrent(this.rootDashboard);
  // }

  loadClients = async () => {
    this.setDocumentType(DocumentType.NONE);
    this.setCurrent(this.clients);
    await this.clients.load();
  }

  setCurrent = async (view: DocumentStore<any, any> = new DocumentStore(this)) => {
    this.current = view;
    // if(this.current.loaded === false) await this.current.load();
  }

  setDocumentType = (type: DocumentType) => {
    this.documentType = type;
  }

  reset = () => {
    this.current?.reset();
    this.user.reset();
    this.clients.reset();
    this.admin?.reset();
    this.project?.reset();
    this.creative?.reset();
  }

  getRoute = (name: string) => {
    if (name === 'dynamic') name = 'creative';
    // iterate through routes as entries and return the first matching route
    for (const [key, value] of Object.entries(this.routes)) {
      if (key === name) {
        return value as Route<RootStore>
      }
    }
  }
};

const store = new RootStore();
export default store;
