import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'stores';
import UploadAssetModal from './UploadAssetModal';

export interface UploadAssetButtonProps { }

class UploadAssetButton extends React.Component<UploadAssetButtonProps>{
  isUploadAssetModalVisible: boolean = false;
  
  constructor(props: UploadAssetButtonProps) {
    super(props);

    makeObservable(this, {
      isUploadAssetModalVisible: observable,
    });
  }

  showUploadAssetModal = () => {
    store.creative.assets.uploadManager.show();
  }

  render() {
    const {
      showUploadAssetModal,
    } = this;

    return (
      <>
        <DrawerButton onClick={showUploadAssetModal}>
          Upload
        </DrawerButton>
      </>
    );
  }
};

export default observer(UploadAssetButton);
