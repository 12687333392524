import { IDynamicProperty } from 'stores/DynamicProperties/Types';
import { Select as BaseSelect } from 'antd';
import React from 'react';
import styled from 'styled-components';
import store from 'stores';
import { observer } from 'mobx-react';

const { Option } = BaseSelect;

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const Label = styled.span`
  font-weight: bold;
  width: 150px;
`;

const Select = styled(BaseSelect)`
  flex: 1;
`;

const CLEAR_ALL_VALUE = 'dynamicPropertDropdown.clear';
const SELECT_ALL_VALUE = 'dynamicPropertDropdown.all';

export interface DynamicPropertyDropdownProps {
  options: IDynamicProperty<any>[];
}

class DynamicPropertyDropdown extends React.Component<DynamicPropertyDropdownProps>{
  constructor(props: DynamicPropertyDropdownProps) {
    super(props);
  }

  clearMyPropertyOptions = () => {
    const { options } = this.props;
    const myProperty = options[0];

    const activeFilters = store.creative.ads.filter.currentSet.properties;
    store.creative.ads.filter.clearCustomSet();

    const newActiveFilters = activeFilters.filter((activeFilter) => activeFilter.name !== myProperty.name);
    store.creative.ads.filter.addPropertyMultiple(newActiveFilters);
  }

  markAllTheOptionsAsActive = () => {
    const { options } = this.props;
    const { markTargetOptionsAsActive } = this;

    markTargetOptionsAsActive(options.map((option) => option.value.toString()));
  }

  markTargetOptionsAsActive = (values: string[]) => {
    const { options } = this.props;
    const { clearMyPropertyOptions } = this;

    clearMyPropertyOptions();
    values.forEach((value) => {
      const targetOption = options.find((option) => option.value.toString() === value.toString());
      if (targetOption) {
        store.creative.ads.filter.addProperty(targetOption);
      }
    });
  }

  handleOnSelect = (values: unknown) => {
    const valuesAsText = values as string[];

    const {
      clearMyPropertyOptions,
      markAllTheOptionsAsActive,
      markTargetOptionsAsActive,
    } = this;

    if (valuesAsText.includes(SELECT_ALL_VALUE)) {
      markAllTheOptionsAsActive();
      return;
    }

    if (valuesAsText.includes(CLEAR_ALL_VALUE)) {
      clearMyPropertyOptions();
      return;
    }

    markTargetOptionsAsActive(valuesAsText);
  }

  render() {
    const { options } = this.props;
    const { handleOnSelect } = this;

    const myProperty = options[0];
    const myPropertyOptionsAtFilters = store.creative.ads.filter.currentSet.properties.filter(
      (activeFilterProp) => activeFilterProp.name === myProperty?.name);

    const disabled = options.length === 0;

    return (
      <Container>
        <Label>{myProperty?.name}</Label>

        <Select
          disabled={disabled}
          mode="tags"
          onChange={handleOnSelect}
          tokenSeparators={[',']}
          value={myPropertyOptionsAtFilters.map((option) => option.value.toString())}
        >
          <Option key='all' value={SELECT_ALL_VALUE}>Select All</Option>
          <Option key='clear' value={CLEAR_ALL_VALUE}>Clear All</Option>

          {options.map((option) => {
            const optionLabel = option.label;
            const optionValue = option.value.toString();

            return (
              <Option
                key={`filterDropdown.${myProperty?.name}.${optionLabel}`}
                label={optionLabel}
                value={optionValue}
              >
                {optionLabel}
              </Option>
            );
          })}
        </Select>
      </Container>
    );
  }
};

export default observer(DynamicPropertyDropdown);
