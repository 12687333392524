import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Button, Radio, Checkbox } from 'antd';
import styled from 'styled-components';
import Ad from '../../../../../stores/Ad';
import store from '../../../../../stores';
import LabelProp from 'stores/DynamicProperties/Types/Label';

const Container = styled.div`
    font-size: 12px;
    display: flex;
    position: absolute;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    padding: 5px 3px 5px 8px;
    user-select: none;
    cursor: pointer;
    background: #ffffff;
    width: 100%;
    transition: all .25s cubic-bezier(.11,.61,.44,.99);

    &.dragging {
        position:relative;
        margin-bottom: -32px;
        transition: top 0s;
    }

    &.active {
        background: #e1f3ff;
        font-weight: bold;

        &:hover {
            background: #eef8ff;
        }
    }

    &.selected {
        background: #f4f4f4;
    }

    &:hover {
        background: #f4f4f4;
    }
`;

const Icon = styled.div`
    position: relative;
    top: 0px;
    padding-right: 2px;
    margin-bottom: -6px;

    .label {
        width: 30px;
        font-size: 13px;
        font-weight: bold;
        position: absolute;
        color: black;
        left: 0px;
        top: 0px;
        text-align: center;
    }

    .read {
        width:30px;
        opacity: 0.2;
    }
    .unread {
        width:30px;
        opacity: 1;
    }
`;

const Label = styled.div`
    flex-grow: 1;
    pointer-events: none;
    white-space: nowrap;
`;

const LabelID = styled.div`
    padding-right: 8px;
    padding-right: 10px;
    font-weight: bold;
    pointer-events: none;
`;

const LabelCheckbox = styled(Checkbox)`
    /* position: relative; */
    /* z-index: 1000; */
    margin-right: 7px;
`;

let mousePosition = { x: 0, y: 0 };

type AdListTableRowProps = {
    id: string,
    className: string,
    dragging: boolean,
    key: string,
    ad: Ad,
    style: { top: string },
    onSelect?: Function | undefined,
    onDragStart: Function,
    onDragEnd: Function
}

class AdListTableRow extends React.Component<AdListTableRowProps> {
    ad: Ad;
    selectHandler: Function | undefined;
    dragStartHandler: Function;
    dragEndHandler: Function;

    constructor(props: AdListTableRowProps) {
        super(props);

        this.ad = props.ad;
        this.selectHandler = props.onSelect;
        this.dragStartHandler = props.onDragStart;
        this.dragEndHandler = props.onDragEnd;
        this.ad.properties.subscribe(this);
    }

    handleView = (e: MouseEvent) => {
        // e.stopPropagation();
        if (this.selectHandler) this.selectHandler(this.ad);
    }

    handleSelect = (e: MouseEvent) => {
        // e.stopPropagation();
        store.creative.ads.select(this.ad);
    }

    handleDragStart = (e: MouseEvent) => {
        if(e.button !== 0) return;
        this.dragStartHandler(e);
    }

    handleDragEnd = (e: MouseEvent) => {
        if(e.button !== 0) return;
        this.dragEndHandler(e);
    }

    render() {
        var state = '';
        let labelProp = this.ad.properties.get('label') as LabelProp;
        let label = (this.ad.properties.getChanged() ? '*' : '') + labelProp?.rendered || '';

        if (this.ad.selected) state = 'selected';
        if (this.ad.active) state = 'active';

        let isRead = this.ad.isRead || this.ad.isViewingComments;

        let commentBubble = <img className="unread" src="/images/message-icon-unread.svg" />;
        if (isRead) commentBubble = <img className="read" src="/images/message-icon.svg" />;

        return (
            <Container key={this.props.key} style={this.props.style} id={this.props.id} className={state} /*onClick={this.handleView}*/ onMouseDown={this.handleDragStart} onMouseUp={this.handleDragEnd}>
                <LabelCheckbox checked={this.ad.selected} onClick={this.handleSelect}></LabelCheckbox>
                <LabelID>{Number(this.ad.data.index) + 1}</LabelID> { /* TODO: check that this is index works. likely connected to an adhub 2.1 feaure implementation */ }
                <Label>{label}</Label>
                {this.ad.numComments > 0 ? <Icon>{commentBubble}<div className="label" style={isRead ? {} : { color: '#ffffff' }}>{this.ad.numComments}</div></Icon> : <span></span>}
            </Container>
        )
    }
}

export default observer(AdListTableRow);