import { db, firebase } from "./Firebase";
import { UserSchemaPublic } from "./User";

type GetUserOptions = {
    public?: boolean;
    refetch?: boolean;
};

class CachedUser {
    data: UserSchemaPublic;
    cachedPhoto: string;
    lastFetched: Date;
    docRef: firebase.firestore.DocumentReference;

    constructor(data: UserSchemaPublic, cachedPhotoData?: string) {
        this.data = data;
        this.cachedPhoto = cachedPhotoData || '';
        this.lastFetched = new Date();
    }
}

class UsersCache {
    usersMap: Map<string, CachedUser>;

    constructor() {
        this.usersMap = new Map();
    }

    fetchById = async (id: string, options: GetUserOptions) => {
        const docPath = this.getDocPath(id, options?.public);
        const docRef = db.doc(docPath);
        const data = (await (docRef.get())).data();
        if (!data) return null;

        const serializedRecord: UserSchemaPublic = {
            department: data.department,
            displayName: data.displayName,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            photoURL: data.photoURL,
            uid: id,
        };

        let imageData = '';
        if (serializedRecord.photoURL) {
            try {
                imageData = await fetchImageAsBase64(serializedRecord.photoURL);
            } catch (error) {
                console.error('Error fetching and converting image:', error);
            }
        }

        let cachedUser = new CachedUser(serializedRecord, imageData);
        this.usersMap.set(id, cachedUser);
        return cachedUser;
    };

    getById = async (id: string, options: GetUserOptions = { public: true, refetch: false }) => {
        if (options?.refetch) {
            return await this.fetchById(id, options);
        }

        const cachedUser = this.usersMap.get(id);
        if (cachedUser) {
            return cachedUser;
        }

        const fetchedUser = await this.fetchById(id, options);
        if (!fetchedUser) {
            throw new Error(`User with ID ${id} not found`);
        }
        return fetchedUser;
    };

    getByIdSync = (id: string) => {
        if (this.usersMap.has(id)) {
            return this.usersMap.get(id);
        } else {
            this.fetchById(id, { public: true, refetch: false });
            return false;
        }
    }

    getPhoto = (user: UserSchemaPublic) => {
        let cachedUser = this.getByIdSync(user.uid);
        if (cachedUser) {
            return cachedUser.cachedPhoto;
        } else {
            return user.photoURL;
        }
    }

    getDocPath = (id: string, isPublicFetch: boolean = true) => {
        return `users/${id}${isPublicFetch ? '/public/info' : ''}`;
    };
}

async function fetchImageAsBase64(url: string): Promise<string> {
    if (!url) return '';

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx?.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };
        img.onerror = (error) => {
            console.error('Error loading image:', error);
            reject(error);
        };
        img.src = url;
    });
}

export default UsersCache;