import { Spin } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  position:relative;

  .ant-table-row {
    cursor: pointer;
    user-select: none;

    .ant-tag, span {
      pointer-events: none;
    }
  }
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(45vh - 64px);
  width: 100%;
`;

export const LoadingSpinner = styled(Spin)`
`;
