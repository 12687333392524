import { DocumentReference } from '@firebase/firestore-types';
import { DocumentCollectionType, DocumentStatus, DocumentType, LooseObject } from './Types';
import root, { RootStore } from './index';
import Creative from './Creative';
import DocumentFolderCollection from './DocumentFolderCollection';
import { FolderPublicSchema, FolderSchema } from './DocumentFolderTypes';
import DocumentFolder from './DocumentFolder';

export type ProjectParams = {
    clientID: string,
    projectID: string
}

export default class Project extends DocumentFolder<ProjectParams> {
    schema = new FolderSchema();
    publicSchema = new FolderPublicSchema();
    type = DocumentType.PROJECT;
    collectionType = DocumentCollectionType.PROJECT;
    documents = new DocumentFolderCollection(this, Creative);

    constructor(parent: any, docRef?: DocumentReference, data?: FolderSchema) {
        super(parent, docRef, data);
        this.init();
    }

    navigateTo = () => {
        root.router.goTo(root.routes.project, this.params);
    }

    onReady = async () => {}

}