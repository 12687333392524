import React, { PropsWithChildren } from 'react';
import { Container, LoadingSpinner, SpinnerContainer } from './ViewsLayout.styles';
import { EmptyContentBoxProps } from 'components/UI/EmptyContentBox/EmptyContentBox';

export type ViewsLayoutProps = EmptyContentBoxProps & {
  className?: string;
  isLoading?: boolean;
  title?: string;
};

class ViewsLayout extends React.Component<PropsWithChildren<ViewsLayoutProps>>{
  constructor(props: ViewsLayoutProps) {
    super(props);
  }

  render() {
    const {
      className,
      children,
      isLoading,
    } = this.props;

    return (
      <Container className={className}>
        {!isLoading && children}

        {isLoading && (
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
        )}
      </Container>
    );
  }
}

export default ViewsLayout;
