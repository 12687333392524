import { Icon } from 'antd';
import Bundle from 'stores/Bundle';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import { observer } from 'mobx-react';

export interface DownloadBundleButtonProps {
  bundle: Bundle;
}

class DownloadBundleButton extends React.Component<DownloadBundleButtonProps>{
  constructor(props: DownloadBundleButtonProps) {
    super(props);
  }

  downloadBundleViaWindow = () => {
    const { bundle } = this.props;
    const { downloadURL } = bundle;

    window.open(downloadURL, '_blank');
  }

  render() {
    const { downloadBundleViaWindow } = this;
    const { bundle } = this.props;

    return (
      <DrawerButton disabled={bundle.loading} onClick={downloadBundleViaWindow}>
        <Icon type="cloud-download" />
      </DrawerButton>
    );
  }
};

export default observer(DownloadBundleButton);
