import { Button, Dropdown, Icon as BaseIcon, Menu } from 'antd';
import Bundle from 'stores/Bundle';
import BundleElement from 'stores/BundleElement';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 36px;
  padding: 10px;
  width: 100%;
`

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FileLabelContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const PrincipalAnchor = styled.a`
  font-size: 18px;
  font-weight: bold;
`;

const SecondaryAnchor = styled.a`
  align-items: center;
  color: rgba(0,0,0,0.65);
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: 24px;
  justify-content: center;
  padding: 0 8px !important;
  transition: all 0.25s;
  text-align: center;

  &:hover {
    background-color: #e6f7ff;
    color: rgba(0,0,0,0.65);
  }
`;

const SpacedBetweenContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Icon = styled(BaseIcon)`
  margin-top: 4px;
`;

const DropdownButton = styled(Button)`
  padding: 0;
`;

export interface BundleElementRowProps {
  bundle: Bundle;
  element: BundleElement;
}

class BundleElementRow extends React.Component<BundleElementRowProps>{
  constructor(props: BundleElementRowProps) {
    super(props);
  }

  render() {
    const { bundle, element } = this.props;
    const currentZipSize = element.data.builds[0].archiveActualSize / 1024;
    const maxZipSize = element.data.builds[1].archiveMaxSize / 1000;
    const isZipOverSize = currentZipSize > maxZipSize;
    return (
      <Container>
        <MetadataContainer>
          <FileLabelContainer>
            <PrincipalAnchor
              href={`${element.stagingURL}`}
              target='_blank'
            >
              {element.data.label}
            </PrincipalAnchor>

            <PrincipalAnchor
              href={`${bundle.baseURL}/${element.data.lossyScreenshotPath}`}
              target='_blank'
            >
              <Icon type='file-image' />
            </PrincipalAnchor>

          </FileLabelContainer>

          <SpacedBetweenContainer>
            <div>
              <b style={ isZipOverSize ? { color: 'red'} : {}}>ZIP FileSize: </b>
              <span style={ isZipOverSize ? { color: 'red'} : {}}>
                {`${currentZipSize.toFixed(1)}K / 
                  ${maxZipSize.toFixed(1)}K`}
              </span>
            </div>

            <div>
              <b>Backup Image Filesize: </b>
              <span>
                {`${(element.data.lossyScreenshotActualSize / 1024).toFixed(1)}K / 
                  ${(element.data.lossyScreenshotMaxSize / 1000).toFixed(1)}K`}
              </span>
            </div>
          </SpacedBetweenContainer>
        </MetadataContainer>

        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item>
                <SecondaryAnchor
                  href={`${bundle.baseURL}/${element.data.builds[0].archiveStorage}?response-content-disposition=attachment`}
                  target="_blank"
                >
                  Download ZIP
                </SecondaryAnchor>
              </Menu.Item>

              <Menu.Item>
                <SecondaryAnchor
                  href={`${element.stagingURL}`}
                  target="_blank"
                >
                  View Bundled Ad
                </SecondaryAnchor>
              </Menu.Item>

              <Menu.Item>
                <SecondaryAnchor
                  href={`${bundle.baseURL}/${element.data.lossyScreenshotPath}?response-content-disposition=attachment`}
                  target="_blank"
                >
                  View Static Backup
                </SecondaryAnchor>
              </Menu.Item>

              <Menu.Item>
                <SecondaryAnchor
                  href={`${bundle.baseURL}/${element.data.losslessScreenshotPath}?response-content-disposition=attachment`}
                  target="_blank"
                >
                  View Screenshot PNG
                </SecondaryAnchor>
              </Menu.Item>
            </Menu>
          )}
        >
          <DropdownButton>
            <Icon type='ellipsis' />
          </DropdownButton>
        </Dropdown>
      </Container>
    );
  }
};

export default BundleElementRow;
