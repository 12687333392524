import { Container } from "./Toggle.styles";
import { Icon, Switch } from "antd";
import { observer } from "mobx-react";
import User from '../../../stores/User'
import React from "react";

export interface ToggleProps {
  store: User;
}

class Toggle extends React.Component<ToggleProps>{
  constructor(props: ToggleProps) {
    super(props);
  }

  render() {
    const { store } = this.props;
    let activated = store?.data.activated;

    return (
      <Container>
        <Switch checked={activated} onClick={store.toggleActivated} />
      </Container>
    );
  }
}

export default observer(Toggle);
