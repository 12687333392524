import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Modal, Input, Button, Select, Switch } from 'antd';
import store from '../../../../../stores';
import { reaction } from 'mobx';

const { Option } = Select;
const TEMPLATE_UPLOAD_MODAL_TITLE = 'Upload Template';

const PropertyModal = styled(Modal)`
    .last-form-item{
        margin-bottom: 0px;
    }
`;

const SwitchContainer = styled.span`
    width: 151px;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
`;

const FileInput = styled.span`
    width:100%;
    display:flex;
    justify-content: left;
    flex-wrap: nowrap;
    margin-bottom: 10px;
`;

const FileNameInput = styled(Input)`
    flex-grow:1;
`;

const UploadElement = styled.input`
    display:none;
    width: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
`;

type UploadAssetModalProps = {
    onClose?: () => void;
}

class UploadTemplateModal extends React.Component<UploadAssetModalProps> {
    uploadInputElement: any = React.createRef();

    title = TEMPLATE_UPLOAD_MODAL_TITLE;

    constructor(props: UploadAssetModalProps) {
        super(props);

        reaction( () => store.creative.templates.uploadManager.modalVisible, (visible) => {
            if ( visible === false) this.reset();
        });
    }

    handleNameChange = (e: any) => {
        store.creative.templates.uploadManager.setTemplateName( removeSpecialCharacters(e.target.value) );
    };

    handleCancel = async () => {
        store.creative.templates.uploadManager.hide();
        this.reset();
    };

    reset = () => {
        this.title = TEMPLATE_UPLOAD_MODAL_TITLE;
        if (this.uploadInputElement && this.uploadInputElement.value) this.uploadInputElement.value = null;
    }

    render() {
        let { title } = this;
        let { modalVisible, template, replaceAssets, templateName, file, fileName, loading, isUpdate, setFile, setTemplate, setReplaceAssets, show, hide, handleConfirm } = store.creative.templates.uploadManager;
        
        let disableConfirm = !(fileName && file && templateName); 

        return (
            <PropertyModal
                title={title}
                visible={modalVisible}
                confirmLoading={loading}
                onCancel={this.handleCancel}
                footer={[
                    <SwitchContainer>Replace Assets<Switch checked={replaceAssets} onChange={(checked) => { setReplaceAssets(checked) }} /></SwitchContainer>,
                    <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                    <Button disabled={disableConfirm} key="submit" type="primary" loading={loading} onClick={handleConfirm}>{isUpdate ? 'Update' : 'Add'}</Button>,
                ]}
                {...this.props}
            >
                <UploadElement type="file" className="file-select" accept=".zip"
                    ref={elem => {
                        this.uploadInputElement = elem;
                    }}
                    onChange={e => {
                        if (e.target.files && e.target.files.length > 0) {
                            let newFile = e.target.files[0];
                            if (!newFile) return;
                            setFile(newFile);
                        }
                    }}
                />
                <FileInput>
                    <FileNameInput placeholder="Select a file from your computer" disabled value={fileName} />
                    <Button style={{ marginLeft: '5px' }} onClick={() => { this.uploadInputElement.click() }}>Browse</Button>
                </FileInput>
                <Input
                    // disabled={isUpdate}
                    placeholder="Type a template name"
                    value={templateName}
                    onChange={this.handleNameChange}
                />
            </PropertyModal>
        );
    }
}

function removeSpecialCharacters(str: string) {
    return str.replace(/[^a-zA-Z0-9\ \%\-\_\!\@\#\$\(\)\+\=]+/g, '');
}

export default observer(UploadTemplateModal);