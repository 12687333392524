import { Button as BaseButton, Icon } from 'antd';
import { TearsheetViewMobileContentMode } from 'stores/Tearsheet';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  align-items: center;
  display: flex;
  font-size: 20px;
  background-color: transparent;
  border: none;
  padding-left: 12px;
  padding-right: 14px;
`;

export interface GoBackButtonProps { }

class GoBackButton extends React.Component<GoBackButtonProps>{
  constructor(props: GoBackButtonProps) {
    super(props);
  }

  navigateBackOnMobileView = () => {
    delete store.router.params?.adIndex;
  }

  render() {
    const { navigateBackOnMobileView } = this;

    return (
      <Button onClick={navigateBackOnMobileView} >
        {/* <Icon type="arrow-left" /> */}
        <img src="/images/caret-left-icon.svg" />
      </Button>
    );
  }
};

export default GoBackButton;
