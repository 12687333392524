import { observer } from 'mobx-react';
import { AnimationPanelViews, AnimationPanelViews as PanelViews } from 'stores/Animation';
import DrawerHeader from './DrawerHeader';
import React from 'react';
import store from 'stores';
import styled from 'styled-components';
import { Icon } from 'components/UI/SsoAvatar/SsoAvatar.styles';
import { DragEvent } from 'stores/UI';

const Container = styled.div<{ visible: boolean }>`
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  flex-shrink: 0;
  background: #F9F9F9;
  height: 100%;
  width: 430px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  border-left: 1px solid #D9D9D9;
`;

const DropIndicator = styled.div`
    position: absolute;
    top: 55px;
    flex-flow: column;
    pointer-events: none;
    height: calc(100% - 55px - 20px);
    width: calc(100% - 20px);
    border: 2px dashed rgba(0,0,0,0.05);
    border-radius: 7px;
    margin: 10px;
    font-size: 25px;
    color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.025);
    opacity: 0;
    transition: all 0.25s;

    .drop-icon{
        transform: rotate(90deg);
        font-size: 92px;
        opacity: 0.4;
    }
`;

const DrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export interface DrawerProps {
  label: string;
  enableDrop?: boolean;
  handleOnClose?: () => void;
  handleOnDrop?: (file: File) => void;
  headerContent?: React.ReactNode;
  panelName: AnimationPanelViews; 
  children?: React.ReactNode;
 }

const drawerPropDefaults: DrawerProps = {
  enableDrop: false,
  handleOnClose: () => {},
  panelName: AnimationPanelViews.NONE,
  label: '',
};

class Drawer extends React.Component<DrawerProps>{
  constructor(props: DrawerProps) {
    super(props);
    let { handleOnDrop } = props;
    if( handleOnDrop) store.ui.addEventListener(DragEvent.FILE_DROPPED, handleOnDrop);
    // subscribe(this, store.animation.assets);
  }

  handleOnClose = () => {
    store.animation.setPanel(PanelViews.NONE);
  }

  render() {
    const propsWithDefaults = { ...drawerPropDefaults, ...this.props };
    const { displayPanel } = store.animation;
    const { enableDrop, handleOnClose, panelName, headerContent, label } = propsWithDefaults;

    const fileDragging = store.ui.fileDragging;
    const showDrawer = displayPanel === panelName && panelName !== AnimationPanelViews.NONE;

    return (
      <Container visible={showDrawer}>
        <DrawerHeader label={label} content={headerContent} handleOnClose={handleOnClose}/>
        <DropIndicator style={{opacity: fileDragging && enableDrop ? 1 : 0}}>
            <Icon className='drop-icon' type="login" />
            <br />
            DROP FILES HERE
        </DropIndicator>
        <DrawerBody>
          {this.props.children}
        </DrawerBody>
      </Container>
    );
  }
};

export default observer(Drawer);
