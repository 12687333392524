import { firestore, db, functionAuthRequest, FirestoreEvents } from '../Firebase';
import root from '../index';
import Ad, { AdSchema } from '../Ad';
import { DocumentCollectionType, DocumentStatus, DocumentType, FilterProperty, FilterSet } from '../Types';
import { SelectState } from './Types';
import DocumentCollection from '../DocumentCollection';
import Creative, { PropertyManagerTypes } from '../Creative';
import Tearsheet from '../Tearsheet';
import { DynamicPropertyData, IDynamicProperty } from '../DynamicProperties/Types';
import Template from '../Template';
import { arrayToChunks, deepObjectCompare, delay } from 'utils';
import DynamicPropertiesManager from 'stores/DynamicProperties/Manager';
import FilterManager from '../FilterManager';
import Papa from 'papaparse';
import store from '../index';
import { deepCopy } from 'utils';
import FirebaseFunctions from 'stores/FirebaseFunctions';
import { DynamicPropertyType } from 'stores/DynamicProperties/Types/DynamicPropertyType';
import SVGText from 'stores/DynamicProperties/Types/SVGText';

/**
 * Ads Data Store
 * 
 * Filtering: Filtering generally works by scanning all ads and collecting all the possible values on a per "property" basis. 
 * 
 * current - The currently selected ad.
 * filterValues - Values that are currently selected for a specific dynamic data property. This can be multiple.
 * filterProperties - All the collective values that exist in the entire data set for this specific property. If 200 ads use a productImage of 'foo.jpg', and another 100 use 'bar.jpg, this will return an array of ['ball.jpg', 'bar.jpg']
 */

// TODO: is this needed? doesn't seem like it.
// const state = {
//     noTemplate: 'NO_TEMPLATES',
//     noAds: 'NO_ADS'
// }

interface FileEvent extends Event {
    target: HTMLInputElement;
}

export enum AdsManagerEvents {
    SELECTED = 'selected',
    ACTIVATED = 'activated',
}

type UID = string;

export type CSVAdRowData = {
    id: string;
    adID: UID;
    template: string;
    dimensions: string;
    label: string;
};

export default class AdsManager extends DocumentCollection<Ad, AdSchema> { // TODO: this should extend Document and use a type variable to pass in child type. 
    query = () => this.collectionRef.orderBy('index');
    // current: Ad; // TODO: see if this can be a circular reference without MobX breaking.
    ready = false;
    empty = true;
    currentId = '';
    currentIndex = NaN;
    // filtering = false;
    filterUpdate = NaN;
    filterSet: FilterSet;
    filters: FilterProperty[] = [];
    filterProperties: FilterProperty[] = [];
    filter: FilterManager = new FilterManager(this);
    lastSelectedIndex = -1;
    lastSelectedAd: Ad;
    workStarted = false;
    workMessage = '';
    working = false;
    selectedFilterProperties: IDynamicProperty<any>[] = [];
    allUniqueAdProperties: IDynamicProperty<any>[] = [];

    constructor(parent: Creative | Tearsheet) {
        super(parent, Ad);
        this.init();
    }

    get filtering() {
        return this.filter.currentSet.properties.length > 0;
    }

    get selectState() {
        let selected = this.all.filter(ad => ad.selected);
        if (selected.length === 0) return SelectState.NONE;
        if (selected.length === this.all.length) return SelectState.ALL;
        if (selected.length < this.all.length) return SelectState.PARTIAL;
    }

    get sorted() {
        return this.children.sort((a, b) => (a.data.index > b.data.index) ? 1 : -1)
    }

    get current() {
        return this.getById(this.currentId);
    }

    get next() {
        if (this.filtering) {
            let noneSelected = true;
            let found = false;
            for (var i in this.filtered) {
                let ad = this.filtered[i];
                if (found === true) {
                    return ad;
                }
                if (ad.selected) {
                    noneSelected = false;
                    found = true;
                }
            }
            if (noneSelected) return this.filtered[0];
        }
        let nextAd = this.children[this.currentIndex + 1];
        if (!nextAd) return null;
        else return nextAd;
    }

    get previous() {
        if (this.filtering) {
            let noneSelected = true;
            for (var key in this.filtered) {
                let i = Number(key);
                let ad = this.filtered[i];
                if (ad.selected) {
                    noneSelected = false;
                    if (i === 0) return this.filtered[i];
                    return this.filtered[i - 1];
                }
            }
            if (noneSelected) return this.filtered[0];
        }
        let prevAd = this.children[this.currentIndex - 1];
        if (!prevAd) return null;
        else return prevAd;
    }

    get lastSelected() {
        for (let i in this.all) {
            let ad = this.all[i];
            if (ad.data.index === this.lastSelectedIndex) return ad;
        }
    }

    onReady = async () => {
        // TODO: enable state on properties when activating ad.
        // for(let ad of this.children) {
        //     await delay(1);
        //     ad.properties.init();
        // }
        console.log('STATE ENABLE DONE');
        // this.ready = true;
    }

    // onReady = () => {
    //     console.log('READY')
    // }

    // get filtered() {
    //     let ads = [...this.children];
    //     let filteredAds: Ad[] = [];
    //     let filterProperties = [...this.filterProperties];

    //     if (filterProperties.length === 0) return ads;

    //     ads.forEach(ad => {
    //         filterProperties.forEach(filterProperty => {
    //             let name = filterProperty.name;
    //             let values = filterProperty.values;

    //             let matching = values.filter(value => {
    //                 return ad.properties.get(name)?.value === value;
    //             });
    //             if (matching.length > 0) {
    //                 filteredAds.push(ad);
    //             }
    //         });
    //     });
    //     return filteredAds;
    // }

    get filtered() {
        let ads = [...this.children];
        let filteredAds: Ad[] = this.filter.getFilteredAds(ads);
        return filteredAds.sort((a, b) => (a.data.index > b.data.index) ? 1 : -1);
    }

    get changed() {
        let ads = [...this.children];
        let changed: Ad[] = [];

        ads.forEach(ad => {
            if (ad.changed) changed.push(ad);
        });

        return changed;
    }

    get selected() {
        var items = this.filtering ? [...this.filtered] : [...this.children];
        var selectedItems: Ad[] = [];

        items.forEach(item => {
            if (item.selected) selectedItems.push(item);
        });
        return selectedItems;
    }

    showWorkPopup = async (message = "Loading...") => {
        this.workStarted = true;
        this.workMessage = message;
        this.working = true;
    }

    hideWorkPopup = async () => {
        await delay(500);
        this.workStarted = false;
        this.working = false;
        this.workMessage = "";
    }

    getByLabel = (label: string) => {
        for (let item of this.children) {
            if (item.properties.get('label')?.value === label) {
                return item;
            }
        }
    }

    addMultipleAds = async (set: AdSchema[], insertIndex?: number) => {
        let children = [ ... this.filtered ];
        let newDocs:Ad[] = [];

        this.selectNone();

        for (let data of set) {
            let newDoc = await this.create(JSON.parse(JSON.stringify(deepCopy(data))));
            newDocs.push(newDoc);
            newDoc.selectToggle();
        }

        if(insertIndex === undefined) {
            children.push(...newDocs);
        } else {
            children.splice(insertIndex, 0, ...newDocs);
        }

        this.children = children;

        this.updateIndexesLocal();
        
        this.status = DocumentStatus.LOADING;
        await this.updateIndexes();
        await this.commitLocal();
        this.status = DocumentStatus.LOADED;
    }

    updateMultiple = async (ads: Ad[], message = "Updating ads...") => {
        let batchSize = 50;
        let parallelBatchSize = 5;
        let currentBatchNumber = 0;
        this.showWorkPopup(message);

        let payloads = ads.map(ad => {
            return {
                index: ad.data.index,
                key: ad.id,
                dynamicData: [...ad.properties.getState().value]
            }
        });

        let batches = [...arrayToChunks(payloads, batchSize)]; // split payloads into batches.
        let parallelBatches = [...arrayToChunks(batches, parallelBatchSize)]; // slipt batches into parallel chunks

        for (let i in parallelBatches) {
            this.workMessage = `Saving... ${Math.round((Number(i) / parallelBatches.length) * 100)}%`;
            console.log('parallelBatch', i);
            let parallel = parallelBatches[i];
            let promises = [];
            for (let i in parallel) {
                let payload = parallel[i];
                currentBatchNumber++;
                promises.push(functionAuthRequest('updateMultipleAds', { ads: payload }, this.parent));
            }
            console.log('Sending parallel ' + (Number(i) + 1) + ' of ' + parallelBatches.length);
            await Promise.all(promises);
        }
        
        ads.forEach(ad => ad.properties.resetChanged());

        this.hideWorkPopup();
        console.log('UpdateMultiple done');
    }

    new = async (dynamicProperties?: DynamicPropertyData[]) => { // TODO: routing needs to be fixed here.
        console.log(`Ads: Creative new ad.`)
        let parent = this.parent as Creative;
        let data = new AdSchema();
        if (dynamicProperties) data.dynamicData = dynamicProperties;
        // else data.dynamicData = parent.dynamicProperties.getState();
        if (this.current) data.index = this.current.data.index + 1;

        let newAd = await this.add(data);


        newAd.navigateTo();
    }

    newFromTemplate = async (template: Template) => {
        console.log('newFromTemplate', template);
        let data = new AdSchema();
        let index = this.current ? this.current.data.index + 1 : this.children.length;
        data.index = index;
    
        // Create the new Ad instance
        let newAd = await this.create(data);
    
        // Set up the new ad's properties
        newAd.properties.setState(deepCopy(store.creative.data.dynamicProperties));
        newAd.properties.merge(template.properties.properties, true, true);
        newAd.properties.get('template')?.set(template.id);
        newAd.properties.get('label')?.set(template.data.name);
        await this.renderFonts(newAd);
        newAd.saveDynamicProperties(false);
    
        this.addMultipleAds([newAd.data], index);
    }

    newFromData = async (adData: AdSchema) => {
        let children = [...this.children];
        let data = adData;
        let index = this.current ? this.current.data.index + 1 : this.children.length;
        data.index = index;

        let newAd = await this.create(data);

        children.splice(index, 0, newAd);
        this.children = children;
        this.updateIndexesLocal();

        await newAd.save();
        this.updateIndexes();
    }

    reflowAds = async () => {
        this.children = [ ... this.children.sort((a, b) => a.data.index - b.data.index) ];
    }

    updateIndexesLocal = () => {
        let ads = [...this.children];
        ads.forEach((ad, index) => {
            ad.setIndex(index);
        });
    }

    updateIndexesLocalWithGap = (gapStartIndex: number = 0, gapSize: number = 0) => {
        let allSorted = this.children.sort((a, b) => a.data.index - b.data.index);
        for (let i = gapStartIndex; i < allSorted.length; i++) {
            let ad = allSorted[i];
            ad.setIndex(i + gapSize);
        }
    }

    updateIndexes = async (set: Ad[] = []) => {
        console.log('AdsManager: updateIndexes');
        let batchSize = 1000;
        let adIndexes: [string, number][] = [];
        let workStarted = this.workStarted;
        if(workStarted === false) { this.showWorkPopup();
            this.workMessage = "Updating Ad IDs... ";
        }
        // if(set.length > 0) adIndexes = set.map(ad => [ad.key, ad.data.index]);
        adIndexes = this.children.filter(ad => ad.isLocalOnly === false).map(ad => [ad.id, ad.data.index]);
        console.log('adIndexes', adIndexes);
        
        let batches = [...arrayToChunks(adIndexes, batchSize)]; // split payloads into batches.
        
        for (let i in batches) {
            let batch = batches[i];
            console.log(JSON.stringify(batch));
            await FirebaseFunctions.updateAdIndexes(this.parent, batch);
        }

        if(workStarted === false) { this.hideWorkPopup(); }
        console.log('Ads Manager: updateAdIndexes done.');
    }

    commitIndexesToFirestore = async () => {
        let items = [...this.children];
        let batch = db.batch();
        items.forEach((item) => {
            let data = { ...item.data };
            batch.update(item.docRef, { index: data.index });
        });
        await batch.commit();
    }

    downloadText = (filename: string, text: string) => {
        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        pom.setAttribute('download', filename);
    
        pom.click();
    }

    promptForCSVFile = (): Promise<string | undefined> => {
        return new Promise(function (resolve, reject) {
            var pom = document.createElement('input');
            pom.setAttribute('type', 'file');
            pom.click();
            pom.onchange = function(){}
            pom.addEventListener('change', async function (e) {
                resolve(await readSingleFile(e as FileEvent));
            }, false);
        });
    }

    getCSV = () => {
        let ads = [ ... this.selected ];
        let creative = this.parent as Creative;
        let data = ads.map(ad => ad.properties.getCSVRowObject());
        this.downloadText(creative.data.name + '.csv', Papa.unparse(data));
    }

    importCSV = async () => {
        try {
            let fileContent = await this.promptForCSVFile();
            if(!fileContent) return;
            let data = await this.parseCVS(fileContent);
            console.log('CSVImport Data:', data);
            this.showWorkPopup();
            let batchSize = 10;
            let adIds = data.map(ad => ad.id);
            let adKeys = data.map(ad => ad.adID);

            let findDuplicates = (arr: any[]) => arr.filter((item, index) => arr.indexOf(item) != index)

            let duplicateAdIds = findDuplicates(adIds);
            let duplicateAdKeys = findDuplicates(adKeys);

            if (duplicateAdIds.length > 0) {
                this.hideWorkPopup();
                console.log(duplicateAdIds);
                throw new Error('CSV Import: Duplicate IDs found. ID(s): ' + duplicateAdIds.join(', '));
            }
            if (duplicateAdKeys.length > 0) {
                this.hideWorkPopup();
                console.log(duplicateAdKeys);
                throw new Error('CSV Import: Duplicate IDs found. ID(s): ' + duplicateAdKeys.join(', ')); /* + '. For new rows leave the adID blank.'*/
            }

            for (let i = 0; i < data.length; i += batchSize) {
                console.log('batch', i);
                this.workMessage = `Importing CSV... ${Math.round((Number(i) / data.length) * 100)}%`;
                try {
                    await this.importCSVBatch(i, data, batchSize);
                } catch (error) {
                    throw new Error(String(error));
                }
            }
            console.log('CSV Import: Done.');
            this.hideWorkPopup();
        } catch (error) {
            console.log('CSV Import: Error', error);
            this.hideWorkPopup();
            throw new Error(String(error));
        }
    }

    importCSVBatch = async (startIndex: number, data: CSVAdRowData[], batchSize: number = 10) => {
        await delay(10);
        let batchCount = 0;
        for (let i = startIndex; i < data.length && batchCount < batchSize; i++) {
            batchCount++;
            let row = data[i];
            let ad = this.getById(row.adID);

            if (ad) {
                let newDynamicProperties = new DynamicPropertiesManager(this.parent.parent);
                newDynamicProperties.setUsingCSVRowObject(row);
                let changed = !deepObjectCompare(newDynamicProperties.getData(), ad.properties.getData());
                // console.log('##CSVImport: changed', changed);
                if (changed) {
                    try {
                        ad.properties.setUsingCSVRowObject(row);
                        await ad.renderFonts();
                        ad.setIndex(Number(row.id) - 1);
                    } catch (error) {
                        throw new Error(String(error));
                        break;
                    }
                }
            } else {
                throw new Error('CSV Import: No current ad exists for row ' + (i + 2) + '. Please make sure the adID is correct.');
                // let index = lastExistingAd ? lastExistingAd.index + 1 : this.all.length;
                // let newAd = this.newLocal(this.root.creative.dynamicProperties.csvDataToPropertyArray(row), index);
                // lastExistingAd = newAd;
                // console.log('NEWAD', newAd);
            }
        }
    }

    parseCVS = (content: string): Promise<CSVAdRowData[]> => {
        return new Promise((resolve, reject) => {
            Papa.parse(content, {
                header: true,
                complete: function (results) {
                    resolve(results.data as CSVAdRowData[]);
                }
            });
        });
    }

    selectChanged = () => {
        this.selectNone();
        this.filtered.forEach((ad, i) => {
            if (ad.properties.getChanged() === true) {
                ad.select()
            }
        });
    }

    selectFirstAvailable = () => {
        if (this.empty) return false;

        for (var item of this.children) {
            if (item.data.index === 0) return this.setCurrent(item.id);
        }

        return false;
    }

    selectAll = () => {
        var all = [...this.children];

        for (let i in all) {
            let ad = all[i];
            ad.select(true);
        }

        // this.selectState = SelectState.ALL;
    }

    select = (selectedAd: Ad) => {
        selectedAd.selectToggle();

        let lastSelectedAd = this.lastSelectedAd;

        if (root.input.shift && this.lastSelectedAd) {
            if (selectedAd.data.index < lastSelectedAd.data.index) {
                for (let i = selectedAd.data.index; i < lastSelectedAd.data.index; i++) {
                    let ad = this.all.sort((a, b) => a.data.index - b.data.index)[i];
                    if (lastSelectedAd.selected) ad.select(true);
                    if (!lastSelectedAd.selected) ad.deselect(true);
                }
            } else {
                for (let i = lastSelectedAd.data.index; i < selectedAd.data.index; i++) {
                    let ad = this.all.sort((a, b) => a.data.index - b.data.index)[i];
                    if (lastSelectedAd.selected) ad.select(true);
                    if (!lastSelectedAd.selected) ad.deselect(true);
                }
            }
        }

        this.lastSelectedAd = selectedAd;
    }

    selectNone = () => {
        var all = [...this.children];

        for (let i in all) {
            let ad = all[i];
            ad.deselect(true);
        }

        // this.selectState = SelectState.NONE;
    }

    selectUpdate = (currentAd: Ad) => { // TODO: re-write so that it uses ad.selectLastModified
        var numSelected = 0;
        var all = [...this.sorted];
        var filtered = [...this.filtered];

        if (this.current) this.current.setViewingComments(false);

        // if(this.current.isViewingComments) { 
        // this.current.updateViewHistory();
        // }

        let lastSelectedAd = this.lastSelectedAd;

        if (root.input.shift && this.lastSelectedIndex !== -1) {
            if (currentAd.data.index < lastSelectedAd.data.index) {
                for (let i = currentAd.data.index; i < lastSelectedAd.data.index; i++) {
                    let ad = all[i];
                    if (lastSelectedAd.selected) ad.select(true);
                    if (!lastSelectedAd.selected) ad.deselect(true);
                }
            } else {
                for (let i = lastSelectedAd.data.index; i < currentAd.data.index; i++) {
                    let ad = all[i];
                    if (lastSelectedAd.selected) ad.select(true);
                    if (!lastSelectedAd.selected) ad.deselect(true);
                }
            }
        }

        for (let i in all) {
            let ad = all[i];
            if (ad.selected) numSelected++;
        }

        // if (numSelected === 0) this.selectState = SelectState.NONE;
        // if (numSelected > 0) this.selectState = SelectState.PARTIAL;
        // if (numSelected === all.length) this.selectState = SelectState.ALL;

        this.lastSelectedIndex = currentAd.data.index;
    }

    setCurrent = (id?: string) => {
        console.log('setCurrent', id);
        
        if(!id) {
            if(this.current) { 
                this.current.deactivate();
                this.currentId = '';
            }
            return;
        }
        
        let currentAd = this.getById(id);
        if (!currentAd) return false;
        if (currentAd.id === this.currentId) return false;

        this.current?.deactivate();
        currentAd.activate();
        
        this.currentId = currentAd.id;
        this.currentIndex = currentAd.data.index;
        
        store.creative.setCurrentPropertyManager(PropertyManagerTypes.AD);
        this.dispatch(AdsManagerEvents.ACTIVATED, currentAd);
        return true;
    }

    renderFonts = async (ad: Ad) => {
        console.log('AdsManager: Rendering fonts');
        for (const prop of ad.properties.properties) {
            // console.log('AdsManager: Checking type for:', prop.name, prop.type, ad.properties.properties.length);
            if(prop.type !== DynamicPropertyType.SVGText) continue;
            let textProp = prop as SVGText;
            console.log('AdsManager: Rendering font for:', textProp.name);
            await textProp.renderTextPaths?.(true);
        }
    }

    addSelectedFilterProperty = (property: IDynamicProperty<any>) => {
        this.selectedFilterProperties.push(property);
    }

    removeSelectedFilterProperty = (property: IDynamicProperty<any>) => {
        this.selectedFilterProperties.forEach( (property, index) => {
            let propertyMatches = (
                property.value === property.value &&
                property.name === property.name &&
                property.type === property.type
            );
            if( propertyMatches ) {
                this.selectedFilterProperties.splice(index, 1);
            }
        })
    }

    onCreate = (ad: Ad) => {
        this.filter.parseFilterPropertiesFromAd(ad);
    }

    removeMultipleAds = async (set: Ad[]) => {
        await this.removeMultiple(set);
        this.updateIndexesLocal();
        this.updateIndexes();
    }

    reset() {
        super.reset();
        this.current?.reset();
        this.current?.properties.reset();
        this.empty = true;
        this.currentId = '';
        this.currentIndex = NaN;
        // this.filtering = false;
        this.filterUpdate = NaN;
        this.filterSet;
        this.filters = [];
        this.filterProperties = [];
        this.lastSelectedIndex = -1;
        this.workStarted = false;
        this.workMessage = '';
        this.working = false;
        this.allUniqueAdProperties = [];
    }
}

function readSingleFile(e: FileEvent): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
        if(e.target?.files && e.target.files.length > 0) {
            var file = e.target.files[0];
            if (!file) {
                return;
            }
            var reader = new FileReader();
            reader.onload = function (e) {
                var contents = e.target?.result as string;
                resolve(contents);
            };
            reader.readAsText(file);
        }
    });
}