import root, { RootStore } from './index';
import Client from './Client';
import { UserRoles } from './UserTypes';
import { db } from './Firebase';
import { FolderSchema } from './DocumentFolderTypes';
import DocumentFolder from './DocumentFolder';
import DocumentFolderCollection from './DocumentFolderCollection';

export default class ClientsManager extends DocumentFolder {
    documents = new DocumentFolderCollection(db.collection('/clients/'), Client);

    onLoad = async (data: FolderSchema) => {
        if(root.user.authenticated === false) {
            console.log('ClientsManager: Aborting loading. User not authenticated.');
            return;
        }
        
        if(root.user.role === UserRoles.ADMIN || root.user.role === UserRoles.MANAGER) {
            this.documents.query = () => this.documents.collectionRef.where('archived', '==', false).orderBy('name', 'asc');
        } else {
            this.documents.query = () => this.documents.collectionRef.where(`members.${root.user.id}.role`, '>', '""');
        }

        await this.documents.load();
    }

    onReady = async () => {
        console.log('Clients ready', this.documents.all.length);
    }
}