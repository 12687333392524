import {
  Caption,
  Column,
  Container,
  MainLabel,
  MetadataContainer,
  NameContainer,
  Subtitle,
  Title,
} from './ProfileCard.styles';
import { format } from 'date-fns';
import { SsoAvatar } from 'components/UI';
import { timestampToDate } from 'utils/Dates';
import { UserSchema } from 'stores/User';
import React from 'react';

export interface ProfileCardProps {
  userData: UserSchema;
}

class ProfileCard extends React.Component<ProfileCardProps>{
  constructor(props: ProfileCardProps) {
    super(props);
  }

  getJoinedDateLabel = () => {
    const { userData } = this.props;
    const { dateCreated } = userData;

    if (!('nanoseconds' in dateCreated)) {
      return '-';
    }

    return format(timestampToDate(dateCreated), 'LLLL dd, yy')
  }

  render() {
    const { getJoinedDateLabel } = this;
    const { userData } = this.props;
    const {
      displayName,
      email,
      photoURL,
      role,
    } = userData;

    return (
      <Container>
        <SsoAvatar icon="google" src={photoURL} />

        <NameContainer>
          <Title>{displayName}</Title>
          <Caption>{email}</Caption>
        </NameContainer>

        <MetadataContainer>
          <Column>
            <Subtitle>Joined</Subtitle>
            <MainLabel>{getJoinedDateLabel()}</MainLabel>
          </Column>

          <Column>
            <Subtitle>Role</Subtitle>
            <MainLabel>{role}</MainLabel>
          </Column>
        </MetadataContainer>
      </Container>
    );
  }
}

export default ProfileCard;
