import { firestore, firebase, db, functionAuthRequest } from './Firebase';
import { DocumentReference, CollectionReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot, FieldValue, Timestamp as FirestoreTimestamp } from '@firebase/firestore-types';
import { DocumentCollectionType, DocumentStatus, DocumentType, FilterProperty, FilterSet, LooseObject } from './Types';
import root, { RootStore } from 'stores';
import Assets from './Assets';
import { FolderSchema } from './DocumentFolderTypes';
import DocumentFolder from './DocumentFolder';
import DocumentFolderCollection from './DocumentFolderCollection';
import DocumentCollection from './DocumentCollection';
import { getFilenameExtention, nowTimestamp } from 'utils/index';
import AnimationPlayerStore from './AnimationPlayer';
import CommentsManager from './CommentsManager';

export class AnimationSchema extends FolderSchema {
    type = DocumentType.ANIMATION;
    archived = false;
    author: string = '';
    currentAnimationID: string = '';
    name: string = '';
    notes: string = '';
    dateModified: FirestoreTimestamp | FieldValue = nowTimestamp();
    tags: string[] = [];
    viewHistory: LooseObject = {};
}

export class AnimationPublicSchema {
    type = DocumentType.ANIMATION;
    name: string = '';
}

export type AnimationParams = {
    clientID: string,
    projectID: string,
    animationID: string
}

export enum AnimationPanelViews {
    CANVAS = 'canvas',
    VERSIONS = 'versions',
    COMMENTS = 'comments',
    EXPORT = 'export',
    NONE = 'none',
}

export default class Animation extends DocumentFolder<AnimationParams, AnimationSchema, AnimationPublicSchema> {
    schema = new AnimationSchema();
    publicSchema = new AnimationPublicSchema();
    type = DocumentType.ANIMATION;
    collectionType = DocumentCollectionType.ANIMATION;    
    
    get defaults() { // TODO: Setup DocumentStore to support this method of getting defaults so that it returns a new instance of the schema. Useful for dynamic values, like getting a newTimestamp on each instance.
        let data = new AnimationSchema();
        let parentCollection = this.parent as DocumentFolderCollection;
        let collectionOwner = parentCollection?.parent ? parentCollection.parent as DocumentFolder<any, AnimationSchema, AnimationPublicSchema> : null;
        if (parentCollection && collectionOwner) {
            data.members = collectionOwner.data.members;
            data.memberKeys = collectionOwner.data.memberKeys;
        }
        data.author = root.user.id;
        return data;
    }

    get currentAnimationAsset() {
        return this.assets.getById(this.data.currentAnimationID);
    }
    
    generating = false;
    // tearsheets = new Tearsheets(this); // TODO: this will be connected to bundles.
    assets = new Assets(this);
    player = new AnimationPlayerStore();
    comments = new CommentsManager(this);
    author = '';
    error = '';
    displayPanel: AnimationPanelViews = AnimationPanelViews.NONE;
    lastDisplayPanel: AnimationPanelViews = AnimationPanelViews.NONE;
    viewScale = 1;

    parent: DocumentCollection | null;

    constructor(parent: any, docRef: DocumentReference, data?: AnimationSchema) {
        super(parent, docRef, data);
        this.init();
    }

    // navigateTo = () => {
    //     console.log('ROUTE', root.routes.creative);
    //     console.log('PARAMS', this.params);
    //     root.router.goTo(root.routes.creative, this.params);
    // }

    onBeforeSave = async () => {
        
    }


    onReady = async () => {
        
    }

    onLoad = async (data: FolderSchema) => {
        // this.projects.query = this.creatives.collectionRef.orderBy('dateModified', 'desc');
        await this.breadcrumbs.load();
        if (this.stub) return;
        await this.members.load();
        await this.assets.load();
        console.log('ANIMATION LOAD END');
    }

    onUpdated = () => {
        // console.log('changed');
    }

    setPanel = (panelView: AnimationPanelViews, toggle = false) => {
        if(panelView !== this.displayPanel) {
            this.lastDisplayPanel = this.displayPanel;
        }
        if(toggle && panelView === this.displayPanel) {
            return this.displayPanel = AnimationPanelViews.NONE;
        }
        this.displayPanel = panelView;
    }

    setCurrentAnimation = async (animationID: string) => {
        this.data.currentAnimationID = animationID;
        await this.save();
    }

    uploadAnimationFile = async (file: File) => {
        if(!file) return;
        if(getFilenameExtention(file.name) === '.json') this.setPanel(AnimationPanelViews.VERSIONS);
        else this.setPanel(AnimationPanelViews.NONE);
    
        if (this.displayPanel === AnimationPanelViews.VERSIONS) {
          // create utc timestamp based name below 
          let fileName = new Date().getTime() + file.name;
          await this.assets.upload(file, fileName);
          await this.setCurrentAnimation(this.assets.newest.id);
        }
      }

    togglePanel = (panelView: AnimationPanelViews) => {
        if(this.displayPanel === panelView) {
            this.displayPanel = AnimationPanelViews.NONE;
        }
        else {
            this.displayPanel = panelView;
        }
    }

    setViewScale = (scale: number) => {
        this.viewScale = scale;
    }

    reset = () => {
        super.reset();
        this.status = DocumentStatus.NONE;
        this.generating = false;
        this.data = new AnimationSchema();
        this.player.reset();
        this.comments.reset();
        this.assets.reset();
        this.assets = new Assets(this);
        this.author = '';
        this.error = '';
        this.displayPanel = AnimationPanelViews.NONE;
        this.viewScale = 1;
    }
}