import {
  Form,
  Input,
  message as AlertTriggerer,
  Radio,
} from 'antd';
import { FormModal } from 'components/UI';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { UserRoles } from 'stores/UserTypes';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import DocumentStore from 'stores/DocumentStore';
import React from 'react';

const ADD_MEMBER_MODAL_TITLE = 'Add User';

export interface AddMemberModalProps {
  isVisible: boolean;
  onClose: () => void;
  parentDocument: DocumentStore;
}

class AddMemberModal extends React.Component<AddMemberModalProps>{
  isLoading: boolean = false;

  constructor(props: AddMemberModalProps) {
    super(props);

    makeObservable(this, {
      isLoading: observable,
    });
  }

  turnOnLoader = () => {
    this.isLoading = true;
  }

  turnOffLoader = () => {
    this.isLoading = false;
  }

  handleFormSubmit = async (values: unknown, formController: WrappedFormUtils<unknown>) => {
    const targetEmail = (values as { email: string; role: UserRoles }).email;
    const targetRole = (values as { email: string; role: UserRoles }).role;

    const { onClose, parentDocument } = this.props;
    const { turnOffLoader, turnOnLoader } = this;

    try {
      turnOnLoader();

      const userAlreadyExists = !!parentDocument.members.getByEmail(targetEmail);

      if (userAlreadyExists) {
        AlertTriggerer.error((<span>User found for email: <b>{targetEmail}</b></span>));
        return;
      }

      await parentDocument.members.add(targetEmail, targetRole);

      formController.resetFields();
      onClose();

    } catch (e) {
      AlertTriggerer.error((<span>Something went wrong. Please <b>try again</b>...</span>));
      console.error('Error creating the new member:', e);
    }
    finally {
      turnOffLoader();
    }
  }

  render() {
    const { isLoading } = this;
    const { isVisible, onClose } = this.props;
    const { handleFormSubmit } = this;

    return (
      <FormModal
        isLoading={isLoading}
        isVisible={isVisible}
        onClose={onClose}
        onSubmit={handleFormSubmit}
        resetOnClose
        submitText={ADD_MEMBER_MODAL_TITLE}
        title={ADD_MEMBER_MODAL_TITLE}
      >
        {
          ({ formController: { getFieldDecorator } }) => (
            <>
              <Form.Item label="User Email">
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'An email address is required.' }]
                })(<Input disabled={isLoading} />)}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('role', {
                  initialValue: 'view',
                })(
                  <Radio.Group>
                    <Radio value="edit">Can Edit</Radio>
                    <Radio value="view">Can View</Radio>
                  </Radio.Group>,
                )}
              </Form.Item>
            </>
          )
        }
      </FormModal>
    );
  }
};

export default observer(AddMemberModal);
