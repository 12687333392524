import { LooseObject, Member, MemberRoles } from "./Types";
import { functionAuthRequest, db } from './Firebase';
import FirebaseFunctions from './FirebaseFunctions';
import { DocumentReference, DocumentData } from '@firebase/firestore-types';
import DocumentStore, { DocumentEvents } from './DocumentStore';
import { DocumentSchema } from "./DocumentSchema";
import User, { UserSchema } from "./User";
import { UserRoles } from "./UserTypes";
import Store from "./Store";
import DocumentCollection from "./DocumentCollection";

export interface MemberMetadataSchema {
    memberKeys: string[];
    members: { [uid: string]: Member };
}

interface MemberDocumentStore extends DocumentStore<MemberMetadataSchema> {
    schema: MemberMetadataSchema;
}

/**
 * @class MembersManager
 * @description
 * The MembersManager class is used to manage the members of a DocumentStore, and whether they can view or edit the document.
 * It is used to add, remove, and update members of a document.
 * 
 * @example
 * // Create a new MembersManager
 * const membersManager = new MembersManager(documentStore);
 * 
 * // Load the members of the document
 * await membersManager.load();
 * 
 * // Get all members
 * const members = membersManager.all;
 * 
 * // Add a new member
 * await membersManager.addMember('user-uid-123', MemberRoles.EDIT);
 */
class MembersManager extends Store {
    context: string = '';
    members: Member[] = [];
    memberKeys: string[];
    docRef: DocumentReference;
    parent: MemberDocumentStore; // TODO: check if this any type works for members manager
    users: User[] = [];
    enabled = false;

    constructor(parent: any) {
        super();
        this.parent = parent;
        this.init();
    }

    /**
     * Loads the members of the parent document.
     * */
    load = async () => {
        this.enabled = true;
        this.setState(this.parent.data);
        this.users = await this.loadUsers();
        this.parent.addEventListener(DocumentEvents.CHANGED, this.update);
    }

    update = async () => {
        this.setState(this.parent.data);
        this.users = await this.loadUsers();
    }

    get all() {
        return [... this.members];
    };

    get keys() {
        return [... this.members].map(item => item.uid);
    }

    canModify(uid: string) {
        let member = this.getById(uid);
        if (!member) return false;
        return this.getById(uid).role === MemberRoles.EDIT;
    }

    canView(uid: string) {
        return this.getById(uid).role === MemberRoles.VIEW;
    }

    add = async (targetEmail: string, role: UserRoles) => {
        const userMetadata =
            await db.collectionGroup('public').where('email', '==', targetEmail).get();
        const { uid } = userMetadata.docs[0].data();

        return FirebaseFunctions.addMember(this.parent, uid, role);
    }

    edit = async (memberId: string, newRole: UserRoles) => {
        return FirebaseFunctions.updateMember(this.parent, memberId, newRole);
    }

    enable = () => {
        this.enabled = true;
    }

    disable = () => {
        this.enabled = false;
    }

    removeMember = async (uid: string) => {
        await FirebaseFunctions.removeMember(this.parent, uid);
    }

    updateMember = async (uid: string, role: UserRoles) => {
        await FirebaseFunctions.updateMember(this.parent, uid, role);
        return this.getById(uid);
    }

    reset = () => {
        this.members = [];
        this.users = [];
    }

    newMember(uid: string, role: MemberRoles): Member {
        return {
            uid: uid,
            role: role
        }
    }

    /**
     * Returns the current state of the members manager to be saved to the parent document.
     * */
    getState = () => {
        let memberEntries = Array(this.members);
        let data: MemberMetadataSchema = {
            memberKeys: [... this.memberKeys ],
            members: Object.fromEntries(memberEntries)
        }
        return data
    }

    /**
     * Hydrates the members manager with the latest data from the parent document.
     * 
     * This uses the properties `memberKeys` and `members` from the parent documents data. 
     * @param data The data to hydrate the members manager with.
     * */
    setState = (data: MemberMetadataSchema) => {
        let members: Member[] = [];

        for (const [key, value] of Object.entries(data.members)) {
            let uid: string = value.uid;
            let role: MemberRoles = value.role;
            members.push({ uid, role });
        };

        this.docRef = this.parent.docRef;
        this.members = members;
        this.memberKeys = data.memberKeys;
    }

    loadUsers = async () => { // TODO: this should probably be in the User class or a Users class
        const usersIds = Object.values(this.memberKeys);
        const users = await Promise.all(usersIds.map(async (userId) => {
            const docRef = db.collection('users').doc(userId).collection('public').doc('info');
            const response = await docRef.get();
            const responseSerialized = response.data() as UserSchema;

            const dataFromMembersList = this.members.find((member) => member.uid === userId);
            const memberUserMetadata: UserSchema = { // UserRole
                ...responseSerialized,
                documentRole: dataFromMembersList?.role || MemberRoles.VIEW,
            };

            let newUser = new User(this, docRef);
            newUser.update(memberUserMetadata);

            return newUser;
        }));

        return users;
    }

    getById(id: string) {
        return this.all.filter(member => member.uid == id)[0];
    }

    getUserByMemberId(id: string) {
        return this.users.find(user => user.data.uid === id);
    }

    getByEmail(email: string) {
        return this.users.find(user => user.data.email === email);
    }

}

export default MembersManager;
