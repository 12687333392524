import { Input } from "antd";
import {
  ColumnProps,
  FilterDropdownProps,
} from "antd/lib/table";
import { timestampToDate } from "utils/Dates";
import {
  ApplyFilterButton,
  Container,
  ResetFiltersButton,
  SearchIcon,
  SearchInput,
} from "./ColumnBuilder.styles";
import {
  BuildColumnArgs,
  TableColumnFilterType,
  TableColumnSortType,
} from "./ColumnBuilder.types";

export const getRendererArgs = <T extends {}>(baseArgs: BuildColumnArgs<T>) => {
  if ('render' in baseArgs) {
    return {
      render: (cell: unknown, rowData: T) => baseArgs.render?.(rowData),
    }
  }

  if ('sourceData' in baseArgs) {
    return {
      render: (cell: unknown, rowData: T) => baseArgs.sourceData?.(rowData),
    }
  }
}

export const getSorterArgs = <T extends {}>(baseArgs: BuildColumnArgs<T>) => {
  const { sortType, sorter } = baseArgs;
  let computedSortType = sortType;

  if (computedSortType === TableColumnSortType.NoSort) {
    return {
      sorter: false,
    }
  }

  if (computedSortType === TableColumnSortType.FirestampDate) {
    return {
      sorter: (a: T, b: T) => {
        // @ts-expect-error
        const aDate = timestampToDate(a.data.dateModified as Timestamp);
        // @ts-expect-error
        const bDate = timestampToDate(b.data.dateModified as Timestamp);

        return aDate > bDate ? 1 : -1;
      }
    }
  }

  if (computedSortType === TableColumnSortType.Alphabetical) {
    if (!('sourceData' in baseArgs)) {
      throw new Error('Can not sort alphabetically when no sourceData attribute at buildColumn');
    }

    return {
      sorter: (a: T, b: T) =>
        String(baseArgs.sourceData?.(a)) > String(baseArgs.sourceData?.(b)) ? 1 : -1,
    }
  }

  if (computedSortType == TableColumnSortType.Custom) {
    return {
      sorter,
    }
  }

  return {};
}

export const getFilterableArgs = <T extends {}>(baseArgs: BuildColumnArgs<T>) => {
  if (baseArgs.filterType === TableColumnFilterType.Search) {
    let searchInput: Input | null = null;

    const handleConfirmFilters = ({
      confirm,
    }: Pick<FilterDropdownProps, 'confirm' | 'selectedKeys'>) => {
      if (confirm) confirm();
    };

    const handleResetFilters = ({
      clearFilters,
    }: Pick<FilterDropdownProps, 'clearFilters' | 'selectedKeys'>) => {
      if (clearFilters) clearFilters();
    }

    const filterDropdownRenderer = ({
      clearFilters,
      confirm,
      selectedKeys,
      setSelectedKeys,
    }: FilterDropdownProps) => (
      <Container>
        <SearchInput
          onChange={e => setSelectedKeys?.(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleConfirmFilters({ confirm, selectedKeys })}
          placeholder={baseArgs.placeholder || 'Search'}
          ref={node => searchInput = node}
          value={selectedKeys?.[0] || ''}
        />
        <ApplyFilterButton onClick={() => handleConfirmFilters({ confirm, selectedKeys })}>
          Search
        </ApplyFilterButton>
        <ResetFiltersButton onClick={() => handleResetFilters({ clearFilters })}>
          Reset
        </ResetFiltersButton>
      </Container>
    );

    const filterIconRenderer = (isTheFilterActive: boolean) => (
      <SearchIcon active={isTheFilterActive} />
    );

    const handleFilterDropdownVisibleState = (isVisible: boolean) => {
      if (searchInput && isVisible) {
        searchInput.select();
      }
    };

    const onFilterHandler = (value: string, rowData: T) =>
      baseArgs.filterer(value, rowData);


    return {
      filterDropdown: filterDropdownRenderer,
      filterIcon: filterIconRenderer,
      onFilter: onFilterHandler,
      onFilterDropdownVisibleChange: handleFilterDropdownVisibleState,
    }
  }

  if (baseArgs.filterType === TableColumnFilterType.Options) {
    return {
      filters: baseArgs.options,
      onFilter: (value: string, rowData: T) => baseArgs.filterer(value, rowData),
    }
  }

  return {};
}

export const buildColumn = <T extends {}>(args: BuildColumnArgs<T>): ColumnProps<T> => {
  const computedArgs: ColumnProps<T> = {
    ...args,
    ...getSorterArgs(args),
    ...getFilterableArgs(args),
    ...getRendererArgs(args),
  }

  return {
    ...computedArgs,
    dataIndex: args.title || '',
  };
};
