import { Button, Modal as BaseModal, Spin } from 'antd';
import Bundle from 'stores/Bundle';
import BundleElementRow from './BundleElementRow';
import CopyBundleLabeledUrlsButton from './CopyBundleLabeledUrlsButton';
import CopyBundleUrlsButton from './CopyBundleUrlsButton';
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const Modal = styled(BaseModal)`
  .ant-modal-body{
    height: 60vh;
    padding: 0;
    overflow: auto;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export interface BundleReportModalProps {
  bundle: Bundle;
  isVisible: boolean;
  onClose: () => void;
}

class BundleReportModal extends React.Component<BundleReportModalProps>{
  constructor(props: BundleReportModalProps) {
    super(props);
  }

  getFooterButtons = () => {
    const { bundle, onClose } = this.props;
    const { id } = bundle;

    return [
      <CopyBundleLabeledUrlsButton
        bundle={bundle}
        key={`creative.bundlesDrawer.bundle.${id}.copyLabeledUrlsButton`}
      />,
      <CopyBundleUrlsButton
        bundle={bundle}
        key={`creative.bundlesDrawer.bundle.${id}.copyUrlsButton`}
      />,
      <Button
        key={`creative.bundlesDrawer.bundle.${id}.cancelButton`}
        onClick={onClose}
      >Cancel
      </Button>
    ];
  }

  render() {
    const { getFooterButtons } = this;
    const { bundle, isVisible, onClose } = this.props;

    const bundleElements = bundle.elements.all;
    const footerButtons = getFooterButtons();

    const spinner = <SpinnerContainer><Spin /></SpinnerContainer>;

    const bundleElementsRows = bundleElements.map((element) => (
      <BundleElementRow
        bundle={bundle}
        element={element}
        key={`creative.bundle.${bundle.id}.report.elementRow.${element.id}`}
      />
    ))
      console.log('bundle.mounted', bundle.mounted);
    return (
      <Modal
        footer={footerButtons}
        onCancel={onClose}
        title="Bundle Report"
        visible={isVisible}
        width={700}
      >
        { bundle.elements.mounted ? bundleElementsRows : spinner }
      </Modal>
    );
  }
};

export default observer(BundleReportModal);
