import { Button as BaseButton } from 'antd';
import { makeObservable, action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

export interface UploadVersionButtonProps {
  onSelected?: (file: File) => void;
}

const Container = styled(BaseButton)`
  align-items: center;
  font-size: 16px;
  padding: 0px 12px;
`;

const UploadInput = styled.input.attrs({
  type: 'file',
  accept: '.json'
})`
  display: none;
`;

class UploadVersionButton extends React.Component<UploadVersionButtonProps> {
  uploadInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: UploadVersionButtonProps) {
    super(props);
    this.uploadInputRef = React.createRef();

    makeObservable(this, {
      handleFileChange: action
    });
  }

  handleUploadClick = () => {
    if (this.uploadInputRef.current) {
      this.uploadInputRef.current.click();
    }
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log('File selected:', file.name);
      
      // Call the onSelected prop if it's provided
      if (this.props.onSelected) {
        this.props.onSelected(file);
      }
    }
  }

  render() {
    return (
      <>
        <Container onClick={this.handleUploadClick}>
          Upload
        </Container>
        <UploadInput 
          ref={this.uploadInputRef}
          onChange={this.handleFileChange}
        />
      </>
    );
  }
};

export default observer(UploadVersionButton);