import Store from '../../Store';
import { DynamicProperty, DynamicPropertyEvent, DynamicPropertyStatus } from "./Property";
import { DynamicPropertyData } from '../Types';
import { DynamicPropertyType } from './DynamicPropertyType';
// import * as Types from '../Types';
import { AllTypes, Group } from '../Types';
import None from './None';
import { IDynamicProperty } from '../Types';
import root from 'stores';
import { CSVAdRowData } from 'stores/AdsManager/AdsManager';
import { useDebounce } from 'utils';
import SVGText from './SVGText';
import DynamicPropertiesManager from '../Manager';

export enum DynamicGroupStatus {
  READY = 'ready',
  LOADING = 'loading'
}

export enum DynamicGroupEvent {
  ADDED = 'added',
  REMOVED = 'removed',
}

export default class DynamicGroup extends Store implements IDynamicProperty<IDynamicProperty<any>[]>{
  label = '';
  value: IDynamicProperty<any>[] = [];
  default: IDynamicProperty<any>[] = [];
  initialValue: IDynamicProperty<any>[] | typeof undefined;
  properties: IDynamicProperty<any>[] = [];
  originalProperties: IDynamicProperty<any>[] = [];
  name: string;
  type = DynamicPropertyType.GROUP;
  reserved = false;
  status = DynamicPropertyStatus.READY;
  parent: IDynamicProperty<any> | null | undefined;
  changeEventsEnabled = true;
  isStateEnabled = false;
  subscribers: any[] = [];
  count = 0;

  changed = false;

  getChanged = () => {
    // see if any properties changed
    if(this.changed) return true;
    let changed = false;
    for (let property of this.properties) {
      if (property.getChanged()) {
        changed = true;
        break;
      }
    }
    return changed;
  }

  get loading() {
    // return if any property is loading
    for (let property of this.properties) {
      if (property.status === DynamicPropertyStatus.LOADING) return true;
    }
  }

  // get properties() {
  //   return this.value || [];
  // }

  // set properties(value: DynamicProperty<any>[]) {
  //   this.value = value;
  //   this.dispatch(DynamicPropertyEvent.CHANGED, value);
  // }

  constructor(parent?: IDynamicProperty<any> | null, name?: string, value?: any, reserved: boolean = false) {
    super();
    this.parent = parent;
    // console.log('DynamicGroup constructor', name, value, reserved);
    if (name) this.name = name;
    if (value !== undefined || value !== null) {
      this.setState(value);
      this.originalProperties = value;
    }
    if (reserved) this.reserved = reserved;
    this.init();
    this.addEventListener(DynamicPropertyEvent.CHANGED, this.broadcastToSubscribers);
  }

  broadcastToSubscribers = () => {
    this.subscribers.forEach(subscriber => {
      if (subscriber.forceUpdate) subscriber.forceUpdate();
    });
  };

  subscribe = (component: any) => {
    if (!component) return;
    if (!this.subscribers.includes(component)) {
      this.subscribers.push(component);
    }
  }

  unsubscribe = (component: any) => {
    if (!component) return;
    if (this.subscribers.includes(component)) {
      this.subscribers.splice(this.subscribers.indexOf(component), 1);
    }
  }


  enableState = () => {
    this.isStateEnabled = true;
    this.init();
    this.properties.forEach(property => {
      if (property instanceof DynamicGroup) property.enableState();
      else property.init();
    });
  }

  onUpdate = (name: string, value: any) => {

  }

  onChange = (property: IDynamicProperty<any> | undefined) => {
    if (this.changeEventsEnabled) {
      if (property) this.dispatch(DynamicPropertyEvent.CHANGED, property);
    }
  }

  onChangeDebounced = useDebounce(this.onChange, 15);

  addPropertyListeners = () => {
    for (let property of this.properties) {
      property.on(DynamicPropertyEvent.CHANGED, () => this.onChange(property));
    }
  }

  transform = (value: any) => {
    return value;
  }

  disableChangeEvents() {
    this.changeEventsEnabled = false;
  }

  checkExists = (property: IDynamicProperty<any>) => {
    let exists = this.properties.find((p) => p.name === property.name && p.type === property.type && p.value === property.value);
    return exists ? true : false;
  }

  revert = () => {
    for (let property of this.properties) {
      property.revert();
    }
    this.dispatch(DynamicPropertyEvent.CHANGED, this);
    this.dispatch(DynamicPropertyEvent.REVERTED, this);
  }

  merge = (newProperties: IDynamicProperty<any>[], overwriteExisting: boolean = true, appendMissing: boolean = true) => {
    for (let newProperty of newProperties) {
      let foundPropertyIndex = this.properties.findIndex(p => p.name === newProperty.name);
      let foundProperty = this.properties.find(p => p.name === newProperty.name);
      if (foundPropertyIndex > -1) {
        if (newProperty instanceof DynamicGroup && foundProperty instanceof DynamicGroup) {
          foundProperty.merge(newProperty.properties, overwriteExisting, appendMissing);
        } else {
          if (overwriteExisting) this.properties[foundPropertyIndex].set(newProperty.value);
        }
      } else {
        if (appendMissing) this.addProperty(newProperty);
      }
    }
    this.dispatch(DynamicGroupEvent.ADDED, this);
    this.dispatch(DynamicPropertyEvent.CHANGED, this);
  }

  append = async (newProperties: IDynamicProperty<any>[]) => {
    for (let newProperty of newProperties) {
      let index = this.properties.findIndex(p => p.name === newProperty.name);
      if (index > -1) {
        throw Error(`Property ${newProperty.name} already exists in group ${this.name}`);
      } else {
        this.addProperty(newProperty);
      }
    }
  }

  defaults = () => {
    return this.default;
  }

  get = (name: string) => {
    if (this.properties.length > 0) {
      return this.properties.find(item => item.name === name);
    }
  }

  getAllPropertiesNames = () => {
    const names = this.properties.map((property) => property.name);
    return [... new Set(names)]
  }

  getAllByName = (name: string) => {
    if (this.properties.length > 0) {
      return this.properties.filter(item => item.name === name);
    }
  }

  getByType = (type: string) => {
    let batch = [];
    for (let item of this.properties) {
      if (item.type === type) batch.push(item);
    }
    return batch;
  }

  getByValue = (type: string, value: any) => {
    let batch = [];
    for (let item of this.properties) {
      if (item.type === type && item.value === value) batch.push(item);
    }
    if (batch.length === 0) return null;
    return batch;
  }

  getByName = (name: string) => {
    for (let item of this.properties) {
      if (item.name === name) return item;
    }
    return null;
  }

  set = (name: string, value: any, silent = false) => {
    let properties = [...this.properties];
    let property = properties.find(item => item.name === name);
    if (property) {
      property.set(value);
    }
    if (silent === false) this.dispatch(DynamicPropertyEvent.CHANGED, this);
    this.onUpdate(name, value);
  }

  setReserved = (isReserved: boolean) => {
    this.reserved = isReserved;
  }

  render = () => {
    // for use in child classes that need to render values.
  }

  reset = () => {
    console.log('reset?');
    this.properties = [];
    this.originalProperties = [];
    this.value = [];
    this.initialValue = [];
    this.reserved = false;
    this.status = DynamicPropertyStatus.READY;
    this.changeEventsEnabled = true;
  }

  resetChanged = () => {
    for (let property of this.properties) {
      property.resetChanged();
    }
    this.initialValue = this.value;
    this.changed = false;
    this.dispatch(DynamicPropertyEvent.CHANGED, this);
  }

  clear = () => {
    this.value = [];
    this.properties = [];
  }

  // rename = (oldName: string, newName:string) => {
  //   let properties = [...this.properties];
  //   let property = properties.find(item => item.name === oldName);
  //   if (property) {
  //     property.rename(newName);
  //     this.dispatch(DynamicPropertyEvent.CHANGED, property);
  //   }
  // }

  get fullpath() {
    // if parent exists, return parent path + this.name and remove trailing dot
    if (this.parent) return this.parent.fullpath + '.' + this.name;
    else return '';

  }

  add = async (name: string, typeName: string, value: any) => {
    let properties = [...this.properties];
    let newProperty = this.create(typeName, name, value, false);

    if (newProperty.type === undefined) return console.warn('Dynamic Properties Manager: Error creating non-existant type "' + newProperty.type + '".');
    properties.push(newProperty);

    this.properties = properties;
    this.value = properties;
    this.changed = true;

    this.dispatch(DynamicGroupEvent.ADDED, newProperty);
    this.dispatch(DynamicPropertyEvent.CHANGED, newProperty);
    return newProperty;
  }

  create = (type: string, name: string, value: any, reserved: boolean): IDynamicProperty<any> => {
    let typeClass = Object(AllTypes)[type];
    if (!typeClass) {
      return new None();
    } else {
      let newProp = new typeClass(this, name, value, reserved) as IDynamicProperty<any>;
      newProp.setReserved(reserved || false);
      let missingValue = value === undefined || value === null;

      if( missingValue && newProp.setDefault) newProp.setDefault();
      
      if( !missingValue) {
        value = newProp.transform(value);
        if (newProp.render) newProp.render();
        newProp.value = value; // TODO: investigate what this is necessary. setting "value" from within class didn't work.
        newProp.initialValue = value; // TODO: investigate what this is necessary. setting "value" from within class didn't work.
      }
      // }
      if (this.isStateEnabled) newProp.init();
      return newProp;
    }
  }

  addProperty = (property: IDynamicProperty<any>) => {
    let value = property.value;
    if (property instanceof DynamicGroup) {
      value = property.properties;
    }
    return this.add(property.name, property.type, property.value);
  }

  addGroup = (name: string, properties: IDynamicProperty<any>[]) => {
    console.log('add group', name, properties);
    let copiedProperties = properties.map((property) => {
      return {
        name: property.name,
        type: property.type,
        value: property.value
      }
    });

    let group = new DynamicGroup(this, name, copiedProperties);
    this.properties.push(group);
    this.value.push(group);
    this.dispatch(DynamicGroupEvent.ADDED, group);
    this.dispatch(DynamicPropertyEvent.CHANGED, group);
    return group;
  }


  remove(name: string) {
    let properties = [...this.properties];
    let property = properties.find(item => item.name === name);
    if (property) {
      properties = properties.filter(item => item.name !== name);
      this.properties = properties;
      this.value = properties;
      this.dispatch(DynamicGroupEvent.REMOVED, property);
      this.dispatch(DynamicPropertyEvent.CHANGED, property);
    }
  }

  removeProperty(property: IDynamicProperty<any>) {
    let properties = [...this.properties];
    for (let i = 0; i < properties.length; i++) {
      if (
        properties[i].name === property.name &&
        properties[i].type === property.type &&
        properties[i].value === property.value
      ) {
        properties.splice(i, 1);
      }
    }
    this.properties = properties;
    this.value = properties;
    this.changed = true;
    this.dispatch(DynamicGroupEvent.REMOVED, property);
    this.dispatch(DynamicPropertyEvent.CHANGED, property);
  }

  rename = (name: string) => {
    this.name = name;
    this.dispatch(DynamicPropertyEvent.CHANGED, this.value);
  }


  setState = (data: any[]) => {
    if (!data) return;
    let propertiesData = data as DynamicPropertyData[];
    let properties: IDynamicProperty<any>[] = [];
  
    for (let propertyData of propertiesData) {
      let { type, name, value, reserved } = propertyData;
  
      // Check if the property already exists
      let existingProperty = this.getByName(name);
  
      if (existingProperty) {
        console.log
        // Update existing property
        if (existingProperty.type === type) {
          if (existingProperty.hasOwnProperty('setState')) {
            let propertyGroup = existingProperty as Group;
            propertyGroup.setState(value);
          } else {
            existingProperty.set(value, true);
          }
          existingProperty.setReserved(reserved || false);
          properties.push(existingProperty);
        } else {
          console.warn(`Property type mismatch for "${name}". Expected ${existingProperty.type}, got ${type}.`);
          properties.push(existingProperty);
        }
      } else {
        // Create new property
        let property = this.create(type, name, value, reserved);
        property.on(DynamicPropertyEvent.CHANGED, () => this.onChange(property));
        property.setInitialValue(property.transform(value));
  
        if (property.hasOwnProperty('setState')) {
          let propertyGroup = property as Group;
          propertyGroup.setState(value);
        } else {
          property.set(value, true, true);
        }
        property.setReserved(reserved || false);
  
        if (property.value === undefined) {
          console.warn('Dynamic Properties Manager: Error creating type "' + property.type + '".');
        } else {
          properties.push(property);
        }
      }
    }
    this.properties = properties;
    this.value = properties;
  
    // Only update initialValue if it's the first time setting the state
    if (!this.initialValue) {
      this.initialValue = properties;
      this.originalProperties = properties;
    }
  
    // Keep the changed status as it was before
    this.changed = false;
  
    // Dispatch the change event
    this.dispatch(DynamicPropertyEvent.CHANGED, this.value);
  }

  getRoot = (): IDynamicProperty<any> => {
    if (this.parent) {
      return this.parent.getRoot();
    } else {
      return this;
    }
  }

  getState = (): DynamicPropertyData => {
    let values = this.properties?.map(item => item.getState());
    return {
      value: values || [],
      name: this.name,
      reserved: this.reserved,
      type: this.type,
    } as DynamicPropertyData;
  }

  getString = () => {
    let data = {} as any;
    for (let property of this.properties) {
      data[property.name] = property.getString();
    }
    return JSON.stringify(data);
  }

  getPreviewData = () => {
    let data = {} as any;
    for (let property of this.properties) {
      data[property.name] = property.getPreviewData();
    }
    return data;
  }

  getCSVData = () => {
    let data = {} as any;
    for (let property of this.properties) {
      data[property.name] = property.getCSVData();
    }
    return JSON.stringify(data);
  }

  throwCSVImportError = (message: string) => {
    let root = this.getRoot() as DynamicPropertiesManager;
    if(!root) return;
    let adLabel = root.get('label')?.value;
    let adId = root.parentAd ? root.parentAd.id : '';
    let propertyPath = this.fullpath.replace(adId + '.', '');
    throw new Error(`CSV Import Error: Ad "${adLabel}" (${adId}): Property "${propertyPath}": ${message}`);
  }

  getObject = () => {
    let data = {} as any;
    for (let property of this.properties) {
      data[property.name] = property.getObject();
    }
    return data;
  }

  getExact = (name: string, type: string, value: any) => {
    let property = this.properties.find(item => {
      return item.name === name && item.type === type && item.value === value;
    });
    return property;
  }

  setInitialValue = (data: any) => {
    for (let property of this.properties) {
      property.setInitialValue(data[property.name]);
    }
  }

  setUsingCSV = (value: string) => { // TODO: Supporting nested groups is currently unsupported.
    let data = JSON.parse(value);
    // console.log('Dynamic Properties Manager: Setting using CSV: ', data);
    for (let name in data) {
      let value = data[name];
      let property = this.getByName(name);
      // console.log('++setUsingCSV: Setting property: ', name, ' to: ', value);
      if (property && property.setUsingCSV) {
        property.setUsingCSV(value);
      }
    }
  }

  setUsingObject = (value: any) => { // TODO: this is untested
    for (let name in value) {
      let property = this.getByName(name);
      if (property && property.type === 'Group' && property.setUsingObject) {
        property.setUsingObject(value[name]);
      }
    }
  }

  setUsingCSVRowObject = (properties: CSVAdRowData) => {
    let ignore = ['id', 'adID'];
    for (let name in properties) {
      if (ignore.includes(name)) continue;
      let value = Object(properties)[name];
      let property = this.getByName(name);
      if (property && property.setUsingCSV) {
        // console.log('++setUsingCSVRowObject: Setting property: ', name, ' to: ', value);
        property.setUsingCSV(value);
      }
    }
  }

}