import { observer } from 'mobx-react';
import Asset from 'stores/Asset';
import DrawerButton from 'components/Views/Creative/Drawers/DrawerButton';
import React from 'react';
import store from 'stores';

export interface NavigateToFolderButtonProps {
  asset: Asset;
}

class NavigateToFolderButton extends React.Component<NavigateToFolderButtonProps>{
  constructor(props: NavigateToFolderButtonProps) {
    super(props);
  }

  navigateToFolder = () => {
    const { asset } = this.props;
    const { creative } = store;
    creative.assets.setCurrentFolder(asset.data.path);
  }

  render() {
    const { navigateToFolder } = this;
    const { asset } = this.props;

    return (
      <DrawerButton disabled={asset.loading} onClick={navigateToFolder}>
        Open
      </DrawerButton>
    );
  }
};

export default observer(NavigateToFolderButton);
