import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Input, InputNumber, Button, Icon, Select, message, Popover } from 'antd';
import DynamicGroup, { DynamicGroupEvent } from '../../../../../stores/DynamicProperties/Types/Group';
import { AllTypes, IDynamicProperty } from '../../../../../stores/DynamicProperties/Types';
import { DynamicPropertyType } from '../../../../../stores/DynamicProperties/Types/DynamicPropertyType';
import InputContainer from './InputContainer';
import { camelCaseToTitle } from 'utils/String';
import DynamicPropertiesManager from 'stores/DynamicProperties/Manager';
import { makeObservable, observable } from 'mobx';
import PropertyPopover from '../PropertyPopover';

const { Option } = Select;

const Container = styled.div`
    display: flex;
    width:100%;
    margin-bottom:10px;
    margin-top:18px;
    padding: 10px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding-bottom: 3px;
    position: relative;
`;

const GroupContainer = styled.div`
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:100%;
    /* flex-wrap: wrap; */
    /* flex-flow:row; */
`;

const GroupLabel = styled.div`
    background: white;
    display: flex;
    position: absolute;
    font-size: 13px;
    font-weight: bold;
    top: -17px;
    left: 8px;
    align-items: center;
    justify-content: center;
    padding: 5px 6px;
`;



type InputGroupProps = {
    property: DynamicPropertiesManager | DynamicGroup | undefined,
    disabled: boolean,
    editMode?: boolean,
    style?: React.CSSProperties,
    hideLabel?: boolean,
    className?: string,
    onRemove: (propertyName: string) => void;
};

class InputGroup extends React.Component<InputGroupProps> {
    newPropertyName = '';
    newPropertyType: DynamicPropertyType = DynamicPropertyType.NONE;
    property: DynamicPropertiesManager | DynamicGroup | undefined;
    properties: IDynamicProperty<any>[] | undefined;
    popoverVisible: boolean = false;

    constructor(props: InputGroupProps) {
        super(props);
        this.property = this.props.property;
        this.properties = this.property?.properties;
        
        makeObservable(this, {
            newPropertyName: observable,
            newPropertyType: observable,
            popoverVisible: observable,
        });
    }

    // componentWillUnmount = () => {
    //     document.onkeydown = null;
    // }

    handleRemoveProperty = (name: string) => {
        this.property?.remove(name);
    }

    handleAddProperty = (name: string, type: DynamicPropertyType) => {
        this.property?.add(name, type, null) // TODO: this value needs to not be null, and some sort of default value provided by the property class
    }

    render() {
        let inputMode = 'single';
        let propertyGroup = this.props.property;
        let hideLabel = this.props.hideLabel || false;
        let editMode = this.props.editMode || false;
        if(!propertyGroup) return <></>;
        let properties = propertyGroup.properties;
        let currentAdLabel = propertyGroup.getRoot().get('label')?.value;

        let exludedAddTypes: string[] = [
            DynamicPropertyType.NONE as string,
            DynamicPropertyType.SVGData as string
        ];

        let allAddTypes = Object.keys(AllTypes).filter(key => !exludedAddTypes.includes(key));

        return (
            <Container className={this.props.className} style={this.props.style}>
                {propertyGroup.name && hideLabel === false && (<PropertyPopover property={propertyGroup} editMode={editMode}><GroupLabel>{camelCaseToTitle(propertyGroup.name)}</GroupLabel></PropertyPopover>)}
                <GroupContainer key={this.property?.name} className={inputMode}>
                    {properties.map((property, index) => {
                        return (
                            <InputContainer 
                                key={property.fullpath} 
                                property={property} 
                                disabled={this.props.disabled} 
                                editMode={this.props.editMode || false} 
                                onRemove={this.handleRemoveProperty}
                            />
                        )
                    })}
        
                    {this.props.editMode && (
                        <span style={{ marginBottom: '5px', display: 'flex' }}>
                            <Select
                                showSearch
                                style={{ width: 200, marginRight: '4px' }}
                                placeholder="Select type"
                                optionFilterProp="children"
                                onChange={(value) => { this.newPropertyType = String(value) as DynamicPropertyType }}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={onSearch}
                                filterOption={(input, option) => {
                                    let value = option.props.children as string;
                                    return value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {allAddTypes.map((key) => <Option key={key} value={key}>{key}</Option>)}
                            </Select>
                            <Input style={{ marginRight: '4px' }} placeholder="Property name" onChange={(e) => { this.newPropertyName = e.target.value }} />
                            <Button
                                disabled={!(this.newPropertyName && this.newPropertyType)}
                                onClick={() => this.handleAddProperty(this.newPropertyName, this.newPropertyType)}
                            >
                                Add Property
                            </Button>
                        </span>
                    )}
                </GroupContainer>
            </Container>
        );
    }
}

export default observer(InputGroup);
