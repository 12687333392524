import { IDynamicProperty } from ".";
import { DynamicPropertyType } from './DynamicPropertyType';
import TemplateStore, { TemplateSchema } from '../../Template';
import { DynamicProperty, DynamicPropertyEvent } from "./Property";
import store from '../../';

export default class Template extends DynamicProperty<string> {
  type = DynamicPropertyType.TEMPLATE;
  value = '';
  default = () => '';
  path: string;
  reserved = false;
  id: string;

  constructor(parent: IDynamicProperty<any>, name: string, value: string) {
    super(parent, name, value);
    this.value = value;
    this.id = value;
    // this.init();
  }

  get store(){
    return store.creative?.templates?.getById(this.value);
  }

  get data(): Partial<TemplateSchema>{
    return this.store ? this.store.data : {};
  }

  get label(){
    return this.store?.data.name || '';
  }

  getHostedURL = () => {
    return this.store?.getHostedURL();
  }

  getString = () => {
    return this.data?.name || '';
  }

  getPreviewData = () => {
    return this.getString();
  }

  getCSVData = () => {
    return this.data.name || '';
  }

  setUsingCSV = (value: string) => {
    let templateExists = store.creative.templates.getByName(value);
    if(templateExists) {
      this.set(templateExists.id);
    } else {
      this.throwCSVImportError(`Invalid template`);
    }
  }
}